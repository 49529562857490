.event_detail {
    max-width: 979px;
    margin: 0 auto;
    box-shadow: 0px 2px 1px #0000000F;
    border-radius: 5px;
    
}
.event_detail_header {
    padding: 33px 40px 26px 40px;
    background-image: url("../../../Assets/images/event_detail_bg_img.png");
    border-radius: 5px 5px 0px 0px;
    background-color: #4759A7;
}
.event_detail_header span {
    color: #fff;
    background-color: #ffffff1f;
    font: normal 14px/19px "Roboto";
    text-transform: uppercase;
    display: inline-block;
    height: 28px;
    padding: 6px 11px 3px;
    align-self: center;
    border-radius: 5px;
    margin-bottom: 15px;
}
.event_detail_header h1 {
    color: #fff;
    font: bold 21px/28px "Roboto";
    margin: 0px;
    max-width: 320px;
}
.event_detail_container {
    padding: 28px 37px 28px 40px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 5px 5px;
}
.event_detail_container_item{
    color: #6370AA;
    font: normal 16px/21px "Roboto";
    margin-bottom: 16px;
    align-items: center;
    display: flex;
}
.event_detail_container_item span {
    color: #5B5B5B;
    margin-left: 9px;
}
.event_attendee_tab {
    /* width: 195px; */
    color: #646670;
    font: normal 16px/21px "Roboto";
    box-shadow: 0px 8px 11px #00000008;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
}
.event_attendee_tab_icon {
    box-shadow: 0px 3px 12px #0000000D;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    object-fit: cover;
}
.event_detail_map{
    margin-bottom: 11px;
}
.event_attendee_tab_add{
    background-color: #F8F8F8;
    box-shadow: none;
    cursor: pointer;
}
.event_detail_container_item img {
    margin-left: 12px;
}
.event_detail_header img {
    margin-left: 8px;
}
.event_detail_container .info-card-detail-left{
    margin-left: 5px;
    max-width: none;
}
.event_detail_container_item p {
    margin: 0px;
    display: flex;
    white-space: nowrap;
    align-self: start;
}
.event_detail_container_item .MuiButton-label{
    color: #21BF73 !important;
}
.event_detail_container_item .MuiButtonBase-root{
    margin-left: 10px;
    max-height: 30px;
    align-self: center;
}
.al_ssel{
    align-self: center !important;
}
.select_parents{
    text-align: end;
}
.select_parents .upload_btns{
    background-color: #BF3354;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto"!important;
    width: 163px;
    height: 38px;
    margin-top: 67px;
    color: #FFFFFF;
    margin-right:15px;
}
.select_parents .ok_btn{
    background-color: #21BF73;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto"!important;
    width: 163px;
    height: 38px;
    margin-top: 67px;
    color: #FFFFFF;
}
.event_title {
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.event_title span {
    font: normal 14px/19px "Roboto" !important;
}
.event_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1800px;
}
.event_attendee_tab:not(.event_attendee_tab_add) > div {
    max-width: calc(100% - 13px);
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;
    position: relative;
}
.event_attendee_tab > div img.auto-assignee-priority-icon.attendees_status_icon{
    position: absolute;
    right:0;
}
.add_company_dialog .text_area, .add_company_dialog .MuiInputBase-multiline {
    height: 100% !important;
    
}
.showType,.showTitle,.showAdd,.showDate,.showDis,.showcc,.showemailCC{
    display: none;
}
.cc_email_container{
    display: flex;
    align-items: flex-start;
}
.cc_email{
    display: block;
}
.event_detail_container_cc{
    margin-top:16px;
}
.info-card-detail-left.external_attendees_input{
    max-width: none;
}
.external_attendees_input .react-tag-input{
    padding: 10.5px 14px;
}
.external_attendees_input .react-tag-input:hover, .external_attendees_input .react-tag-input:focus{
    border-color: rgba(0, 0, 0, 0.87);
}
.external_attendees_input .react-tag-input input{
    padding: 0;
    height:19px;
}
.external_attendees_input .react-tag-input input::placeholder{
    color: #AAAAAA;
}
.event_detail_item_name .event_attendee_tab_icon{
    margin-right:0;
}
.event_attendee_tab.event_attendee_tab_add{
    background-color: rgba(248,248,248,0.5);
    border: 1px solid rgba(243,243,243,0.7);
    font: normal 13px/16px "Roboto";
    border-radius: 5px;
}
.event_attendee_tab.event_attendee_tab_add > div{
    display: flex;
    align-items: center;
} 
.event_attendee_tab.event_attendee_tab_add .event_attendee_tab_icon{
    height: 16px;
    width: 16px;
    margin-right: 6px;
}
.event_attendee_tab.event_attendee_tab_add.event_address_tab img,
.event_attendee_tab.event_attendee_tab_add.event_address_tab span{
    margin-left: 0px;
}
.event_detail_container_item.event_detail_address_item > p{
 align-self: center;
}
@media (max-width: 767px){
    .event_detail_container_cc{
        flex-wrap: wrap;
    }
    .event_detail_container_cc span{
        margin:0;
    }
    .event_detail_container_cc .cc_email_container{
        width:100%;
        margin-top:10px;
    }
}