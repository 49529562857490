.accept_email_invite h1 {
    color: #3A3A3A;
    font: bold 42px/55px "Roboto";
    margin-bottom: 15px;
    text-align: center;
}
.accept_email_invite {
    height: 100vh;
    display: flex;
    margin: 0px 10px;
    justify-content: center;
    flex-flow: column;
}
.accept_email_invite p {
    color: #303960;
    margin: 0px;
    font: normal 19px/25px "Roboto";
    text-align: center;
}