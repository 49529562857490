.cms_container {
  background-color: #ffffff;
  padding: 20px;
}

.cms_container .cms_label {
  color: #424242;
  font-size: 24px;
}

.cms_container .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.cms_container .ant-table-cell:last-child {
  text-align: right;
  cursor: pointer;
}

.cms_container .module_name {
  color: #333758;
  font-size: 20px;
  text-align: left;
}

.cms_container .last_updated_by {
  color: #b9b9b9;
  font-size: 10px;
}

.cms_container .date_time {
  color: #2d2424;
  font-size: 10px;
}

.cms_container .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-direction: row-reverse !important;
}

.cms_container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transform: rotate(-90deg);
}

.cms_container .ant-collapse {
  border: none;
  background: transparent;
}

.cancel_inputbox {
  margin-right: 10px;
  width: -webkit-fill-available;
  border: 1px solid #e2e5f2;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
}

.item_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 10px;
}

.cancel_edit,
.cancel_delete {
  padding: 8px;
  cursor: pointer;
}
.cms_container .save_note_btn,
.cms_container .cancel_note_btn {
  font-size: 16px;
  color: #6a6a6a;
  border-radius: 4px;
  width: 120px;
}

.cms_container .save_note_btn {
  background-color: #21bf73;
  color: white;
  margin-left: 24px;
}

.cms_container .cancel_note_btn {
  border: 1px solid #bebebe;
  background: transparent;
}

.cms_container .save_note_btn:hover {
  background-color: #21bf73;
}

.cms_container .cancel_note_btn:hover {
  border: 1px solid #bebebe;
  background: transparent;
}

.cms_container .cms_rows {
  flex-flow: nowrap;
  width: 100%;
}

.cms_container .module_updated_date {
  display: flex;
  margin-left: auto;
  padding-right: 24px;
  align-items: center;
}

.cms_container .module_detail {
  position: relative;
  width: 100%;
}

.cms_container .text_input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: #aaaaaa;
  border: 1px solid #e2e5f2;
}

.cms_container .module_detail img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.cms_container .text_input_value {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: black;
  border: 1px solid #e2e5f2;
}

.cms_container .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e8e8e8;
}

.cms_container .ant-collapse > .ant-collapse-item:last-child {
  border-bottom: none;
}

.cms_container .ant-collapse > .ant-collapse-item:first-child {
  border-top: 1px solid #e8e8e8;
}

.cms_container .ant-collapse-content {
  border-top: none;
}

.cms_container .add_reason_cancel_note {
  color: #4759a7;
  margin-bottom: 10px;
  cursor: pointer;
}

.formControlLabel{
  height:40px;
  float: right;
}

.cms_container .MuiFormControlLabel-labelPlacementStart{
  margin-right:0 !important;
}

.customized-scrollbar-container {
  max-height: 210px;
  overflow-y: auto;
}

.customized-scrollbar-container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.customized-scrollbar-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}