.deleteServiceDialog .MuiDialog-paperWidthSm {
    max-width: 100% !important;
    width:40vw;
}
.deleteServiceDialog  .MuiDialogTitle-root{
    padding: 13px 10px 7px 10px;
}
.deleteServiceDialog h2{
    font: normal 16px/21px "Roboto";
    color: #505050;
}
.deleteServiceDialog .MuiDialogContent-root{
    padding: 0px 10px 8px 10px;
}
.deleteServiceDialog .MuiDialogContent-root p{
    color: #9C9C9C;
    font: normal 14px/19px "Roboto";
    margin: 0px;
}
.deleteServiceDialog .MuiDialogActions-root{
    padding: 0px 10px 17px 10px;
    justify-content: center;
}
.deleteServiceDialog .cancel,.deleteServiceDialog .delete{
    width: 130px;
    height: 35px;
    font: normal 16px/21px "Roboto"!important;
    text-transform: capitalize;
    color: #6A6A6A;
    border: 1px solid #BEBEBE;
}
.deleteServiceDialog .delete{
    border: none;
    background-color: #BF3354!important;
    color: #fff !important;
}
.services_btn_right{
    display: flex;
    /* justify-content: flex-end; */
}
.services_btn_right .add_service_main_btn{
    margin-right: 8px;
}
@media only screen and (max-width: 576px){
    .header-add-btn-service{
        display: block !important;
    }
    .services_btn_right .tableaddBtn{
        padding: 11px 18px 9px 17px !important;
    }
    .services_btn_right .tableaddBtn{
        padding: 10px 18px 10px 17px !important;
    }
}