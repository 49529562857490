@import url(https://cdn.syncfusion.com/ej2/material.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* common - font */
body *{
  font-family:"Roboto";
}

/* Buttons-Hover */
.green-btn:hover, .green-btn:focus{
  background-color: #12A45E !important;
} 
.primary-btn:hover, .primary-btn:focus{
  background-color: #21BF732E !important;
}
.white-btn:hover, .white-btn:focus{
  background-color: #F5F5F5 !important;
}
.grey-btn:hover, .grey-btn:focus{
  background-color: #30396057 !important;
}
.red-btn:hover, .red-btn:focus{
  background-color: #9F1B3A !important;
}
.disabled-btn{
  background-color: #21BF730F !important;
}
.yellow-btn:hover, .yellow-btn:focus{
  background-color:#dea403 !important;
}
.MuiButton-root.Mui-disabled{
  color:unset !important;
}
.MuiButton-root.add_btn{
  color:green !important;
}
.add_company_action .ok_btn.MuiButton-root.Mui-disabled{
  background-color: #21BF73;
}

/* location */
.pac-container.pac-logo::after{
  display:none !important;
}

/* error msg */
.error {
  color: red !important;
  font-size: 12px;
  display: block;
  min-height: 14px;
  line-height: 14px;
}

/* label */
.label.bl-label{
  margin-bottom: 6px !important;
  display: inline-block;
  margin-top:0;
}

/* custom scrollbar */
.custom_scrollbar > div:nth-child(3){
  right:5px !important;
  width:7px !important;
}
.custom_scrollbar > div:nth-child(3) > div{
  background-color: rgba(0,0,0,0.3) !important;
}
.custom_scrollbar > div:nth-child(3) > div:hover{
  background-color: rgba(0,0,0,0.5) !important;
}

/* pagination */
.mat-pagination{
  display: flex;
  justify-content: flex-end;
}
.mat-pagination ul li > button{
  font: 500 12px/14px "Roboto";
  color: #424242;
}
.mat-pagination .MuiSvgIcon-root{
  color: #424242 !important;
}
.mat-pagination ul li > button.MuiPaginationItem-page:hover {
  background-color: rgba(47, 61, 123, 0.2);
}
.mat-pagination ul li > button.MuiPaginationItem-page.Mui-selected{
  background-color: #2f3d7b;
  color:#ffffff;
}

/* error */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* height increase in modal */
.auto_incress .MuiDialog-paperWidthMd {
  min-height: 350px;
}

/* Tooltip */
.tooltip-container{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width:100%;
}

/* Table Cell Cursor */
.tablecell_container td{
  cursor: pointer;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none !important;
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.login_form_container{
    height: 100vh;
}
.login_form_div{
    padding: 45px 35px 45px 35px;
}
.login_form .logo{
    margin-bottom: 40px;
}
.login_form .login-header{
    font: bold 33px/44px "Roboto";
    margin: 0;
    margin-bottom: 38px;
    color: #424242;
}
.inputHeight{
    margin-bottom: 30px !important;
}
.mar-bot-10{
    margin-bottom: 3px !important;
}
.inputHeight, .MuiInputBase-root{
    height:50px !important;
    width: 100%;
}
.inputHeight input{
    padding: 13px 17px;
    font: normal 18px/24px "Roboto"  !important;
    color: #141A3B;
}
.loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
}
.loginCheckbox .MuiTypography-root, .forget_password{
    font: normal 14px/19px "Roboto" !important;
    color: #303960 !important;
    align-self: center;
    cursor: pointer;
}
.LoginBtn{
    width: 100% !important;
    padding: 17px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #21BF73 !important;
    box-shadow: none !important;
    margin-top: 20px !important;
}
.login_banner{
    background-image: url("/static/media/Group 145.f8f32214.png");
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.login_banner p{
    position: absolute;
    top: calc(50% - 16px);
    padding-left: 66px;
    font: normal 24px/31px "Roboto" !important;
    color: #F5F7FF;
    max-width: 428px;
    font-weight: 400;position: absolute;
    top: calc(50% - 16px);
    left: 50px;
    padding-left: 10px;
    border-left: 6px solid #21BF73;
    font: normal 24px/31px "Roboto" !important;
    color: #F5F7FF;
    max-width: 428px;
    font-weight: 400;
}
.login_banner .icon_small {
    position: absolute;
    left: 43px;
    top: 31px;
}
.login_banner span {
    position: absolute;
    right: 24px;
    color: #FFFFFF;
    bottom: 20px;
    max-width: 243px;
    font: normal 14px/19px "Roboto" !important;
}
.repeat_grid_bottom{
    position: absolute;
    left: 25px;
    bottom: -53px;
}
.repeat_grid_right{
    position: absolute;
    top: 118px;
    right: -126px;
}
.social-link{
    padding: 0px 10px 12px 10px;
}
.social-link .mobile_app a{
    margin-left: 7.5px;
    display: inline-block;
}
.social-link .mobile_app a img{
    vertical-align: unset !important;
}
.social-link .mobile_app{
    display: flex;
}
.social-link .mobile_app span{
    font: normal 12px/16px "Roboto" !important;
    align-self: center;
}
.social_links a{
    font: normal 12px/16px "Roboto" !important;
    margin-right: 10px;
}
.err-msg{
    margin-left: 14px;
    color: red;
    font-size: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}
.social-link .mobile_app a{
    display: inline-flex;
    align-items: center;
}
@media (max-width: 600px){
    .login_banner{
        display:none;
    }
    .header-content .go-home-btn{
        padding: 12px 25px !important;
    }
}
/* .company-comp .MuiPaper-root{
    width: 99% !important;
    margin: 0 auto;
} */
.company-comp .Component-horizontalScrollContainer-22{
    width: 99%;
    margin: 0 auto;
}

.add_company_dialog .MuiDialog-paperWidthMd{
    max-width: 680px;
}
.closeBtn{
    position: absolute !important;
    right: 0;
}
.add_company_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #14193A !important;
    
  }
  .add_company_dialog .MuiSelect-outlined.MuiSelect-outlined{
      padding-left: 0px;
      background: transparent;
  }
#form-dialog-title h2{
    display: flex;
    width: 100%;
}
#form-dialog-title h2 img{ 
    margin-right: 10px;
}
#form-dialog-title h2 span{
    font: normal 18px/24px "Roboto" !important;
    align-self: center;
    color: #424242;
}
 .add_company_dialog .MuiDialogTitle-root{
    padding:20px 20px 26px 20px
}
.add_company_dialog .MuiDialogContent-root{
    padding: 0px 20px 20px 20px;
    overflow-x: hidden;
}
.add_company_dialog label{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
}
.add_company_dialog input, .customSelect{
    padding: 13px 17px;
    font: normal 16px/21px "Roboto"  !important;
    color: #424242;
}
.add_company_dialog  .MuiFormControl-marginDense, .add_company_dialog .MuiFormControl-root{
  margin-top: 2px;
}
.add_company_action{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.add_company_action .cancel_btn{
    min-width: 130px;
    color: #6A6A6A;
    height: 40px;
    border: 1px solid #BEBEBE;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
}
.add_company_action .ok_btn{
    background-color: #21BF73;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
}
.custom_upload{
    position: relative;
    height: 38px;
    margin-bottom: 10px;
}
.custom_upload .input_one {
    left: 0;
    height: 38px;
    position: absolute;
    width: 71%;
    opacity: 0;
    z-index: 999;
}
.custom_upload .input_two{
    position: absolute;
    padding: 8px 11px;
    left: 0;
    max-height: 38px;
    border: 1px solid #E2E5F2;
    border-radius: 5px;
    width: 71%;
    margin-bottom: 10px;
    font: normal 16px/21px "Roboto" !important;
    color: #B5B5B5;
    background-color: #FAF9FE;
}
.input_one{
    cursor: pointer;
}
.add_company_dialog .MuiFormControl-root, .add_company_dialog .MuiInputBase-root{
    height:38px !important;
    
}
.custom_upload button{
    position: absolute;
    right: 0;
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
    width: 145px;
    height: 39px;
}
.MuiTablePagination-toolbar p:first-of-type{
    display: block !important;
}
.Comp-Table .Component-horizontalScrollContainer-17,.Comp-Table .jss17{
    margin: 0px 5px;
}
.Comp-Table .MuiTableHead-root {
    border-radius: 5px;
}
.Comp-Table table {
    border-collapse: separate;
}
.address-field{
    max-width: 0;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;
}
.modal-err-msg{
    color: red;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
}
.Comp-Table .MuiFormControl-root, .Comp-Table .MuiOutlinedInput-root{
    height: 40px !important;
}
.filter-search{
    display: flex;
    justify-content: space-between;
    /* background: white; */
    padding: 17px 20px 17px 20px;
}
.showName,.showPhone,.showEmail,.showAddress,.showWebsite,.showDesc,.showSize{
    display: none;
}
.add_company_modal .custom_upload{
    margin-bottom:0;
}
.add_company_action .delete{
    background-color: #BF3354;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
  }
#form-dialog-title h2{
  align-items: center;
}
p.reject_text{
  font: normal 16px/21px "Roboto";
  color: #8D8D8D ;
  margin-bottom:5px;
}
.add_company_dialog .pricing_field input{
    padding: 13px 0;
}
.dollar_symbol{
    margin-right: 0 !important;
}
.dollar_symbol p{
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}
.remaining_days {
    margin-right:0;
    padding: 0 3px;
    font: normal 14px/19px "Roboto";
    color: #979797;
}
.trail_field{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 13px 15px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trail_field .ant-picker{
    background: transparent;
}
.trail_field .ant-picker-input{
    max-width:90px !important;
}
.trail_field .ant-picker-input input{
    font: normal 14px/19px "Roboto";
    color: #404040;
}
.header-logo .switch_login_btn{
    border-radius: 5px;
    background-color: #F9F9F9;
    padding: 9px 11px;
    color: #303960;
    font: normal 14px/19px "Roboto";
    text-transform: none;
    margin-left: auto;
}
@media (max-width:959px){
    .trail_field_left{
        padding-bottom:0 !important; 
    }
    .trail_field_right{
        padding-top:0 !important;
    }
}
@media only screen and (max-width: 767px){
    .add_company_dialog .MuiDialog-paper{
        margin: 0;
        max-width: 100%;
      /* removed border-radius */
    }
    .custom_upload{
        height: auto;
    }
    .custom_upload .input_one{
        height: 100%;
    }
    .custom_upload .input_two{
        width: auto;
        position: unset;
    }
    .custom_upload button{
        position: unset;
        width: 100%;
    }
    .add_company_dialog .MuiDialog-paper{
        margin: 0 15px;
      }
    .create-service-dialog .MuiDialog-paper {
        margin: 15px;
    }
    .header-logo .switch_login_btn{
        margin-top: 10px;
        margin-left:0;
    }
}

/* company-details */
.comp_flex{
    max-width: 1082px;
    margin: 0px auto;
}
.comp-layout{
    background:#FFFFFF !important;
    padding: 23px 37px;
    /* margin-top: 85px; */
}
.header-comp{
    display: flex;
    background: #FCFCFC !important;
    border-radius: 5px;
    justify-content: space-between;
    padding: 13px 20px;
    margin-bottom: 30px;
}
.logo_btn{
font: normal 16px/21px "Roboto" !important;
color: #21BF73 !important;
background: #21BF730F !important;
margin-left: 38px !important;
border:1px solid #21BF73 !important;
border-radius: 5px;
}
.compCodeBtn{
    background:#303960;
}
.compCodeBtn span{
    font:bold 18px/24px "Roboto" !important;
    color: #303960;
}
.compCodeBtn span span{
    margin-left:4px;
}
.contentTitle{
    margin-bottom: 20px;
}
.header-logo{
    display: flex;
    padding:9px 36px;
}
.comp_descr{
    width: 875px;
    height: 89px;
}
.comp_name{
    font:bold 26px/34px "Roboto" !important;
}
.comp_edit_icon{
    margin-left: 15px;
    height: 14px;
    width: 14px;
}
.comp_content_edit_icon{
    /* margin-right: 10px; */
    height: 14px;
    width: 14px;
}
/* .bread-crumb-main{
    margin-bottom: 45px !important;
} */

/* company-detail */
.comp-detail .comp-layout{
    margin-top:45px;
    padding:0;
    border-radius:5px;
}
.comp-detail .header-logo{
    padding: 23px 37px 10px;
    align-items: center;
} 
.comp-detail .header-logo .profile_upload{
    padding:8px 0;
}
.comp-detail .logo_btn{
    margin-left:31px !important;
    text-transform: capitalize;
    border-radius:5px!important;
    padding: 7.5px 36px;
}
.comp-detail .header-logo .comp-image{
    width:150px;
    height:80px;
    display: flex;
    align-items: center;
}
.comp-detail .header-logo img {
    width:100%;
    height:100%;
    object-fit: contain;
}
.comp-detail .header-comp{
    padding:14px 37px;
    align-items: center;
}
.comp-name{
    display: inline-flex;
    align-items: center;
}
.comp-name img.comp-img{
    width:23px;
    height:22px;
}
.comp-detail .header-comp .custom-span{
    font: bold 26px/34px "Roboto";
    margin:0 14px 0 9px;
    color:#424242;
}
.comp-detail .header-comp .edit-input{
    margin:0 13px 0 9px;
}
.comp-detail .header-comp .edit-input input{
    font: bold 26px/34px "Roboto";
    color:#424242;
    padding: 5px 14px;
}
.comp-detail .header-comp .MuiInputBase-root, .comp-contact .MuiInputBase-root, .comp-description  .MuiInputBase-root{
    height: auto !important;
}
.comp-detail .compCodeBtn{
    padding: 9px 31px;
}
.comp-detail .compCodeBtn span{
    text-transform: capitalize;
    font: bold 18px/24px "Roboto" !important;
}
.comp-detail .comp-container{
    padding: 0 37px 100px;
}
.contact-title, .description-title{
    font: normal 16px/21px "Roboto";
    color:#303960;
    margin-bottom:8px;
}
.contact-content{
    display:flex;
    align-items: center;
}
.comp-contact .custom-span{
    margin: 0 10px;
    font: normal 16px/21px "Roboto";
    color:#2D2D2D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0px;
}
.comp-contact .edit-input{
    align-items: center;
    margin:0 5px;
}
.comp-contact .edit-input input{
    font: normal 16px/21px "Roboto";
    color:#2D2D2D;
    min-height:none;
    padding:5px 14px;
}
.comp-contact .edit-input .MuiFormControl-marginDense{
    margin:0;
}
.comp-contact .edit-input .MuiOutlinedInput-inputMarginDense{
    padding-top:4px;
    padding-bottom:4px;
}
.comp-contact .edit-input .customSelect{
    padding:0;
}
.comp-contact .edit-input button{
    margin-left:0;
} 
.comp-size{
    margin: 16px 0 11px;
}
.description-content{
    display: flex;
    align-items: center;
}
.description-edit{
    align-self:flex-start;
    margin-top:3px;
}
.comp-description{
    margin-bottom:11px;
}
.comp-description .edit-input input{
    padding:5px;
}
.description-content .custom-span{
    display: block;
    max-width:875px;
    padding-right: 20px;
    font: normal 16px/21px "Roboto";
    color:#5B5B5B;
}
.deleteServiceDialog button.delete:hover{
    background-color: #9F1B3A !important;
}
.add_company_action .ok_btn{
    color:#fff!important;
}

.comp_flex.comp-detail .header-logo-error.modal-err-msg{
    padding: 0px 37px 23px;
}
.reject_company_modal #form-dialog-title h2 span{
  font: bold 18px/24px "Roboto" !important;
  color: #424242;
}
.deleteServiceDialog.create-service-dialog .MuiDialog-paperWidthSm{
    max-width:400px !important;
}
.deleteServiceDialog.create-service-dialog .MuiDialogTitle-root {
    padding: 13px 20px 7px;
}
.deleteServiceDialog.create-service-dialog .MuiDialogContent-root {
    padding: 0px 20px 8px;
}
.deleteServiceDialog.create-service-dialog .MuiDialogActions-root {
    padding: 0px 20px 17px;
}
.deleteServiceDialog .MuiDialog-container{
    height: 100%;
    outline: 0;
    width: 100%;
}
.create-service-dialog .ok_btn{
    background-color: #21BF73;
    color: #FFFFFF !important;
    text-transform: capitalize;
    min-width:130px
}
.billing-detail-heading{
    font: 700 17px/23px "Roboto";
    margin-bottom: 0;
    color: #424242;
}
.comp-detail .header-comp.billing-header{
    margin: 0 -37px 20px;
}
.billing-container .comp-contact .custom-span {
    margin: 0;
    margin-right: 10px;
}
.billing-container .trail_field{
    margin-right: 10px;
}
.billing-container .trail_field .ant-picker{
    margin-right: 10px;
}
.comp-detail span.custom-span{
    min-height: 15px;
}
.comp-detail .billing-container .edit-input{
    margin:0;
}
@media (max-width: 767px){
    .comp-detail .header-comp,.comp-detail .header-logo{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
    .comp-detail .header-logo button.logo_btn{
        margin:10px auto 0 0!important;
    }
    .comp-detail .compCodeBtn{
        margin-top:10px;
    }
}
@media (min-width:600px){
    .description-content .MuiOutlinedInput-multiline{
        min-width:350px;
    }
}
@media (max-width:599px){
    .add_company_dialog label.products-label {
        margin: 0 0 5px;
        display: inline-block;
    }
    .comp-layout{
        padding: 23px 15px;
    }
    .comp-detail .header-logo{
        padding: 23px 15px 10px;
    } 
    .comp-detail .header-comp{
        padding:14px 15px;
    }
    .comp-detail .comp-container{
        padding: 0 15px 100px;
    }
    .comp_flex.comp-detail .header-logo-error.modal-err-msg{
        padding: 0px 15px 23px;
    }
    .comp-detail .header-comp.billing-header{
        margin: 0 -15px 20px;
    }
    .trail_period_section .edit-input{
        flex-wrap: wrap;
    }
    .trail_period_section .trail_field{
        margin-right: 10px;
    }
    .trail_period_section .edit-input button.MuiButton-root{
        margin:0;
    }
}
@media (max-width:480px){
    .comp-detail .header-comp .custom-span{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 225px;
    }
    .add_company_dialog .MuiDialog-paper{
        margin: 0 5px;
    }
    .trail_field{
        flex-wrap: wrap;
        height:75px;
    }
    .trail_field .ant-picker{
        width:100%;
    }
    .add_company_modal .add_company_action button{
        padding: 6px 10px;
    }
    .create-service-dialog .MuiDialog-paper {
        margin: 5px;
    }
    .deleteServiceDialog.create-service-dialog .MuiDialogActions-root {
        padding: 0px 10px 17px;
    }
    .trail_field .custom-span {
        width: 100%;
    }
}
.task_notification {
  width: 350px !important;
  padding: 5px !important;
  border-radius: 39px !important;
  background-color: #303960 !important;
}
.task_notification .ant-notification-notice-icon {
  margin: 0px !important;
  position: relative !important;
  float: left;
  padding-right: 15px !important;
}
.task_notification .ant-notification-notice-message {
  margin: 0px;
  color: #fff;
  margin-left: 18px !important;
  padding-left: 6px !important;
  padding-top: 10px;
  font: normal 18px/24px "Roboto" !important;
}
.task_notification .ant-notification-notice-close {
  display: none;
}
.update-notify {
  background-color: #4759a7 !important;
}
.error-notify {
  background-color: #eb4f15 !important;
}
.success-notify {
  background-color: #21bf73 !important;
}
.ant-notification {
  z-index: 99999 !important;
}
@media (max-width: 480px) {
  div.ant-notification {
    margin-right: 5px;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon
    .ant-notification-notice-icon
    img {
    width: 45px;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    font: normal 14px/19px "Roboto" !important;
  }
  .task-detail-timeline-one {
    flex-wrap: wrap;
  }
  .task-detail-timeline-one .task-detail-timeline-dtime {
    width: 100%;
  }
}

.add_company_action .completed_btn {
    background: #21BF73 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #FFFFFF !important;
    height: 40px !important;
    text-align: center !important;
    outline: none !important;
    box-shadow: none !important;
}

.add_company_action .completed_btn2 {
    background: #039f54 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #03351d !important;
    height: 40px !important;
    text-align: center !important;
    outline: none !important;
    box-shadow: none !important;
}

.add_company_action .completed_btn:hover {
    background: #039f54 0% 0% no-repeat padding-box !important;
    color: #03351d !important;
}

.add_company_action .uncovered_btn {
    background: #f3d06f 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: white !important;
    height: 40px !important;
    left: 20px !important;
    outline: none;
}

.add_company_action .uncovered_btn2 {
    background: #f2b306 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #cc1837 !important;
    height: 40px !important;
    left: 20px !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.add_company_action .uncovered_btn:hover {
    background: #f2b306 0% 0% no-repeat padding-box !important;
    color: #CC1837 !important;
}

.add_company_action .cancelled_btn {
    background: #cc5065 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: white !important;
    height: 40px !important;
    left: 40px !important;
    box-shadow: none;
}

.add_company_action .cancelled_btn2 {
    background: #cc3f56 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #45040f !important;
    height: 40px !important;
    left: 40px !important;
    box-shadow: none;
}

.add_company_action .cancelled_btn:hover {
    background: #cc3f56 0% 0% no-repeat padding-box !important;
    color: #45040f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: none !important;
}

.Radio.Button:focus {
    outline: none !important;
    box-shadow: none !important;
}


.removeCiricleIcon{
    margin:auto !important;
}

.custom-ant-picker-select .ant-picker-suffix {
    display: none !important;
}

.to-label.MuiGrid-grid-xs-2 {
    flex-basis: 0% !important;
    align-self: center !important;
}

.time-picker-container.MuiGrid-grid-xs-2 {
    max-width: 21.666667% !important;
    flex-basis: 20.666667% !important;
}

.error-wrapper{
    display: none;
}

.mobile-label-start.MuiGrid-grid-xs-6{
    flex-grow: 0;
    max-width: 53%;
    flex-basis: 53%;
}

.mobile-label-end.MuiGrid-grid-xs-6{
    flex-grow: 0;
    max-width: 47%;
    flex-basis: 47%;
}
.date-time-separator.custom-ant-select .ant-picker{
    padding: 8px;
}
.error-messageor,
.error-wrapper{
    position: absolute;
}

.date-time-separator{
    padding-bottom: 10px;
}

.time-zone{
    padding-bottom: 10px;
}

#picker_addtask {
      padding: 0px !important;
}

.description,
.notes{
    margin-top: 10px !important;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .time-zone {
        margin-top: 82px !important;
    }

    .addtional_rep {
        margin-top: 52px !important;
    }
    
    .to-label {
        display: none !important;
    }

    .mobile-view-container {
        position: absolute !important;
        width: 100% !important;
        left: 18px !important;
    }

    .mobile-label-start {
        position: absolute;
        width: 100% !important;
        left: 18px !important;
    }
    .error-wrapper {
        display: contents;
    }

    .error-wrapper{
        position: relative !important;
    }
    .error-wrapper .error-message{
        position: absolute;
        width: -webkit-max-content !important;
        width: max-content !important;
    } 
    .error-messageor{
        display: none;
    }
    .start-date-picker-container.MuiGrid-grid-xs-3,
    .end-date-picker-container.MuiGrid-grid-xs-3 {
        max-width: 28% !important;
        flex-basis: 28% !important;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
    .to-label {
        display: none !important;
    }

    .time-picker-container.MuiGrid-grid-xs-2 {
        max-width: 40% !important;
        flex-basis: 45.666667% !important;
    }

    .start-date-picker-container.MuiGrid-grid-xs-3,
    .end-date-picker-container.MuiGrid-grid-xs-3 {
        max-width: 60% !important;
        flex-basis: 60% !important;
    }

    .mobile-label-end {
        z-index: 2;
        position: absolute;
        width: 100% !important;
        top: 90px;
    }

    .date-time-separator{
        position: relative !important;
    }

    .date-time-separator.MuiGrid-spacing-xs-2 > .MuiGrid-item{
        padding: 8px 8px 16px 8px;
    }
    .start-date-picker-container{
        padding-bottom: 32px !important;
    }
    .error-wrapper{
        display: none;
    }
}
.endDate:disabled {
    background-color: #e9ecef;
    opacity: 1;
    cursor: not-allowed;
}
.container_weekly {
  display: inline-table;
  padding-right: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.container_enddate{
  display: inline-table;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.fieldName {
  vertical-align: middle;
  padding: 20px;
}
.fieldName_monthly {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  margin-left:5px;
}
.fieldName_monthlys {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  margin-left:5px;
  opacity: 0.5;
}
.radioBtn{
  vertical-align: middle;
  padding: 5px;
  padding-right: 5px;
}

.cancel-task-img {
  text-align: center !important;
}

.closeBtn {
  position: absolute !important;
  right: 0;
  margin-top: 10px !important;
  padding-top: 30px !important;
}

.cancel-task_action {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.cancel-task_action .cancel_btn {
  min-width: 130px;
  color: #6A6A6A;
  height: 40px;
  border: 1px solid #BEBEBE;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
}

.cancel-task_action .ok_btn {
  background-color: #21BF73;
  box-shadow: none;
  color: #FFFFFF;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
}

.cancel-task-label {
  width: 156px !important;
  height: 37px !important;
  text-align: left !important;
  font: normal normal bold 28px/37px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #3A3A3A !important;
  opacity: 1 !important;
}

.cancel-task-msg {
  width: 363px !important;
  height: 57px !important;
  text-align: left !important;
  font: normal normal normal 20px/24px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #303960 !important;
  opacity: 1 !important;
  margin-top: 30px !important;
}


.waring-img {
  left: 700px !important;
  width: 188px !important;
  height: 165px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.cancel-radio-btn {
  width: 16px !important;
  height: 16px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #646670 !important;
  opacity: 1 !important;
}

.add-note {
  top: 40px !important;
  height: 24px !important;
  text-align: left !important;
  font: normal normal normal 18px/24px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #424242 !important;
  opacity: 1 !important;
}

.task_msg {
  width: 100% !important;
  height: 110px !important;
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  margin-top: 10px !important;
}

.reschedule_err_msg {
  color: red !important;
  background: #F3183D1C 0% 0% no-repeat padding-box !important;
  border-radius: 9px;
  margin-top: 30px;
  opacity: 0.1;
  padding: 15px 15px 15px 15px;
  width: 100% !important;
  height: 47px;
  text-align: left;
  font: normal normal normal 16px/21px Acumin Variable Concept !important;
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
}

.reschedule_err_msg .MuiInputBase-root {
  color: red !important
}

.register-form-middle {
  margin-top: 60px !important;
}

.add_company_action {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.add_company_action .ok_btn.MuiButton-root.Mui-disabled {
  background-color: #21BF73;
}

.add_company_action .ok_btn {
  background-color: #21BF73;
  box-shadow: none;
  color: #FFFFFF;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
}

.add_company_action .cancel_btn {
  min-width: 130px;
  color: #6A6A6A;
  height: 40px;
  border: 1px solid #BEBEBE;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
}

.add_company_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #14193a !important;
}

.add_company_dialog .MuiSelect-outlined.MuiSelect-outlined {
  padding-left: 0px;
  background: transparent;
}

.add_account_dialog .custom_upload button {
  text-transform: inherit !important;
  font: normal 14px/19px "Roboto" !important;
}

.add_account_dialog .MuiExpansionPanel-root {
  box-shadow: none !important;
}

.add_account_dialog .MuiInputBase-root {
  background: #fff;
}

.custom-ant-select .label {
  display: block;
  margin: 0px;
}

.label.bl-label {
  margin-bottom: 6px !important;
  display: inline-block;
  margin-top: 0;
}

.recurring_popup .MuiInputBase-root{
  max-height: 450px !important;
}

.reschedule-recurring .MuiDialog-paperWidthMd{
    height: 400px !important;
    width: 600px !important;
}
.reschedule-recurring .ant-picker-input{
    padding-left: 10px !important;
}


#header-navbar .MuiAppBar-colorPrimary {
    background-color: #fff;
    box-shadow: 0px 2px 2px #0000000D;
}
.header-logo img{
    width: 142px;
}
.grow{
    flex-grow: 1;
}
#header-navbar .MuiToolbar-gutters{
    padding: 0px 10px;
    height: 75px ;
}
#header-navbar .navbars{
    position: relative;
    margin-left: 15px;
    font: normal 16px/21px "Roboto";
    color: #303960;
    text-transform: capitalize;
    padding: 27px 10px 27px 10px;
    min-width: unset;
    text-decoration: none;
}
#header-navbar .navbars.new_custom_filter_div{
    font: normal 16px/21px "Roboto" !important;
    cursor: pointer;
}
#more-menu .MuiMenuItem-root{
    font: normal 16px/21px "Roboto" !important;
    color: #303960 !important;
}
#header-navbar .add_task_btn{
    background-color: #21BF73;
    font: normal 16px/21px "Roboto";
    text-transform: capitalize;
    padding: 11px 35px 7px 62px;
    margin-right: 18px;
    box-shadow: none;
}
#header-navbar .add_task_btn:before{
    content: url(/static/media/add_task.2191971a.svg);
    position: absolute;
    left: 4px;
    height: 32px;
    top: 4px;
    width: 32px;
}
#header-navbar .user_icon:after{
    content: url(/static/media/user_arrow.b5514a9d.svg);
    position: absolute;
    right: 0;
    width: 11px;
    height: 6px;
    top: 12px;
    left:62px
}
#header-navbar .add_task_btn_mobile{
    font: normal 24px "Roboto";
    color: #fff;
    border-radius: 5px;
    background-color: #21BF73;
    margin-right: 8px;
    height: 39px;
    width: 40px;
}
.mobile_menu_dialog #header-navbar{
    display: contents;
}
.mobile-menu{
    padding: 10px;
}
.mobile_menu_dialog .user_icon {
    min-width: auto !important; 
}
.user_icon.user_profile{
    margin-left:0;
}
.mobile_menu_dialog .user_icon img{
    
    width: 32px;
    height: 32px;
}
.mobile-menu .MuiList-padding{
    padding: 0px !important;
}
.mobile-menu-list {
    height: 40px;
    background-color: #FBFBFB !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #21BF73 !important;
}
.mobile-menu-list .MuiListItemText-root span{ 
    font: normal 16px/21px "Roboto";
    color: #424242;
}
.mobile-menu-expansion{
    background-color: #FBFBFB !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #21BF73 !important;
    box-shadow: none !important;
}
.mobile-menu-expansion p{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
}
.MuiExpansionPanel-root:before{
    opacity: 0 !important;
}


.header-profile .MuiTypography-root.user-name{
    color: #303960;
    font: normal 16px/21px "Roboto";
    margin-bottom: 5px;
}
.header-profile .MuiList-root .user-email{
    color: #B4B4B4;
    font: normal 12px "Roboto";
    margin-bottom: 21px;
}
.header-profile .MuiPopover-paper .MuiList-padding{
    padding: 16px;
    text-align: center;
    min-width: 234px;
}
.header-profile .MuiMenu-list a{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
}
.accountimg{
    margin-right: 8px;
}
.header-profile .MuiList-root .hr-line{
    margin-bottom: 9px;
}
.MuiButtonBase-root.logout-button{
    background-color: #21BF730F;
    border: 1px solid #21BF73;
    font: normal 14px "Roboto";
    color: #21BF73;
    text-transform: inherit;
    padding: 10px 30px 5px;
    box-shadow: none;
}
.MuiButtonBase-root.logout-button:hover, .MuiButtonBase-root.logout-button:focus {
    background-color: #21BF730F;
    border: 1px solid #21BF73;
    box-shadow: none;
}
#header-navbar .navbars.active::before, #header-navbar .navbars:hover::before {
    content: "";
    background-color: #303960;
    height: 3px;
    width: 100%;
    /* z-index: 9999; */
    position: absolute;
    bottom: 0;
    border-radius: 19px 19px 0px 0px;
    left: 0;
}
.labelAsterisk, .upload-doc-label .labelAsterisk{
    color: #F20A0A;
}
.switch-container .MuiFormControlLabel-label{
    font: normal 14px/19px "Roboto" !important;
    margin: 0px 10px 0px 0px;
}
.switch-container{
    padding: 17px 18px 16px 15px;
    background: #FFF8F8;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 4px;
}
.switch-container label{
    margin: 0px !important;
}
.add_task_dialog .MuiDialog-paperWidthMd {
    max-width: 1050px !important;
    width: 1050px !important
}
.toDate, .fromDate{
    width: 100%;
}
.toDate .MuiInputBase-root, .fromDate .MuiInputBase-root{
    background-color: #F9F9F9;
    border-radius: 4px
}
.toDate input, .fromDate input{
    font: normal 14px/19px "Roboto" !important;
}
.labelEmergency{
    align-self: center;
}
.labelEmergency span{
    padding-left: 12px;
    font: normal 14px/19px "Roboto" !important;
}


.main_notification .MuiMenu-paper {
    max-width: 300px;
    overflow-y: hidden;
    padding: 12px 5px 12px 0;
    cursor:pointer;
}
.main_notification .notification_header{
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
    margin-bottom: 21px;
}
.main_notification .notification_header .notification_header_name{
    color: #A2A2A2;
    font: normal 14px/19px "Roboto" !important;
}
.main_notification .notification_header .notification_header_count{
    background-color: #29CC7E3B;
    height: 19px;
    width: 19px;
    border-radius: 10px;
    padding: 1px 6px;
    color: #2AC179;
    font: normal 14px/19px "Roboto" !important;
    align-self: flex-end;
}
.main_notification .notification_title{
    color: #303960;
    font: normal 14px/19px "Roboto" !important;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
}
.main_notification .notification_description{
    color: #ABABAB;
    font: normal 12px/15px "Roboto" !important;
    margin-bottom: 3px;
}
.main_notification .notification_hrs{
    color: #C4C4C4;
    font: normal 12px/15px "Roboto" !important;
   
}
.main_notification .notification_header .mark_all{
    color: #6C728A;
    font: normal 14px/19px "Roboto" !important;
    align-self: flex-end;
    cursor: pointer;
}
.main_notification .notification_card_div {
    margin: 0px 15px 10px 15px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
}
/* .main_notification .notification_card_div:nth-child(1){
    background-color: red;:nth-child(odd)
} */
.read_card::before {
    content: "";
    background-color: #21BF73;
    height: 100%;
    width: 3px;
    position: absolute;
    bottom: 0;
    border-radius: 0px 4px 4px 0px;
    left: -15px;
}
.main_notification .MuiList-padding{
    padding-bottom: 0px;
    padding-top: 0px;
}
.notification_read_check{
    padding: 0px 5px !important;
}
.notification_clear{
    padding: 4px 5px !important;
}
.notification_title_btns{
    display: flex;
    align-items: start;
}
.profile-pic{
    width: 44px;
    height: 44px;
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #E5E5E5;
    opacity: 1;
    border-radius: 50px;
    object-fit: cover;
}


.task_assignment_dialog .add_user_header {
    padding: 25px !important;
    background-color: #ffffff !important;
    margin-bottom: 50px !important;
}
.task_assignment_dialog .MuiDialog-paperWidthSm{
    max-width: 844px !important;
    width: 844px !important;
}
.task-img-width{
    width: 100%;
}
.tasking-assign-right h1 {
    margin-bottom: 7px;
    color: #3A3A3A;
    font: bold 28px/37px "Roboto"!important;
}
.tasking-assign-right p{
    color: #303960;
    font: normal 19px/25px "Roboto"!important;
    margin-bottom: 7px;
    display: flex;
}
.tasking-assign-right p img{
    margin-right: 7.5px;
    align-self: start;
}
.tasking-assign-right span{
    color: #7E7E7E;
    font: normal 16px/21px "Roboto"!important;
    margin-bottom: 38px;
    display: block;
}
.task_assign_action{
    justify-content: left;
    margin-top: 0px;
}
.task_assign_model_container{
    margin-bottom: 60px;
}
.task_assignment_header  h2 img{
    margin-right: 25px !important;
}
.task_assign_errror_msg{
color: #D27700;
/* height: 30px; */
background-color: #FFF6DC;
border-radius: 5px;
margin-bottom: 10px;
width: 100%;
max-width: 389px;
padding: 8px 10px 7px 10px;
font: normal 12px/14px "Roboto"!important;
}
.task_assign_errror_msg img{
    margin-right: 5px;
}

#header-navbar .add_task_btn_mobile{
    padding:0;
}

#header-navbar .add_task_btn:before{
    z-index:9;
}
.mobile-menu > nav > div.MuiListItem-root{
    padding:0;
    height:40px;
}
.mobile-menu > nav > div.MuiListItem-root a.navbars{
    padding:10px 15px 9px;
    font: 500 16px/21px "Roboto";
    color: #424242;
    width:100%;
}
.mobile_menu_dialog .user_icon img{
    margin:0 10px;
}
.MuiButtonBase-root.logout-button{
    padding: 4px 30px;
    font: normal 14px/19px "Roboto";
    color: #21BF73;
}
#header-navbar .add_task_btn{
    padding: 9px 35px 9px 62px;
}
.mobile-profile >  .MuiPopover-paper{
    top:50% !important;
    left:50% !important;
    transform: translate(-50%, -50%) !important;
}
.header-profile.mobile-profile .MuiPopover-paper .MuiList-padding{
    min-width: 285px;
}
.header-profile.mobile-profile .MuiMenu-list a{
    justify-content: center;
}
header .header-logo:hover{
    background: none;
}
.infinite_scroll::-webkit-scrollbar {
    width: 7px;
    padding-right:2px;
}
.infinite_scroll::-webkit-scrollbar-track {
    padding-right:2px;
}
.infinite_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0,0,0,0.3); 
} 
.infinite_scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.5); 
} 
.new_custom_filter_div.navbars.more-option label{
    margin:0;
}
.new_custom_filter_div.navbars.more-option img{
    margin-left: 7px;
    width: 12px;
    height: 10px;
    cursor: pointer;
}
.add_company_action.subscribe_btns, .add_company_action.subscribe_btn{
    margin-top:0;
    margin-bottom: 10px;
    flex-wrap: wrap;
}
.add_company_action.subscribe_btns{
    margin: 0 -7px 10px;
}
.add_company_action.subscribe_btns button{
    margin: 0 7px 7px;
}

.account-product-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14ch;
}

@media only screen and (max-width: 768px){
    .header-logo img{
        width: 104px;
    }
    .login_form_div{
        padding: 17px 10px;
    }
    .login_form .logo{
        width: 140px;
    }
    .login_form .login-header{
        font: bold 24px/31px "Roboto";
    }
}
@media only screen and (max-width: 1180px){
    #header-navbar .add_task_btn{
        display:none;
    }
}
@media only screen and (min-width: 1180px){
    #header-navbar .add_task_btn_mobile{
        display: none;
    }

}
@media only screen and (max-width: 1130px){
    #header-navbar .navbars, #header-navbar .user_icon{
        display: none;
    }
    #header-navbar .MuiToolbar-gutters{
        height: 67px;
        background-color: #FCFCFE;
    }
    
}
@media only screen and (min-width: 1130px){
     #header-navbar .mobile-menu-icon{
        display: none;
    }

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.recurring-text{
  background:#2B2B2B21;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  padding-left:5px;
  padding-right:5px;
  border-radius:5px
}

.recurring-text-detail{
  background:#2B2B2B21;
  padding-left:5px;
  padding-right:5px;
  border-radius:5px;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  max-width:100%;
  display: flex;
  min-width: 250px;
  width: 100%;
}
/* Dialog box */
.MuiDialog-paper{
    border-radius: 10px !important;
}
.dialog-box {
    text-align: center;
    top: 121px;
    left: 463px;
    width: 442px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: 2px solid #FF6F5E;
    opacity: 1;
}

.dialog-box h1 {
    font: normal normal bold 32px/43px "Roboto" !important;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
    margin-bottom: 10px;
}

.dialog-box p {
    font: normal normal 18px/24px "Roboto" !important;
    letter-spacing: 0px;
    color: #303960;
    opacity: 1;
    margin-bottom: 10px;
}

.dialog-box h5 {
    font: normal normal bold 18px/24px "Roboto" !important;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
}

.dialog-box .cancel_btn {
    color: #3A3A3A;
    right: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-red {
    color: #FFFFFF;
    background-color: #D5450F;
    left: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-red:hover {
    background-color: #D5450F;
}

.dialog-box .ok_btn-green {
    color: #FFFFFF;
    background-color: green;
    left: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-green:hover {
    background-color: green;
}

.reminderBtn {
  padding: 10px 20px 0px 0px !important
}

.custom_pagination{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.custom_select{
    display: flex;
    align-items: center;
}
.custom_select span{
    white-space: nowrap;
}
.custom_select .MuiInputBase-root{
    margin-left: 8px;
    margin-right: 32px;
}
.pagination_container{
    padding:0 !important;
}
.custom_pagination .MuiSelect-select.MuiSelect-select, .Acc-Table .custom_pagination .MuiInput-underline{
    background-color: transparent !important;
}
.calender-page {
    padding: 0px 38px;
}

.recurring_icon{
    padding-left: 5px
}
.calender-page .fc .fc-toolbar.fc-header-toolbar {
    margin: 0px;
    padding: 10px 0px;
}

.calender-page .fc .fc-button-primary {
    color: #5B5B5B !important;
    background: #F2F4FB !important;
    border: 1px solid #E2E5F2 !important;
    text-transform: capitalize;
    width: 75px;
    height: 30px;
    padding: 0px;
    margin-left: 24px;
    font: normal 14px/19px "Roboto" !important;
}

.calender-page .fc .fc-myEventButton-button {
    border-color: #2F3D7B !important;
    color:#2F3D7B !important;
    width: 60px !important;
}

.calender-page .fc .fc-eventTypeBtn-button {
    border-color: #2F3D7B !important;
    color:#2F3D7B !important;
    width: 112px !important;
}

.territory_filter .ant-select {
    width: 230px !important;
    top: 5px;
    left: 110px
}

.calender-page .fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #5B5B5B !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 12px #0000001C !important;
    text-transform: capitalize;
    width: 75px;
    border: none !important;
}

.calender-page .fc .fc-toolbar-title {
    font: bold 24px/31px "Roboto" !important;
    color: #303960 !important;
    margin-top: 5px; 
    margin-left: 60px !important;
}

.calender-page .ant-picker-calendar-mini {
    border-radius: 0px !important;
    margin-bottom: 30px;
}

.calender-page .fc-theme-standard .fc-scrollgrid {
    border: none !important;
}

.calender-page .fc .fc-scrollgrid-section>td {
    border: none !important;
}

.calender-page .fc-theme-standard td,
.fc-theme-standard th {
    border: 1px dashed #F1F1F1 !important;
    border-right-style: solid !important;
}

.calender-page .fc-day-other {
    background-color: #FCFCFC !important;
}

.calender-page .fc .fc-daygrid-day.fc-day-today {
    background-color: #F6FFFB;
    border-bottom: 2px solid #21BF73 !important;
}

.calender-page th.fc-col-header-cell.fc-day {
    background-color: #FCFCFC;
}

.calender-page .fc .fc-col-header-cell-cushion {
    font: normal 14px/19px "Roboto" !important;
    color: #5B5B5B;
    padding: 8px 0px 3px 0px;
}

.calender-page .fc-day-today .fc-daygrid-day-number {
    color: #21BF73 !important;
}

.calender-page .fc-view-harness {
    background-color: white;
}

.calender-page .fc-header-toolbar .fc-prev-button,
.fc-header-toolbar .fc-next-button {
    display: none !important;
}


.calender-page .ant-picker-calendar-mode-switch {
    display: none !important;
}

.calender-page .fc-daygrid-day-number {
    text-align: left !important;
    font: 400 16px/21px "Roboto" !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    padding: 10px !important;
}

.calender-page .fc-event-title {
    font: normal 10px/13px "Roboto" !important;
    text-overflow: ellipsis;
    color: #5B5B5B;
}

.calender-page .event-class {
    background-color: #3f51b5;
}

.calender-page .event-class .fc-event-title {
    font: normal 10px/13px "Roboto" !important;
    text-overflow: ellipsis;
    color: #fff;

}

.admin-only {
    display: none !important;
}

.calender-page .uncovered {
    border-left: 2px solid #D24856;
    background-color: #FFD0BE;
    color: #D24856;
}

.calender-page .inprogress {
    border-left: 2px solid #69F3B1;
    background-color: #F6FFFB;
    color: #69F3B1;
}

.calender-page .unassigned,
.calender-page .pending {
    border-left: 2px solid #617FFF;
    background-color: #E1E6F9;
    color: #617FFF;
}

.calender-page .assigned {
    border-left: 2px solid #303960;
    background-color: #CCD6FF;
    color: #303960;
}

.calender-page .completed {
    border-left: 2px solid #21BF73;
    background-color: #9be5c2;
    color: #21BF73;
}

.calender-page .cancelled {
    border-left: 2px solid #888888;
    background-color: #E2E5F2;
    color: #888888;
}

.calender-page .fc .fc-daygrid-event,
.calender-page .fc .fc-timegrid-event {
    margin-bottom: 1px;
    border-radius: 2px;
    padding: 4px 7px 0px 7px;
}

.calender-page .fc .fc-view-harness-active>.fc-view {
    padding: 20px 20px 0px 0px;
}

.calender-page .fc-daygrid-event-dot {
    display: none;
}
.event_titles {
    max-width: 40% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.event_titles span {
    font: normal 14px/19px "Roboto" !important;
}

.event_titles {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 300px !important;
}
.cal-task-selected-title {
    font: 500 14px/19px "Roboto" !important;
    margin-bottom: 11.5px;
    color: #424242;
    padding-bottom: 9.5px;
    border-bottom: 1px solid #F5F5F5;
}

.cal-task-selected-status {
    font: normal 10px/13px "Roboto" !important;
    margin-bottom: 6px;
    display: inline-block;
    color: #424242;
    padding: 3px 11px 1px 12px;
    border-radius: 2px;
}


.cal-task-selected-text {
    color: #424242;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
    cursor: pointer;
}

.cal-task-selected-assigne {
    color: #7B7B7B;
    font: normal 12px/16px "Roboto" !important;
    margin-bottom: 10px;
}

.cal-task-selected-assigne span {
    color: #434343;
    margin-left: 8px;
}

.cal-task-selected-date {
    color: #747A97;
    font: normal 14px/19px "Roboto" !important;
    margin-bottom: 19px;
}

.cal-selected-cancel {
    position: relative !important;
}

.cal-task-selected-btn {
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    font: normal 14px/19px "Roboto" !important;
    width: 50% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #2F3D7B 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.cal-task-auto-selected-btn {
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    font: normal 14px/19px "Roboto" !important;
    width: 100% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #2F3D7B 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.cal-task-cancel-btn {
    width: 50% !important;
    height: 38px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #BEBEBE !important;
    position: absolute !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    margin-left: 5px !important;
    text-transform: none !important;
}

.cal-task-cancel-selected-btn {
    text-transform: capitalize !important;
    font: normal 14px/19px "Roboto" !important;
    width: 100% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #BEBEBE !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.calender-left-container {
    padding: 20px 39px 20px 20px;
    background-color: #ffff;


}

.calender-left-grid {
    background-color: #fff;
    margin-top: 56px !important;
}

.calender-page .ant-picker-calendar-mini .ant-picker-content th,
.calender-page .ant-picker-cell .ant-picker-cell-inner {
    font: normal 14px/19px "Roboto" !important;
    /* color: #E2E5F2; */
}

.calender-page .ant-picker-cell-in-view,
.calender-page .ant-picker-calendar-mini .ant-picker-content th {
    color: #5B5B5B !important;
}

.calender-page .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: transparent;
    color: #21BF73;
}

.calender-page .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.calender-page .ant-picker-calendar .ant-picker-panel {
    border: none;
}

.calender-page .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    font: normal 14px/19px "Roboto" !important;
    padding-top: 2px;
}

.calender-page .fc .fc-daygrid-more-link {
    font: normal 14px/16px "Roboto" !important;
}

.calender-page .fc-v-event,
.calender-page .fc-h-event {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
}

.calender-page .fc .fc-timegrid-slot-label-cushion,
.calender-page .fc .fc-timegrid-axis-cushion {
    font: normal 14px/19px "Roboto" !important;
    color: #5B5B5B;
}

.calender-page .fc .fc-timegrid-slot-label,
.calender-page .fc .fc-timegrid-axis {
    background-color: #FCFCFC;
}

.fc-scrollgrid .fc-scrollgrid-section-body {
    display: none;
}

.fc-scrollgrid .fc-scrollgrid-section-liquid {
    display: contents;
}

.fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
}

.custom-ant-select .label {
    display: block;
    margin: 0px;
}

.custom-ant-select .ant-select-selector {
    align-items: center;
}

.custom-ant-select .ant-select-selection-item-content,
.custom-ant-select .ant-select-selection-item-content>span {
    display: inline-flex !important;
    align-items: center;
}

.custom-ant-select .ant-select-selection-item img {
    width: 20px !important;
    height: 20px !important;
}

.custom-ant-select .ant-select-selection-item .auto-assignee-priority-icon {
    width: 12px !important;
}

.custom-ant-select .ant-select {
    width: 100% !important;
    margin: 2px 0px 4px 0px;
    /* height: 38px; */
}

.add_event_cusautocomplete {
    min-height: 38px;
    margin-bottom: 4px;
}

.custom-ant-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    height: 38px !important;
}

.custom-ant-select .ant-select-show-search.ant-select-multiple .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    min-height: 38px;
}

.custom-ant-select .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #14193A;
}

.custom-ant-select .ant-select {
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}

.add_event_cusautocomplete .MuiFormControl-root {
    margin-top: 0px !important;
}

.pac-target-input {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    margin-bottom: 4px;
}

.pac-target-input::placeholder {
    color: #b2b2b2;
}

.pac-target-input:hover,
.pac-target-input:focus {
    border: 1px solid #14193A !important;
    outline: none;
}

/* .fc-daygrid-event-dot {
    margin: 0 4px !important;
    box-sizing: content-box !important;
    width: 2px !important;
    height: 17px !important;
    background-color: #4759A7;
    border-radius: 2px 0px 0px 2px !important;
    border: none !important;
} */
/* .fc-daygrid-event{
    background: #E1E6F9 0% 0% no-repeat padding-box;
    border-radius: 2px;
} */
.fc .fc-button-group {
    min-width: 260px;
}

.calender-page .fc .fc-col-header-cell-cushion {
    padding: 5px 0px 5px 0px;
}

.calender-page .fc .fc-daygrid-event,
.calender-page .fc .fc-timegrid-event {
    padding: 2px 7px;
}

.calender-custom-filters {
    position: absolute;
    padding-top: 20px;
}

.custom-ant-select .ant-picker {
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
    width: 100% !important;
    margin: 2px 0px 4px 0px;
    padding: 0 17px 0px 0px;
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    height: 38px !important;
}

.custom-ant-select .ant-picker:hover,
.custom-ant-select .ant-picker-focused {
    border-color: #14193A;
    border-right-width: 1px !important;
}

.email_cc .react-tag-input,
.location_field input {
    margin-top: 2px;
    margin-bottom: 4px;
    padding: 0;
}

.email_cc input {
    margin: 0;
}

.email_cc .react-tag-input input::placeholder,
.location_field input::placeholder {
    color: #bcbcbc;
}

.email_cc .react-tag-input,
.location_field input {
    border: 1px solid #E2E5F2;
}

.email_cc .react-tag-input:hover,
.location_field input:hover,
.location_field input:focus {
    border-color: #14193A;
    border-radius: 3px;
    outline: none;
}

.location_field.disabled input {
    cursor: not-allowed;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #14193A !important;
    box-shadow: none !important;
}

.register-form-middle .register-form-left,
.register-form-middle .register-form-right {
    padding-bottom: 0 !important;
}

.calender-page .fc-h-event .fc-event-title-container {
    line-height: normal;
}

.form_multi_mail_field .react-multi-email {
    border-radius: 5px;
    background-color: #F2F2F2;
    padding: 6px 13px;
    min-height: 38px;
    border-color: #E2E5F2;
    border-width: 0;
    margin-bottom: 15px;
    border: 1px solid transparent !important;
}

.form_multi_mail_field .react-multi-email.focused {
    border: 1px solid #14193A !important;
}

.form_multi_mail_field .react-multi-email>div {
    text-align: left;
    font: normal 14px/17px 'Roboto';
    letter-spacing: 0px;
    color: #424242;
    line-height: 0;
    padding: 2px 6px;
    background-color: #DBDBDB;
    margin: 2px;
    margin-right: 10px;
    margin-left: 0;
}

.form_multi_mail_field .react-multi-email input {
    background-color: transparent;
    padding: 2px 0 !important;
    font-size: 1rem;
    color: #424242;
}

.form_multi_mail_field .react-multi-email span {
    padding: 8px 0 !important;
    font-size: 1rem;
    color: rgb(0, 0, 0) !important;
    top: auto;
    left: auto;
}

.form_multi_mail_field .react-multi-email>span {
    color: rgba(0, 0, 0, 0.3) !important;
}

.cancel_reps .cancel_btn {
    max-width: 220px;
}
.carret_btn{
    margin-left: 0px !important;
    margin-right: 2px !important;
}

.reset_btn {
    margin-left: 240px;
    cursor: pointer;
    text-align: center;
}

.reset_btn_admin{
    margin-left: 12px;
    cursor: pointer;
    text-align: center; 
}

.event_type{
    display: inline-block;
    width: 96px !important;
}
.event_type_all{
    display: inline-block;
    width: 8px !important;
    margin-left: 5px;
    margin-right: 14px;
}

.cancel-reps {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.recurring_assigned {
    top: 471px;
    left: 210px;
    width: 12px;
    height: 16px;
    opacity: 1;
}

@media (min-width:768px) and (max-width:991px) {
    .calender-page {
        padding: 0 20px;
    }
}

@media only screen and (max-width:767px) {
    .calender-page .fc .fc-toolbar-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .calender-page .fc .fc-toolbar.fc-header-toolbar {
        display: block;
    }

    .calender-page {
        padding: 0px 20px;
    }

    .calender-page .fc .fc-view-harness-active>.fc-view {
        padding: 10px;
    }
}

@media (max-width:400px) {
    .calender-custom-filters {
        position: relative;
    }

    .calender-left-grid {
        margin-top: 0 !important;
    }
}
/* .account-comp .MuiPaper-root{
    width: 99% !important;
    margin: 0 auto;
}
.account-comp .Component-horizontalScrollContainer-22{
    width: 99%;
    margin: 0 auto;
} */
.body-bg-color{
    background-color: #f2f4fb;
    padding-bottom: 30px;
    min-height: 100vh;
}
.header-add-btn{
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
}
.table_action_btns img{
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Acc-Table{
    margin: 0 10px;
    border-radius: 10px;
    background-color: #ffffff;
}
.Acc-Table .MuiTableCell-head{
    background-color: rgb(234, 236, 251);
    color: rgb(90, 99, 162);
    font: normal 14px/19px "Roboto";
    border: 0px;
    padding: 19px 16px;
}
/* .Acc-Table .MuiToolbar-gutters{
    padding: 20px 18px;
} */
.Acc-Table .MuiTableHead-root{
    border-radius: 5px;
}
.Acc-Table table { border-collapse: separate; }
/* .Acc-Table td { border: solid 1px #000; } */
.Acc-Table th:first-child, .Acc-Table td:first-child  { border-top-left-radius: 5px; }
.Acc-Table th:first-child, .Acc-Table td:first-child  { border-bottom-left-radius: 5px; }
.Acc-Table th:last-child, .Acc-Table td:last-child  { border-top-right-radius: 5px; }
.Acc-Table th:last-child, .Acc-Table td:last-child { border-bottom-right-radius: 5px; }

.Acc-Table .Component-horizontalScrollContainer-16,.Acc-Table .jss16{
    margin: 0px 5px;
}
.Acc-Table td{
    border: none;
    font: normal 16px/19px "Roboto" !important;
    color: #303960;
    padding: 16px 13px 16px 16px;
}
.Acc-Table .table_container{
    margin: 0 5px;
}
.custom_filter{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    width: 106%;
    position: initial !important;
}
.Acc-Table .MuiFormControl-root, .Acc-Table .MuiOutlinedInput-root{
    height: 40px !important;
    border-radius: 5px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #14193A !important;
    
  }
.MuiTablePagination-toolbar .MuiInputBase-root{
width: auto !important;
}
.Component-paginationToolbar-8{
    width: auto !important;
}
.MuiDialog-paperWidthMd{
    width: 960px !important;
}
/* .Acc-Table .MuiTable-root{
    width: 99% !important;
    margin: 0 auto;
} */
.Desc_height .MuiInputBase-root{
    height: auto !important;
}
.add_contacts .MuiExpansionPanelSummary-content{
    display: flex;
    justify-content: space-between;
}
.department-expansion .MuiRadio-colorSecondary.Mui-checked,.MuiCheckbox-colorPrimary.Mui-checked {
    color: #21bf73 !important;
}
.add_dept_btn{
    width: 100%;
    margin-top: 10px !important;
    border: 1px dashed #E3E3E3 !important;
    height: 66px;
    background-color: #F4F4F54A !important;
}
.add_dept_btn .MuiButton-label{
    display: flex !important;
    flex-direction: column;
    color: #8D8D8D !important;
    font: normal 14px/19px "Roboto" !important;
    text-transform: capitalize !important;
}


.add_account_dialog  .custom_upload button{
    text-transform: inherit !important;
    font: normal 14px/19px "Roboto" !important;
}
.add_account_dialog .MuiExpansionPanel-root{
    box-shadow: none !important;
}
.department-expansion-text{
    font: normal 20px/26px "Roboto" !important;
    color: #424242;
}
.department-expansion .contact-expansion-text{
    font: normal 15px/20px "Roboto" !important;
    color: #354795;
    padding-left: 0px !important;
    text-transform: uppercase;
}
.add_more_btn{
    font: normal 14px/19px "Roboto" !important;
    color: #424242 !important;
    text-transform: inherit !important;
}
.remove_btn{
    font: normal 12px/16px "Roboto" !important;
    color: #ff1818 !important;
    letter-spacing: 0px;
    text-transform: inherit !important;
}
.add_account_dialog .MuiInputBase-root{
    background: #fff;
}
.contact-expansion .MuiExpansionPanelSummary-root{
    padding: 0px;
}
.contact-expansion .MuiExpansionPanelDetails-root {
    padding: 8px 0px;
}
.department-expansion .sub_select_input .MuiFormControl-root{
    margin: 0 !important;
}
.sub_select fieldset{
    border: 0 !important;
}
.sub_select_input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 0 !important;
}
.sub_select_input .MuiSelect-outlined.MuiSelect-outlined{
    padding-left: 10px !important;
    
}
.sub_select_input .sub_select {
    background-color: #E2E5F2 !important;
    border-radius: 0px 5px 5px 0px;
}

.sub_select_input .sub_select_edit {
    border-radius: 0px 5px 5px 0px;
}
.sub_select_edit fieldset{
    border: 0 !important;
}
.sub_select_edit input{
    color: #303960 !important;
}
.sub_select_input .MuiInputBase-root{
    padding-right: 0 !important;
}
.sub_select input{
    color: #303960 !important;
}
.add_account_check .MuiTypography-body1{
    font: normal 14px/19px "Roboto" !important;
    color: #424242 !important;
}
.account_custom_upload{
    width: 75%;
    position: absolute;
}
.account_custom_upload .MuiAutocomplete-endAdornment{
    display: none !important;
}

.account_custom_upload-half{
    width: 50%;
    float : left;
}
.hide {
    display: none;
}
.tableTitle{
    font: bold 24px/31px "Roboto" !important;
      color: #303960 !important;
      align-self: center !important;
  }
.tableaddBtn {
    font: normal 16px/21px "Roboto" !important;
    color: #303960 !important;
    padding: 11px 29px 9px 33px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    background-color: #30396029 !important;
  }
  .MuiInput-underline:before{
    border-bottom: 0px !important;
  }
  .Acc-Table .MuiInput-underline,.Comp-Table .MuiInput-underline{
    background: #F9F9F9 0% 0% no-repeat padding-box !important;
      border-radius: 5px !important;
      opacity: 1;
  }
  .show-act{
      display: block !important;
  }
  .hide-act{
    display: none !important;
    }
 .custom_filter .MuiSelect-select{
    padding: 10px 18px !important;
 }
 .MuiInput-underline:after{
     border: none !important;
 }
  .MTableToolbar-searchField-11 .Mui-disabled,.MTableToolbar-searchField-10 .Mui-disabled{
    display:none !important;
  }
  /* .jss11 .Mui-disabled,.jss10 .Mui-disabled{
    display:none !important;
  } */
  .MTableToolbar-title-10,.jss10,.MTableToolbar-title-9,.jss9{
      overflow: unset !important;
  }
  /* .MTableToolbar-title-10 .MuiFormControl-root,.jss10 .MuiFormControl-root,.MTableToolbar-title-9 .MuiFormControl-root,.jss9 .MuiFormControl-root{
    width: 125% !important;
} */
.MuiInputBase-root.MuiOutlinedInput-root.custom_filter.MuiInputBase-formControl {
    width: 125px;
}
.one-msg{
display: flex;
justify-content: flex-end;
}
.two-msg{
display: flex;
justify-content: space-between;
}
/* .Comp-Table .MTableToolbar-root-6,.Comp-Table .jss6,.Acc-Table .jss5,.Acc-Table .MTableToolbar-root-5{
    padding: 17px 20px 17px 20px !important;
    min-height: 0px !important;
} */
.filter-search .MuiSelect-select:focus{
    background-color: white !important;
}
  .Acc-Table .MuiSvgIcon-root{
    color:#D3D3D3;
  }
  .filter-class{
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .Acc-Table .MuiInputLabel-outlined.MuiInputLabel-shrink,.Comp-Table .MuiInputLabel-outlined.MuiInputLabel-shrink{
    display: none;
  }
  .Acc-Table .MuiTablePagination-caption,.Comp-Table .MuiTablePagination-caption, .Acc-Table .MuiTablePagination-select,.Acc-Table  .MuiTypography-caption{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
  }

  .custom_filter_label{
          font: normal 16px/21px "Roboto" !important;
    transform: translate(18px, 12px) scale(1) !important;
  }

.add_user_header .MuiIconButton-root:hover{
    background: none !important;
}
.Acc-Table .MuiInputBase-input,.Comp-Table .MuiInputBase-input{
    padding: 12px 0px;
    font: normal 16px/21px "Roboto";
    color: #303960;
}
.select_parent{
   padding: 18px 10px;
}
.select_parent .breadCrumbs{
    display: flex;
    margin-bottom: 18px;
}
.account-detail-header{
    display: flex;
    justify-content: space-between;
}
.account-detail-header.account-detail-title{
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.bread-crumb-back{
    height: 18px;
    align-self: center;
    margin-right: 10px;
}
.bread-crumb-main{
    font: normal 16px/21px "Roboto" !important;
}
.bread-crumb-main .bread-crumb-link{
    color: #8F8F8F;
}
.bread-crumb-main .bread-crumb-name{
    color: #4759A7;
    line-height: normal;
}
.account-detail-container{
    margin: 0 10px;
}
.account-expansion-text{
    font: 500 18px/24px "Roboto" !important;
    color: #424242;
}
.account-detail-container .account-expansion{
    box-shadow: 0px 2px 1px #0000000F !important;
    border-radius: 5px !important;
    margin-bottom: 30px;
}
.account-expansion .MuiExpansionPanelSummary-content.Mui-expanded{
    margin: 12px 0px;
}
.account-detail-container .MuiExpansionPanelSummary-root{
    padding: 0 20px;
}
.account-detail-container .MuiExpansionPanelDetails-root{
    padding: 17px 20px;
}
.info-card{
    width: 100%;
}
.info-card .info-card-sub-name{
    font: 500 16px/21px "Roboto" !important;
    color: #6370AA;
    margin-bottom: 5px;
}
.info-card-detail-last{
    margin-bottom: 0px !important;
}
.info-card-detail{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
/* .info-card-detail img{
    align-self: start;
} */
.info-card-detail-left .custom-span{
    font: normal 18px/24px "Roboto" !important;
    color: #2D2D2D;
}
.info-card-detail-left img {
    margin-right: 9px;
}
.info-card-detail-left .account-des-span{
    font: normal 16px/21px "Roboto" !important;
    margin-left: 0px;
    overflow-wrap: break-word;
    word-break: break-word;
}
.info-card-detail-left{
    display: flex;
    max-width: 287px;
    min-width: 250px;
    width: 100%;
}
.info-card-detail-recurring{
    display: flex;
    max-width: 100%;
    min-width: 250px;
    width: 100%;
}
.info-card-detail-left .edit-input, .info-card-detail-left .edit-input .MuiAutocomplete-root{
    width: 100%;
}
/* .info-card-detail-left .edit-input .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
    padding-right: 40px !important;
} */
.select_parent .upload_btn{
    right: 0;
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
    width: 163px;
    height: 41px;
}



.save_btn{
    right: 0;
    border: 1px solid #21BF73 !important;
    background-color: #21BF730F !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
    width: 110px;
    height: 38px;
}
/* .addnoteHeight{
    height:38px !important;
    width: 700px !important;
} */



.account-detail-container-right-btns{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.account-detail-container-right-btns .account-btns{
    text-transform: capitalize !important;
    font: normal 14px/19px "Roboto" !important;
    border: 1px solid #C5C5C5 !important;
    border-radius: 25px !important;
    background-color: #fff !important;
    width: 130px;
    height: 38px;
    margin-right: 20px !important;
}
.info-card-detail-right{
    display: flex;
}
.info-card .info-card-detail-right .info-card-sub-name{
    margin: 0 !important;
    margin-right: 10px !important;
}
.info-card-detail-two img{
    /* align-self: start; */
    margin-left: 11px;
}
.info-card-detail-right .custom-span{
    font: normal 18px/21px "Roboto" !important;
    color: #2D2D2D;
    overflow-wrap: break-word;
    word-break: break-word;
   
}
.account-detail-add-more{
    font: normal 16px/21px "Roboto" !important;
    color: #4759A7;
    margin-left: 8px;
}
.class-flex{
    display: flex;
    align-self: flex-end;
}
.info-card-detail-right-justify{
    justify-content: space-between;
}
.mar-bot-12{
    margin-bottom: 12px;
}
.info-card .info-card-detail-right .plus-task{
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    
    width: 80px;
    height: 33px;
    padding: 7px 8px 5px 8px;
}
.info-card .info-card-detail-right .plus-task span{
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
}
.account-detail-remove{
    font: normal 16px/21px "Roboto" !important;
    color: #6A6A6A;
}
.info-card-detail-right-last{
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F4FB;
    margin-bottom: 20px;
}
.account-detail .add_dept_btn{
    box-shadow: none;
    margin-top: -10px !important;
   margin-bottom: 30px;
   background-color: #D1D1D14A !important;
}
.account-detail .add-note-card{
    box-shadow: 0px 2px 1px #0000000F !important;
}
.add-note-card .add-note-card-title{
    padding: 15px 20px;
    border-bottom: 1px solid hsl(227, 53%, 97%);
}
.add-note-card .add-note-card-title span{
    font: normal 18px/24px "Roboto" !important;
    color: #424242;
}

.add-note-card .MuiCardContent-root:last-child{
    padding: 12px 20px 32px 20px !important;
}
.account-details-input{
    display: flex;
}
.account-details-input .MuiFormControl-root{
    margin-top: 0px !important;
    width: 100%;
    margin-right: 20px;
}
.account-detail-container .account-expansion:last-child{
    margin-bottom: 0px !important;
}
/* .MuiDialog-paperWidthLg{
    width:1000px;
}
.dept-header{
    background: #F8F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 50px;
    align-items: center;
    display: flex;
} */
.flex-div div{
    display: flex;
}
.edit-input{
    display: flex;
    align-items: flex-end;
}
/* .edit-input .MuiInputBase-root{
    height: 40px !important;
    width: 100%;
} */
.edit-input button.MuiButton-root{
    color: #21BF73 !important;
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}
.info-card-wo-add{
    display: flex;
    align-items: center;
}
.flexSpan{
    display: flex;
    margin-right: 10px;
}
.hide-dept{
    display:none !important;
}
.hide-edit{
    display:none !important;
}
.pac-container{
    z-index: 1400 !important;
}
.nameFlex, .displayFlex{
    display: flex;
}

.acc-modal-err-msg {
    color: red;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
    display: block;
}


.account_quick_view_dialog .MuiDialog-paperWidthLg{
    max-width: 948px !important;
    width: 948px;
}
.quickItem {
    margin-bottom: 17px;
}
.quickItemName {
    color: #929292;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
}
.quickItemValue{
    display: flex;
}
.addrs-img{
    align-self: start;
}
.quickItemValue img{
   
    margin-right: 8px
}
.quickItemValue .quickItemText {
    color: #5B5B5B;
    font: normal 16px/21px "Roboto" !important;
}
.getDirectionBtn{
    align-self: center;
}
.getDirectionBtn .select_parent{
    padding: 0px;
    float: right;
}
.account_quick_view_dialog .dept-header {
    color: #424242;
    font: normal 18px/24px "Roboto" !important;
    padding: 13px 13px 13px 35px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: #F8F8FF;
    margin-bottom: 26px;
}
.account-quick-exp-title{
    font: 500 16px/21px "Roboto" !important;
    color: #5B5B5B;
}
.account_quick_view_dialog .account-quick-exp {
    box-shadow: none !important;
    /* height: 40px; */
    margin-bottom: 15px !important;
    border-radius: 5px !important;
}
.plus-task-green{
    font: 500 16px/21px "Roboto" !important;
    color: #21BF73;
    margin-left: 15px;
}
.account_quick_view_dialog .MuiExpansionPanelDetails-root{
    padding: 0px !important;
    margin-top: 15px;
}
.account_quick_view_dialog  .MuiPaper-outlined{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35);
    transition: all .4s;
}

.account_quick_view_dialog .MuiCardContent-root{
    padding: 15px 15px 5px 15px !important;
}
.qucikDepView {
    margin-bottom: 10px;
}
.qucikDepView img{
    margin-right: 8.3px;
}
.qucikDepViewValue {
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    color: #424242;
}
.account_quick_view_dialog .MuiExpansionPanelSummary-root{
    min-height: 44px;
    background-color: #F9F6FF;
    border-radius: 5px;
}
.account_quick_view_dialog .MuiExpansionPanelSummary-root.Mui-expanded{
    min-height: 44px !important;
    height: 44px;
}
.account_quick_view_dialog .add_user_header{
    margin-bottom: 15px !important;
}
.gm-style-mtc, .gm-svpc {
    display: none;
}
.qucikDepView .qucikDepViewValue:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
}
.tableaddBtn {
    padding: 10px 29px 10px 33px !important;
}
.Acc-Table .MuiTableCell-head{
    font: 500 14px/19px "Roboto";
}
.website_title {
    max-width: 50% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.website_title span {
    font: normal 14px/19px "Roboto" !important;
}

.website_title {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 250px !important;
}

.website_title span {
    font: normal 14px/19px "Roboto" !important;
}

.website_title {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 250px !important;
}
.dept-container .MuiMenu-paper{
    box-shadow: 0px 3px 18px #00000014;
}
.align_start{
    align-self: start;
}
.custom_upload.add_account_name{
    margin-bottom: 5px ;
}
.add_account_name.custom_upload{
    display:flex;
    justify-content: space-between;
    height:auto !important;
}
.add_account_name_input{
    position: relative;
    width:75%;
}
.add_account_name_input .account_custom_upload{
    width: 100%;
    position: relative;
}
.add_team_btn button{
    position: relative;
    margin-bottom:4px
}
.register-form-top .register-form-left, .register-form-top .register-form-right{
    padding-bottom: 0 !important;
}
.info-card-img-align-start{
    align-self: start;
    margin-top: 2px;
}
.info-card-img-align-start.info-card-img-email{
    margin-top: 6px;
}
.info-card-img-align-start.info-card-img-website{
    margin-top: 4px;
}
.filter-search.account_detail_filter{
    padding: 0 0 15px;
}
.super-admin-tab.Acc-Table{
    background-color: transparent;
}
/* add department modal*/
.add_department_header h2{
    display: flex;
    align-items: center;
    width:100%
}
.add_department_header h2 span{
    font: normal 18px/24px "Roboto" !important;
    align-self: center;
    color: #424242;
}
.add_department_header h2 img {
    margin-right: 10px;
}
.add_department_content .txt_field{
    padding:0 !important;
}
.add_department_content .label{
    font: normal 16px/21px "Roboto" !important;
    color: #333758 !important;
}
.add_department_content .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87) !important;
    border-width: 1px;
}
.add_department_content .disabled-btn.ok_btn{
background-color: #21BF73 !important;
}
.account-detail-header.account-detail-title > .edit-input{
    width:100%;
    margin-bottom: 4px;
}
.account-detail-header.account-detail-title > .show-edit{
    margin-top: 4px;
}
.info-card-detail-left.info-email-detail-left{
    max-width: none;
}
.edit_autocomplete_parent .info-card-img-align-start{
    max-width: 18px;
    margin-left: 0;
}

@media (max-width:992px){
    
    .table_container td.MuiTableCell-body, .table_container th.MuiTableCell-head{
        max-width:none !important;
        white-space: nowrap !important;
    }
}
@media (min-width: 768px){
    span.add_team_btn {
        text-align: end;
    }
}
@media only screen and (max-width: 767px){
    .account_custom_upload{
        width: 100%;
        position: relative;
    } 
    .info-card-detail-right, .info-card-wo-add ,.account-detail-header{
        display: block;
    }
    .flexSpan, .info-card .info-card-detail-right .info-card-sub-name{
        margin-bottom: 5px !important;
    }
    .account-detail-header .tableTitle{
        margin-bottom: 10px !important;
    }
    .account-detail-add-more{
        margin-left: 0px;
        margin-bottom: 5px;
    }
    .displayFlex{
        display: flex;
        margin-bottom: 8px;
    }
    .add_account_name{
        flex-wrap:wrap;
    }
    .add_account_name.custom_upload{
        flex-wrap: wrap;
    }
    .add_account_name.custom_upload > span{
        width:100%;
    }
}

@media only screen and (max-width: 600px){
    .getDirectionBtn .select_parent{
        float: left;
        margin-bottom: 17px;
    }
    .header-dept{
        padding: 14px 20px 13px 20px !important;
    }  
    .header-add-btn-account{
        flex-wrap:wrap;
    }
    .header-add-btn-account .MuiTypography-h5{
        margin-bottom:10px;
    }
    .header-add-btn-account .services_btn_right{
        width:100%;
    }
    
}

 /* adduser button  */
.add_user_btn{
    width: 97%;
    margin-top: 28px !important;
    border: 1px dashed #D4D4D4 !important;
    border-radius: 5px !important;
    height: 50px;
    left: 16px;
    background-color: #FCFCFC 0% 0% no-repeat padding-box !important;
    opacity: 1;
}
.add_user_btn .MuiButton-label{
    display: flex !important;
    flex-direction: column;
    color: #8D8D8D !important;
    font: normal 14px/19px "Roboto" !important;
    /* text-transform: capitalize !important; */
}
/* add department name */
.add_dept_name{
    display: flex !important;
    font: Bold 16px/21px "Roboto";
    justify-content: flex-end !important;
    color: #21BF73 !important;
}
.dept_title{
    font: bold 18px/24px "Roboto";
    color: #424242;
}
.dept_name_grid{
   background: #FFFFFF;
   box-shadow: 0px 6px 10px #00000008;
   border: 1px solid #F2F2F2;
   border-radius: 10px;
   display: flex;
   padding: 12px 25px !important;
   margin: 17px 17px 27px;
   height: 45px;
}
.dept_name_text{
    text-align: left;
    font: normal 16px/21px "Roboto";
    color: #424242;
    margin-right: 6px;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dept-options{
    display:inline-flex;
    align-items: center;
}
.dept_edit_icon{
    width: 16px;
    height: 14px;
    /* margin-left: 49px; */
}
.dept_del_icon{
    width: 14px;
    height: 17px;
    margin-left: 15px;
}
.add_dept_btn{
   background: #FFFFFF;
   box-shadow: 0px 3px 18px #00000014;
   border-radius: 5px;
}
.header-dept{
    display: flex;
    background: #EEEEEE;
    padding: 14px 27px 13px 27px;
    border-radius: 5px;
    justify-content: space-between;
}
.super-admin-tab .ant-tabs-content-holder {
    padding: 15px 10px !important;
}
.dept_name_edit_del{
    display: flex;
}
.dept_name_grid span{
    color:#424242 !important;
}

.account-detail-container .info-card-url .info-card-detail-left .custom-span{
    word-break: break-all;
}
.add-user-container button{
  width:100%;
  left:0;
}

.parent_edit_name {
    display: flex;
    align-items: center;
}

.parent_edit_name p {
    margin: 0px 10px 0px 0px;
    font-weight: 600;
}

.edit_autocomplete_parent{
   display: flex; 
}
.edit_autocomplete_parent img {
    margin-left: 10px;
}
.edit_parent_icon {
    margin: 0px 8px;
}

.edit_autocomplete_parent .MuiInputBase-root {
    height: auto !important;
}
.edit_autocomplete_parent .MuiAutocomplete-endAdornment{
    display: none;
}
@media only screen and (max-width: 767px){
  .account-detail-container .tab-card-container .ant-tabs-tab{
    width:auto;
  }
}
@media (max-width: 599px){
    .parent_edit_name {
        display: block;
    }
    .register-form-left{
        padding-bottom:0 !important;
    }
    .register-form-right{
        padding-top: 0 !important;
    }
}
@media (max-width: 480px){
    .header-add-btn.account_header{
        flex-wrap: wrap;
    }
    .header-add-btn.account_header > .tableTitle{
        margin: 0 10px 10px 0;
    }
}





/* .account-detail .add_division_btn{
   margin-bottom: 30px;
   background-color: #EBF5F0 !important;
} */

.divisionTitle{
    text-align: left;
font: 500 21px/28px 'Roboto' !important;
letter-spacing: 0px !important;
color: #424242;
opacity: 1;
}
.level_select_parent .open_all_btn{
    width: 100px;
    height: 32px;
    background: #21BF730F 0% 0% no-repeat padding-box;
    border: 1px solid #21BF73;
    border-radius: 3px;
    opacity: 1;
    right: 0;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
}
.division-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.accordion-first-mgr{
    text-align: left;
    font: 400 16px 'Roboto' !important;
    letter-spacing: 0px;
    color: #424242;
}

.accordion-head{
    text-align: left;
    font: 500 16px/21px 'Roboto';
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}
.accordion-users{
    text-align: left;
    font: 16px'Roboto';
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 5px;
}


/* .accord-padd{
    padding: 10px;
} */
.account-detail .add_level_btn {
    background: #F6F2FF 0% 0% no-repeat padding-box !important;
}
.add_level_btn {
    width: 100%;
    border: 1px dashed #747A97 !important;
    height: 34px;
}
.add_level_btn .MuiButton-label{
    display: flex !important;
    flex-direction: column;
    color: #646670 !important;
    font: normal 14px/19px "Roboto" !important;
    text-transform: capitalize !important;
}


.division-left{
    display: flex !important;
    align-items: baseline;
}


.accordion-load{
    text-align: center;
    padding: 100px !important;
}
.add-level{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.level_save{
    /* width: 100px; */
    height: 32px;
    background: #21BF730F 0% 0% no-repeat padding-box !important;
    border: 1px solid #21BF73 !important;
    border-radius: 3px !important;
    opacity: 1;
    right: 0;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
}
.level_cancel{
    min-width: 25px !important;
    width: 25px;
    height: 32px;
    background: #bf21210f 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    border-radius: 3px !important;
    opacity: 1;
    right: 0;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: red !important;
}
.edit-input-level{
    align-items: center;
    display: flex;
    margin-bottom: 7px;
}
.edit-input-level button {
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}


/* .accordion-users::before{
    content: ".";
    width: 6px;
    height: 6px;
    background: #21BF73 0% 0% no-repeat padding-box;
    opacity: 1;
} */


.accordion-box .MuiAccordionSummary-root{
    min-height: 70px !important;
}


/* .accordion-box .MuiAccordionDetails-root{
    padding: 0px;
} */
.accordion-first .accordion-summary-first{
    padding: 0px !important;
    min-height: auto !important;
    margin: 15px 0px;
    height: auto !important;
}
.accord-details-area{
    display: block !important;
    padding: 0px !important;
}
.accordion-box .MuiPaper-elevation1{
    box-shadow: none !important;
    margin: 0px!important;
}
.accordion-first .accordion-summary-first .MuiAccordionSummary-content{
    margin: 0px !important;
}
.accordion-first .accordion-summary-first .MuiIconButton-edgeEnd{
    padding: 0px !important;
    margin: 0px !important;
}
.accordion-first:before{
    opacity: 0 !important;
}

.accord-details{
    display: block !important;
    padding: 15px 0px 15px 48px !important;
}
.accordion-box .accord-padd-ext{
    margin-bottom: 15px;
}
.accordion-box .MuiAccordion-rounded {
    border-radius: 2px !important;
}
.menu-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu-icon button{
    padding: 0px;
}
.accordion-box .MuiAccordionSummary-content.Mui-expanded{
    margin: 0px !important;
}
.accordion-box .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg) !important;
    align-self: flex-start;
}
.accordion-box .MuiAccordionSummary-expandIcon{
    transform: rotate(270deg) !important;
    align-self: flex-start;
}
.division-grid .MuiPaper-outlined{
    border: none;
    box-shadow: 0px 1px 2px #0000001A;
}
.division-grid .MuiCardContent-root{
    padding: 0px !important;
}
.add_division_btn .MuiButton-label {
    color: #6A6A6A !important;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize !important;
}

.division-grid .add_division_btn{
    width: 100%;
   
    border: 1px dashed #21BF73 !important;
    height: 50px;
    background-color: #EBF5F0 !important;
}
.add_division_btn_div{
    padding: 20px 15px 27px 15px;
}
.division-grid .MuiListItem-root{
    padding: 0px !important;
}
.division-grid .MuiList-padding {
    padding: 10px 27px !important;
}
.division-grid .division-list {
    margin: 0px !important;
}
.division-grid .division-list .MuiTypography-displayBlock{

    font: 500 18px/24px 'Roboto';
   
    color: #424242;

}
.division-grid .division-list .division-mgr{
    color: #646670;
    font: normal 14px/19px 'Roboto';
}
.division-grid .MuiListItemIcon-root{
    min-width: 0px;
    align-self: start;
    margin-top: 2px;
}
.division-grid .device-hub{
    color: #AFAFAF;
}
.device-hub{
    color: #21BF73;
    width: 15px !important;
    height: 15px !important;
    margin-right: 11px;
}
.add-level-btns{
    display: flex;
    /* width: 25%; */
    justify-content: space-evenly;
}
.accordion-acts{
    width: 100%;
    align-items: center;
    display: flex;
    
}
.accordion-acts .MuiAutocomplete-tag{
    height: auto;
}
.accordion-acts .level_cancel{
    margin-left: 10px;
}

.accordion-acts .MuiInputBase-root {
    min-height: 30px !important;
    height: auto !important;
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
}
.MuiAccordionDetails-root .cusautocomplete{
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E5F2;
    border-radius: 2px;
    min-height: 30px;
    margin: 0px 10px 0px 5px;
}
.MuiAccordionDetails-root .cusautocomplete .MuiChip-label{
    font: normal 12px/16px 'Roboto';
    color: #646670;
    padding-top: 2px;
}
.MuiAccordionDetails-root .cusautocomplete  .MuiChip-deleteIcon{
    width: 15px !important;
}
.accordion-details{
    display: flex;
    flex-direction: column;
    padding: 0px 16px 10px 50px !important;
}
/* .accordion-box .MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
} */
.accord-territory .MuiAccordionSummary-root{
    height: 45px !important;
    min-height: auto !important;
}
.division-grid .division-list .division-mgr span{
    color: #424242;
}
.add-level-input{
    width: 100%;
    /* margin-right: 10px !important; */
}
.add-level-input .MuiInputBase-root{
    height: 32px !important; 
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
.edit-input-level .MuiFormControl-root{
    width: 100%;
}
.active_level_nav{
    border-bottom: 1px solid #21BF73;
    background-color: #F5FBF8;
}
.active_level_nav .device-hub{
    color: #21BF73 !important;
}
.add-level button{
    margin-left: 10px;
}
.accordion-area .MuiAccordionSummary-root{
    border-bottom: 2px solid #9D9FDB;
}
.accordion-region .MuiAccordionSummary-root{
    border-bottom: 2px solid #37A2FF;
}
.accordion-territory {
    border-bottom: 2px solid #15DCDC;
}
.accordion-territory .MuiAccordionSummary-root{
    border: none !important;
}
.no-data-level{
    text-align: center;
    padding: 50px;
    font: 500 large "Roboto" !important;
}
.accordion-territory .MuiAutocomplete-clearIndicator{
    display: none !important;
}
/* button-hover */
.division-grid .add_division_btn.primary-btn{
    background-color: #21BF730F !important;
}
.division-grid .add_division_btn.primary-btn:hover, .division-grid .add_division_btn.primary-btn:focus{
    background-color: #21BF732E !important;
}
.accordion-box .MuiAccordionSummary-content.Mui-expanded{
    margin: 12px 0px !important;
}
.billing_detail_item{
    margin-bottom:15px;
}
.billing_detail_item > p{
    margin-bottom:5px;
}
.billing_top_detail_item{
    margin-top:0;
    margin-right: 10px;
    margin-bottom:0;
}
.billing_detail_item .edit-input{
    align-items: center;
}
.billing_detail_item > p > span:not(.edit-input){
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
}
.accordion-box .add_company_dialog .MuiFormControl-root, 
.accordion-box .add_company_dialog .MuiInputBase-root{
    width: 100%;
    max-width:256px;
}
.add_subscription_level_dialog .task_assign_model_container{
    margin-bottom:30px;
}
.add_subscription_level_dialog .task_assign_model_container > .MuiGrid-container{
    align-items: flex-start;
}
.add_subscription_level_dialog .task_assignment_header{
    margin-bottom:20px;
}
.add_subscription_action{
    margin-top:0;
}
.accordion-first .MuiAccordionSummary-root.Mui-focused{
  background-color: transparent;
}
.billing_detail_item .edit-input .MuiOutlinedInput-adornedStart{
 padding-left:17px;
}
.billing_detail_item .edit-input .MuiOutlinedInput-adornedStart input{
    padding-left: 3px;
}
.billing_detail_item .trail_field{
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
}
.billing_detail_item .trail_field:hover, .billing_detail_item .trail_field:focus{
    border: 1px solid #14193A !important;
}
@media only screen and (min-width:768px){
    .accordion-box{
        padding-right: 28px !important;
    }
    .accordion-box .accordion-first{
        margin-left: 52px;
    }
    .accordion-acts-span{
        min-width: 78px;
    }
    .billing_top_detail_item{
        min-width:310px;
    }
}
@media only screen and (max-width:767px){
    .accord-details{
        padding: 15px 0px 15px 20px !important;
    }
    .accordion-acts .level_cancel {
        margin: 10px 0px 0px 0px;
        width: 100%;
    }
    .MuiAccordionDetails-root .cusautocomplete{
        margin: 10px 0px 10px 0px;
    }
    .accordion-acts .level_save{
        width: 100%;
    }
    .accordion-acts{
        display: block;
    }
    .accordion-box .MuiAccordionSummary-root{
        /* min-height: 70px !important; */
        height: auto !important;
    }
    .accordion-box .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0px !important;
    }
    .accordion-box .MuiAccordionSummary-content{
        flex-wrap: wrap;
    }
    .billing_top_detail_item{
        margin-right: 0;
        width:100%;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width:480px){
    .billing_detail_item .billing-period .edit-input{
        flex-wrap: wrap;
        margin-top: 2px;
    }
    .billing-period .trail_field{
        height: 38px;
        flex-wrap: nowrap;
    }
    .billing_detail_item .billing-period .edit-input > span{
        width: 100%;
    }
    .billing_detail_item .billing-period .edit-input button.MuiButton-root{
        margin-left: 0;
    }
}
.new_filter_modal .MuiPaper-root {
    min-width: 278px;
    overflow: hidden;
    padding: 11px 0px;
    max-height: 320px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-top: 5px !important;
}
.new_filter_search {
    padding: 0px 19px 7px 17px;
    border-bottom: 1px solid #E9E9E9;
}
.new_filter_search .MuiFormControl-root{
    width: 100%;
    height: 19px;
    color: #5B5B5B;
    
    /* font: normal 14px/19px "Roboto"; */
}
.new_filter_search .MuiFormControl-root input{
    color: #5B5B5B;
    font: normal 16px/19px "Roboto";
}
.new_filter_list{
    padding: 3px 0px 0px 0px;
    overflow-y: auto;
    max-height: 300px;
    height: 100%;
}
.new_filter_lists{
    color: #424242;
    font: normal 16px/21px "Roboto";
    padding: 8px 19px;
    cursor: pointer;
}
.new_filter_modal .MuiPaper-root .new_filter_lists span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 268px;
    display: list-item;
}
.new_filter_lists:hover{
    background-color: #FBFBFB;
}
.new_custom_filter_div {
    color: #5B5B5B;
    font: normal 14px/19px "Roboto" !important;
    align-items: center;
    display: flex;
    /* cursor: pointer; */
}
.rightName{
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    cursor: pointer;
}
.new_custom_filter_div .carret{
    margin-left: 7px;
    width: 10px;
    height: 5px;
    cursor: pointer;
}
.filter_date_range.ant-picker-range .ant-picker-active-bar{
    margin-left: 0px !important;
}
.addUserBtn {
  color: #fff !important;
  background-color: #21bf73 !important;
}
.User-Table .userFilters{
  margin-right: 25px;
    width: 150px;
}
.user-detail-header{
  display: flex;
  position: relative;
}
.user-detail-header .profile_photo_edit{
  cursor: pointer;
  position: absolute;
  bottom: -5px;
  left: 37px;
}
.user-detail-container .MuiAccordionDetails-root{
  padding: 10px 10px 20px 10px !important;
}
.user-detail-container .Acc-Table{
  width: 100%;
    margin: 0;
}
.user-detail-container  .Acc-Table .table_container{
  margin: 0px;
}
.user-detail-container .upcoming-task .MuiAccordionSummary-expandIcon{
  display: none !important;
}
.user-role-width{
  width: 180px !important;
}
.user-profile-img {
  width: 50px;
  height: 50px;
  box-shadow: 0px 3px 20px #0000000F;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}
.user-profile-img-task{
  width: 40px;
  height: 40px;
}
.edit-input-header{
  display: flex;
  align-self: center;
}
.edit-input-header button{
  color: #21BF73 !important;
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}
.edit-profile-buttons{
  margin-right:10px;
}
.profile_upload{
  display: flex;
}
.profile_upload div{
  position: relative;;
}
.profile_upload .input_one {
  left: 0;
  height: 38px;
  width: 100%;
  /* position: absolute; */
  /* width: 71%; */
  opacity: 0;
  z-index: 999;
}
.user-detail-header .tableTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile_upload .input_two{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  padding: 8px 11px;
  top:0;
  left: 0;
  max-height: 38px;
  border: 1px solid #E2E5F2;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  font: normal 16px/21px "Roboto" !important;
  color: #B5B5B5;
  background-color: #FAF9FE;
}
.add_company_dialog .MuiFormControl-root, .add_company_dialog .MuiInputBase-root{
  height:38px !important;
  
}
.profile_upload .suc-button{
  /* position: absolute;
  right: 0; */
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #21BF73;
  background-color: #21BF730F;
  box-shadow: none;
  text-transform: capitalize;
  font: normal 16px/21px "Roboto" !important;
  color: #21BF73 !important;
  /* width: 145px; */
  height: 38px;
}
.profile_upload .fail-button{
  /* position: fixed;
  right: 47%; */
  height: 38px;
  margin-right: 10px;
}
.edit-flex{
  align-items: center !important;
}
.hide-edit-1{
  display: none;
}
.user-detail-container .Acc-Table .MuiTableRow-root td:nth-child(2) {
  padding-left: 42px !important;
  position: relative;
}
.new_custom_filter_div .leftName {
  font-weight: 600 !important;
  line-height: 18px;
}
.table_action_btns img{
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-detail-container .Acc-Table .MuiTableRow-root td:nth-child(2)::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #21BF73;
  left: 16px;
  position: absolute;
  top: calc(50% - 5px);
}
.update_status_btn_user {
  font: normal 14px/19px "Roboto" !important;
  color: #21BF73;
  text-align: center;
  padding: 7px 0px 4px 0px;
  cursor: pointer;
}
.ant-dropdown-menu li {
  cursor: pointer;
}
.date-color{
  color: #ACACAC !important;
}
.user-detail-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select_parent .switch_login_btn{
  border-radius: 5px;
  background-color: rgba(48, 57, 96, 0.16);
  padding: 10px 29px 10px 33px!important;
  color: #303960;
  font: normal 16px/21px "Roboto"!important;
  text-transform: none;
  font-weight: 600!important;
}

.select_parent .switch_login_btn:hover{
  background-color: rgba(48,57,96,.3411764705882353)!important;
}
@media only screen and (max-width:1288px){
  .User-Table .userFilters{
    margin-right: 15px;
      width: 150px;
      margin-bottom: 15px;
  }
}
@media only screen and (max-width:800px){
  .userfilter-search-dev{
    max-width: 60%;
  }
}
@media only screen and (max-width: 767px){
  .user-detail-wrapper{
    flex-wrap: wrap;
  }
  .user-detail-wrapper .user-detail-header{
    width:100%;
  }
  .select_parent .switch_login_btn{
    margin-top:10px;
    margin-right: auto;
  }
}
@media only screen and (max-width:567px){
  .filter-search{
    padding: 17px 5px;
  }
}


.signup-body-bg-color{
    background-color: #f2f4fb;
    min-height : 653px;
}
.signup-root{
    max-width: 820px;
    margin: auto;
  }
  .bullet{
    display: 'inline-block';
    margin: '0 2px';
    transform: 'scale(0.8)';
  }
  .title  {
    font-size: 14;
  }
  .pos {
    margin-bottom: 12;
  } 
  .card-header{
    background: transparent linear-gradient(101deg, #1E3C72 0%, #2A5298 100%) 0% 0% no-repeat padding-box;
  }
  .card-header h1,.card-header h2,.card-header h3,.card-header p{
    color: rgb(248, 244, 244);
    margin: 0px;
}
.pt-10{
    padding: 20px !important;
}

.loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
    margin-top: 10px;
}

.drop-zone-area {
    height: 100%;
}
.buttonGroup{
    float : right;
    padding: 0;
    margin-top: 10px;
}
.signupBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #21BF73 !important;
    box-shadow: none !important;
    margin-top: 20px !important;
}
.cancelBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #eae7e7!important;
    box-shadow: none !important;
    margin-top: 20px !important;
    margin: 5px;
}
.cancelBtn .MuiButton-label{
    color: black;
}
.signup-body{
    margin-top: 30px;
}

.signup-logo{
    display: flex;
    margin-bottom: 20px;
}
.signup-logo img{
    width:180px;
}

.header-content{
    padding: 20px 35px;
}

.signup-name{
    font: normal 16px "Roboto" !important;
    margin-bottom: 10px;
}

.pragraph-text{
    font: bold 22px "Roboto" !important;
}
.MuiDropzoneArea-root{
    border: 1px dashed #E2E5F2 !important;
    min-height: 118px !important;
    background-color: #FCFCFC;
    border-radius: 5px;
}

.dropzone-icon{
    text-align: center;
    padding: 13px;
}
.dropzone-icon img{
    margin-bottom: 6px;
}
.dropzone-inst{
    color: #B5B5B5;
    font: normal 14px "Roboto";
}

.dropzone-paragraph{
    color: #303960;
    font: normal 18px "Roboto" !important;
    margin-bottom: 7px; 
}
.dropzone-paragraph span {
    color: #3C57CB;
}
.cancel-button{
    border: 1px solid #BEBEBE !important;
    border-radius: 5px !important;
    color: #6A6A6A;
    background-color: #fff !important;
    padding: 12px 39px !important;
    margin-top: 0 !important;
}

.success-button{
    margin-left: 15px !important;
    padding: 12px !important;
    margin-top: 0 !important;
}

.success-button .MuiButton-label{
    font: normal 16px/17px "Roboto" !important;
}
.cancel-button .MuiButton-label{
    font: normal 16px/16px "Roboto" !important;
    color: #6A6A6A;
}

.MuiPaper-elevation1{
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35) !important;
}
section.MuiDropzoneArea-root.img_upload {
    border: 1px dashed #21BF73 !important;
    background-color: #F2FCF8;
}
section.MuiDropzoneArea-root.img_upload .dropzone-inst{
    color: #21BF73;
}
.img_upload .dropzone-inst img{
    display: inline-block !important;
}
p.dropzone-inst img {
    margin-right: 10px;
    margin-top: 2px;
    display: none;
}


@media (max-width: 767px) {
    .body-bg-color .signup-body {
        padding-left: 5px;
        padding-right: 5px;
    }
    .header-content {
        padding: 20px 20px;
    }
}
/* .emptypage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.error_main, .error-page {
  height: 100%;
margin: auto;
}
.rotate {
animation: rotation 2s;
}

.linear {
animation-timing-function: linear;
}

.infinite {
animation-iteration-count: infinite;
}
.error-page, .error-page_wrap {
display: flex;
align-items: center;
justify-content: center;
}
.error-page {
flex-direction: column;
}
.four {
color: #303960;
font-size: 210px;
font-family: 'Roboto';
margin: auto;
}
.error_head {
font: bold 24px "Roboto";
color: #424242;
}
.error-txt_p {
font: normal 16px "Roboto";
color: #808080;
margin-bottom: 30px;
}
.error-text {
text-align: center;
}
.error_back {
font: normal 16px "Roboto";
background-color: #303960;
color: #fff;
border: none;
padding: 12px 44px;
cursor: pointer;
}
@keyframes rotation {
from {
transform: rotate(0deg);
}
to {
transform: rotate(359deg);
}
}

.adduserform {
  position: relative;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 0 4px !important;
}
.defaultSelect {
  border: 1px solid #e2e5f2;
  border-radius: 5px;
  height: 38px !important;
  font: normal 16px/21px "Roboto" !important;
  color: #424242;
  padding: 9px 14px;
}

.MuiCircularProgress-colorPrimary {
  color: #3f51b5;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 99;
}

.add_company_dialog .add_user_header {
  background: #fcfcfe;
  padding: 16px 20px 16px 20px;
  margin-bottom: 5px;
}

.add_user_select_team label {
  margin-bottom: 0px;
  margin-right: 0px;
}
.add_user_select_team {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
 /* custom code start*/
 .drag-sample-wrapper {
    display: flex;

}
.e-schedule-toolbar-container .e-toolbar-right{
    display: none !important;
}
.schedule-container {
    padding-right: 10px;
    width: 100%;
}

.title-container {
    padding-bottom: 10px;
    position: relative;
}

.title-container button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #21BF73;
    color: #ffffff;
    font: normal 16px/21px "Roboto";
    text-transform: capitalize;
    padding: 5px 20px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.treeview-external-drag #waiting {
    height: 100%;
    padding: 0;
}

.treeview-external-drag #waitid {
    float: left;
    width: 13%;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-family: "Segoe UI";
    color: #f9920b;
    overflow: hidden;
    margin: 9px 0;
    padding: 0 2px 0 0;
}

.treeview-external-drag #waitdetails {
    width: 95%;
    float: left;
    height: 100%;
    padding: 0;
}

.title-text {
    font-size: 18px;
    margin: 0px;
    font-weight: bold;
    text-align: center;
}
 /* custom code end*/
.treeview-external-drag #waitlist {
    width: 100%;
    height: auto;
    font-weight: bold;
    font-family: "Segoe UI";
    font-size: 12px;
    color: #545554;
    padding: 5px 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag #waitcategory {
    height: 50%;
    font-family: "Segoe UI";
    font-size: 10px;
    color: #545554;
    opacity: 0.6;
    padding-left: 10px;
    padding-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag .e-list-text,
.treeview-external-drag.e-rtl .e-list-text,
.e-bigger .treeview-external-drag .e-list-text,
.e-bigger .treeview-external-drag.e-rtl .e-list-text {
    background: white;
    border: 0.5px solid #E1E7EC;
    height: 50px;
    line-height: 15px;
    padding: 0 5px;
    width: 220px;
}

.treeview-external-drag .e-list-parent,
.treeview-external-drag.e-rtl .e-list-parent,
.e-bigger .treeview-external-drag .e-list-parent,
.e-bigger .treeview-external-drag.e-rtl .e-list-parent {
    height: 100%;
    padding: 0 2px;
}

.treeview-external-drag .e-list-item,
.e-bigger .treeview-external-drag .e-list-item {
    height: 100%;
    padding: 0 0 5px 0;
}

.treeview-external-drag .e-fullrow,
.e-bigger .treeview-external-drag .e-fullrow {
    height: 55px;
}

.treeview-external-drag .e-list-item.e-hover>.e-fullrow,
.treeview-external-drag .e-list-item.e-active>.e-fullrow,
.treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-hover>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}

.treeview-external-drag .e-text-content,
.e-bigger .treeview-external-drag .e-text-content,
.treeview-external-drag.e-rtl .e-text-content,
.e-bigger .treeview-external-drag.e-rtl .e-text-content {
    padding: 0;
}
.e-drag-item.e-treeview.treeview-external-drag,
.e-bigger .e-drag-item.e-treeview.treeview-external-drag {
    padding: 0 !important;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
    width: 160px;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-left: 30px
}

.e-schedule.e-rtl.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-right: 30px
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category,
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
    padding: 5px
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-name {
    padding: 0 10px
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-name {
    font-size: 13px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-designation {
    font-size: 10px;
}

.e-schedule.schedule-drag-drop .specialist-image {
    width: 45px;
    height: 40px;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 45px;
}

.e-device-hover {
    background-color: #e0e0e0!important;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-image {
    display: none;
}

.e-location-container,.e-all-day-time-zone-row,.e-recurrenceeditor .e-editor,.e-description-row,.e-edit,.e-delete,.e-cell-popup,.e-start-end-row .e-date-icon,.e-start-end-row .e-time-icon{
    display: none !important;
}
.e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells), .e-schedule .e-timeline-month-view .e-work-cells:hover:not(.e-resource-group-cells) {
    background: #ffffff !important;
}
.e-schedule .e-timeline-view .e-selected-cell {
    background-color: #ffffff !important;
}
.e-subject-container{
    width: 100% !important;
}
.e-dialog-parent .e-label-top{
    color: #0014ff !important;
}
.e-footer-content .e-event-save{
    color: #0014ff !important;
}
.e-input[disabled], .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled{
    border-bottom-color: #949494 !important;
}
.e-quick-popup-wrapper .e-event-popup .e-popup-footer{
    display: block !important;
}
/* .schedule-drag-drop.e-schedule .template-wrap .specialist-image.alice {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robert {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robson {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.laura {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.nancy {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.margaret {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png');
} */

/* Treeview */
.schedule-task-container{
    padding:10px 21px 11px 8px;
}
.schedule-task-container .treeview-container{
    background-color: #fff;
    margin-right: 13px;
    min-width: 308px;
    max-height: calc(100vh - 110px);
    width: 308px;
    position: relative;
    margin-top: 38px;
}
.schedule-task-container .treeview-container .title-container{
    border-bottom: 1px solid #F5F5F5;
    padding:0;
}
.schedule-task-container .treeview-container h1.title-text{
    font: 500 18px/24px "Roboto";
    color:#424242;
    text-align: left;
    padding: 13px 14px 8.5px
}
.schedule-task-container .treeview-external-drag .e-list-parent{
    padding: 4px 13.5px 0px 14.5px;
    margin: 7px 5px 13px 0;
}
/* .schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar {
    width: 6px;
    position: relative;
    z-index: 9999;
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-track {
  background: #F5F5F5; 
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-thumb {
  background: #C2C2C2;
  border-radius:15px;
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
} */
.schedule-task-container .e-text-content{
    display: inline-flex;
    flex-direction: row-reverse;
}
.schedule-task-container .e-icon-expandable{
    transform: translate(0, -2px);
}
.schedule-task-container .e-treeview .e-list-item .e-icons.e-icon-collapsible{
    transform: translate(0, -2px) rotate(90deg);
}
.schedule-task-container .treeview-external-drag .e-list-item{
    padding: 10.5px 0; 
    border-bottom: 1px solid #F5F5F5;
    position: relative;
}
.schedule-task-container .treeview-external-drag .e-fullrow{
    height:100%;
    top:0;
}
.schedule-task-container .treeview-external-drag .e-text-content{
    border:0;
}
.schedule-task-container .treeview-external-drag .e-list-text{
    height:auto;
    width:100%;
    padding:0;
    border: none;
}
.schedule-task-container .task-name#waitlist{
    font: normal 16px/21px "Roboto";
    color:#424242;
    margin-bottom:10px;
    padding:0;
    white-space: normal;
}
.schedule-task-container .task-duration#waitcategory{
    font: normal 14px/19px "Roboto";
    color:#747A97;
    padding:0;
    white-space: normal;
}
/* Schedule-table */
.schedule-container .e-schedule{
    border:0;
    padding: 10px 21px 0 19px;
}
.schedule-container .title-container .title-text{
    font-family: "Roboto";
    color: #424242;
}
.schedule-container .e-schedule .e-schedule-toolbar .e-toolbar-items,
.schedule-container .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.schedule-container .e-schedule .e-timeline-view .e-child-node,
.schedule-container .e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table td:first-child,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table tbody td.e-time-slots{
    background-color:#FCFCFC;
}
.schedule-container .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table td, 
.schedule-container .e-schedule .e-timeline-month-view .e-date-header-wrap table td,
.schedule-container .e-schedule .e-timeline-view .e-work-cells, 
.schedule-container .e-schedule .e-timeline-month-view .e-work-cells,
.schedule-container .e-schedule .e-timeline-view .e-resource-cells, 
.schedule-container .e-schedule .e-timeline-month-view .e-resource-cells{
    border-color: #F1F1F1;
}
.schedule-container .e-schedule .e-schedule-toolbar{
    margin-bottom:0;
    box-shadow: none;
}
.schedule-container .e-schedule .e-timeline-view .e-work-cells{
    background-color: #fff;
}
.schedule-container .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table tbody td > span, .schedule-container .e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span{
    font: normal 12px/16px "Roboto";
    color:#5B5B5B;
    cursor: text;
}
/* .e-dlg-container.e-dlg-center-center.e-schedule-dialog-container {
    display: none !important;
} */
.schedule-container .e-schedule.schedule-drag-drop .e-resource-cells .specialist-name{
    font-family:"Roboto";
    color:#5B5B5B;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.schedule-container .e-schedule .e-timeline-view .e-appointment .e-subject,
.schedule-container .e-schedule .e-timeline-view .e-appointment .e-time{
    font-family:"Roboto";
}
.schedule-container .e-schedule.schedule-drag-drop .specialist-image{
    height:auto;
}
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category{
    display:flex;
    align-items: center;
}

.schedule-control-section .ant-select-selector input{
    font-family: "Roboto";
}

.control-section .ant-select-single .ant-select-selector{
    border:0 !important;
}
/* .schedule-container .e-toolbar-left .e-toolbar-item.e-date-range.e-overflow-show{
    display:none;
} */
.e-control.e-schedule.e-lib.schedule-drag-drop.task_list_container.e-keyboard{
    height:100vh !important;
    max-height: calc(100vh - 110px);
}
@media (max-width: 767px){
    .schedule-task-container{
        flex-wrap: wrap;
        padding: 10px 8px 11px 8px;
    }
    .schedule-task-container .treeview-container{
        width: 100%;
        margin-right: 0;
        margin-bottom:20px;
    }
    .schedule-task-container .treeview-container .empty_div{
        padding-bottom:10%;
    }
    .schedule-container{
        padding-right:0;
    }
}
@media (max-width: 550px) {
    .drag-sample-wrapper {
        display: block;
    }

    .schedule-container {
        padding-bottom: 10px
    }

    .treeview-external-drag.e-treeview,
    .e-bigger .treeview-external-drag.e-treeview {
        width: 225px;
    }

    .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
        position: relative !important;
    }
    .schedule-task-container .treeview-external-drag.e-treeview{
        width:100%;
    }
}
@media (max-width:480px){
    .schedule-control-section .control-section .ant-select{
        width: calc(100% - 16px) !important;
    }
}
.prod_list{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #0000001A;
    border-radius: 5px;
}
.prod_list_text_main{
    padding:21px 15px;
    font: 500 18px/24px "Roboto" !important;
    border-bottom:1px solid #EFEFEF;
    background:#FFFFFF;
}
 .active_level{
    border-bottom:1px solid #21BF73;
    background: #F5FBF8;
} 
.prod_cat_list_one{
    font: normal 16px/23px "Roboto" !important;
    padding: 15px 20px;
    display: flex;
}
.prod_cat_list_one:hover{
background-color: #F9FAFC !important;
}
.prod_list_one a{
    color:#424242 !important;
}
.prod_list_one{
    max-width: 250px;
    overflow: hidden;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.division_head{
    text-align: left;
    font: 500 17px/23px "Roboto";
    color: #FFFFFF;
    background-color: #303960;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
}
.divison_main{
    border-radius: 5px;
    background: #FFFFFF;
}
.prod_category_main_div{
    padding: 0px 10px;
}
.add_btn{
    right: 0;
    border: 1px solid #21BF73 !important;
    background-color: #21BF730F !important;
    box-shadow: none;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
    width: 291px;
    height: 41px;
    margin-top: 10px !important;
}
.txt_field{
    width: 291px;
    height: 50px;
    border: 1px solid #E2E5F2 !important;
    border-radius: 5px;
    padding: 5px 12px !important;
}
.add_btn_labels{
    padding: 8px 9px;
    text-align: left;
    font: normal 15px/20px "Roboto" !important;
}
.prod_cat_division_one{
    padding: 23px 0px;
}
.prod_edit_icon{
    margin-right: 15px;
    height: 15px;
    width: 15px;
}
.prod_del_icon{
    width: 11px;
    height: 14px;
}
.add_product_category_dialog .MuiDialog-paperWidthMd {
    max-width: 500px;
}
.prod_category_main_div .prod_list{
    cursor: pointer;
}
.deleteServiceDialog .MuiDialog-paperWidthSm {
    max-width: 100% !important;
    width:40vw;
}
.deleteServiceDialog  .MuiDialogTitle-root{
    padding: 13px 10px 7px 10px;
}
.deleteServiceDialog h2{
    font: normal 16px/21px "Roboto";
    color: #505050;
}
.deleteServiceDialog .MuiDialogContent-root{
    padding: 0px 10px 8px 10px;
}
.deleteServiceDialog .MuiDialogContent-root p{
    color: #9C9C9C;
    font: normal 14px/19px "Roboto";
    margin: 0px;
}
.deleteServiceDialog .MuiDialogActions-root{
    padding: 0px 10px 17px 10px;
    justify-content: center;
}
.deleteServiceDialog .cancel,.deleteServiceDialog .delete{
    width: 130px;
    height: 35px;
    font: normal 16px/21px "Roboto"!important;
    text-transform: capitalize;
    color: #6A6A6A;
    border: 1px solid #BEBEBE;
}
.deleteServiceDialog .delete{
    border: none;
    background-color: #BF3354!important;
    color: #fff !important;
}
.services_btn_right{
    display: flex;
    /* justify-content: flex-end; */
}
.services_btn_right .add_service_main_btn{
    margin-right: 8px;
}
@media only screen and (max-width: 576px){
    .header-add-btn-service{
        display: block !important;
    }
    .services_btn_right .tableaddBtn{
        padding: 11px 18px 9px 17px !important;
    }
    .services_btn_right .tableaddBtn{
        padding: 10px 18px 10px 17px !important;
    }
}
.taskfilter {
  display: flex;
  /* margin-bottom: 25px; */
  padding: 0 10px;
  flex-wrap: wrap;
}
.filterSearch {
  float: right;
  display: flex;
  justify-content: space-between;
  /* background: white; */
  padding: 17px 20px 17px 20px;
}
.error_massage {
  color: red;
  font-size: 14px;
}

.task_custom_filter_label {
  font: normal 16px/21px "Roboto" !important;
  color: #b2b2b2 !important;
  margin-right: 13px !important;
  align-self: center !important;
  margin-bottom: 25px;
}
.taskfilter .MuiFormControl-root,
.taskfilter .MuiAutocomplete-root {
  height: 40px !important;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 25px;
  background-color: #fff !important;
}
.taskfilter .ant-picker-range {
  width: 240px;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  color: #505050 !important;
  padding: 11px 13px 9px 13px;
  height: 40px;
}
.taskfilter .ant-picker:hover,
.ant-picker-focused {
  box-shadow: none !important;
  border-color: #14193a !important;
}
.taskfilter .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 0 !important;
}
.task-detail-header {
  margin-bottom: 22px;
}
.task-detail-stepper {
  display: flex;
  background: #fff;
  box-shadow: 0px 5px 35px #0000000a;
  border-radius: 5px;
  padding: 24px 0px 24px 20px;
}
.task-detail-stepper-item {
  position: relative;
  width: 25%;
  text-align: right;
}
.task-detail-stepper-pending:before {
  background-color: #617fff;
}
.task-detail-stepper-assigned:before {
  background-color: #303960;
}
.task-detail-stepper-inprogress:before {
  background-color: #69f3b1;
}
.task-detail-stepper-completed:before {
  background-color: #21bf73;
}
.task-detail-stepper-uncovered:before {
  background-color: #d24856;
}
.task-detail-stepper-gray:before {
  background-color: #888888;
}
.task-detail-stepper-diabled:before {
  background-color: #dbdbdb;
}
.task-detail-stepper-item:before {
  content: "";
  position: absolute;
  /* background-color: red; */
  left: 0;
  height: 4px;
  top: calc(50% - 2px);
  width: calc(100% - 66px);
  border-radius: 21px;
}
.task-detail-stepper-item img {
  margin-right: 10px;
}
.task-detail-stepper-item span {
  position: absolute;
  left: 0;
  bottom: 0;
  font: 500 16px/21px "Roboto" !important;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.task-detail-timeline .task-detail-timeline-header {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  border-bottom: 1px solid #cecece;
  padding-bottom: 12px;
  margin: 0px;
}
.task-detail-timeline-card {
  box-shadow: 0px 3px 6px #e1e1e129;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
  /* border-left: 8px solid #21BF73; */
  margin-bottom: 20px;
  position: relative;
}
.dark_blue_color {
  border-left: 8px solid #303960;
}
.light_green_color {
  border-left: 8px solid #67f3b1;
}
.dark_green_color {
  border-left: 8px solid #21bf73;
}
.gray_color {
  border-left: 8px solid #888888;
}
.light_blue_color {
  border-left: 8px solid #617fff;
}
.red_color {
  border-left: 8px solid #d24856;
}
.medium_blue_color {
  border-left: 8px solid #303960;
}
.medium_blue_color {
  border-left: 8px solid #303960;
}

.dark_blue_color:before {
  border: 2px solid #303960;
}
.light_green_color:before {
  border: 2px solid #67f3b1;
}
.dark_green_color:before {
  border: 2px solid #21bf73;
}
.gray_color:before {
  border: 2px solid #888888;
}
.light_blue_color:before {
  border: 2px solid #617fff;
}
.red_color:before {
  border: 2px solid #d24856;
}
.medium_blue_color:before {
  border: 2px solid #303960;
}
.medium_blue_color:before {
  border: 2px solid #303960;
}
.task-detail-timeline-one {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.task-detail-timeline-one-name {
  color: #424242;
  font: 500 16px/21px "Roboto" !important;
}
.task-detail-timeline-dtime {
  align-self: center;
  color: #aaaaaa;
  font: 500 14px/19px "Roboto" !important;
}
.task-detail-timeline-two .task-detail-timeline-two-status {
  color: #21bf73;
  font: 500 18px/24px "Roboto" !important;
  margin-bottom: 5px;
}
.task-detail-timeline-three-text {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  word-wrap: break-word !important;
}
.task-detail-timeline-main {
  padding: 20px 0px 0px 36px;
  margin-left: 36px;
  border-left: 1px solid #303960;
}
.task-detail-timeline-card:before {
  content: "";
  background-color: #dfe5ff;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* border: 2px solid #21BF73; */
  top: calc(50% - 12.5px);
  left: -56.5px;
}
.tab-card-container .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none !important;
}
.tab-card-container .ant-tabs-tab {
  margin: 0 !important;
  border: none !important;
  border-radius: 5px 5px 0px 0px !important;
  height: 38px;
  width: 160px;
  text-align: center;
  background-color: transparent !important;
  font: 500 16px/21px "Roboto" !important;
  box-shadow: none;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  color: #646670 !important;
  width: 100%;
  font-weight: 600;
}
.tab-card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #fff !important;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

.tab-card-container .ant-tabs > .ant-tabs-nav {
  margin: 0px !important;
}
.tab-card-container .ant-tabs {
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 30px;
}
.tab-card-container .ant-tabs-content-holder {
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 0px 5px 5px 5px;
}
.tab-card-container .ant-tabs-content-holder .tab-container-title {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  padding: 0px 10px 10px 12px;
  border-bottom: 1px solid #f2f4fb;
  margin-bottom: 12px;
}

.tab-container-expense {
  display: flex;
  padding: 0px 30px 11.5px 42px;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 15.5px;
}

.tab-container-expense-type {
  margin: 0;
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
  position: relative;
}

.tab-container-expense-doller {
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
  margin-right: 27px;
}
.tab-container-expense-remove {
  color: #6a6a6a;
  font: 500 16px/21px "Roboto" !important;
  cursor: pointer;
}
.tab-container-expense-user {
  display: flex;
  justify-content: space-between;
  padding: 0px 116px 14px 42px;
}
.tab-container-expense-user-name img {
  margin-right: 9px;
}
.tab-container-expense-user-name span {
  color: #707070;
  font: normal 18px/24px "Roboto" !important;
}
.tab-container-expense-user-name,
.tab-container-expense-dtime {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tab-container-expense-date {
  color: #707070;
  font: normal 18px/24px "Roboto" !important;
  margin-right: 20px;
}
.tab-container-expense-time {
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
}
.tab-container-expense-add-btn {
  margin: 0px 30px 18px 42px;
}
.tab-container-expense-add-btn button {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 15px 0px 10px 0px;
}
.tab-container-expense-add-btn button span {
  color: #949494;
  font: normal 14px/19px "Roboto" !important;
}
.total_expense {
  color: #303960;
  font: normal 16px/21px "Roboto" !important;
  padding-right: 30px;
  text-align: right;
}
.tab-container-expense-type::before {
  content: "";
  position: absolute;
  left: -32px;
  background-color: #c9fbe3;
  height: 16px;
  width: 16px;
  border: 1px solid #21bf73;
  border-radius: 50%;
}
.tab-card-container-timeline {
  display: flex;
  padding: 0px 12px;
}
.tab-card-container-timeline button {
  height: 38px;
  margin-left: 20px;
  background-color: #21bf730f;
  margin-top: 2px;
  border: 1px solid #21bf73;
  width: 110px;
}
.tab-card-container-timeline button span {
  font: normal 14px/19px "Roboto" !important;
  color: #21bf73;
  text-transform: capitalize;
}
.tab_product_container {
  padding: 0px 10px;
}
.task-detail .add_dept_btn {
  margin: 0px !important;
  border: 1px dashed #d4d4d4 !important;
  background-color: #fcfcfc !important;
  height: 50px;
}
.task-detail .add_dept_btn .MuiButton-label {
  color: #303960 !important;
  font: normal 16px/21px "Roboto" !important;
}
.info-card-detail-contact {
  border: 1px solid #f3f3f3;
  margin-bottom: 10px;
}
.info-card-detail-left.info-card-detail-left-contact {
  max-width: none;
  margin-bottom: 15px;
}
.info-card-detail-left.info-card-detail-left-contact img {
  align-self: start;
  margin-top: 5px;
}
span.custom-span.info-card-detail-left-contact-name {
  font: normal 14px/19px "Roboto" !important;
  width: 100%;
  padding: 6px 15px;
  background-color: #f3f3f3;
}
.info-card-detail-left-contact-data {
  padding: 0px 15px 15px 15px;
}
.task-detail-header-btns {
  display: flex;
}
.task-detail-header-btns .assigned-to-btn {
  font: normal 16px/21px "Roboto" !important;
  color: #878787;
  height: 40px;
  padding: 10px 13px;
  box-shadow: 0px 2px 2px #0000001c;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  /* max-width: 230px; */
  /* min-width: 270px; */
  /* justify-content: space-between; */
}
.assigned-to-btn-edit
  .edit-input
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiAutocomplete-inputRoot {
  padding-right: 25px !important;
}
.assigned-to-btn-edit .edit-input button {
  margin-left: 0px !important;
}
.task-detail-header-btns .assigned-to-btn span {
  white-space: nowrap;
}
.task_detail_drop_btn {
  display: flex;
}
.assigned-to-btn-edit {
  padding: 10px 0 10px 13px !important;
  min-width: 240px;
  align-items: center;
}
.assigned-to-btn-edit .MuiAutocomplete-endAdornment {
  display: none;
}
.assigned-to-btn-edit.assigned-to-btn
  .edit-input
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiAutocomplete-inputRoot {
  padding-right: 13px !important;
  padding-left: 0;
}
.task-detail-header-btns .custom-span {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 600;
  color: #303960 !important;
}
.task-detail-header-btns .assigned-to-btn img {
  margin-left: 10px;
}
.task-detail-header-btns .assigned-to-btn .assigned-to-btn-rep img {
  margin-left: 5px;
  width: 20px !important;
  height: 20px !important;
}
.add_company_dialog .task-assignee-person .MuiFormControl-root {
  margin-top: 0;
}
.task-detail-header-btns .update_status_btn {
  height: 40px;
  margin-left: 10px;
  background-color: #21bf730f;
  border: 1px solid #21bf73;
  padding: 9px 30px 11px 30px;
  /* margin-right: 10px; */
}
.task-detail-header-btns .update_status_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #21bf73;
  white-space: nowrap;
  text-transform: capitalize;
}

.task-detail-header-btns .revert_status_btn {
  height: 40px;
  color: #303960;
  border: 1px solid #303960;
  margin-left: 10px;
  padding: 18px;
}
.task-detail-header-btns .revert_status_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #303960;
  white-space: nowrap;
  text-transform: capitalize;
}

.task-detail-header-btns .task_detail_cancel_btn {
  height: 40px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid #bebebe;
  padding: 10px 40px;
}
.task-detail-header-btns .task_detail_cancel_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #6a6a6a;

  text-transform: capitalize;
}
.task_detail_dialog
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.task-detail-header-btns .assigned-to-btn .edit-input {
  width: 100%;
}
.cal-info-card-detail-left .edit-input {
  width: auto !important;
}
.cal-info-card-detail-left {
  max-width: none !important;
}
.task_priority_conflict .MuiDialog-paperWidthSm,
.task_emergency .MuiDialog-paperWidthSm {
  border: 2px solid #ff6f5e;
}
.task_conflict .MuiDialog-paperWidthSm {
  border: 2px solid #f6ac4c;
}
.auto-assignee-profile-pic {
  height: 30px !important;
  width: 30px !important;
}
.auto-assignee-profile-name {
  color: #565656;
  font: normal 14px/19px "Roboto" !important;
  margin-left: 8px;
}
.auto-assignee-profile-name.task-detail-assignee-name {
  text-overflow: ellipsis;
  max-width: 90px;
}
.auto-assignee-profile-name.assingee-to-other {
  display: flex;
  align-items: center;
}
.auto-assignee-name-field {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ant-select-item-option {
  align-items: center;
}
.new_custom_filters {
  display: flex;
  flex-wrap: wrap;
}
.new_custom_filters .new_custom_filter_div {
  margin-right: 20px;
}
.filter_date_range {
  padding: 0px !important;
  border: none !important;
}
.filter_date_range .ant-picker-input > input {
  text-align: center;
  padding: 0;
}
.filter_date_range .ant-picker-range .ant-picker-active-bar {
  margin-left: 0px !important;
}
.new_custom_filters .new_custom_filter_div {
  margin-right: 10px;
}
.priority_container {
  padding: 16px 13px;
  background: #ffffff;
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 5px;
}
.add_company_dialog .priority_container label {
  margin: 0;
  font: 500 14px/19px "Roboto";
  color: #333758;
}
.add_company_dialog .priority_container label span.MuiFormControlLabel-label {
  margin-right: 9px;
}
.priority_container .labelEmergency span {
  color: #fb4d4d;
  padding-left: 6px;
}
.priority_icon {
  margin-left: 5px;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper {
  display: flex;
  align-items: flex-start;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper > span {
  display: block;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper .priority_icon {
  margin-left: 0;
  margin-right: 5px;
}
.auto-assignee-priority-icon {
  margin-left: 5px;
  width: 15px;
  height: auto;
  object-fit: contain;
}
img.auto-assignee-priority-icon.attendees_status_icon {
  width: 20px;
}
.ant-picker-panel-container {
  margin: 15px 0;
}
@media (min-width: 960px) and (max-width: 1122px) {
  .calender-page {
    padding: 0 10px;
  }
  .calender-page .fc .fc-toolbar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75px;
  }
  .new_custom_filters .new_custom_filter_div {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 961px) {
  .assigned-to-btn-edit {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 960px) {
  .task-detail-header {
    display: block !important;
  }
  .task-detail-header .tableTitle {
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .filter-search {
    display: block;
  }
  .new_custom_filters .new_custom_filter_div {
    margin-bottom: 10px;
  }
  .tab-container-expense-user {
    padding: 0px 30px 14px 30px;
  }
  .task_detail_drop_btn {
    justify-content: space-between;
  }
  .taskfilter {
    display: block;
  }
  .task_custom_filter_label {
    margin-bottom: 5px;
  }
  .taskfilter .MuiFormControl-root,
  .taskfilter .MuiAutocomplete-root {
    margin-bottom: 15px;
  }
  .task-detail-stepper-item span {
    text-align: center;
    width: 100%;
    bottom: -21px;
  }
  .task-detail-stepper {
    padding: 15px 0px 33px 10px;
  }
  .tab-card-container .ant-tabs-tab {
    width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .task-detail-header-btns {
    display: initial;
  }
  .task-detail-header-btns .assigned-to-btn {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .update_status_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .revert_status_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .task_detail_cancel_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .update_status_btn,
  .task-detail-header-btns .revert_status_btn,
  .task-detail-header-btns .task_detail_cancel_btn {
    width: 100%;
  }
  .task_detail_drop_btn {
    display: block;
  }
  /* ant date picker */
  .ant-picker-dropdown .ant-picker-panels,
  .ant-picker-dropdown .ant-picker-datetime-panel {
    max-width: 280px;
    display: flex;
    flex-wrap: wrap !important;
  }
  .auto-assignee-profile-name {
    max-width: 100%;
  }
}
.new_custom_filter_div .carret {
  margin-left: 7px;
  width: 10px;
  height: 5px;
  cursor: pointer;
}

.task-detail-header .tableTitle img {
  width: 26px;
  height: 22px;
  cursor: pointer;
}
.task-detail-header .tableTitle img.priority_icon {
  margin-bottom: 4px;
}
.task-detail-header .tableTitle img.priority_icon {
  margin-bottom: 4px;
}



/* .body-bg-color {
  background-color: #f2f4fb;
  padding-bottom: 30px;
} */
.taskhide {
  display: none;
}
.taskblock {
  display: block;
}
.pendinghide {
  display: none;
}
.pendingblock {
  display: block;
}
.lazyLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-container {
  padding: 0px 10px;
}
.todays_tasks_grid_heading {
  display: flex;
  padding: 40px 0px 16px 0px;
  justify-content: space-between;
}
.todays_tasks_grid_heading .tableTitle {
  margin-right: 8px;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one{
  overflow: hidden;
  white-space: nowrap;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one span {
  color: #7e7e7e;
  align-self: center;
  font: normal 16px/21px "Roboto" !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one,
.todays_tasks_grid_heading .todays_tasks_grid_heading_two {
  display: flex;
}

.todays_tasks_grid_heading_two .MuiFormControl-root,
.todays_tasks_grid_heading_two .MuiOutlinedInput-root {
  height: 40px !important;
  border-radius: 5px;
  width: 144px;
  background-color: #fff;
}
.todays_tasks_grid_heading_two .MuiInputBase-input {
  font: normal 16px/21px "Roboto";
  color: #303960;
  padding: 12px 18px 8px;
}
.todays_tasks_grid_heading_select {
  margin-right: 15px !important;
}
.todays_tasks_grid_heading_two fieldset {
  border: none;
  box-shadow: 0px 1px 2px #30396030;
}
.todays_tasks_grid_heading_two input {
  padding: 0px !important;
}
.todays_tasks_grid_heading_two .MuiAutocomplete-inputRoot {
  padding: 12px 18px 8px !important;
}
.update_status_btn_common_overdue {
  background-color: #e81c2312 !important;
  color: #cc1837 !important;
}
.update_status_btn_common_overdue_pending {
  background-color: #bfbebd0f !important;
  color: #9a9a9a !important;
}
/* .todays_tasks_grid_list {
  margin: 10px 10px;
} */
.in_progress_btn span {
  margin-left: 13px;
  position: relative;
}
.update_status_btn_common_overdue span:before {
  background-color: #cc1837 !important;
}
.update_status_btn_common_overdue_pending span:before {
  background-color: #9f9f9f !important;
}
.update_status_dialog .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: unset !important;
}
.update_status_dialog .user_selected_color .MuiListItemText-root span {
  color: #f9b907 !important;
}
.dashboard_task_tab_common {
  width: 50%;
  border: 1px solid #4759a7;

  text-align: center;
  font: normal 16px/21px "Roboto" !important;
  padding: 9px 0px 7px 0px;
  color: #4759a7;
}
.dashboard_task_tab {
  margin-top: 8px;
  display: none;
}
.dashboard_task_tab_one {
  border-radius: 5px 0px 0px 5px;
}
.dashboard_task_tab_active {
  background-color: #4759a7 !important;
  color: #ffffff !important;
}
.dashboard_task_tab_two {
  border-radius: 0px 5px 5px 0px;
}
.in_progress_btn span:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #f9b907;
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: -13px;
}
.dashboard_task_card {
  background-color: #ffffff;
  padding: 12px 10px;
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 5px 5px 0px 5px;
  display: flex;
  margin-bottom: 35px;
  position: relative;
  justify-content: space-between;
}
.dashboard_task_card .dashboard_task_card_item_name {
  color: #303960;
  font: normal 16px/21px "Roboto" !important;
  margin-bottom: 8px;
  cursor: pointer;
}
.dashboard_task_card_item_flex {
  display: flex;
  justify-content: space-between;
}
.dashboard_task_card_item_one {
  width: 215px;
  /* margin-right: 34px; */
}
.hosp_loc {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.event_title_name {
  max-width: 200px;
  overflow: hidden ;
  text-overflow: ellipsis ;
  white-space: nowrap ;
}
.dashboard_event_description{
  max-width: 500px;
  overflow: hidden ;
  text-overflow: ellipsis ;
  white-space: nowrap ;
}
/* .dashboard_task_card_item_two {
  margin-right: 25px;
} */
.update_status_btn_common {
  height: 30px;
  margin: 0;
  width: 122px;
  font: normal 14px/19px "Roboto" !important;
  color: #303960;
  background-color: #f8f8f8;
  text-align: center;
  padding: 7px 0px 4px 0px;
  border-radius: 15px;
}
.in_progress_btn {
  background-color: #fffaeb;
  color: #f9b907;
  margin-bottom: 9px;
}
.hosp_loc span {
  font: normal 14px/19px "Roboto" !important;
}
.event_title_name span {
  font: normal 14px/19px "Roboto" !important;
}
.hosp_loc img {
  margin-right: 5px;
}
.update_status_btn_common img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.assigned_to_card {
  padding: 12px 17px;
  background-color: #f6f7fc;
  border-radius: 5px;
  max-width: 150px;
  /* margin-right: 23px; */
}
.assigned_to_card span {
  color: #7b7b7b;
  font: normal 14px/19px "Roboto" !important;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
  display: block;
}
.dashboard_task_card_item_two{
  align-self: center;
}
.assigned_to_card p {
  color: #434343;
  margin: 0px;
  font: normal 16px/21px "Roboto" !important;
}
.dashboard_task_card_item_start {
  color: #424242;
  font: 500 21px/28px "Roboto" !important;
  margin-bottom: 3px;
}
.dashboard_task_card_item_four {
  text-align: center;
  margin-right: 25px;
}
.dashboard_task_card_item_end,
.dashboard_task_card_item_to {
  color: #bbbbbb;
  font: normal 12px/16px "Roboto" !important;
  margin-bottom: 0px;
}
.dashboard_get_dir_btn {
  position: absolute;
  right: 0;
  bottom: -27px;
  width: 132px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;
 
}
.dashboard_get_dir_btns {
  position: absolute;
  right: 0;
  bottom: -27px;
  width: 200px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;

}
.dashboard_get_dir_btn_left{
  position: absolute;
  left: 0;
  bottom: -27px;
  width: 132px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;
 
}
.dashboard_get_dir_btn_left img {
  margin-right: 5px;
}
.dashboard_get_dir_btn_lefts {
  position: absolute;
  left: 0;
  right :25px;
  bottom: -27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: left;
  padding: 2px 6px 6px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90% !important;

}
.bold_carret {
  margin-top: 5px;
  cursor: pointer;
}
.cursor_pointer{
  cursor: pointer;
}
.dashboard_get_dir_btn span {
  font: 500 14px/19px "Roboto" !important;
  color: #2f3d7b;
  margin-left: 8.45px;
}
.dashboard_task_card_overdue {
  background-color: #f3183d1c;
}
.dashboard-container .dashboard-submit-btn {
  border: 1px solid #21bf73;
  background-color: rgba(33, 191, 115, 0.06);
  box-shadow: none;
  text-transform: capitalize;
  color: #21bf73;
  height: 50px;
  font: normal 20px/26px "Roboto" !important;
  margin-top: 7px;
  padding: 14px 0px 10px 0px;
}
.error_task {
  position: absolute;
  right: 0px;
  align-self: center;
  height: 100%;
  top: 0;
  display: flex;
  font: normal 22px/29px "Roboto" !important;
  justify-content: center;
  color: #ffffff;
  width: 25px;
  background-color: #f12642fa;
  border-radius: 0px 5px 0px 0px;
}
.error_task span {
  align-self: center;
}
.updateStatusCloseBtn {
  padding: 0px !important;
}
#form-dialog-title .updateStatusCloseBtn h2 img {
  margin-right: 20px;
  margin-top: 4px;
}
.update_status_dialog .MuiDialog-paperWidthSm {
  width: 680px;
}
.update_status_dialog ul {
  margin-top: 15px;
}
.update_status_dialog ul,
.update_status_dialog li {
  padding: 0px;
}
.update_status_dialog .MuiListItemText-root {
  margin: 0px;
}
.select_status_label {
  margin-top: 15px;
  display: block;
}
.update_status_dialog li {
  margin-right: 30px;
}
.update_status_dialog li .MuiListItemText-root span {
  font: normal 16px/21px "Roboto" !important;
  color: #333758;
}
.reassign-task {
  background-color: #fb7c4d0f !important;
  color: #fb7c4d !important;
}
.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.empty_div {
  margin-top: 10%;
}
.task_status{
  color:#FB7C4D !important
}
.dashboard_task_card_item_three{
  align-self: center;
}
.update_status_btn_common_overdue_pending_one{
  margin-bottom: 0px !important;
}
.dashboard_task_event .dashboard_task_card_item_name {
  margin: 0px;
  color: #424242 !important;
  font-weight: 600 !important;
}
.dashboard_task_event {
  display: flex;
  margin-bottom: 5px;
}
.dashboard_task_event .dashboard_task_event_location {
  color: #4759A7;
  font: normal 14px/19px "Roboto" !important;
  margin: 0px 0px 0px 9px;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}
.dashboard_task_event .dashboard_task_event_location img{
  margin-right: 3.5px;
}
.dashboard_task_event .dashboard_task_event_type {
  color: #B4B4B4;
  font: normal 12px/16px "Roboto" !important;
  align-self: center;
  margin-left: 9px;
}
.dashboard_task_event_text {
  margin-bottom: 5px !important;
}
.dashboard_task_event_attendees {
  color: #434343;
  font: normal 14px/19px "Roboto" !important;
}
.dashboard_task_event_attendees_plus {
  color: #AAAAAA;
}
.dashboard_task_event_attendee {
  color: #5B5B5B;
}
.dashboard_task_event_item_start {
  margin: 0px;
  font: 500 16px/21px "Roboto" !important;
  color: #3A3A3A;
}
.dashboard_task_filters{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}
.task_summary_item {
  text-align: center;
  /* padding: 11px 0px 4px 0px; */
  box-shadow: 0px 3px 13px #00000012;
  border-radius: 10px;
}
.task_summary_item_top {
  background-color: #EBEFFF;
  padding: 10.5px 0px;
  border-radius: 5px 5px 0px 0px;
}
.task_summary_item_bottom {
  padding: 12px 0px 4px 0px;
  background-color: #fff;
  border-radius: 0px 0px 5px 5px;
}
.task_summary_item_bottom p {
  color: #424242;
  font: 500 14px/19px "Roboto" !important;
  margin: 0px;
  text-transform: uppercase;
}
.task_summary_item_bottom span {
  color: #303960;
  font: 500 40px/53px "Roboto" !important;
}

/* footer added so removing parent padding */
.body-bg-color.dashboard-page{
  padding-bottom:116px;
  position: relative;
}
.in_progress_btn span:before{
  top:5px;
}
/* event_acceptance_actions */
.dashboard_task_card_item_three .event_acceptance_actions{
  margin:0;
  text-align: center;
}
.dashboard_task_card_item_three .event_acceptance_actions button{
  margin: 7px 0 0;
}
.dashboard_task_card_item_three .event_acceptance_actions > button:first-child{
  margin-top: 0;
}
/* pagination */
.task-list-pagination{
  display: inline-block;
  margin-top: 4px;
  width: 100%;
}
.task-list-pagination .mat-pagination{
  padding:8px;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
}
.event_attendee_tab_icon.event_organizer_icon{
  margin: 0 5px;
  border: 1px solid #ffffff;
}
.ant-popover-inner-content .event_attendee_tab_icon.event_organizer_icon{
  margin-left: 0;
}
.dashboard_task_wrapper{
  display: flex;
  justify-content: space-between;
  width: 54%;
}
.warning_icon{
  width: 21px;
  height: 19px;
}
.recurring_text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1800px;
}
@media (max-width:991px){
  .body-bg-color.dashboard-page {
    padding-bottom: 140px;
  }
}
@media only screen and (min-width: 600px){
  .dashboard_task_card_item.pending_acceptance_event{
    max-width: 54%;
  }
}
@media only screen and (max-width: 600px) {
  .dashboard_task_tab {
    display: flex;
  }
  .dashboard_task_filters{
    display: block;
  }
  .dashboard_task_filters .tableTitle{
    margin-bottom: 10px;
    display: block;
  }
  .emergency_card {
    border: 1px solid #e10000;
  }
  .todays_tasks_grid_heading {
    display: block;
  }
  .error_task {
    display: none;
  }
  .dashboard_task_card {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .todays_tasks_grid_heading_one {
    margin-bottom: 5px;
  }
  .dashboard_task_card_item_one {
    margin-right: 0px;
    max-width: 200px;
  }
  .dashboard_task_card_item_four {
    margin-right: 0px;
  }
  .hosp_loc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 98px;
  }
  .event_title_name {
      overflow: hidden ;
      text-overflow: ellipsis;
      white-space: nowrap ;
      max-width: 98px;  
  }
  .dashboard_task_card_item_one,
  .dashboard_task_card_item_two,
  .pending_acceptance_event {
    margin-bottom: 8px;
  }
  .dashboard_task_card_item_four {
    width: 122px;
  }
  .body-bg-color.dashboard-page {
    padding-bottom: 163px;
  }
}
.selected{
  font-weight: bold !important;
}

.task_summary_inprogress{
  background-color: #F5FFFA;
}
.task_summary_cancelled{
  background-color: #E3E3E3;
}
.task_summary_uncovered{
  background-color: #FFD1D1;
}

.task_summary_completed{
  background-color: #E1FFF0;
}
.task_summary_assigned{
  background-color: #D1DAFF;
}
.task_summary_unassigned{
  background-color:#EBEFFF ;
}

/* tasks-summary */
.tasks_section{
  margin:0;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tasks_section .task_summary_item{
  width: calc(14.28% - 11px);
  background: #fff;
  display: inline-flex;
  flex-direction: column;
}
.tasks_section .task_summary_item_bottom{
  display:inline-flex;
  flex-direction: column;
  flex-grow: 1;
}
.tasks_section .task_summary_item_bottom span{
  margin-top:auto;
}
.task_unassigned .task_summary_item_bottom span{
  color:#617FFF;
}
.task_pending .task_summary_item_bottom span{
  color:#617FFF;
}
.task_assigned .task_summary_item_bottom span{
  color:#303960;
}
.task_inprogress .task_summary_item_bottom span{
  color:#69F3B1;
}
.task_completed .task_summary_item_bottom span{
  color:#21BF73;
}
.task_uncovered .task_summary_item_bottom span{
  color:#D24856;
}
.task_cancelled .task_summary_item_bottom span{
  color:#888888;
}

/* pending-task-summary */
.dashboard_task_card_item_three{
  max-width:115px;
  width:100%;
  text-align: center;
}
.dashboard_task_card_item_three .assigned_to_card p{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pending_acceptance_event{
  max-width: 320px;
  width: 100%;
}
.task_assign_model_container > .MuiGrid-container{
  align-items: center;
}
.task_event_heading {
  display: flex;
  align-items: center;
  margin-bottom:20px;
}
.task_event_heading > h1.event_heading{
  font: 500 12px/16px "Roboto" !important;
  color: #424242;
  background: #D4D4D41F;
  border-radius: 5px;
  margin-right: 13px;
  padding:6px 13px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.task_event_heading .task_event_emergency{
  font: 500 12px/16px "Roboto" !important;
  color: #E91111;
  background: #F2222226;
  border-radius: 5px;
  padding:6px 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.tasking-assign-right span.task_event_timing{
  margin-bottom: 10px;
  
}
.tasking-assign-right .dashboard_task_event_attendees_section  span {
  margin-bottom: 0;
} 

.tasking-assign-right .dashboard_task_event_attendees_section  {
  margin-bottom: 10px;
} 
.task_assignment_dialog .add_user_header.task_assignment_modal_header{
  margin-bottom:17px !important;
}
.task_assign_errror_msg{
  margin-bottom:0;
  display: flex;
  align-items: flex-start;
}
.task_assign_errror_msg img{
  margin-top:1px;
}
.task_assign_action_buttons, .task_assign_action_buttons > button{
  margin-top: 10px;
}
.task_assign_action_buttons .cancel_btn{
  margin-right: 30px;
}
.task_assignment_modal_content{
  padding:0 25px 20px !important;
}
.task_reassign_action{
  flex-wrap: wrap;
}
.task_assignment_accept_dialog .task_assign_model_container{
  margin-bottom:50px;
}
.task_assignment_accept_dialog .MuiPaper-rounded{
  border-radius: 10px;
}
.dashboard_task_event_attendees_section{
  display:flex;
}
.dashboard_task_event_attendees_section .dashboard_task_event_attendees{
  display:inline-flex;
  align-items: center;
}
.dashboard_task_event_organizer{
  margin-right:5px;
}
.dashboard_task_event_attendee_images{
  position: relative;
  padding-right: 25px;
}
.dashboard_task_event_attendee_images > span{
  display: inline-block;
}
.dashboard_task_event_attendee_images > span:nth-child(2){
  position: absolute;
  left:13px;
}
.dashboard_task_event_attendee_images > span:nth-child(3){
  position: absolute;
  left:26px;
}
.dashboard_task_event_attendees_section .dashboard_task_event_attendees_plus{
  color:#617FFF;
}
.recurring_acceptance{
  top: 471px;
    left: 210px;
    right: 210px;
    width: 25px;
    height: 16px;
    opacity: 1;
}

@media (min-width:1024px){
  .task_assign_action_buttons .ok_btn{
    min-width:170px;
  }
  .task_assign_action_buttons .cancel_btn, .task_reassign_action .skip-btn{
    min-width:155px;
  }
}
@media (max-width:991px){
  .tasks_section{
    justify-content: start;
    margin: 0 -10px;
  }
  .tasks_section .task_summary_item{
    width: calc(33.33% - 22px);
    margin: 11px;
  }
}
@media (min-width:767px){
  .task-list-pagination .mat-pagination{
    height: 50px;
  }
}
@media (max-width:600px){
  .tasks_section{
    justify-content: center;
    margin:0;
  }
  .tasks_section .task_summary_item{
    width:100%;
    margin:0 0 22px;
  }
  .tasks_section .task_summary_item:last-child{
    margin-bottom:0;
  }
}
@media (max-width:378px){
  .dashboard_task_card_item.dashboard_task_card_item_two{
    margin-bottom: 0;
  }
  .dashboard_task_card_item:not(.dashboard_task_card_item_four){
    margin-bottom:15px;
  }
}
@media (max-width:350px){
  .dashboard_task_event_attendees_section{
    flex-wrap: wrap;
  }
  .dashboard_task_event_organizer{
    width:100%;
    margin-bottom: 5px;
  }
}
.event_titles {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.err-msg{
    margin-left: 14px;
    color: red;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

.signup-body-bg-color{
    background-color: #f2f4fb;
    min-height : 653px;
}
.signup-root{
    max-width: 820px;
    margin: auto;
  }
  .bullet{
    display: 'inline-block';
    margin: '0 2px';
    transform: 'scale(0.8)';
  }
  .title  {
    font-size: 14;
  }
  .pos {
    margin-bottom: 12;
  } 
  .card-header{
    background: transparent linear-gradient(101deg, #1E3C72 0%, #2A5298 100%) 0% 0% no-repeat padding-box;
  }
  /* .card-header h1,h2,h3,p{
    color: rgb(248, 244, 244);
    margin: 0px;
} */
.pt-10{
    padding: 20px !important;
}

.loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
    margin-top: 10px;
}

.drop-zone-area {
    height: 100%;
}
.buttonGroup{
    float : right;
    padding: 0;
    margin-top: 10px;
}
.signupBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #21BF73 !important;
    box-shadow: none !important;
    margin-top: 20px !important;
}
.cancelBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #eae7e7!important;
    box-shadow: none !important;
    margin-top: 20px !important;
    margin: 5px;
}
.cancelBtn .MuiButton-label{
    color: black;
}
.signup-body{
    margin-top: 30px;
}

.signup-logo{
    display: flex;
    margin-bottom: 20px;
}

.header-content{
    padding: 20px 35px;
}

.signup-name{
    font: normal 16px "Roboto" !important;
    margin-bottom: 10px !important;
}

.pragraph-text{
    font: bold 22px "Roboto" !important;
}
.MuiDropzoneArea-root{
    border: 1px dashed #E2E5F2 !important;
    min-height: 118px !important;
    background-color: #FCFCFC;
    border-radius: 5px;
}

.dropzone-icon{
    text-align: center;
    padding: 13px;
}
.dropzone-icon img{
    margin-bottom: 6px;
}
.dropzone-inst{
    color: #B5B5B5;
    font: normal 14px "Roboto";
}

.dropzone-paragraph{
    color: #303960;
    font: normal 18px "Roboto" !important;
    margin-bottom: 7px; 
}
.dropzone-paragraph span {
    color: #3C57CB;
}
.cancel-button{
    border: 1px solid #BEBEBE !important;
    border-radius: 5px !important;
    color: #6A6A6A;
    background-color: #fff !important;
    padding: 12px 39px !important;
    margin-top: 0 !important;
}

.success-button{
    margin-left: 15px !important;
    padding: 12px !important;
    margin-top: 0 !important;
}

.success-button .MuiButton-label{
    font: normal 16px/17px "Roboto" !important;
}
.cancel-button .MuiButton-label{
    font: normal 16px/16px "Roboto" !important;
    color: #6A6A6A;
}

.MuiPaper-elevation1{
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35) !important;
}

.signup-body-bg-color.signup-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-body-bg-color.signup-section .signup-body{
    margin-top: 30px;
    margin-bottom: 30px;
}
@media (max-width: 767px) {
    .body-bg-color .signup-body {
        padding-left: 5px;
        padding-right: 5px;
    }
    .header-content {
        padding: 20px 20px;
    }
    .signup-body-bg-color.signup-section .signup-body {
        padding: 0px;
        margin: 64px 15px;
    }
}
@media (max-width: 480px) {
    .signup-body-bg-color.signup-section .signup-body {
        margin: 64px 5px;
}
}
.event_detail {
    max-width: 979px;
    margin: 0 auto;
    box-shadow: 0px 2px 1px #0000000F;
    border-radius: 5px;
    
}
.event_detail_header {
    padding: 33px 40px 26px 40px;
    background-image: url(/static/media/event_detail_bg_img.921415d0.png);
    border-radius: 5px 5px 0px 0px;
    background-color: #4759A7;
}
.event_detail_header span {
    color: #fff;
    background-color: #ffffff1f;
    font: normal 14px/19px "Roboto";
    text-transform: uppercase;
    display: inline-block;
    height: 28px;
    padding: 6px 11px 3px;
    align-self: center;
    border-radius: 5px;
    margin-bottom: 15px;
}
.event_detail_header h1 {
    color: #fff;
    font: bold 21px/28px "Roboto";
    margin: 0px;
    max-width: 320px;
}
.event_detail_container {
    padding: 28px 37px 28px 40px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 5px 5px;
}
.event_detail_container_item{
    color: #6370AA;
    font: normal 16px/21px "Roboto";
    margin-bottom: 16px;
    align-items: center;
    display: flex;
}
.event_detail_container_item span {
    color: #5B5B5B;
    margin-left: 9px;
}
.event_attendee_tab {
    /* width: 195px; */
    color: #646670;
    font: normal 16px/21px "Roboto";
    box-shadow: 0px 8px 11px #00000008;
    border: 1px solid #F3F3F3;
    border-radius: 10px;
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
}
.event_attendee_tab_icon {
    box-shadow: 0px 3px 12px #0000000D;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    object-fit: cover;
}
.event_detail_map{
    margin-bottom: 11px;
}
.event_attendee_tab_add{
    background-color: #F8F8F8;
    box-shadow: none;
    cursor: pointer;
}
.event_detail_container_item img {
    margin-left: 12px;
}
.event_detail_header img {
    margin-left: 8px;
}
.event_detail_container .info-card-detail-left{
    margin-left: 5px;
    max-width: none;
}
.event_detail_container_item p {
    margin: 0px;
    display: flex;
    white-space: nowrap;
    align-self: start;
}
.event_detail_container_item .MuiButton-label{
    color: #21BF73 !important;
}
.event_detail_container_item .MuiButtonBase-root{
    margin-left: 10px;
    max-height: 30px;
    align-self: center;
}
.al_ssel{
    align-self: center !important;
}
.select_parents{
    text-align: end;
}
.select_parents .upload_btns{
    background-color: #BF3354;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto"!important;
    width: 163px;
    height: 38px;
    margin-top: 67px;
    color: #FFFFFF;
    margin-right:15px;
}
.select_parents .ok_btn{
    background-color: #21BF73;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto"!important;
    width: 163px;
    height: 38px;
    margin-top: 67px;
    color: #FFFFFF;
}
.event_title {
    max-width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.event_title span {
    font: normal 14px/19px "Roboto" !important;
}
.event_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1800px;
}
.event_attendee_tab:not(.event_attendee_tab_add) > div {
    max-width: calc(100% - 13px);
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;
    position: relative;
}
.event_attendee_tab > div img.auto-assignee-priority-icon.attendees_status_icon{
    position: absolute;
    right:0;
}
.add_company_dialog .text_area, .add_company_dialog .MuiInputBase-multiline {
    height: 100% !important;
    
}
.showType,.showTitle,.showAdd,.showDate,.showDis,.showcc,.showemailCC{
    display: none;
}
.cc_email_container{
    display: flex;
    align-items: flex-start;
}
.cc_email{
    display: block;
}
.event_detail_container_cc{
    margin-top:16px;
}
.info-card-detail-left.external_attendees_input{
    max-width: none;
}
.external_attendees_input .react-tag-input{
    padding: 10.5px 14px;
}
.external_attendees_input .react-tag-input:hover, .external_attendees_input .react-tag-input:focus{
    border-color: rgba(0, 0, 0, 0.87);
}
.external_attendees_input .react-tag-input input{
    padding: 0;
    height:19px;
}
.external_attendees_input .react-tag-input input::placeholder{
    color: #AAAAAA;
}
.event_detail_item_name .event_attendee_tab_icon{
    margin-right:0;
}
.event_attendee_tab.event_attendee_tab_add{
    background-color: rgba(248,248,248,0.5);
    border: 1px solid rgba(243,243,243,0.7);
    font: normal 13px/16px "Roboto";
    border-radius: 5px;
}
.event_attendee_tab.event_attendee_tab_add > div{
    display: flex;
    align-items: center;
} 
.event_attendee_tab.event_attendee_tab_add .event_attendee_tab_icon{
    height: 16px;
    width: 16px;
    margin-right: 6px;
}
.event_attendee_tab.event_attendee_tab_add.event_address_tab img,
.event_attendee_tab.event_attendee_tab_add.event_address_tab span{
    margin-left: 0px;
}
.event_detail_container_item.event_detail_address_item > p{
 align-self: center;
}
@media (max-width: 767px){
    .event_detail_container_cc{
        flex-wrap: wrap;
    }
    .event_detail_container_cc span{
        margin:0;
    }
    .event_detail_container_cc .cc_email_container{
        width:100%;
        margin-top:10px;
    }
}
.cancel-task-img {
    text-align: center !important;
}

.closeButton {
    position: absolute !important;
    right: 0;
    margin-top: 10px !important;
    padding-top: 30px !important;
}

.cancel-task_action {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.cancel-task_action .cancel_btn {
    min-width: 130px;
    color: #6A6A6A;
    height: 40px;
    border: 1px solid #BEBEBE;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
}

.cancel-task_action .ok_btn {
    background-color: #21BF73;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
}

.cancel-task-label {
    /* top: 231px;
    left: 611px; */
    width: 156px !important;
    height: 37px !important;
    /* UI Properties */
    text-align: left !important;
    font: normal normal bold 28px/37px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #3A3A3A !important;
    opacity: 1 !important;
    /* text-decoration: none !important; */
}

.cancel-task-msg {
    /* top: 277px;
        left: 611px; */
    width: 363px !important;
    height: 57px !important;
    /* UI Properties */
    text-align: left !important;
    font: normal normal normal 20px/24px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #303960 !important;
    opacity: 1 !important;
    margin-top: 30px !important;
}

.waring-img {
    /* top: 221px; */
    left: 700px !important;
    width: 188px !important;
    height: 165px !important;
    /* UI Properties */
    background: transparent 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
}

.cancel-radio-btn {
    /* top: 374px;
    left: 611px; */
    width: 16px !important;
    height: 16px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #646670 !important;
    opacity: 1 !important;
}

.add-note {
    /* left: 364px;
        width: 75px; */
    top: 40px !important;
    height: 24px !important;
    text-align: left !important;
    font: normal normal normal 18px/24px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    opacity: 1 !important;
}

.task_msg {
    /* top: 449px;
        left: 364px; */
    width: 100% !important;
    height: 110px !important;
    background: #F8F8F8 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    margin-top: 10px !important;
}

.register-form-middle {
    margin-top: 60px !important;
}

.recurring_popup .MuiInputBase-root {
    max-height: 450px !important;
}

.deleteServiceDialog.additional-rep .MuiDialog-paper{
    height: 400px !important;
    padding: 5px 10px 5px !important;
}

.deleteServiceDialog.additional-reps .MuiDialog-paper{
    height: 200px !important;
    padding: 5px 10px 5px !important;
}

.additionalRepCloseBtn {
   padding: 5px 10px 5px !important;
}

.add_company_action.additional_rep {
    margin-top: 250px !important;
}
.recurring-text-detail{
    background:#2B2B2B21;
    padding-left:5px;
    padding-right:5px;
    border-radius:5px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    max-width:100%;
    display: flex;
    min-width: 250px;
  }
.recurring-header{
    font: 410 18px/10px Roboto;
    padding-left:20px; 
    display:block
}

.products_btn_right{
    display: flex;
}
.products_btn_right .add_product_main_btn{
    margin-right: 8px;
}
@media (max-width: 600px){
.header-add-btn-product .MuiTypography-h5 {
    margin-bottom: 10px;
}
}

.map-container{
    height: calc(100vh - 75px);
}
.map_detail_dialog{
    max-width: 395px;
    top: 75px !important;
}
.map_detail_dialog .MuiDialog-paperWidthXs{
    max-width: 370px;
    width: 100%;
    max-height: calc(100% - 20px);
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 8px 23px #00000052;
}
.map_detail_dialog .MuiDialogContent-root{
    padding: 20px 0px;
}
.map-hosp-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
}
.map-hosp-empty{
    padding:0 20px;
    align-items: center;
}
.map-hosp-name {
    color: #AAAAAA;
    font: normal 14px/19px "Roboto";
    margin-right: 10px;
    display: inline-block;
}
.map-hosp-condition {
    color: #EE0E0E;
    font: normal 12px/16px "Roboto";
    padding: 2px 14px 1px 14px;
    background-color: #FF00001A;
    border-radius: 5px;
    display: inline-block;
}
.map_dialog_close{
    height: 11px;
    width: 11px;
    cursor: pointer;
}
.map_dialog_header h2{
    font: bold 18px/24px "Roboto";
    color: #424242;
    margin-bottom: 13px;
}
.map-status-time {
    display: flex;
    justify-content: space-between;
}
.map-status {
    padding: 3px 0px;
    font: normal 14px/19px "Roboto";
    border-radius: 5px;
    background-color: #E81C2312;
    color: #CC1837;
    width: 93px;
    text-align: center;
    margin: 0px;
    margin-top:10px;
}
.map_event_title {
  white-space: nowrap; 
  width: 300px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.date_time_div span{
    display: block;
    text-align: right;
}
.date_time_div_top span{
    display: inline;
}
   .map_time_unassigned{
    color:#AAAAAA !important;
    }
    .map_time_pending{
    color:#617FFF !important;
    }
    .map_time_assigned{
    color:#303960 !important;
    }
    .map_time_inprogress{
    color:#33BE9D !important;
    }
    .map_time_completed{
    color:#21BF73 !important;
    }
    .map_time_uncovered{
    color:#D24856 !important;
    }
    .map_time_cancelled{
    color:#888888 !important;
    }

/* .date_time_div span:first-child{
    color: "#AAAAAA";
} */
.map-status span{
    margin-left: 13px;
    position: relative;
}
.map-status span:before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #CC1837;
    position: absolute;
    border-radius: 50%;
    top: 5px;
    left: -13px;
}
.map-time{
    font: normal 18px/24px "Roboto";
    color: #21BF73;
}
.map_dialog_header {
    border-bottom: 0.5px solid #F6F6F6;
    padding-bottom: 13.5px;
    padding: 0px 20px 13.5px 20px;
    margin-bottom: 20.5px;
}
.map-get-dir-btn {
    padding: 0px 20px 20px;
}
.map-other-task{
    font: 500 16px/21px "Roboto";
    color: #424242;
    padding: 10px 20px 9px 20px;
    background-color: #FBFBFB;
    margin-bottom: 10px;
}
.map-other-task-detail {
    padding: 0px 20px 14px;
    border-bottom: 0.5px solid #F6F6F6;
    margin-bottom: 14px;
}
.map-other-task-detail-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.map-other-task-detail-flex p{
    max-width: 252px;
    font: normal 16px/21px "Roboto";
    margin: 0px;
    color: #424242;
}
.map-other-task-detail-flex span{
    align-self: flex-end;
    color: #4759A7;
    font: normal 14px/19px "Roboto";
}
.map-loc-cal-sub img{
    margin-right: 8px;
}
.map-loc-cal-sub span{
    color: #5B5B5B;
    font: normal 16px/21px "Roboto";
}
.map-loc-cal-sub {
    margin-bottom: 10px;
    display: flex;
}
.map_event_dialog_header{
    margin: 0px;
    border: none;
}
.map-attendees{
    padding: 0px 20px 20px;
}
.map-attendee{
    margin-bottom: 10px;
}
.map_event_dialog_header .map-hosp-name {
    font: normal 16px/21px "Roboto";
    color: #424242;
}
.map-attendee img{
    box-shadow: 0px 3px 12px #0000000D;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    object-fit: cover;
}
.map-attendee span{
    color: #424242;
    font: normal 16px/21px "Roboto";
}
.map_sales_rep_dialog_profile{
    padding: 0px 20px 20.5px 20px;
    margin-bottom: 20.5px;
    border-bottom: 1px solid #E2E5F2;
}
.map_sales_rep_dialog_profile-one .map_sales_rep_dialog_profile_img{
    box-shadow: 0px 5px 28px #00000014;
    border-radius: 33px;
    width: 66px;
    height: 66px;
    margin-right: 25px;
}
.map_sales_rep_dialog_profile_name p{
    color: #424242;
    font: normal 16px/21px "Roboto";
    margin-bottom: 2px;
}
.map_sales_rep_dialog_profile_name h3{
    color: #424242;
    font: 500 18px/24px "Roboto";
    margin: 0px;
}
.map_sales_rep_dialog_profile-one {
    display: flex;
    justify-content: space-between;
}
.map_sales_rep_dialog_profile-one_div{
    display: flex;
}
.infoBox img {
    display: none;
}
.infoBox{
    min-width:125px !important;
}
.infoBox h2{
    font: normal 12px/16px "Roboto";
    background-color: #303960;
    margin: 0px;
    padding: 9px 9px 7px 9px;
    color: #FFFFFF;
    border-radius: 5px;
}
.map_sales_rep_dialog_profile_name {
    align-self: center;
}
.map_sales_rep_dialog_profile_info{
    margin-bottom: 20px;
}
.map_sales_rep_dialog_profile_info img{
    margin-right: 8px;
}
.map_sales_rep_dialog_profile_info span{
    color: #5B5B5B;
    font: normal 16px/21px "Roboto";
}
.map_sales_rep_other_task{
    display: inline-block;
    margin-bottom: 0px;
}
.map_sales_rep_other_task_main{
    margin-bottom: 10px;
}
.MuiDialogContent-root .map-other-task-detail:last-child {
    border: none;
}
.map_sales_rep_dialog_profile-two .map_sales_rep_dialog_profile_info:last-child {
    margin-bottom: 0px;
}
.custom_map_filters .ant-select-lg{
  margin:5px  
}
.custom_map_filters{
    padding:10px;
    display: flex;
}
.new_custom_filter_div.todays_tasks_grid_heading_select.map_filter {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 1px 2px #30396030;
    border-radius: 5px;
}
.map-container .gmnoprint, .map-container .gm-style-cc{
    display:none;
}
.map_event_dialog_header .dashboard_task_event_attendees{
    margin-bottom: 10px;
}
.map-attendee{
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 1130px){
    .map-container{
        height: calc(100vh - 67px);
    }
}
@media only screen and (min-width: 768px){
    .map_detail_dialog .MuiDialog-scrollPaper{
        justify-content: left;
    }
}

img[src^="https://salesc2userprofile.s3.amazonaws.com/"] {
   border-radius: 50% !important;
  }
/* .highcharts-figure, .highcharts-data-table table {
    min-width: 310px; 
    max-width: 800px;
    margin: 1em auto;
}

#container {
    height: 400px;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #EBEBEB;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
	font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.highcharts-credits{
    display: none;
} */


.selectBoi > span{
    color: #707070;
    font-size: 14px !important;
    font-weight: bolder;
    width: 10px;
    height: 5px;
}

.report_header_btn button {
    box-shadow: 0px 3px 10px #0000000A;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font: normal 14px/19px "Roboto";
    background-color: #fff;
    color: #424242;
    text-transform: none;
    min-width: 150px;
    height: 35px;
    padding: 10px 0px 6px 0px;
}
.reports_down_tab button{
    box-shadow: 0px 3px 10px #0000000A;
    border-radius: 5px;
    font: normal 14px/19px "Roboto";
    background-color: #21bf73;
    color: #fff;
    text-transform: none;
    min-width: 250px;
    height: 40px;
    padding: 8px 0px 8px 0px;
}
.report_header_btn button img{
    margin-right: 10px;
    margin-bottom: 3px;
}
.report_header_btn_two{
    margin-left: 6px !important;
}
.reports_tab{
    padding: 0px 20px;
}
.report-tab-table{
    width: -webkit-max-content;
    width: max-content;
    min-width: 50%;
    max-width: 100%;
}
.report-tab-table-header th.MuiTableCell-head:nth-child(2),
.report-tab-table-body td.MuiTableCell-body:nth-child(2){
    text-align: left !important;
}
.reports_tab .ant-tabs-tab {
    color: #AAAAAA;
    font: normal 14px/19px "Roboto" !important;
    padding: 17px 15px 11px 15px;
}
.reports_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #444444 !important;
}

.reports_tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 2px solid #E2E5F2;
}
.reports_tab .ant-tabs-ink-bar{
    background: #21BF73 !important;
}
.report_filter {
    margin: 0px 10px 10px;
}
.report_ant_select .ant-select-selector {
    border: none !important;
    box-shadow: 0px 1px 2px #0000001A;
    border-radius: 5px !important;
    width: 285px !important;
    font: 500 14px/19px "Roboto" !important;
    color: #424242 !important;
    padding: 13px 15px 13px 15px !important;
    height: 45px !important;
    align-items: center;
}

.report_ant_select{
    margin-right:20px;
}
.report_filter .ant-picker, .report_filter .ant-picker-clear{
    background-color: transparent;
}
.reports_tab .highcharts-axis{
    font: normal 16px/21px "Roboto" !important;
    fill: #424242 !important;
}
.reports_tab .highcharts-xaxis-labels text{
    font: normal 11px/14px "Roboto" !important;
    fill: #444444 !important;
}
.reports_tab .highcharts-yaxis-labels text{
    font: normal 11px/14px "Roboto" !important;
    fill: #424242 !important;
}
.reports_tab .highcharts-axis .highcharts-axis-title{
    font: normal 16px/21px "Roboto";
    fill: #424242 !important;
}
.normal-barchart .highcharts-xaxis .highcharts-axis-title, .vertical-barchart .highcharts-yaxis .highcharts-axis-title{
    transform: translate(0, 10px);
}   
/* .reports_tab .highcharts-yaxis text.highcharts-axis-title{
    transform: translate(1 , 10px);
} */
.report_filter .new_custom_filters > div {
    margin-bottom: 10px;
}
.graph-table-header{
    text-align: left;
    font:normal 600 21px/28px "Roboto" !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    opacity: 1;
}
.graph-table-header.table-header{
  margin-bottom: 15px;
}
.graph-table-header.graph-header{
  margin-bottom:29px;
}
.report-popup-menu{
    width:223px;
    background: #FFFFFF;
    box-shadow: 0px 8px 17px #0000000F;
    border-radius: 10px;
    padding:0 0 2px;
}
.report-popup-menu .popup-header{
    font: normal normal 600 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    padding:14px 23px;
}
.report-popup-menu .popup-list{
    padding: 12px 23px;
    font: normal 14px/19px "Roboto";
    letter-spacing: 0px;
    color: #5B5B5B;
}
.report-popup-menu hr{
    background-color:#E2E5F2;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d8d3cd;
}
.report-graph.utilization .highcharts-color-0, .report-graph.utilization .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#5980b3;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #f8bd7f;
}

.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d9adad;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #84a9ac;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d8d3cd;
}

.report-graph.account-satisfaction .highcharts-color-0, .report-graph.account-satisfaction .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.report-graph.account-satisfaction .highcharts-color-1, .report-graph.account-satisfaction .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.uncovered .highcharts-color-1, .report-graph.uncovered .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#D24856;
}
.report-graph.cancelled .highcharts-color-1, .report-graph.cancelled .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#888888;
}
.reports_down_tab{
    max-width: 100% !important;
    text-align: center !important;
    padding: 50px 0;
}
.reports_tab .ant-empty{
    padding-bottom:30px;
}
.report_header_btn_down svg.MuiSvgIcon-root{
    color:#ffffff;
}
.report_table{
    text-align: "right"; 
    padding-right:"30px"
}
.chart-conatiner{
    padding:12px;
}
.chart-conatiner .chart-section{
    margin-bottom: 11px;
}
.chart-conatiner .new_custom_filter_div.filter-option label,
.chart-conatiner .new_custom_filter_div.filter-option span{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.chart-conatiner .new_custom_filter_div.current-selected span{
    font-family:"Roboto";
}
.chart-conatiner .chart-section:last-child{
    margin-bottom: 0;
}
.graph-section > .MuiGrid-item{
    display:flex;
}
.chart-conatiner h3.summary-title{
    font: bold 18px/24px "Roboto";
    color: #424242;
    letter-spacing: 0px;
}
.task-graph{
    border-radius:20px;
    background-color: #fff;
    padding:19px 8px;
    width:100%;
}
.task-graph > .chart-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 12px;
}
.task-graph h5.chart-title{
    text-transform: uppercase;
    font: bold 17px/23px "Roboto";
    margin-bottom:0;
    color: #424242;
    text-transform: none;
}
.task-graph .chart-filter label{
font: normal 14px/19px "Roboto" !important;
color: #424242;
}
.task-graph select.select-box{
    font: normal 14px/19px "Roboto";
    border:none;
    color: #424242;
}
.task-graph img{
    width:100%;
}
.team-conatiner{
    margin-top:23px;
}
.team-conatiner .team-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color:#F9F9F9;
    margin: 0 -4px 23px;
    padding: 5px 17px;
}
.team-conatiner h4.team-title, .team-conatiner h4.report-title{
    color:#424242;
    font: 500 14px/19px "Roboto";
    margin-bottom:0;
}
.team-conatiner h4.report-title{
    margin-right:27px;
}
.team-conatiner ul.team-list{
    padding:0 17px;
    margin-bottom:0;
}
.team-conatiner ul.team-list li{
    margin-bottom:25px;
    list-style: none;
    display:flex;
    align-items: center;
    justify-content:space-between;
}
.team-conatiner ul.team-list li span{
    font: normal 14px/19px "Roboto";
    color:#5D5D5D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right:10px;
}
.team-conatiner ul.team-list li span.download-file{
    font: normal 14px/19px "Roboto";
    display: inline-flex;
    align-items: end;
    color: #21BF73;
    cursor: pointer;
}
.team-conatiner ul.team-list li span.download-file img{
    margin-right:8px;
    cursor: pointer;
}
.filter-wrapper{
    display:flex;
    margin:0 -5px;
}
.filter-wrapper > div{
    margin:0 5px;
}
/* chart */
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d8d3cd;
}

.task-graph.utilization .highcharts-color-0, .task-graph.utilization .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #f8bd7f;
}

.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d9adad;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #84a9ac;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d8d3cd;
}

.task-graph.cost-revenue .highcharts-xaxis-labels text,
.task-graph.cost-revenue .highcharts-yaxis-labels text,
.task-graph.utilization .highcharts-xaxis-labels text,
.task-graph.utilization .highcharts-yaxis-labels text,
.task-graph.num-tasks .highcharts-xaxis-labels text,
.task-graph.num-tasks .highcharts-yaxis-labels text,
.task-graph.active-acc .highcharts-xaxis-labels text,
.task-graph.active-acc .highcharts-yaxis-labels text,
.task-graph.sales .highcharts-xaxis-labels text,
.task-graph.sales .highcharts-yaxis-labels text{
    font: normal 11px "Roboto" !important;
    fill:#505050 !important
}
.graph-section .task-graph .highcharts-data-label text{
    font: bold 9px/11px "Roboto" !important;
}
.graph-section .task-graph .highcharts-axis-title{
    font: bold 17px/23px "Roboto";
    fill: #424242 !important;
}
.task-graph.cost-revenue .highcharts-yaxis text,
.task-graph.num-tasks .highcharts-xaxis text,
.task-graph.active-acc .highcharts-xaxis text,
.task-graph.sales .highcharts-xaxis text,
.task-graph.utilization .highcharts-yaxis text{
    transform: translate(0, 20px);
}

.task-graph .team-conatiner{
    position: relative;
}
.task-graph .summary_loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);  
    display: flex;
    align-items: center;
    justify-content: center;
}
.task-graph .highcharts-legend > g{
    transform: translate( 30px , 10px);
}
.chart-conatiner .dashboard_task_filters{
    margin:0 0 9px;
}
/* Pending Events Acceptance */
.task-graph.cost-revenue.acceptance_section{
    padding-bottom:22px;
}
.acceptance_container{
    padding:0 16px;
    margin: 0 -24px;
    display: flex;
    flex-wrap: wrap;
}
.event_acceptance{
    padding:13px 10px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 0 20px 30px;
    width: calc( 50% - 40px);
}
.event_acceptance_header{
    display:flex;
    align-items: center;
    margin-bottom: 11px;
}
.event_acceptance_header h5{
    font: bold 16px/19px "Roboto";
    color: #424242;
    margin-right:13px;
    margin-bottom:0;
    cursor: pointer;
}
.event_acceptance_header h5 span{
    white-space: nowrap; 
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event_acceptance_details{
    margin-right:10px;
}
.event_acceptance_location{
    display: inline-flex;
    align-items: center;
    margin-right:9px;
}
.event_acceptance_location img{
    width: 9px !important;
    height: 12px;
    margin-right:3px;
}
.event_acceptance_location span{
    font: normal 14px/17px "Roboto";
    color: #4759A7;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
}
.event_acceptance_type span{
    font: normal 12px/14px "Roboto";
    color: #B4B4B4;
    text-transform: uppercase;
}
.event_acceptance_title{
    font: normal 16px/19px "Roboto";
    color:#303960;
    margin-bottom: 10px;
}
.event_acceptance_attendees{
    display: flex;
    align-items: flex-start;
    margin-bottom:16px;
}
.attendees_title{
    font: normal 14px/17px "Roboto";
    color: #5B5B5B;
    margin-right:5px;
    white-space: nowrap;
}
.attendees_name{
    font: normal 14px/17px "Roboto";
    color: #aaaaaa;
    margin-right:7px;
}
.event_acceptance_attendees .attendees_name > span:last-child{
    font: normal 14px/17px "Roboto";
    color:#B4B4B4;
    white-space: nowrap;
}
.event_acceptance_time{
    display: flex;
    align-items: center;
}
.event_acceptance .event_acceptance_time img{
    width: 15px;
    height: 17px;
    margin-right: 7px;
}
.event_acceptance_time span{ 
    font: normal 14px/17px "Roboto";   
    color:#3A3A3A;
}
.event_acceptance_actions{
    margin:0 -7px;
    align-self: flex-end;
    text-align: end;
    width: auto;
}
.event_acceptance_actions button{
    width:100px;
    height:30px;
    cursor: pointer;
    transition: 0.2s linear all;
    margin: 15px 7px 0;   
}
.reject_btn{
    font: normal 14px/17px "Roboto";  
    background-color: #F9F9F9;
    border: none;
    border-radius: 5px;
    color:#424242;
}
.accept_btn{
    font: normal 14px/17px "Roboto";  
    background-color:#21BF73;
    border: none;
    border-radius: 5px;
    color:#FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.warning_btn{
    font: normal 14px/17px "Roboto";  
    background-color:#EEB20C;
    border: none;
    border-radius: 5px;
    color:#FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;    
}
button.warning_btn:focus, button.accept_btn:focus, button.reject_btn:focus{
    outline:none
}
.event_acceptance_actions .warning_btn img{
    width: 11px;
    height: 10px;
    margin-right: 5px;
}
@media (min-width:1122px){
    .event_acceptance_details{
        width:300px;
    }
}
@media (min-width: 992px) and (max-width: 1121px){
    .event_acceptance_actions{
        width:-webkit-min-content;
        width:min-content;
    }
    .event_acceptance_details{
        width:300px;
    }
}
@media (max-width: 991px){
    .event_acceptance{
        width: 100%;
    }
}
@media (min-width:601px) and (max-width:767px){
    .event_acceptance_details{
        width:300px;
    }
}
@media (max-width : 600px){
    .task-graph > .chart-header{
        flex-wrap: wrap;
    }
    .task-graph h5.chart-title{
        margin-right:10px;
    }
    .event_acceptance, .event_acceptance_header , .event_acceptance_attendees{
        flex-wrap: wrap;
    }
    .event_acceptance_details{
        width:100%;
    }
}
.chart-conatiner{
    padding:12px;
}
.chart-conatiner .chart-section{
    margin-bottom: 11px;
}
.chart-conatiner .chart-section.table-section{
    margin-bottom:7px;
}
.chart-conatiner .new_custom_filter_div.filter-option label,
.chart-conatiner .new_custom_filter_div.filter-option span{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.chart-conatiner .new_custom_filter_div.current-selected span{
    font-family:"Roboto";
}
.chart-section > div {
    display: flex;
}
.chart-conatiner .chart-section:last-child{
    margin-bottom: 0;
}
.chart-conatiner h3.summary-title{
    font: bold 18px/24px "Roboto";
    color: #424242;
    letter-spacing: 0px;
}
.access-section, .task-graph{
    border-radius:20px;
    background-color: #fff;
    padding: 16px 4px;
    width:100%;
    overflow: hidden;
}
.access-section > .chart-header, .task-graph > .chart-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 0 12px;
}
.access-section h5.chart-title, .task-graph h5.chart-title{
    text-transform: uppercase;
    font: bold 17px/23px "Roboto";
    margin-bottom:0;
    color: #424242;
    text-transform: none;
}
.access-section .chart-filter label, .task-graph .chart-filter label{
font: normal 14px/19px "Roboto" !important;
color: #424242;
}
.access-section select.select-box, .task-graph select.select-box{
    font: normal 14px/19px "Roboto";
    border:none;
    color: #424242;
}
.access-section img, .task-graph img{
    width:100%;
}

/* Search Result */
.access-section .result-detial {
    padding:15px 12px 0; 
}
.result-detial .result-header{
    display:flex;
    align-items: center;
    margin-bottom:14px;
}
.result-detial .result-header img{
    height:45px;
    width:45px;
    border-radius:5px;
}
.result-detial .result-header .acc-initial{
    height:45px;
    width:45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font: 500 21px/28px "Roboto";
    color: #5A5A5A;
    background-color: #EEEEEE;
    border-radius:5px;
}
.result-detial .result-header h3.acc-name{
    font: normal 14px/19px "Roboto";
    color:#424242;
    padding:0 17px;
    margin-bottom:0;
}
.result-detial .result-actions{
    display:flex;
    flex-wrap: wrap;
    margin-bottom:10px;
}
.result-detial .result-actions:last-child{
    margin-bottom: 0;
}
.access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
    background: #21BF730F   ;
    border: 1px solid #21BF73;
    font: 500 14px/19px "Roboto";
    color: #21BF73;
    text-transform: inherit;
    padding: 8px 30px 8px;
    box-shadow: none;
    border-radius:5px;
    margin-right:16px;
    margin-top:10px;
    cursor: pointer;
    outline:none;
}
.result-detial .result-contact{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom:10px;
}
.result-contact > div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.result-contact .contact-label{
    font: normal 14px/19px "Roboto";
    color:#AAAAAA;
}
.result-contact .contact-detial{
    font: normal 14px/19px "Roboto";
    color:#303960;
    padding: 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.result-contact > div:last-child{
    width:100%
}
.access-section .chart-search{
    display:flex;
    flex-direction: column;
    padding: 0 12px;
}
.chart-search .search-box{
    display:flex;
    align-items: center;
    margin-bottom: 8px;
    border: 1px solid #EBEBEB !important;
    border-radius:5px;
}
.search-box .ant-select-selector, .search-box .ant-select-selector:focus{
    border:0 !important;
    box-shadow: none !important;
}
.search-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 100%;
    color:#141A3B;
}
.search-box .ant-select-single .ant-select-selector .ant-select-selection-search-input::placeholder{
    color:#A6A9C4;
}
.chart-search .search-field{
    margin:0;
    width:100%;
}
.chart-search .search-field > div{
    height:40px !important;
    margin:0;
}
.chart-search .search-field input{
    padding:10px;
    font: normal 14px/19px "Roboto";
    color:#AAAAAA; 
}
.chart-search .search-field input::placeholder{
    font: normal 14px/19px "Roboto";
    color:#AAAAAA; 
}
.chart-search .search-box img.search-icon{
    width:15px;
    height:auto;
    margin:0 10px;
}
.chart-search .search-label{
    font: normal 12px/16px "Roboto";
    color:#6b6969;
}
.chart-header .view-btn{
    font: normal 14px/20px "Roboto";
    color: #424242;
    width:98px;
    height:31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:0;
    border-radius: 38px;
    background: #efefef;
}
.chart-header .view-btn:focus{
    outline:none !important;
}
.pending-reg-table .table-header{
    font: 500 14px/19px "Roboto";
    color: #5A63A2;
    border-bottom:0;
    padding:7px 12px 23px;
    white-space: nowrap;
} 
.pending-reg-table .table-values{
    font: normal 14px/19px "Roboto";
    color: #303960;
    border-bottom:0;
    padding:0 12px 28px;
    white-space: nowrap;
} 
.pending-reg-table .table-values a{
    font: normal 14px/19px "Roboto";
    border-bottom:0;
    white-space: nowrap;
}

/* charts */
.task-graph.active-accounts .highcharts-point.highcharts-color-0, 
.task-graph.active-accounts .highcharts-legend-item.highcharts-color-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#E2E2E2!important;
}
.task-graph.active-accounts .highcharts-point.highcharts-color-1, 
.task-graph.active-accounts .highcharts-legend-item.highcharts-color-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#3C57CB!important;
}
.task-graph.company-report .highcharts-label text{
    font: bold 14px/19px "Roboto" !important;
    fill: #000000!important;
} 
.task-graph.company-report .highcharts-legend-item text, .task-graph.active-accounts .highcharts-data-labels .highcharts-label text{
    font-family: "Roboto" !important;
}
.task-graph.active-accounts .highcharts-legend-item text{
    font:  500 16px/21px "Roboto" !important;
    fill: #424242!important;
}
.task-graph.tasks-created .highcharts-axis-labels text, .task-graph.quick-access  .highcharts-axis-labels text{
    font: normal 11px/12px "Roboto" !important;
    fill: #666666 !important;
} 
.task-graph.tasks-created .highcharts-legend-item text, .task-graph.quick-access .highcharts-legend-item text{
    font:bold 12px/15px "Roboto" !important;
    fill: #333333 !important;
}
.task-graph.tasks-created .highcharts-axis text, .task-graph.quick-access .highcharts-axis text{ 
    font: normal 20px/24px "Roboto" !important;
    letter-spacing: 0px;
    fill: #666666 !important;
}
text.highcharts-credits{
    display:none;
}
.chart-conatiner .dashboard_task_filters{
    margin: 0 0 9px;
}
.event_acceptance_pagination {
    width:100%;
}
.event_acceptance_pagination .MuiPagination-ul{
    justify-content: flex-end;
}
@media (min-width:1199px){
    .result-detial .result-actions{
        flex-wrap: nowrap;
    }
}
@media (min-width: 960px){
    .pending-reg-table .table-values{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width:0;
    }
    .access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 165px;
    } 
}
@media (max-width:480px){
    .access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 165px;
    } 
}
@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-black.4f5771d3.eot);
    src: url(/static/media/roboto-black.4f5771d3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-black.72f6fd21.woff2) format('woff2'),
        url(/static/media/roboto-black.fdd88b74.woff) format('woff'),
        url(/static/media/roboto-black.232725fa.ttf) format('truetype'),
        url(/static/media/roboto-black.138bf742.svg#roboto-black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-bold.4a1d8c27.eot);
    src: url(/static/media/roboto-bold.4a1d8c27.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-bold.f3a02e25.woff2) format('woff2'),
        url(/static/media/roboto-bold.08cb8f79.woff) format('woff'),
        url(/static/media/roboto-bold.16d7bb99.ttf) format('truetype'),
        url(/static/media/roboto-bold.c6fc3b27.svg#roboto-bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-light.35d85034.eot);
    src: url(/static/media/roboto-light.35d85034.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-light.ed4b08d2.woff2) format('woff2'),
        url(/static/media/roboto-light.10ad0f86.woff) format('woff'),
        url(/static/media/roboto-light.2382fa8a.ttf) format('truetype'),
        url(/static/media/roboto-light.8e1f37ab.svg#roboto-light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-thin.307ba414.eot);
    src: url(/static/media/roboto-thin.307ba414.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-thin.46888f27.woff2) format('woff2'),
        url(/static/media/roboto-thin.f09d9b5e.woff) format('woff'),
        url(/static/media/roboto-thin.0f5cc8c0.ttf) format('truetype'),
        url(/static/media/roboto-thin.0c980688.svg#roboto-thin) format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-medium.02aa6e7b.eot);
    src: url(/static/media/roboto-medium.02aa6e7b.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-medium.50d01d3e.woff2) format('woff2'),
        url(/static/media/roboto-medium.9a3bf7ac.woff) format('woff'),
        url(/static/media/roboto-medium.b2bcaa52.ttf) format('truetype'),
        url(/static/media/roboto-medium.759fe6c5.svg#roboto-medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(/static/media/roboto-regular.b9077621.eot);
    src: url(/static/media/roboto-regular.b9077621.eot?#iefix) format('embedded-opentype'),
        url(/static/media/roboto-regular.9feb0110.woff2) format('woff2'),
        url(/static/media/roboto-regular.94dac78e.woff) format('woff'),
        url(/static/media/roboto-regular.4312f1fb.ttf) format('truetype'),
        url(/static/media/roboto-regular.30e7106f.svg#roboto-regular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.body-bg-color.billing-page{
    padding-bottom:0;
  }
  .billing-page header{
    position: relative;
  }
  .billing_container{
    display: flex;
  }
  .billing_sidebar{
    width:271px;
    min-height: calc(100vh - 75px);
    background: #FFFFFF;
    padding: 28px 18px;
  }
  .billing_sidebar .comp-image img{
    width:100%;
  }
  .sidebar_header{
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 44px;
  }
  .sidebar_companyname{
    font: normal 14px/17px "Roboto";
    color: #424242;
    padding: 17px 0 10px;
    text-transform: capitalize;
  }
  .billing_list{
    list-style: none;
    margin-bottom:0;
    padding:0
  }
  .billing_list_item{
    margin-bottom:8px;
  }
  .configure_btn{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
    display: inline-block;
    background: transparent;
    border:none;
    outline: none;
    border-radius: 5px;
    padding: 11px 17px;
    width:100%;
    text-align: left;
  }
  .configure_btn.active, .configure_btn:hover{
    background: #FBFBFB;
    border:none;
    outline: none;
    border-radius: 5px;
    font: normal 16px/21px "Roboto";
    color: #4759A7;
    padding: 11px 17px;
    width:100%;
  }
  .billing_page{
    width: calc( 100% - 271px );
    padding: 28px 70px 16px;
  }
  .billing_main_container{
    width: calc( 100% - 271px );
  }
  .billing_main_container .billing_page{
    width: 100%;
    padding: 16px 70px;
  }
  .billing_configure{
    padding: 28px 11px 16px 70px; 
  }
  .billing_page_title{
    font: bold 21px/25px "Roboto";
    color: #303960;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .billing_configure_form{
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 25px 25px 31px;
    width: 100%;
    max-width: 1014px;
  }
  .billing_form_title{
    font: 500 18px/22px "Roboto";
    color: #424242;
    margin-bottom: 26px;
  }
  .billing_configure_form .label{
    font: 500 16px/21px "Roboto";
    color: #333758;
  }
  .label.bill_label{
    margin-bottom: 8px;
    display: inline-block;
  }
  .form_input_field{
    position: relative;
  }
  .form_input_field span.error{
    position: absolute;
    bottom:3px;
  }
  .billing_configure .MuiGrid-spacing-xs-2 > .MuiGrid-item.billing_form_field{
    padding-top:0 ;
    padding-bottom:0 ;
  }
  .billing_configure .MuiInputBase-root{
    height: 38px !important;
  }
  .billing_configure .MuiInputBase-root input{
    padding: 13px 17px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
  }
  .add_card_btn{
    padding:12px !important;
    font: 500 14px/17px "Roboto";
    color: #424242 !important;
    background-color: #F6F6F6 !important;
    border-radius: 5px !important;
    border:none !important;
  }
  .billing_configure_form .add_card_btn{
    width: 100%;
    margin:8px 0 10px;
    padding:10px 0;
    font: 500 14px/19px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    text-transform: capitalize;
    background: #F6F6F6;
    border-radius: 5px;
    margin: 6px 0 10px;
  }
  .billing_configure_form .form_input_field span.error{
    bottom:0;
  }
  
  /* Billing Overview */
  .billing_overview_sections .MuiGrid-root.MuiGrid-item > *{
    min-height: 100%;
  }
  .billing_overview_section{
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 23px 25px;
  }
  .billing_overview_section .overview_section_heading{
    font: 500 18px/24px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  .bill_estimate_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 18px 20px;
  }
  .bill_estimate_container h4{
    font: normal 900 21px/21px "Roboto";
    color: #030303;
    margin-bottom:8px;
  }
  .bill_estimate_container span{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
  }
  .bill_estimate_container p{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom:0;
  }
  .billing_history_list{
    margin:0;
    padding:0;
  }
  .billing_history_list li{
    background: #FBFBFB;
    border-radius: 5px;
    margin-bottom:15px;
    padding: 10px 20px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .billing_history_list li>:last-child{
    margin-bottom: 0;
  }
  .billing_history_list li p{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  .bill_contact_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 16px 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .bill_contact_image_section{
    margin-right:9px;
  }
  .bill_contact_content_section{
    overflow: hidden;
  }
  .bill_contact_content_section > *{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bill_contact_profile{
    width:43px;
    height:43px;
    object-fit: cover;
    border-radius: 50%;
  }
  .bill_contact_username{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  .bill_contact_email{
    font: normal 14px/19px "Roboto";
    color: #A2A2A2;
    margin-bottom: 0;
  }
  .bill_details_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding:9px 15px 11px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .bill_period_heading{
    font: 500 14px/19px "Roboto";
    color: #5B5B5B;
    margin-bottom: 0;
    margin-right: 39px;
    text-transform: capitalize;
  }
  .bill_period{
    font: normal 14px/19px "Roboto";
    color: #646670;
    margin-bottom: 0;
  }
  .bill_details_card_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 15px;
    display: flex;
  }
  .bill_details_image_section{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    width:33px;
    height:33px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-right:16px;
  }
  .bill_contact_card_image{
    width:22px;
    height:16px;
    object-fit: cover;
  }
  .bill_details_card_heading{
    font: normal 14px/19px "Roboto";
    color: #A2A2A2;
    margin-bottom: 0;
  }
  .bill_details_card_number{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 2px;
  }
  .bill_details_card_number span{
    font-size: 40px;
    line-height: 0;
    display: inline-block;
    transform: translate(0px, -2px);
    margin-right: 5px;
    letter-spacing: -2px;
  }
  .bill_details_card_holder{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 4px;
    margin-bottom: 4px;
  }
  .bill_details_card_expire{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  /* Billing History */
  .billing_history_table{
    max-width:786px;
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    padding: 20px 25px;
    border-radius: 10px;
  }
  .billing_history_table .MuiTableCell-root{
  border-bottom:0;
  }
  .billing_history_table thead.MuiTableHead-root .MuiTableRow-head{
    background: #4759A7;
  }
  .billing_history_table .MuiTableCell-head{
    padding: 17px 15px 19px;
    font: 700 14px/19px "Roboto";
    color: #FFFFFF;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .billing_history_table .MuiTableCell-body {
    padding:0 !important;
    white-space: nowrap;
  }
  .billing_history_table .MuiTableCell-body div{
    padding: 17px 15px 19px;
    font: 700 14px/19px "Roboto";
    background: #F5F5F5;
    margin-bottom: 15px;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status{
    padding: 16px 15px;
  }
  .billing_history_table tr:first-child td.MuiTableCell-body div{
    margin-top:8px;
  } 
  .billing_history_table tr:last-child td div{
    margin-bottom: 0;
  }
  .billing_history_table thead tr td:first-child div, .billing_history_table thead tr th:first-child,
  .billing_history_table tbody tr td:first-child div, .billing_history_table tbody tr th:first-child{
    padding: 17px 15px 19px 20px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
 }
  .billing_history_table thead tr td:last-child div, .billing_history_table thead tr th:last-child,
  .billing_history_table tbody tr td:last-child div, .billing_history_table tbody tr th:last-child{
    padding: 17px 20px 19px 15px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
 }
  .billing_history_table tbody.MuiTableBody-root .billing_invoice_section{
    background: transparent;
  }
  .billing_history_table .billing_invoice_section .MuiTableCell-body{
    font: 500 14px/19px "Roboto";
    color: #606060;
    text-transform: uppercase;
    padding: 8px 0 15px;
  }
  .billing_history_table .billing_invoice_section.billing_history_section .MuiTableCell-body{
    padding: 13px 0 15px;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_date{
    font: 500 14px/19px "Roboto";
    color: #303960;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_number{
    font: 500 14px/19px "Roboto";
    color: #5B5B5B;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_plan,
  .billing_history_table .MuiTableCell-body div.billing_history_amount{
    font: normal 14px/19px "Roboto";
    color: #6A6A6A;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status p.billing_history_paid{
    font: normal 14px/19px "Roboto";
    color: #363636;
    width: 58px;
    height: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(101, 236, 114, 31%);
    border-radius: 11px;
    margin-bottom: 0;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status p.billing_history_unpaid{
    font: normal 14px/19px "Roboto";
    color: #363636;
    width: 75px;
    height: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #FEDE93;
    border-radius: 11px;
    margin-bottom: 0;
  }
  .billing_history_action img{
    margin-left: 7px;
  }

  .billing_detail .bill_details_container {
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
}
.billing_detail .bill_details_container div {
  display: flex;
}
.billing_detail .bill_details_container .bill_period {
  font: bold 14px/19px "Roboto";
}
.switch_annual_paymt {
  color: #4759A7;
  font: 500 14px/19px "Roboto";
  cursor: pointer;
  border: 0;
  background: transparent;
}
.switch_annual_paymt:disabled{
  cursor: not-allowed;
}
  .billing_detail .bill_details_card_container{
    margin-bottom: 23px;
    position: relative;
  }
  .billing_detail .bill_edit_btn {
    position: absolute;
    right: 10px;
    text-transform: capitalize;
    bottom: 10px;
    background-color: #E3E3E3;
    border-radius: 5px;
    color: #424242;
    font: 500 14px/19px "Roboto";
}
.bill_edit_btn img {
  margin-right: 6.5px;
}
  .billing_detail .contact_detail_info {
  padding: 11px 15px;
  margin-bottom: 15px;
  background-color: #F1F4FF;
  border-radius: 5px;
  font: normal 13px/18px "Roboto";
  color: #424242;
}
.billing_detail .contact_detail_info img{
  margin-right: 9.5px;
}
.contact_detail_main_header {
  font: 500 16px/21px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.contact_detail_main_values {
  border: 1px solid #F3F3F3;
  border-radius: 10px;
  padding: 15px 13px 10px 10px;
  position: relative;
}
.contact_detail_main_key {
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.contact_detail_main_key_info {
  font: normal 13px/18px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 13px;
}
.contact_detail_main {
  margin-bottom: 20px;
}
.contact_detail_main_footer p {
  font: 500 14px/19px "Roboto";
  margin: 0px;
  color: #424242;
  padding: 11px 27px;
  background-color: #F6F6F6;
  border-radius: 5px;
  margin-right: 40px;
  cursor: pointer;
}
.contact_detail_main_footer span{
  align-self: center;
  font: 500 14px/19px "Roboto";
  margin: 0px;
  color: #4759A7;
  cursor: pointer;
}
.billing_detail .billing_overview_sections .MuiGrid-root.MuiGrid-item > * {
  min-height: unset;
}
.billing_estimate{
  padding: 23px 20px 20px 20px;
}
.contact_detail_main_footer{
  display: flex;
}
.billing_estimate .overview_section_heading {
  margin-bottom: 3px !important;
}
.billing_estimate_date {
  color: #646670;
  font: normal 12px/16px "Roboto";
  margin-bottom: 20px;
  display: block;
}
.billing_estimate_price_secion {
  padding: 20px 15px;
  background-color: #FBFBFB;
  border-radius: 10px;
  margin-bottom: 20px;
}
.billing_estimate_price_plans {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}
.billing_estimate_price_plan {
  margin: 0px;
  font: 500 14px/19px "Roboto";
  color: #424242;
}
.billing_estimate_price_plan_amount{
  margin: 0px;
  font: bold 14px/19px "Roboto";
  color: #424242;
}
.billing_estimate_price_plan_duration {
  color: #646670;
  font: normal 12px/16px "Roboto";
  display: block;
  margin-bottom: 20px;
}
.billing_estimate_price_plans_main {
  margin-bottom: 18.5px;
  border-bottom: 1px solid #dfdfdf;
}
.billing_estimate_price_tax {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18.5px;
}
.billing_estimate_price_tax.billing_estimate_amount{
  margin-bottom: 41px;
}
.billing_estimate_price_tax p {
  margin: 0px;
  font: 500 12px/16px "Roboto";
  color: #959595;
}
.billing_estimate_price_total {
  display: flex;
  justify-content: space-between;
  
}
.billing_estimate_price_total p {
  margin: 0px;
  font: 500 16px/21px "Roboto";
  color: #959595;
}
.billing_estimate_price_total .billing_estimate_price_total_amount {
  font: 600 16px/21px "Roboto";
  color: #424242;
}
.billing_estimate .view_full_estimate_btn{
  border: 1px solid transparent;
  width: 100%;
  padding: 10px;
  font: 500 14px/19px "Roboto";
}
/* Billing Estimate */
.billing_estimate_section{
  background: #FFFFFF;
  box-shadow: 0px 23px 36px #00000008;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}
.billing_estimate_main{
  padding: 33px 25px 25px;
}
.billing_estimate_contact{
  padding: 23px 20px 25px;
}
.billing_estimate_time{
font: normal 16px/21px "Roboto";
color: #424242;
margin-bottom:20px;
}
.billing_estimate_subscription_container{
  padding: 11px 15px;
  background: #F1F4FF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.billing_estimate_subscription_container img{
  margin-right: 10px;
}
.billing_estimate_subscription_container p{
  font: normal 12px/16px "Roboto";
  color: #424242;
  margin-bottom:0;
}
.billing_estimate_subscription_container p span{
  color: #3C57CB;
}
.billing_estimate_plan_container{
  background: #F5F5F5;
  border-radius: 10px;
  padding: 25px 20px 25px 25px;
  display: flex;
  margin-bottom: 10px;
}
.billing_estimate_plan_container img{
  margin-right: 15px;
}
.billing_estimate_plan_name{
  font: 700 18px/24px "Roboto";
  color: #424242;
  margin-bottom:5px;
}
.billing_estimate_plan_period{
  font: normal 12px/16px "Roboto";
  color: #424242;
  margin-bottom: 0;
}
.billing_estimate_plan_price{
  font: 700 16px/21px "Roboto";
  color: #171717;
  margin-bottom: 0;
  margin-left: auto;
}
.billing_estimate_tax_container{
  background: #FCFCFC;
  border-radius: 10px;
  padding: 10px 20px 10px 25px;
  margin-bottom: 15px;
  display: flex;
}
.billing_estimate_tax_container h6{
  font: 500 16px/21px "Roboto";
  color: #424242;
  margin-bottom: 0;
}
.billing_estimate_total{
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  padding: 24px 20px 25px 25px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.billing_estimate_total h5{
  font: 700 21px/28px "Roboto";
  color: #303960;
  margin-bottom: 0;
}
.billing_estimate_message{
  font: 500 12px/16px "Roboto";
  color: #646670;
  margin-bottom: 0;
}
.billing_estimate_contact h5{
  font: 500 18px/24px "Roboto";
  color: #424242;
  margin-bottom: 20px;
}
.billing_estimate_contact p{
  font: normal 16px/21px "Roboto";
  color: #3C57CB;
  margin-bottom: 14px;
}
.billing_estimate_contact p:last-child{
  margin-bottom: 0;
}
.billing_detail .billing_send_invoice_label{
  font: 500 16px/21px "Roboto" !important;
  color: #333758 !important;
}
.update_billing_contact .MuiPaper-root{
  max-width:600px;
  width:100%;
}
/* manage subcription */
.manage_subscriptions_section{
  padding: 23px 25px 20px;
  background: #FFFFFF;
  box-shadow: 0px 23px 36px #00000008;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}
.active_subscription_heading{
  font: 500 18px/24px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.subscription_plan_container{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 17px 20px 20px;
}
.subscription_plan_heading_section{
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.subscription_plan_heading_section img{
  width:34px;
  margin-right: 8px;
}
.subscription_plan_heading_section h5{
  font: 700 16px/21px "Roboto";
  letter-spacing: 0px;
  color: #313131;
  margin-bottom: 0;
}
.subscription_plan_detail_section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  border-bottom: 1px solid #E8E8E8;
}
.subscription_plan_detail_section h5{
  font: 500  12px/16px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.subscription_plan_detail_section h6{
  font: normal 12px/16px "Roboto";
  letter-spacing: 0px;
  color: #787878;
}
.subscription_plan_cancel{
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #646670;
  margin-bottom: 0;
  text-align: end;
  cursor: pointer;
}
.subscription_plan_cancel:hover{
  color:#EE0E0E;
}
.subscription_plan_actions{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.subscription_plan_change{
  display: flex;
  margin-top: 14px;
}
.subscription_plan_change h5{
  font: 700 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #303960;
  margin-bottom: 0;
  padding-right: 10px;
  border-right: 1px solid #B8B8B8;
}
.subscription_plan_change p{
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #21BF73;
  margin-bottom: 0;
  padding: 0 10px;
  cursor: pointer;
}
.subscription_plan_actions .manage-btn{
  padding:8px 29px;
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  border-radius: 5px;
  background: #F6F6F6;
  margin-top: 14px;
}
.billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div{
  margin-top: 5px;
}
.billing_page.pending_status .reports_down_tab {
padding: 0;
}
.billing_page .Acc-Table.export_btn{
  background: transparent;
}
.billing_page .export_btn button{
  min-width:130px;
}
.billing_page.pending_status, .billing_page.subscriptions_page{
  padding: 28px 10px 16px
}
.card_added_success{
  margin: 6px 0 10px;
  border: 1px dashed #21bf73;
  background-color: #f2fcf8;
  border-radius: 5px;
  padding:12px;
  width: 100%;
  color: #21bf73;
  text-align: center;
}
.card_added_success img{
  margin-bottom:2px;
}
.comp_container{
  display: flex;
  align-items: center;
  margin: 0 6px 17.5px;
}
.comp_container > img{
  width: 44px;
  height: 44px;
  object-fit: cover;
  object-position: center;
  border-radius: 25px;
  border: 1px solid #E5E5E5;
}
.comp_container .team_level{
  width: 44px;
  height: 44px;
  object-fit: contain;
  object-position: center;
  border-radius: 25px;
  border: 1px solid #E5E5E5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.comp_container .team_level img{
  width: 21.5px;
  height: 21.5px;
}
.comp_container .comp_title{
  margin-left: 15px;
}
.comp_container .comp_title h5{
  font: bold 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #2D2D2D;
  margin-bottom: 3px;
  text-align: left !important;
}
.comp_container .comp_title p{
  font: normal 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #5D5D5D;
  margin-bottom: 0;
  text-align: left !important;
}
.billing_overview_container .billing_history_table{
  max-width: none;
}
.subscriptions_custom_filter input:-webkit-autofill,
.subscriptions_custom_filter input:-webkit-autofill:hover, 
.subscriptions_custom_filter input:-webkit-autofill:focus, 
.subscriptions_custom_filter input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s;
  transition: background-color 5000s;
}
.bill_contact_details{
  margin-bottom: 6px;
}
.bill_contact_heading{
  color: #424242;
  font: 500 14px/19px "Roboto";
  margin-bottom: 5px;
}
.bill_contact_details p{
  color: #424242;
  font: normal 13px/18px "Roboto";
  margin-bottom: 15px;
}
.bill_plan_section{
  padding: 9px 11px 11px;
  background-color: #FBFBFB;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.bill_plan_title{
  color: #5B5B5B;
  font: 500 14px/19px "Roboto";
  margin-bottom: 0;
  margin-right: 20px;
}
.bill_current_plan{
  color: #646670;
  font: bold 14px/19px "Roboto";
  margin-bottom: 0;
  margin-right: 20px;
}
.bill_plan_switch{
  color: #4759A7;
  font: 500 14px/19px "Roboto";
  margin-bottom: 0;
  margin-left:auto;
}
.billing_due_total{
  margin-top:-4px;
  margin-bottom: 15px;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 18px;
}
.billing_due_total h6{
  font: bold 18px/24px "Roboto";
  color:#303960;
  margin-bottom: 0;
}
.billing_overview_container .view_full_estimate_btn{
  padding: 14px 10px; 
}
.pagination_container_history ul{
  justify-content: flex-end;
  padding:12px 0;
}
.no-data-found{
  color: #424242;
  font: 500 14px/19px "Roboto";
  margin-bottom: 5px;
  background-color: transparent !important;
  margin-bottom:0 !important;
}
.card_added_failure{
  margin-bottom:5px;
  background-color: rgba(232, 71, 29, 0.18);
  border-radius: 4px;
  padding: 9px 12px;
  color: #E8471D;
  font: normal 16px/21px "Roboto";
  text-align: center;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogContent-root{
  padding-bottom:0;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogContentText-root{
  margin-bottom: 0;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogActions-root{
  padding: 0 20px 20px 20px;
}
.subscribing_section{
  display: flex;
  justify-content: space-between;
  align-items:center;
  flex-wrap: wrap;
  margin-bottom: 13px;
}
.billing_main_container .subscribing_section{
  margin: 28px 70px 1px;
  
} 
.subscribing_section .add_company_action{
  margin-bottom: 5px;
  margin-top: 0;
}
.subscribing_section .add_company_action .ok_btn{
  font: 500 14px/19px "Roboto" !important;
}
.bill_multi_card_container .bill_card_left{
  display: flex;
}
.bill_multi_card_container .bill_details_card_container{
  display: flex;
  justify-content: space-between;
  padding: 16px 13px 14px 25px;
  margin-bottom: 10px;
}
.bill_multi_card_container .bill_details_card_container:last-child{
  margin-bottom: 0;
}

/* Invoice Page */
.billing_page.billing_invoice{
  max-width: 1058px;
}
.billing_heading_section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.billing_heading_section{
  margin-bottom: 19px;
}
.billing_heading_section .billing_page_title{
  margin-bottom: 0;
}
.billing_heading_section .logout-button{
  padding: 10px 17px;
  font: 500 14px/19px "Roboto";
}
.billing_heading_section .logout-button img{
  margin-right: 13px;
}
.billing_invoice_section{
  padding: 26px 29px 36px 35px;
}


.billing_invoice .invoice_heading {
  padding: 0 0 10px;
  border-bottom: 1px solid #F1F1F3;
  display: flex;
  justify-content: space-between;
}
.billing_invoice .invoice_heading_right {
  text-align: right;
}
.billing_invoice .company_name {
  font: normal 28px/39px "Roboto";
  color: #43425D;
  margin-bottom: 14px;
}
.billing_invoice .invoice_heading_left .mail_section {
  margin-bottom: 38px;
}
.billing_invoice .invoice_heading_right .mail_section {
  margin-bottom: 15px;
}
.billing_invoice .mail_section h6 {
  font: bold 14px/25px "Roboto";
  color: #515465;
  margin-bottom: 2px;
}
.billing_invoice .mail_section p {
  font: normal 14px/19px "Roboto";
  color: #4D4F5C;
  margin-bottom: 0;
}
.billing_invoice .bill_due {
  font: bold 30px/52px "Roboto";
  color: #515465;
  margin-bottom: 0;
}
.billing_invoice .invoice {
  font: normal 20px/28px "Roboto";
  color: #43425D;
  margin-bottom: 14px;
}
.billing_invoice .invoice_date {
  list-style: none;
  margin-bottom: 0;
  padding-left:0;
}
.billing_invoice .invoice_date li {
  font: normal 14px/19px "Roboto";
  color: #4D4F5C;
  margin-bottom: 4px;
}
.billing_invoice .invoice_date li:last-child{
  margin-bottom: 0;
}
.billing_invoice .invoice_date_content {
  display: inline-block;
  min-width: 118px;
}
.billing_invoice .invoice_date li .invoice_date_label {
  font: 500 14px/19px "Roboto";
}
.billing_invoice .invoice_detail {
  padding: 24px 0 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.billing_invoice .invoice_detail ul{
  margin-bottom: 0;
  padding-left: 0;
}
.billing_invoice .invoice_detail li {
  list-style: none;
  font: normal 14px/25px "Roboto";
  color: #515465;
  margin-bottom: 6px;
}
.billing_invoice .invoice_detail li .invoice_detail_label {
  font: 500 14px/25px "Roboto";
  display: inline-block;
}
.billing_invoice .invoice_detail_first li .invoice_detail_label {
  min-width: 77px;
}
.billing_invoice .invoice_detail_second li .invoice_detail_label {
  min-width: 50px
}
.billing_invoice .invoice_detail_third li .invoice_detail_label {
  min-width: 69px
}
.billing_invoice .invoice_table {
  width: 100%;
}
.billing_invoice .invoice_table {
  border-spacing: 0;
  padding-bottom: 2px;
  border-bottom: 1px solid #F1F1F3;
  margin-bottom: 17px;
}
.billing_invoice .invoice_table th {
  background-color: #F5F6FA;
  padding: 8px 9px;
  border-spacing: 0;
  font: bold 14px/19px "Roboto";
  color: #A3A6B4;
  margin-bottom:0;
  border-bottom: none;
}
.billing_invoice .invoice_table td {
  font: normal 14px/19px "Roboto";
  padding: 12px 9px;
  color: #4D4F5C;
  margin-bottom: 0;
  border-bottom: none;
  white-space: nowrap;
}
.billing_invoice .invoice_total {
  text-align: end;
}
.billing_invoice .invoice_total h5 {
  font: normal 14px/19px "Roboto";
  color: #43425D;
  margin-bottom: 10px;
}
.billing_invoice .invoice_total h5 .invoice_total_label {
  font: bold 14px/19px "Roboto";
  min-width: 147px;
  display: inline-block;
  margin-right: 13px;
}
.billing_invoice .invoice_total .total_amount {
  font: bold 20px/28px "Roboto";
  color: #43425D;
  margin-bottom: 0;
}
.billing_overview_section .billing_estimate{
  padding:0;
  margin-top: 15px;
}
.billing_overview_section .billing_estimate .view_full_estimate_btn{
  max-width: 300px;
}

/* cost break up */
.credit_card_dialog.cost_breakup_dialog .MuiPaper-root{
  max-width: 427px;
}
.add_company_dialog.cost_breakup_dialog .MuiDialogContent-root{
  padding: 12px 16px 17px;
}
.cost_breakup_table th{
  font: 500 14px/19px  "Roboto";
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  padding: 0 2px 13px;
  border: 0;
}
.cost_breakup_table td{
  border:0;
  padding:0;
  padding-bottom:11px;
}
.cost_breakup_table td div{
  background: #FBFBFB;
  font: 500 14px/19px  "Roboto";
  color: #424242;
  letter-spacing: 0px;
  border: 0;
  text-align: center;
  padding: 10px 2px 10px;
  position: relative;
}
.cost_breakup_table td div:after{
  content: "";
  position: absolute;
  width:1px;
  height: 18px;
  background-color: #DFDBDB;
  right:0;
  top:50%;
  transform: translate(0, -50%);
}
.cost_breakup_table td:last-child div:after{
  content: none;
}
.cost_breakup_table tr td:first-child div{
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.cost_breakup_table tr td:last-child div{
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.cost_breakup_total{
  text-align: end;
  margin-top: 3px;
}
.cost_breakup_total h5 {
  font: normal 14px/19px "Roboto";
  color: #424242;
  margin-bottom: 7px;
}
.cost_breakup_total h5 .cost_breakup_total_label{
  font: 500 14px/17px "Roboto";
  display: inline-block;
  margin-right: 15px;
}
.cost_breakup_total h5 .cost_breakup_total_amount, .cost_breakup_total .total_amount .cost_breakup_total_amount{
  display: inline-block;
  min-width: 62px;
}
.cost_breakup_total .total_amount {
  font: 500 16px/17px "Roboto";
  color: #43425D;
  margin-bottom: 0;
}
.cost_breakup_total h3.total_amount .cost_breakup_total_label{
  font: bold 16px/17px "Roboto";
  margin-right: 15px;
}
/* cost break up */

/* multi-card */
/* The container */
/* The container */
.bill_card_select_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.bill_card_select_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.bill_card_select_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.bill_card_select_container input:checked ~ .checkmark {
  background-color:#4759A7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.bill_card_select_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.credit_card_dialog.multi_card_dialog .MuiPaper-root{
 max-width: 623px;
}
.bill_multi_card_container{
  padding: 23px 15px 0;
}
.bill_multi_card_container .bill_details_section{
  margin-left: 5px;
}
.bill_card_right{
  text-align: right;
}
.bill_card_select_container .checkmark:after {
  top: 5.4px;
  left: 5.4px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.expiry_details_field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -5px;
}
.expiry_details_field > div{
  margin: 0 5px;
  width:100%;
}
.bill_card_buttons{
  margin: 0 -10px;
}
.bill_card_buttons button{
  padding:0; 
  margin: 0 10px;
  font: normal 14px/19px "Roboto";
  color: #828282;
  text-transform: capitalize;
  min-width: 0;
}
.bill_card_buttons button:last-child{
  color: #4759A7;
}
.bill_card_buttons button:hover{
  background-color: transparent;
  text-decoration: underline;
}
.atm_card_image{
  margin-bottom: 8px;
}
/* multi-card */
@media (max-width:1121px){
  .billing_page.pending_status .filter-search{
    flex-wrap: wrap;
  }
  .billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div{
    margin-bottom:10px;
    margin-right: 10px;
  }
  .billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div:last-child{
    margin-top: 0;
    width:auto;
  }
 
}
@media (min-width:769px) and (max-width:1121px){
  .billing_page.billing_invoice{
    padding: 28px 30px 16px;
  }
}
  @media (max-width: 992px){
    .billing_configure{
      padding-left:40px;
    }
    .billing_history_table .MuiTableCell-body {
      white-space: nowrap;
      max-width: none;
    }
    .subscription_plan_actions{
      flex-wrap: wrap;
    }
  }
  @media (min-width:769px) and (max-width: 992px){
    .billing_main_container .billing_page{
      padding: 16px 30px;
    }
    .billing_main_container .subscribing_section{
      margin: 20px 30px 1px;
    }
    .billing_invoice .invoice_heading{
      flex-wrap: wrap;
    }
    .billing_invoice .invoice_heading_right{
      text-align: left;
      width:100%;
    }
  }
  @media (max-width: 768px){
    .billing_container{
      flex-wrap: wrap;
    }
    .billing_sidebar{
      width: 100%;
      min-height: auto;
    }
    .billing_main_container{
      width:100%;
    }
    .billing_main_container .billing_page{
      width:100%;
      padding:16px 15px;
    }
    .billing_page{
      width:100%;
      padding: 20px 15px;
    }
    .billing_main_container .subscribing_section{
      margin: 20px 15px 1px;
    }
    .billing_page.pending_status, .billing_page.subscriptions_page{
      padding:20px 0;
    }
    .billing_sidebar > *{
      text-align: center;
    }
  }
  @media (max-width: 699px){
    .billing_invoice .invoice_heading{
      flex-wrap: wrap;
    }
    .billing_invoice .invoice_heading_right{
      text-align: left;
      width:100%;
    }
    .expiry_details_field{
      flex-wrap: wrap;
    }
  }
  @media (max-width: 480px){
    .billing_configure{
      padding: 20px 5px;
    }
    .billing_configure_form {
      padding: 25px 15px 31px;
    }
    .billing_container .add_company_action .cancel_btn {
      min-width: 120px;
      margin-right: 10px;
    }
    .billing_detail .bill_details_container{
      display: block;
    }
    .switch_annual_paymt{
      margin-top: 10px;
    }
    .bill_plan_section{
      flex-wrap: wrap;
    }
    .bill_plan_section > *{
      margin-top: 5px;
    } 
    .bill_plan_switch{
      margin-left: 0;
    }
    .billing_invoice_section{
      padding: 15px 15px 25px;
    }
    .billing_invoice  ul{
      width:100%;
    }
    .billing_invoice  li{
      display: flex;
    }
    .billing_invoice  li span{
      display: inline-block;
    }
    .billing_invoice  li .invoice_detail_label{
      min-width: 70px !important;
    }
    .manage_subscriptions_section.credit_card_add_section {
        padding: 23px 10px 20px;
    }
    .add_company_dialog.multi_card_dialog .MuiDialogContent-root{
      padding-right:0;
      padding-left:0;
    }
    .bill_multi_card_container .bill_details_card_container{
      flex-wrap: wrap;
    }
    .bill_details_card_container .bill_card_left{
      width:100%;
    }
    .bill_details_card_container .bill_card_right{
      text-align: left;
      margin-left: 40px;
      margin-top: 5px;
    }
  }
  @media (min-width:769px) and (max-width:840px){
    .billing_detail .bill_details_container{
      display: block;
    }
    .switch_annual_paymt{
      margin-top: 10px;
    }

  }
/* .card_details_form_container{
    min-width: 100vh;
    min-height: 100vh;
}
.card_details_form{
    max-width:600px;
    border-radius:5px;
    margin: 40px auto 0;
    padding:20px 30px;
}
.card_detail_input_field{
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font: normal 16px/21px "Roboto" !important;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    min-width:100%;
}
.card_pay_button{
      white-space: nowrap;
      border: 0;
      outline: 0;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      color: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      background-color: #6772e5;
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin-top: 10px;
      width:100%;
}
.card_pay_button:hover{
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}


.form_input_field{
    position: relative;
  }
  .form_input_field span.error{
    position: absolute;
    bottom:3px;
  } */
.credit_card_dialog .MuiPaper-root{
    width:100%;
    max-width:661px;
}
.card_detail_input_field{
    border: 1px solid #E2E5F2 !important;
    margin: 2px 0 4px;
    padding: 9px 17px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    height: 38px;
    border-radius: 4px;
}
.card_detail_input_field .ElementsApp .InputElementinput{
    color: #424242 !important;
    font: normal 16px/21px "Roboto" !important;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field:hover, .card_detail_input_field:focus{
    border: 1px solid #14193A !important;
}
.card_pay_button{
    width:100%;
}
.card_cvc_number_field{
    position: relative;
}
.card_cvc_number_field > img{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0 , -50%);
}
@media (max-width: 959px){
    .credit_card_dialog .MuiGrid-item.card_expiration_date{
        padding-bottom:0;
    }
    .credit_card_dialog .MuiGrid-item.card_cvc_number{
        padding-top:0;
    }
}

.accept_email_invite h1 {
    color: #3A3A3A;
    font: bold 42px/55px "Roboto";
    margin-bottom: 15px;
    text-align: center;
}
.accept_email_invite {
    height: 100vh;
    display: flex;
    margin: 0px 10px;
    justify-content: center;
    flex-flow: column;
}
.accept_email_invite p {
    color: #303960;
    margin: 0px;
    font: normal 19px/25px "Roboto";
    text-align: center;
}
/* SideBar */
.settings_sidebar{
    padding: 27px 20px 27px 21px;
}
.sidebar_heading{
    font: normal 600 24px/31px "Roboto";
    letter-spacing: 0px;
    color: #303960;
    padding-left: 14px;
    margin-bottom: 25px;
}
.settings_sidebar .configure_btn{
    padding: 9px 10px 9px 12px;
    color: #424242;
    display: flex;
    align-items: center;
}
.settings_sidebar .configure_btn.active,
.settings_sidebar .configure_btn:hover{
    color: #295195;
}
.settings_sidebar .configure_btn > span{
    border: 1px solid #E5E5E5;
    width:44px;
    height:44px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
}
.settings_sidebar .configure_btn > span img.blue-img{
    position: absolute;
    opacity: 0;
}
.settings_sidebar .configure_btn > span.manage-profile-images img{
    width: 19px;
}
.settings_sidebar .configure_btn > span.passoword-images img{
    width: 14px;
}
.settings_sidebar .configure_btn > span.notication-images img{
    width: 16px;
}
.settings_sidebar .configure_btn:hover > span img.blue-img, .settings_sidebar .configure_btn.active > span img.blue-img{
    opacity: 1;
}
/* SideBar */

/* Settings Content */
.settings_page{
    padding: 60px 92px 51px;
}
.settings_page > div{
    max-width: 830px;
    margin-left:0;
}
/* Settings Content */

/* Notifications */
.notifications-container{
    background: #FFFFFF;
    box-shadow: 0px 3px 22px #0000001A;
}
.notifications-header .MuiTableCell-head{
    padding: 16px 28px 20px;
    background: #FCFCFE;
    border-bottom: 0;
}
.notifications-header h4{
    font: normal 600 24px/31px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 0;
}
.notifications-header h5{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #333758;
    margin-bottom: 0;
}
.notifications-container .MuiFormControlLabel-labelPlacementStart{
    margin-left:0;
}
.notifications-container .MuiTableCell-body{
    padding:8px 28px;
    border-bottom: none;
}
.notifications-container .MuiTableCell-body h6{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #858585;
    margin-bottom: 0;
}
.notifications-container .MuiTableCell-body p{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color:#333758;
    margin-bottom: 0;
}
.notifications-container .notifications-title.MuiTableCell-body{
    padding: 28px 28px 6px;
}
.notifications-container .add_company_action{
    padding: 25px 32px 31px;
    margin-top:0;
}
.notifications-container .notifications-body .MuiTableRow-root:first-child .MuiTableCell-body{
    padding-top: 17px;
}
.notifications-container .notifications-body .MuiTableRow-root:last-child .MuiTableCell-body{
    padding-bottom: 31px;
}
/* Notifications */
/* timezone */
.timezone_container > div{
    display: flex;
    justify-content: space-between;
}
.timezone_top{
    padding: 16px 28px 20px;
    background-color: #FCFCFE;
    align-items: center;
}
.timezone_title{
    font: 500 24px/31px "Roboto";
    color: #424242;
    margin-bottom: 0;
}
.timezone_content{
    font: normal 16px/21px "Roboto";
    margin-bottom: 0;
    color: #646670;
}
.timezone_middle{
    padding: 15px 28px 20px;
    align-items: center;
}
.timezone_date{
    font: 500 16px/21px "Roboto";
    color: #333758;
    margin-bottom: 0;
}
label.timezone_date{
    white-space: nowrap;
}
.timezone_bottom{
    align-items: flex-start;
    padding:17px 28px 25px;
    background-color: #FBFBFB;
}
.timezone_bottom > div{
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.timezone_bottom .ant-select{
    max-width: 300px;
    font: 500 16px/21px "Roboto" !important;
    color: #333758;
    margin-bottom: 14px;
    margin-top:0;
} 
.timezone_bottom .ant-select .ant-select-selection-item{
    padding-right:28px
}
.timezone_container .timezone_bottom .ant-select .ant-select-selector,
.timezone_container .timezone_bottom .ant-select .ant-select-selector input{
    background-color: transparent;
    border:none !important;
    box-shadow: none !important;
    height:21px !important;
    padding:0;
    width:300px;
}
.set-time-button{
    border: 1px solid #295195;
    background-color: #F1F6FF;
    border-radius: 5px;
    font: 500 11px/14px "Roboto";
    color: #295195;
    padding: 7px 8px 8px;
    margin-bottom: 0;
    cursor: pointer;
}
.set-time-button:hover{
    background-color: #295195;
    color:#ffffff;
}
.set-time-button:focus{
    outline: none;
}
/* Password & Security */
.password_header{
    background-color: #FCFCFE;
    padding: 16px 28px 20px;
}
.password_header h5{
    font: 500 24px/31px "Roboto"; 
    color: #424242;
    letter-spacing: 0;
    margin-bottom: 0;  
}
.password_body{
    padding: 18px 32px 33px 28px;
}
.password_rules{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 22px;
}
.password_rules h6{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
    letter-spacing: 0;
    margin-bottom: 12px;
}
.password_rules ul{
    padding:0;
    margin-bottom: 0;
    list-style: none;
}
.password_rules li{
    font: normal 16px/26px "Roboto";
    color: #929292;
    letter-spacing: 0;
    margin-bottom: 3px;
}
.password_rules ul li:last-child{
    margin-bottom: 0;
}
.notifications-container.password-container .add_company_action{
    padding:0;
    margin-top: 6px;
}
/* Password & Security */
@media (min-width:769px) and (max-width: 1122px){
    .settings_page{
        padding: 60px 30px 51px; 
    }
    .timezone_top, .timezone_middle, .timezone_bottom{
        padding-right:10px;
        padding-left:10px;
    }
}
@media (max-width: 959px){
    .password_rules{
        margin-left: 0;
    }
}
@media (max-width: 768px){
    .settings_page{
        padding:20px 15px !important;
    }
    .settings_sidebar .sidebar_heading{
        text-align: left;
    }
    .timezone_container > div{
        flex-wrap: wrap;
    }
    .timezone_top, .timezone_middle, .timezone_bottom{
        padding-right:15px;
        padding-left:15px;
    }
    .timezone_top label, .timezone_middle label, .timezone_bottom label{
        width:100%;
        margin-bottom:5px;
    }
    .timezone_bottom > div{
        text-align: left;
        align-items: flex-start;
    }
}
@media (max-width: 480px){
    .notifications-container .add_company_action{
        padding: 25px 17px 31px;
    }
    .password_header {
        padding: 16px 15px 20px;
    }
    .password_body {
        padding: 18px 15px 33px;
    }
}

/* integrations */
.integrations-container{
    background: #FFFFFF;
    box-shadow: 0px 3px 22px #0000001A;
}
.integrations-header .MuiTableCell-head{
    padding: 16px 28px 20px;
    /* background: #FCFCFE; */
    border-bottom: 0;
}
.integrations-header h4{
    font: normal 600 24px/31px "Roboto";
    letter-spacing: 0px;
    margin-bottom: 0;
}
.integrations-header h5{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    margin-bottom: 0;
}
.integrations-container .MuiFormControlLabel-labelPlacementStart{
    margin-left:0;
}
.integrations-container .MuiTableCell-body{
    padding:8px 28px;
    border-bottom: none;
}
.integrations-container .MuiTableCell-body h6{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #858585;
    margin-bottom: 0;
}
.integrations-container .MuiTableCell-body p{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color:#333758;
    margin-bottom: 0;
}
.integrations-container .integrations-title.MuiTableCell-body{
    padding: 28px 28px 6px;
}
.integrations-container .add_company_action{
    padding: 25px 32px 31px;
    margin-top:0;
}
.integrations-container .integrations-body .MuiTableRow-root:first-child .MuiTableCell-body{
    padding-top: 17px;
}
.integrations-container .integrations-body .MuiTableRow-root:last-child .MuiTableCell-body{
    padding-bottom: 31px;
}
.integrations_top{
    padding: 16px 28px 20px;
    background-color: #FCFCFE;
    align-items: center;
}
.integrations_title{
    font: 500 24px/31px "Roboto";
    color: #424242;
    margin-bottom: 0;
}
.integration-row:hover{
    background: #FBFBFB;
}
/* integrations */
.profile-header-content {
    padding: 30px;
    display: flex;
    align-items: center;
}
.avatar-uploader > .ant-upload {
    width: 60px;
    height: 60px;
  }
  .hidden{

    display: none;
}
.avatar_input_file{
	opacity: 0;
    z-index: 999;
}
.avatar_edit_img.avatar_input_file{
    overflow: hidden;
    padding:15px;
    text-indent: 15px;
    border-radius: 50px;
}
.prof_avtar{
    height: 84px;
    width: 84px;
    box-shadow: 0px 3px 6px #00000012;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
}
.prof_avtar .avatar_img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.profile-header-content-two{
    display: block !important;
}
.profile_value{
    margin-bottom: 10px;
    display: flex;
}
.profile_value img{
    margin-right: 8px;
}
.profile_value .profile_value_span{
    color: #2D2D2D;
    font: normal 16px/21px "Roboto" !important;
    
}
.profile_value .edit_icon{
    margin-left: 21px;
    margin-bottom: 4px;
}
.profile_value_seperator{
    margin-left: 16px;
    margin-right: 16px;
}
.profile_value .edit-input img{
    align-self: center;
    margin-left: 10px;
}
.division_img{
    align-self: start;
}
.profile_value_two{
    color: #8D8D8D !important;
}
.key_name {
    color: #858585;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
}
.prof_name h2{
    color: #FFFFFF;
    font: normal 28px/36px "Roboto";
}
.prof_name p{
    color: #FCFCFC !important;
    font: normal 18px/24px "Roboto" !important;
}
.avatar_edit_img{
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    width: 30px;
} 
.division_value{
    display: flex;
    margin-bottom: 9px;

}
.division_value .profile_value_span_key{
    min-width: 60px;
    display: block;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.profile_ErrorMassage{
    margin-bottom: 5px;
    display: block;
    margin-left: 25px;
}
.pro_box_ht{
    height: 150px;
}
.pro_loding{
    position: relative;
    top: -64px;
    left: 15%;
}
.showPro,.showPhone,.showAddress{
    display: none;
}
.manage-accoutn-container{
    min-height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.manage-accoutn-container .signup-body{
    margin:30px;
    padding:0;
}
.profile-header-content-two .profile_value .info-card-detail-left{
    max-width:500px;
}
.prof_avtar{
    position: relative;
}
.avatar_input_file_container::after{
    content:"Please Upload a file with these extensions: jpg, gif, png, jpeg.";
    position: absolute;
    font: normal 12px/16px "Roboto";
    color : #c0cce2;
    left:0;
    bottom: -25px;
    opacity: 0;
    z-index: 0;
    transition: 0.2s linear all;
    width: 350px;
}
.avatar_input_file_note::after{
    opacity: 1;
    z-index: 1;
}

@media (max-width:1130px){
    .manage-accoutn-container{
        min-height: calc(100vh - 67px);
    }
}
@media (max-width:767px){
    .manage-accoutn-container .signup-body{
        margin:30px 15px;
    }
}
@media (max-width:480px){
    .manage-accoutn-container .signup-body{
        margin:30px 5px;
    }
    .profile-header-content-two .profile_value .info-card-detail-left{
        min-width: 220px;
    }
}
.cms_container {
  background-color: #ffffff;
  padding: 20px;
}

.cms_container .cms_label {
  color: #424242;
  font-size: 24px;
}

.cms_container .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.cms_container .ant-table-cell:last-child {
  text-align: right;
  cursor: pointer;
}

.cms_container .module_name {
  color: #333758;
  font-size: 20px;
  text-align: left;
}

.cms_container .last_updated_by {
  color: #b9b9b9;
  font-size: 10px;
}

.cms_container .date_time {
  color: #2d2424;
  font-size: 10px;
}

.cms_container .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-direction: row-reverse !important;
}

.cms_container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transform: rotate(-90deg);
}

.cms_container .ant-collapse {
  border: none;
  background: transparent;
}

.cancel_inputbox {
  margin-right: 10px;
  width: -webkit-fill-available;
  border: 1px solid #e2e5f2;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
}

.item_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 10px;
}

.cancel_edit,
.cancel_delete {
  padding: 8px;
  cursor: pointer;
}
.cms_container .save_note_btn,
.cms_container .cancel_note_btn {
  font-size: 16px;
  color: #6a6a6a;
  border-radius: 4px;
  width: 120px;
}

.cms_container .save_note_btn {
  background-color: #21bf73;
  color: white;
  margin-left: 24px;
}

.cms_container .cancel_note_btn {
  border: 1px solid #bebebe;
  background: transparent;
}

.cms_container .save_note_btn:hover {
  background-color: #21bf73;
}

.cms_container .cancel_note_btn:hover {
  border: 1px solid #bebebe;
  background: transparent;
}

.cms_container .cms_rows {
  flex-flow: nowrap;
  width: 100%;
}

.cms_container .module_updated_date {
  display: flex;
  margin-left: auto;
  padding-right: 24px;
  align-items: center;
}

.cms_container .module_detail {
  position: relative;
  width: 100%;
}

.cms_container .text_input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: #aaaaaa;
  border: 1px solid #e2e5f2;
}

.cms_container .module_detail img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.cms_container .text_input_value {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: black;
  border: 1px solid #e2e5f2;
}

.cms_container .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e8e8e8;
}

.cms_container .ant-collapse > .ant-collapse-item:last-child {
  border-bottom: none;
}

.cms_container .ant-collapse > .ant-collapse-item:first-child {
  border-top: 1px solid #e8e8e8;
}

.cms_container .ant-collapse-content {
  border-top: none;
}

.cms_container .add_reason_cancel_note {
  color: #4759a7;
  margin-bottom: 10px;
  cursor: pointer;
}

.formControlLabel{
  height:40px;
  float: right;
}

.cms_container .MuiFormControlLabel-labelPlacementStart{
  margin-right:0 !important;
}

.customized-scrollbar-container {
  max-height: 210px;
  overflow-y: auto;
}

.customized-scrollbar-container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.customized-scrollbar-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); 
  border-radius: 4px;
}
.credit_card_dialog .MuiPaper-root{
    width:100%;
    max-width:661px;
}
.card_detail_input_field{
    border: 1px solid #E2E5F2 !important;
    margin: 2px 0 4px;
    padding: 9px 17px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    height: 38px;
    border-radius: 4px;
}
.card_detail_input_field .ElementsApp .InputElementinput{
    color: #424242 !important;
    font: normal 16px/21px "Roboto" !important;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field:hover, .card_detail_input_field:focus{
    border: 1px solid #14193A !important;
}
.card_pay_button{
    width:100%;
}
.card_cvc_number_field{
    position: relative;
}
.card_cvc_number_field > img{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0 , -50%);
}
.subscription_page{
    padding: 30px;
}
.subscription_section{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 23px 25px;
    max-width:705px;
}
.subscription_section .bill_details_card_container{
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    margin-right: 20px;
}
.subscription_page .add_company_action{
    justify-content: flex-start;
}
@media (max-width: 959px){
    .credit_card_dialog .MuiGrid-item.card_expiration_date{
        padding-bottom:0;
    }
    .credit_card_dialog .MuiGrid-item.card_cvc_number{
        padding-top:0;
    }
}
@media (max-width: 480px){
    .subscription_page{
        padding: 30px 15px;
    }
    .subscription_section{
        padding:23px 15px;
    }
    .subscription_section .bill_details_card_container{
        min-width: auto;
        margin-right: 0;
    }
}

.textContainer{
    position:-webkit-sticky;
    position:sticky;
    bottom:0px; 
    width:100%;
    z-index: 1;
}

.timeZoneText{
    background-color:#000000;
    color:white; 
    opacity:0.75; 
    text-align:center; 
    padding:15px
}

.updatebtn{
    background:#21BF73; 
    padding:5px 15px; 
    border-radius:5px;
    margin-left:20px; 
    cursor:pointer;
    border: none;
}

.closebtn{
    float:right; 
    cursor:pointer
}
