.add_company_action .completed_btn {
    background: #21BF73 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #FFFFFF !important;
    height: 40px !important;
    text-align: center !important;
    outline: none !important;
    box-shadow: none !important;
}

.add_company_action .completed_btn2 {
    background: #039f54 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #03351d !important;
    height: 40px !important;
    text-align: center !important;
    outline: none !important;
    box-shadow: none !important;
}

.add_company_action .completed_btn:hover {
    background: #039f54 0% 0% no-repeat padding-box !important;
    color: #03351d !important;
}

.add_company_action .uncovered_btn {
    background: #f3d06f 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: white !important;
    height: 40px !important;
    left: 20px !important;
    outline: none;
}

.add_company_action .uncovered_btn2 {
    background: #f2b306 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #cc1837 !important;
    height: 40px !important;
    left: 20px !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.add_company_action .uncovered_btn:hover {
    background: #f2b306 0% 0% no-repeat padding-box !important;
    color: #CC1837 !important;
}

.add_company_action .cancelled_btn {
    background: #cc5065 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: white !important;
    height: 40px !important;
    left: 40px !important;
    box-shadow: none;
}

.add_company_action .cancelled_btn2 {
    background: #cc3f56 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    color: #45040f !important;
    height: 40px !important;
    left: 40px !important;
    box-shadow: none;
}

.add_company_action .cancelled_btn:hover {
    background: #cc3f56 0% 0% no-repeat padding-box !important;
    color: #45040f !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: none !important;
}

.Radio.Button:focus {
    outline: none !important;
    box-shadow: none !important;
}


.removeCiricleIcon{
    margin:auto !important;
}

.custom-ant-picker-select .ant-picker-suffix {
    display: none !important;
}

.to-label.MuiGrid-grid-xs-2 {
    flex-basis: 0% !important;
    align-self: center !important;
}

.time-picker-container.MuiGrid-grid-xs-2 {
    max-width: 21.666667% !important;
    flex-basis: 20.666667% !important;
}

.error-wrapper{
    display: none;
}

.mobile-label-start.MuiGrid-grid-xs-6{
    flex-grow: 0;
    max-width: 53%;
    flex-basis: 53%;
}

.mobile-label-end.MuiGrid-grid-xs-6{
    flex-grow: 0;
    max-width: 47%;
    flex-basis: 47%;
}
.date-time-separator.custom-ant-select .ant-picker{
    padding: 8px;
}
.error-messageor,
.error-wrapper{
    position: absolute;
}

.date-time-separator{
    padding-bottom: 10px;
}

.time-zone{
    padding-bottom: 10px;
}

#picker_addtask {
      padding: 0px !important;
}

.description,
.notes{
    margin-top: 10px !important;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .time-zone {
        margin-top: 82px !important;
    }

    .addtional_rep {
        margin-top: 52px !important;
    }
    
    .to-label {
        display: none !important;
    }

    .mobile-view-container {
        position: absolute !important;
        width: 100% !important;
        left: 18px !important;
    }

    .mobile-label-start {
        position: absolute;
        width: 100% !important;
        left: 18px !important;
    }
    .error-wrapper {
        display: contents;
    }

    .error-wrapper{
        position: relative !important;
    }
    .error-wrapper .error-message{
        position: absolute;
        width: max-content !important;
    } 
    .error-messageor{
        display: none;
    }
    .start-date-picker-container.MuiGrid-grid-xs-3,
    .end-date-picker-container.MuiGrid-grid-xs-3 {
        max-width: 28% !important;
        flex-basis: 28% !important;
    }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
    .to-label {
        display: none !important;
    }

    .time-picker-container.MuiGrid-grid-xs-2 {
        max-width: 40% !important;
        flex-basis: 45.666667% !important;
    }

    .start-date-picker-container.MuiGrid-grid-xs-3,
    .end-date-picker-container.MuiGrid-grid-xs-3 {
        max-width: 60% !important;
        flex-basis: 60% !important;
    }

    .mobile-label-end {
        z-index: 2;
        position: absolute;
        width: 100% !important;
        top: 90px;
    }

    .date-time-separator{
        position: relative !important;
    }

    .date-time-separator.MuiGrid-spacing-xs-2 > .MuiGrid-item{
        padding: 8px 8px 16px 8px;
    }
    .start-date-picker-container{
        padding-bottom: 32px !important;
    }
    .error-wrapper{
        display: none;
    }
}