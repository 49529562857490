.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.recurring-text{
  background:#2B2B2B21;
  width:fit-content;
  padding-left:5px;
  padding-right:5px;
  border-radius:5px
}

.recurring-text-detail{
  background:#2B2B2B21;
  padding-left:5px;
  padding-right:5px;
  border-radius:5px;
  width:fit-content;
  max-width:100%;
  display: flex;
  min-width: 250px;
  width: 100%;
}