/* .emptypage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.error_main, .error-page {
  height: 100%;
margin: auto;
}
.rotate {
animation: rotation 2s;
}

.linear {
animation-timing-function: linear;
}

.infinite {
animation-iteration-count: infinite;
}
.error-page, .error-page_wrap {
display: flex;
align-items: center;
justify-content: center;
}
.error-page {
flex-direction: column;
}
.four {
color: #303960;
font-size: 210px;
font-family: 'Roboto';
margin: auto;
}
.error_head {
font: bold 24px "Roboto";
color: #424242;
}
.error-txt_p {
font: normal 16px "Roboto";
color: #808080;
margin-bottom: 30px;
}
.error-text {
text-align: center;
}
.error_back {
font: normal 16px "Roboto";
background-color: #303960;
color: #fff;
border: none;
padding: 12px 44px;
cursor: pointer;
}
@keyframes rotation {
from {
transform: rotate(0deg);
}
to {
transform: rotate(359deg);
}
}
