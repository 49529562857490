.calender-page {
    padding: 0px 38px;
}

.recurring_icon{
    padding-left: 5px
}
.calender-page .fc .fc-toolbar.fc-header-toolbar {
    margin: 0px;
    padding: 10px 0px;
}

.calender-page .fc .fc-button-primary {
    color: #5B5B5B !important;
    background: #F2F4FB !important;
    border: 1px solid #E2E5F2 !important;
    text-transform: capitalize;
    width: 75px;
    height: 30px;
    padding: 0px;
    margin-left: 24px;
    font: normal 14px/19px "Roboto" !important;
}

.calender-page .fc .fc-myEventButton-button {
    border-color: #2F3D7B !important;
    color:#2F3D7B !important;
    width: 60px !important;
}

.calender-page .fc .fc-eventTypeBtn-button {
    border-color: #2F3D7B !important;
    color:#2F3D7B !important;
    width: 112px !important;
}

.territory_filter .ant-select {
    width: 230px !important;
    top: 5px;
    left: 110px
}

.calender-page .fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #5B5B5B !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 12px #0000001C !important;
    text-transform: capitalize;
    width: 75px;
    border: none !important;
}

.calender-page .fc .fc-toolbar-title {
    font: bold 24px/31px "Roboto" !important;
    color: #303960 !important;
    margin-top: 5px; 
    margin-left: 60px !important;
}

.calender-page .ant-picker-calendar-mini {
    border-radius: 0px !important;
    margin-bottom: 30px;
}

.calender-page .fc-theme-standard .fc-scrollgrid {
    border: none !important;
}

.calender-page .fc .fc-scrollgrid-section>td {
    border: none !important;
}

.calender-page .fc-theme-standard td,
.fc-theme-standard th {
    border: 1px dashed #F1F1F1 !important;
    border-right-style: solid !important;
}

.calender-page .fc-day-other {
    background-color: #FCFCFC !important;
}

.calender-page .fc .fc-daygrid-day.fc-day-today {
    background-color: #F6FFFB;
    border-bottom: 2px solid #21BF73 !important;
}

.calender-page th.fc-col-header-cell.fc-day {
    background-color: #FCFCFC;
}

.calender-page .fc .fc-col-header-cell-cushion {
    font: normal 14px/19px "Roboto" !important;
    color: #5B5B5B;
    padding: 8px 0px 3px 0px;
}

.calender-page .fc-day-today .fc-daygrid-day-number {
    color: #21BF73 !important;
}

.calender-page .fc-view-harness {
    background-color: white;
}

.calender-page .fc-header-toolbar .fc-prev-button,
.fc-header-toolbar .fc-next-button {
    display: none !important;
}


.calender-page .ant-picker-calendar-mode-switch {
    display: none !important;
}

.calender-page .fc-daygrid-day-number {
    text-align: left !important;
    font: 400 16px/21px "Roboto" !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    padding: 10px !important;
}

.calender-page .fc-event-title {
    font: normal 10px/13px "Roboto" !important;
    text-overflow: ellipsis;
    color: #5B5B5B;
}

.calender-page .event-class {
    background-color: #3f51b5;
}

.calender-page .event-class .fc-event-title {
    font: normal 10px/13px "Roboto" !important;
    text-overflow: ellipsis;
    color: #fff;

}

.admin-only {
    display: none !important;
}

.calender-page .uncovered {
    border-left: 2px solid #D24856;
    background-color: #FFD0BE;
    color: #D24856;
}

.calender-page .inprogress {
    border-left: 2px solid #69F3B1;
    background-color: #F6FFFB;
    color: #69F3B1;
}

.calender-page .unassigned,
.calender-page .pending {
    border-left: 2px solid #617FFF;
    background-color: #E1E6F9;
    color: #617FFF;
}

.calender-page .assigned {
    border-left: 2px solid #303960;
    background-color: #CCD6FF;
    color: #303960;
}

.calender-page .completed {
    border-left: 2px solid #21BF73;
    background-color: #9be5c2;
    color: #21BF73;
}

.calender-page .cancelled {
    border-left: 2px solid #888888;
    background-color: #E2E5F2;
    color: #888888;
}

.calender-page .fc .fc-daygrid-event,
.calender-page .fc .fc-timegrid-event {
    margin-bottom: 1px;
    border-radius: 2px;
    padding: 4px 7px 0px 7px;
}

.calender-page .fc .fc-view-harness-active>.fc-view {
    padding: 20px 20px 0px 0px;
}

.calender-page .fc-daygrid-event-dot {
    display: none;
}
.event_titles {
    max-width: 40% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.event_titles span {
    font: normal 14px/19px "Roboto" !important;
}

.event_titles {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 300px !important;
}
.cal-task-selected-title {
    font: 500 14px/19px "Roboto" !important;
    margin-bottom: 11.5px;
    color: #424242;
    padding-bottom: 9.5px;
    border-bottom: 1px solid #F5F5F5;
}

.cal-task-selected-status {
    font: normal 10px/13px "Roboto" !important;
    margin-bottom: 6px;
    display: inline-block;
    color: #424242;
    padding: 3px 11px 1px 12px;
    border-radius: 2px;
}


.cal-task-selected-text {
    color: #424242;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
    cursor: pointer;
}

.cal-task-selected-assigne {
    color: #7B7B7B;
    font: normal 12px/16px "Roboto" !important;
    margin-bottom: 10px;
}

.cal-task-selected-assigne span {
    color: #434343;
    margin-left: 8px;
}

.cal-task-selected-date {
    color: #747A97;
    font: normal 14px/19px "Roboto" !important;
    margin-bottom: 19px;
}

.cal-selected-cancel {
    position: relative !important;
}

.cal-task-selected-btn {
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    font: normal 14px/19px "Roboto" !important;
    width: 50% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #2F3D7B 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.cal-task-auto-selected-btn {
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    font: normal 14px/19px "Roboto" !important;
    width: 100% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #2F3D7B 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.cal-task-cancel-btn {
    width: 50% !important;
    height: 38px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #BEBEBE !important;
    position: absolute !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    margin-left: 5px !important;
    text-transform: none !important;
}

.cal-task-cancel-selected-btn {
    text-transform: capitalize !important;
    font: normal 14px/19px "Roboto" !important;
    width: 100% !important;
    /* position: absolute !important; */
    height: 38px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #BEBEBE !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.calender-left-container {
    padding: 20px 39px 20px 20px;
    background-color: #ffff;


}

.calender-left-grid {
    background-color: #fff;
    margin-top: 56px !important;
}

.calender-page .ant-picker-calendar-mini .ant-picker-content th,
.calender-page .ant-picker-cell .ant-picker-cell-inner {
    font: normal 14px/19px "Roboto" !important;
    /* color: #E2E5F2; */
}

.calender-page .ant-picker-cell-in-view,
.calender-page .ant-picker-calendar-mini .ant-picker-content th {
    color: #5B5B5B !important;
}

.calender-page .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: transparent;
    color: #21BF73;
}

.calender-page .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.calender-page .ant-picker-calendar .ant-picker-panel {
    border: none;
}

.calender-page .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    font: normal 14px/19px "Roboto" !important;
    padding-top: 2px;
}

.calender-page .fc .fc-daygrid-more-link {
    font: normal 14px/16px "Roboto" !important;
}

.calender-page .fc-v-event,
.calender-page .fc-h-event {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
}

.calender-page .fc .fc-timegrid-slot-label-cushion,
.calender-page .fc .fc-timegrid-axis-cushion {
    font: normal 14px/19px "Roboto" !important;
    color: #5B5B5B;
}

.calender-page .fc .fc-timegrid-slot-label,
.calender-page .fc .fc-timegrid-axis {
    background-color: #FCFCFC;
}

.fc-scrollgrid .fc-scrollgrid-section-body {
    display: none;
}

.fc-scrollgrid .fc-scrollgrid-section-liquid {
    display: contents;
}

.fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
}

.custom-ant-select .label {
    display: block;
    margin: 0px;
}

.custom-ant-select .ant-select-selector {
    align-items: center;
}

.custom-ant-select .ant-select-selection-item-content,
.custom-ant-select .ant-select-selection-item-content>span {
    display: inline-flex !important;
    align-items: center;
}

.custom-ant-select .ant-select-selection-item img {
    width: 20px !important;
    height: 20px !important;
}

.custom-ant-select .ant-select-selection-item .auto-assignee-priority-icon {
    width: 12px !important;
}

.custom-ant-select .ant-select {
    width: 100% !important;
    margin: 2px 0px 4px 0px;
    /* height: 38px; */
}

.add_event_cusautocomplete {
    min-height: 38px;
    margin-bottom: 4px;
}

.custom-ant-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    height: 38px !important;
}

.custom-ant-select .ant-select-show-search.ant-select-multiple .ant-select-selector {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    min-height: 38px;
}

.custom-ant-select .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #14193A;
}

.custom-ant-select .ant-select {
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}

.add_event_cusautocomplete .MuiFormControl-root {
    margin-top: 0px !important;
}

.pac-target-input {
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    margin-bottom: 4px;
}

.pac-target-input::placeholder {
    color: #b2b2b2;
}

.pac-target-input:hover,
.pac-target-input:focus {
    border: 1px solid #14193A !important;
    outline: none;
}

/* .fc-daygrid-event-dot {
    margin: 0 4px !important;
    box-sizing: content-box !important;
    width: 2px !important;
    height: 17px !important;
    background-color: #4759A7;
    border-radius: 2px 0px 0px 2px !important;
    border: none !important;
} */
/* .fc-daygrid-event{
    background: #E1E6F9 0% 0% no-repeat padding-box;
    border-radius: 2px;
} */
.fc .fc-button-group {
    min-width: 260px;
}

.calender-page .fc .fc-col-header-cell-cushion {
    padding: 5px 0px 5px 0px;
}

.calender-page .fc .fc-daygrid-event,
.calender-page .fc .fc-timegrid-event {
    padding: 2px 7px;
}

.calender-custom-filters {
    position: absolute;
    padding-top: 20px;
}

.custom-ant-select .ant-picker {
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
    width: 100% !important;
    margin: 2px 0px 4px 0px;
    padding: 0 17px 0px 0px;
    border-radius: 4px;
    border: 1px solid #E2E5F2;
    height: 38px !important;
}

.custom-ant-select .ant-picker:hover,
.custom-ant-select .ant-picker-focused {
    border-color: #14193A;
    border-right-width: 1px !important;
}

.email_cc .react-tag-input,
.location_field input {
    margin-top: 2px;
    margin-bottom: 4px;
    padding: 0;
}

.email_cc input {
    margin: 0;
}

.email_cc .react-tag-input input::placeholder,
.location_field input::placeholder {
    color: #bcbcbc;
}

.email_cc .react-tag-input,
.location_field input {
    border: 1px solid #E2E5F2;
}

.email_cc .react-tag-input:hover,
.location_field input:hover,
.location_field input:focus {
    border-color: #14193A;
    border-radius: 3px;
    outline: none;
}

.location_field.disabled input {
    cursor: not-allowed;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #14193A !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.register-form-middle .register-form-left,
.register-form-middle .register-form-right {
    padding-bottom: 0 !important;
}

.calender-page .fc-h-event .fc-event-title-container {
    line-height: normal;
}

.form_multi_mail_field .react-multi-email {
    border-radius: 5px;
    background-color: #F2F2F2;
    padding: 6px 13px;
    min-height: 38px;
    border-color: #E2E5F2;
    border-width: 0;
    margin-bottom: 15px;
    border: 1px solid transparent !important;
}

.form_multi_mail_field .react-multi-email.focused {
    border: 1px solid #14193A !important;
}

.form_multi_mail_field .react-multi-email>div {
    text-align: left;
    font: normal 14px/17px 'Roboto';
    letter-spacing: 0px;
    color: #424242;
    line-height: 0;
    padding: 2px 6px;
    background-color: #DBDBDB;
    margin: 2px;
    margin-right: 10px;
    margin-left: 0;
}

.form_multi_mail_field .react-multi-email input {
    background-color: transparent;
    padding: 2px 0 !important;
    font-size: 1rem;
    color: #424242;
}

.form_multi_mail_field .react-multi-email span {
    padding: 8px 0 !important;
    font-size: 1rem;
    color: rgb(0, 0, 0) !important;
    top: auto;
    left: auto;
}

.form_multi_mail_field .react-multi-email>span {
    color: rgba(0, 0, 0, 0.3) !important;
}

.cancel_reps .cancel_btn {
    max-width: 220px;
}
.carret_btn{
    margin-left: 0px !important;
    margin-right: 2px !important;
}

.reset_btn {
    margin-left: 240px;
    cursor: pointer;
    text-align: center;
}

.reset_btn_admin{
    margin-left: 12px;
    cursor: pointer;
    text-align: center; 
}

.event_type{
    display: inline-block;
    width: 96px !important;
}
.event_type_all{
    display: inline-block;
    width: 8px !important;
    margin-left: 5px;
    margin-right: 14px;
}

.cancel-reps {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.recurring_assigned {
    top: 471px;
    left: 210px;
    width: 12px;
    height: 16px;
    opacity: 1;
}

@media (min-width:768px) and (max-width:991px) {
    .calender-page {
        padding: 0 20px;
    }
}

@media only screen and (max-width:767px) {
    .calender-page .fc .fc-toolbar-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .calender-page .fc .fc-toolbar.fc-header-toolbar {
        display: block;
    }

    .calender-page {
        padding: 0px 20px;
    }

    .calender-page .fc .fc-view-harness-active>.fc-view {
        padding: 10px;
    }
}

@media (max-width:400px) {
    .calender-custom-filters {
        position: relative;
    }

    .calender-left-grid {
        margin-top: 0 !important;
    }
}