.custom_pagination{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.custom_select{
    display: flex;
    align-items: center;
}
.custom_select span{
    white-space: nowrap;
}
.custom_select .MuiInputBase-root{
    margin-left: 8px;
    margin-right: 32px;
}
.pagination_container{
    padding:0 !important;
}
.custom_pagination .MuiSelect-select.MuiSelect-select, .Acc-Table .custom_pagination .MuiInput-underline{
    background-color: transparent !important;
}