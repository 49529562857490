/* .company-comp .MuiPaper-root{
    width: 99% !important;
    margin: 0 auto;
} */
.company-comp .Component-horizontalScrollContainer-22{
    width: 99%;
    margin: 0 auto;
}

.add_company_dialog .MuiDialog-paperWidthMd{
    max-width: 680px;
}
.closeBtn{
    position: absolute !important;
    right: 0;
}
.add_company_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #14193A !important;
    
  }
  .add_company_dialog .MuiSelect-outlined.MuiSelect-outlined{
      padding-left: 0px;
      background: transparent;
  }
#form-dialog-title h2{
    display: flex;
    width: 100%;
}
#form-dialog-title h2 img{ 
    margin-right: 10px;
}
#form-dialog-title h2 span{
    font: normal 18px/24px "Roboto" !important;
    align-self: center;
    color: #424242;
}
 .add_company_dialog .MuiDialogTitle-root{
    padding:20px 20px 26px 20px
}
.add_company_dialog .MuiDialogContent-root{
    padding: 0px 20px 20px 20px;
    overflow-x: hidden;
}
.add_company_dialog label{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
}
.add_company_dialog input, .customSelect{
    padding: 13px 17px;
    font: normal 16px/21px "Roboto"  !important;
    color: #424242;
}
.add_company_dialog  .MuiFormControl-marginDense, .add_company_dialog .MuiFormControl-root{
  margin-top: 2px;
}
.add_company_action{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.add_company_action .cancel_btn{
    min-width: 130px;
    color: #6A6A6A;
    height: 40px;
    border: 1px solid #BEBEBE;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
}
.add_company_action .ok_btn{
    background-color: #21BF73;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
}
.custom_upload{
    position: relative;
    height: 38px;
    margin-bottom: 10px;
}
.custom_upload .input_one {
    left: 0;
    height: 38px;
    position: absolute;
    width: 71%;
    opacity: 0;
    z-index: 999;
}
.custom_upload .input_two{
    position: absolute;
    padding: 8px 11px;
    left: 0;
    max-height: 38px;
    border: 1px solid #E2E5F2;
    border-radius: 5px;
    width: 71%;
    margin-bottom: 10px;
    font: normal 16px/21px "Roboto" !important;
    color: #B5B5B5;
    background-color: #FAF9FE;
}
.input_one{
    cursor: pointer;
}
.add_company_dialog .MuiFormControl-root, .add_company_dialog .MuiInputBase-root{
    height:38px !important;
    
}
.custom_upload button{
    position: absolute;
    right: 0;
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
    width: 145px;
    height: 39px;
}
.MuiTablePagination-toolbar p:first-of-type{
    display: block !important;
}
.Comp-Table .Component-horizontalScrollContainer-17,.Comp-Table .jss17{
    margin: 0px 5px;
}
.Comp-Table .MuiTableHead-root {
    border-radius: 5px;
}
.Comp-Table table {
    border-collapse: separate;
}
.address-field{
    max-width: 0;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;
}
.modal-err-msg{
    color: red;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
}
.Comp-Table .MuiFormControl-root, .Comp-Table .MuiOutlinedInput-root{
    height: 40px !important;
}
.filter-search{
    display: flex;
    justify-content: space-between;
    /* background: white; */
    padding: 17px 20px 17px 20px;
}
.showName,.showPhone,.showEmail,.showAddress,.showWebsite,.showDesc,.showSize{
    display: none;
}
.add_company_modal .custom_upload{
    margin-bottom:0;
}
.add_company_action .delete{
    background-color: #BF3354;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
  }
#form-dialog-title h2{
  align-items: center;
}
p.reject_text{
  font: normal 16px/21px "Roboto";
  color: #8D8D8D ;
  margin-bottom:5px;
}
.add_company_dialog .pricing_field input{
    padding: 13px 0;
}
.dollar_symbol{
    margin-right: 0 !important;
}
.dollar_symbol p{
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}
.remaining_days {
    margin-right:0;
    padding: 0 3px;
    font: normal 14px/19px "Roboto";
    color: #979797;
}
.trail_field{
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 13px 15px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trail_field .ant-picker{
    background: transparent;
}
.trail_field .ant-picker-input{
    max-width:90px !important;
}
.trail_field .ant-picker-input input{
    font: normal 14px/19px "Roboto";
    color: #404040;
}
.header-logo .switch_login_btn{
    border-radius: 5px;
    background-color: #F9F9F9;
    padding: 9px 11px;
    color: #303960;
    font: normal 14px/19px "Roboto";
    text-transform: none;
    margin-left: auto;
}
@media (max-width:959px){
    .trail_field_left{
        padding-bottom:0 !important; 
    }
    .trail_field_right{
        padding-top:0 !important;
    }
}
@media only screen and (max-width: 767px){
    .add_company_dialog .MuiDialog-paper{
        margin: 0;
        max-width: 100%;
      /* removed border-radius */
    }
    .custom_upload{
        height: auto;
    }
    .custom_upload .input_one{
        height: 100%;
    }
    .custom_upload .input_two{
        width: auto;
        position: unset;
    }
    .custom_upload button{
        position: unset;
        width: 100%;
    }
    .add_company_dialog .MuiDialog-paper{
        margin: 0 15px;
      }
    .create-service-dialog .MuiDialog-paper {
        margin: 15px;
    }
    .header-logo .switch_login_btn{
        margin-top: 10px;
        margin-left:0;
    }
}

/* company-details */
.comp_flex{
    max-width: 1082px;
    margin: 0px auto;
}
.comp-layout{
    background:#FFFFFF !important;
    padding: 23px 37px;
    /* margin-top: 85px; */
}
.header-comp{
    display: flex;
    background: #FCFCFC !important;
    border-radius: 5px;
    justify-content: space-between;
    padding: 13px 20px;
    margin-bottom: 30px;
}
.logo_btn{
font: normal 16px/21px "Roboto" !important;
color: #21BF73 !important;
background: #21BF730F !important;
margin-left: 38px !important;
border:1px solid #21BF73 !important;
border-radius: 5px;
}
.compCodeBtn{
    background:#303960;
}
.compCodeBtn span{
    font:bold 18px/24px "Roboto" !important;
    color: #303960;
}
.compCodeBtn span span{
    margin-left:4px;
}
.contentTitle{
    margin-bottom: 20px;
}
.header-logo{
    display: flex;
    padding:9px 36px;
}
.comp_descr{
    width: 875px;
    height: 89px;
}
.comp_name{
    font:bold 26px/34px "Roboto" !important;
}
.comp_edit_icon{
    margin-left: 15px;
    height: 14px;
    width: 14px;
}
.comp_content_edit_icon{
    /* margin-right: 10px; */
    height: 14px;
    width: 14px;
}
/* .bread-crumb-main{
    margin-bottom: 45px !important;
} */

/* company-detail */
.comp-detail .comp-layout{
    margin-top:45px;
    padding:0;
    border-radius:5px;
}
.comp-detail .header-logo{
    padding: 23px 37px 10px;
    align-items: center;
} 
.comp-detail .header-logo .profile_upload{
    padding:8px 0;
}
.comp-detail .logo_btn{
    margin-left:31px !important;
    text-transform: capitalize;
    border-radius:5px!important;
    padding: 7.5px 36px;
}
.comp-detail .header-logo .comp-image{
    width:150px;
    height:80px;
    display: flex;
    align-items: center;
}
.comp-detail .header-logo img {
    width:100%;
    height:100%;
    object-fit: contain;
}
.comp-detail .header-comp{
    padding:14px 37px;
    align-items: center;
}
.comp-name{
    display: inline-flex;
    align-items: center;
}
.comp-name img.comp-img{
    width:23px;
    height:22px;
}
.comp-detail .header-comp .custom-span{
    font: bold 26px/34px "Roboto";
    margin:0 14px 0 9px;
    color:#424242;
}
.comp-detail .header-comp .edit-input{
    margin:0 13px 0 9px;
}
.comp-detail .header-comp .edit-input input{
    font: bold 26px/34px "Roboto";
    color:#424242;
    padding: 5px 14px;
}
.comp-detail .header-comp .MuiInputBase-root, .comp-contact .MuiInputBase-root, .comp-description  .MuiInputBase-root{
    height: auto !important;
}
.comp-detail .compCodeBtn{
    padding: 9px 31px;
}
.comp-detail .compCodeBtn span{
    text-transform: capitalize;
    font: bold 18px/24px "Roboto" !important;
}
.comp-detail .comp-container{
    padding: 0 37px 100px;
}
.contact-title, .description-title{
    font: normal 16px/21px "Roboto";
    color:#303960;
    margin-bottom:8px;
}
.contact-content{
    display:flex;
    align-items: center;
}
.comp-contact .custom-span{
    margin: 0 10px;
    font: normal 16px/21px "Roboto";
    color:#2D2D2D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0px;
}
.comp-contact .edit-input{
    align-items: center;
    margin:0 5px;
}
.comp-contact .edit-input input{
    font: normal 16px/21px "Roboto";
    color:#2D2D2D;
    min-height:none;
    padding:5px 14px;
}
.comp-contact .edit-input .MuiFormControl-marginDense{
    margin:0;
}
.comp-contact .edit-input .MuiOutlinedInput-inputMarginDense{
    padding-top:4px;
    padding-bottom:4px;
}
.comp-contact .edit-input .customSelect{
    padding:0;
}
.comp-contact .edit-input button{
    margin-left:0;
} 
.comp-size{
    margin: 16px 0 11px;
}
.description-content{
    display: flex;
    align-items: center;
}
.description-edit{
    align-self:flex-start;
    margin-top:3px;
}
.comp-description{
    margin-bottom:11px;
}
.comp-description .edit-input input{
    padding:5px;
}
.description-content .custom-span{
    display: block;
    max-width:875px;
    padding-right: 20px;
    font: normal 16px/21px "Roboto";
    color:#5B5B5B;
}
.deleteServiceDialog button.delete:hover{
    background-color: #9F1B3A !important;
}
.add_company_action .ok_btn{
    color:#fff!important;
}

.comp_flex.comp-detail .header-logo-error.modal-err-msg{
    padding: 0px 37px 23px;
}
.reject_company_modal #form-dialog-title h2 span{
  font: bold 18px/24px "Roboto" !important;
  color: #424242;
}
.deleteServiceDialog.create-service-dialog .MuiDialog-paperWidthSm{
    max-width:400px !important;
}
.deleteServiceDialog.create-service-dialog .MuiDialogTitle-root {
    padding: 13px 20px 7px;
}
.deleteServiceDialog.create-service-dialog .MuiDialogContent-root {
    padding: 0px 20px 8px;
}
.deleteServiceDialog.create-service-dialog .MuiDialogActions-root {
    padding: 0px 20px 17px;
}
.deleteServiceDialog .MuiDialog-container{
    height: 100%;
    outline: 0;
    width: 100%;
}
.create-service-dialog .ok_btn{
    background-color: #21BF73;
    color: #FFFFFF !important;
    text-transform: capitalize;
    min-width:130px
}
.billing-detail-heading{
    font: 700 17px/23px "Roboto";
    margin-bottom: 0;
    color: #424242;
}
.comp-detail .header-comp.billing-header{
    margin: 0 -37px 20px;
}
.billing-container .comp-contact .custom-span {
    margin: 0;
    margin-right: 10px;
}
.billing-container .trail_field{
    margin-right: 10px;
}
.billing-container .trail_field .ant-picker{
    margin-right: 10px;
}
.comp-detail span.custom-span{
    min-height: 15px;
}
.comp-detail .billing-container .edit-input{
    margin:0;
}
@media (max-width: 767px){
    .comp-detail .header-comp,.comp-detail .header-logo{
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
    .comp-detail .header-logo button.logo_btn{
        margin:10px auto 0 0!important;
    }
    .comp-detail .compCodeBtn{
        margin-top:10px;
    }
}
@media (min-width:600px){
    .description-content .MuiOutlinedInput-multiline{
        min-width:350px;
    }
}
@media (max-width:599px){
    .add_company_dialog label.products-label {
        margin: 0 0 5px;
        display: inline-block;
    }
    .comp-layout{
        padding: 23px 15px;
    }
    .comp-detail .header-logo{
        padding: 23px 15px 10px;
    } 
    .comp-detail .header-comp{
        padding:14px 15px;
    }
    .comp-detail .comp-container{
        padding: 0 15px 100px;
    }
    .comp_flex.comp-detail .header-logo-error.modal-err-msg{
        padding: 0px 15px 23px;
    }
    .comp-detail .header-comp.billing-header{
        margin: 0 -15px 20px;
    }
    .trail_period_section .edit-input{
        flex-wrap: wrap;
    }
    .trail_period_section .trail_field{
        margin-right: 10px;
    }
    .trail_period_section .edit-input button.MuiButton-root{
        margin:0;
    }
}
@media (max-width:480px){
    .comp-detail .header-comp .custom-span{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 225px;
    }
    .add_company_dialog .MuiDialog-paper{
        margin: 0 5px;
    }
    .trail_field{
        flex-wrap: wrap;
        height:75px;
    }
    .trail_field .ant-picker{
        width:100%;
    }
    .add_company_modal .add_company_action button{
        padding: 6px 10px;
    }
    .create-service-dialog .MuiDialog-paper {
        margin: 5px;
    }
    .deleteServiceDialog.create-service-dialog .MuiDialogActions-root {
        padding: 0px 10px 17px;
    }
    .trail_field .custom-span {
        width: 100%;
    }
}