/* .body-bg-color {
  background-color: #f2f4fb;
  padding-bottom: 30px;
} */
.taskhide {
  display: none;
}
.taskblock {
  display: block;
}
.pendinghide {
  display: none;
}
.pendingblock {
  display: block;
}
.lazyLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-container {
  padding: 0px 10px;
}
.todays_tasks_grid_heading {
  display: flex;
  padding: 40px 0px 16px 0px;
  justify-content: space-between;
}
.todays_tasks_grid_heading .tableTitle {
  margin-right: 8px;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one{
  overflow: hidden;
  white-space: nowrap;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one span {
  color: #7e7e7e;
  align-self: center;
  font: normal 16px/21px "Roboto" !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todays_tasks_grid_heading .todays_tasks_grid_heading_one,
.todays_tasks_grid_heading .todays_tasks_grid_heading_two {
  display: flex;
}

.todays_tasks_grid_heading_two .MuiFormControl-root,
.todays_tasks_grid_heading_two .MuiOutlinedInput-root {
  height: 40px !important;
  border-radius: 5px;
  width: 144px;
  background-color: #fff;
}
.todays_tasks_grid_heading_two .MuiInputBase-input {
  font: normal 16px/21px "Roboto";
  color: #303960;
  padding: 12px 18px 8px;
}
.todays_tasks_grid_heading_select {
  margin-right: 15px !important;
}
.todays_tasks_grid_heading_two fieldset {
  border: none;
  box-shadow: 0px 1px 2px #30396030;
}
.todays_tasks_grid_heading_two input {
  padding: 0px !important;
}
.todays_tasks_grid_heading_two .MuiAutocomplete-inputRoot {
  padding: 12px 18px 8px !important;
}
.update_status_btn_common_overdue {
  background-color: #e81c2312 !important;
  color: #cc1837 !important;
}
.update_status_btn_common_overdue_pending {
  background-color: #bfbebd0f !important;
  color: #9a9a9a !important;
}
/* .todays_tasks_grid_list {
  margin: 10px 10px;
} */
.in_progress_btn span {
  margin-left: 13px;
  position: relative;
}
.update_status_btn_common_overdue span:before {
  background-color: #cc1837 !important;
}
.update_status_btn_common_overdue_pending span:before {
  background-color: #9f9f9f !important;
}
.update_status_dialog .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: unset !important;
}
.update_status_dialog .user_selected_color .MuiListItemText-root span {
  color: #f9b907 !important;
}
.dashboard_task_tab_common {
  width: 50%;
  border: 1px solid #4759a7;

  text-align: center;
  font: normal 16px/21px "Roboto" !important;
  padding: 9px 0px 7px 0px;
  color: #4759a7;
}
.dashboard_task_tab {
  margin-top: 8px;
  display: none;
}
.dashboard_task_tab_one {
  border-radius: 5px 0px 0px 5px;
}
.dashboard_task_tab_active {
  background-color: #4759a7 !important;
  color: #ffffff !important;
}
.dashboard_task_tab_two {
  border-radius: 0px 5px 5px 0px;
}
.in_progress_btn span:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #f9b907;
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: -13px;
}
.dashboard_task_card {
  background-color: #ffffff;
  padding: 12px 10px;
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 5px 5px 0px 5px;
  display: flex;
  margin-bottom: 35px;
  position: relative;
  justify-content: space-between;
}
.dashboard_task_card .dashboard_task_card_item_name {
  color: #303960;
  font: normal 16px/21px "Roboto" !important;
  margin-bottom: 8px;
  cursor: pointer;
}
.dashboard_task_card_item_flex {
  display: flex;
  justify-content: space-between;
}
.dashboard_task_card_item_one {
  width: 215px;
  /* margin-right: 34px; */
}
.hosp_loc {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.event_title_name {
  max-width: 200px;
  overflow: hidden ;
  text-overflow: ellipsis ;
  white-space: nowrap ;
}
.dashboard_event_description{
  max-width: 500px;
  overflow: hidden ;
  text-overflow: ellipsis ;
  white-space: nowrap ;
}
/* .dashboard_task_card_item_two {
  margin-right: 25px;
} */
.update_status_btn_common {
  height: 30px;
  margin: 0;
  width: 122px;
  font: normal 14px/19px "Roboto" !important;
  color: #303960;
  background-color: #f8f8f8;
  text-align: center;
  padding: 7px 0px 4px 0px;
  border-radius: 15px;
}
.in_progress_btn {
  background-color: #fffaeb;
  color: #f9b907;
  margin-bottom: 9px;
}
.hosp_loc span {
  font: normal 14px/19px "Roboto" !important;
}
.event_title_name span {
  font: normal 14px/19px "Roboto" !important;
}
.hosp_loc img {
  margin-right: 5px;
}
.update_status_btn_common img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.assigned_to_card {
  padding: 12px 17px;
  background-color: #f6f7fc;
  border-radius: 5px;
  max-width: 150px;
  /* margin-right: 23px; */
}
.assigned_to_card span {
  color: #7b7b7b;
  font: normal 14px/19px "Roboto" !important;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
  display: block;
}
.dashboard_task_card_item_two{
  align-self: center;
}
.assigned_to_card p {
  color: #434343;
  margin: 0px;
  font: normal 16px/21px "Roboto" !important;
}
.dashboard_task_card_item_start {
  color: #424242;
  font: 500 21px/28px "Roboto" !important;
  margin-bottom: 3px;
}
.dashboard_task_card_item_four {
  text-align: center;
  margin-right: 25px;
}
.dashboard_task_card_item_end,
.dashboard_task_card_item_to {
  color: #bbbbbb;
  font: normal 12px/16px "Roboto" !important;
  margin-bottom: 0px;
}
.dashboard_get_dir_btn {
  position: absolute;
  right: 0;
  bottom: -27px;
  width: 132px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;
 
}
.dashboard_get_dir_btns {
  position: absolute;
  right: 0;
  bottom: -27px;
  width: 200px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;

}
.dashboard_get_dir_btn_left{
  position: absolute;
  left: 0;
  bottom: -27px;
  width: 132px;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  padding: 2px 0px 6px 0px;
 
}
.dashboard_get_dir_btn_left img {
  margin-right: 5px;
}
.dashboard_get_dir_btn_lefts {
  position: absolute;
  left: 0;
  right :25px;
  bottom: -27px;
  width: fit-content;
  background-color: #3c57cb21;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  text-align: left;
  padding: 2px 6px 6px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90% !important;

}
.bold_carret {
  margin-top: 5px;
  cursor: pointer;
}
.cursor_pointer{
  cursor: pointer;
}
.dashboard_get_dir_btn span {
  font: 500 14px/19px "Roboto" !important;
  color: #2f3d7b;
  margin-left: 8.45px;
}
.dashboard_task_card_overdue {
  background-color: #f3183d1c;
}
.dashboard-container .dashboard-submit-btn {
  border: 1px solid #21bf73;
  background-color: rgba(33, 191, 115, 0.06);
  box-shadow: none;
  text-transform: capitalize;
  color: #21bf73;
  height: 50px;
  font: normal 20px/26px "Roboto" !important;
  margin-top: 7px;
  padding: 14px 0px 10px 0px;
}
.error_task {
  position: absolute;
  right: 0px;
  align-self: center;
  height: 100%;
  top: 0;
  display: flex;
  font: normal 22px/29px "Roboto" !important;
  justify-content: center;
  color: #ffffff;
  width: 25px;
  background-color: #f12642fa;
  border-radius: 0px 5px 0px 0px;
}
.error_task span {
  align-self: center;
}
.updateStatusCloseBtn {
  padding: 0px !important;
}
#form-dialog-title .updateStatusCloseBtn h2 img {
  margin-right: 20px;
  margin-top: 4px;
}
.update_status_dialog .MuiDialog-paperWidthSm {
  width: 680px;
}
.update_status_dialog ul {
  margin-top: 15px;
}
.update_status_dialog ul,
.update_status_dialog li {
  padding: 0px;
}
.update_status_dialog .MuiListItemText-root {
  margin: 0px;
}
.select_status_label {
  margin-top: 15px;
  display: block;
}
.update_status_dialog li {
  margin-right: 30px;
}
.update_status_dialog li .MuiListItemText-root span {
  font: normal 16px/21px "Roboto" !important;
  color: #333758;
}
.reassign-task {
  background-color: #fb7c4d0f !important;
  color: #fb7c4d !important;
}
.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
.empty_div {
  margin-top: 10%;
}
.task_status{
  color:#FB7C4D !important
}
.dashboard_task_card_item_three{
  align-self: center;
}
.update_status_btn_common_overdue_pending_one{
  margin-bottom: 0px !important;
}
.dashboard_task_event .dashboard_task_card_item_name {
  margin: 0px;
  color: #424242 !important;
  font-weight: 600 !important;
}
.dashboard_task_event {
  display: flex;
  margin-bottom: 5px;
}
.dashboard_task_event .dashboard_task_event_location {
  color: #4759A7;
  font: normal 14px/19px "Roboto" !important;
  margin: 0px 0px 0px 9px;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
}
.dashboard_task_event .dashboard_task_event_location img{
  margin-right: 3.5px;
}
.dashboard_task_event .dashboard_task_event_type {
  color: #B4B4B4;
  font: normal 12px/16px "Roboto" !important;
  align-self: center;
  margin-left: 9px;
}
.dashboard_task_event_text {
  margin-bottom: 5px !important;
}
.dashboard_task_event_attendees {
  color: #434343;
  font: normal 14px/19px "Roboto" !important;
}
.dashboard_task_event_attendees_plus {
  color: #AAAAAA;
}
.dashboard_task_event_attendee {
  color: #5B5B5B;
}
.dashboard_task_event_item_start {
  margin: 0px;
  font: 500 16px/21px "Roboto" !important;
  color: #3A3A3A;
}
.dashboard_task_filters{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}
.task_summary_item {
  text-align: center;
  /* padding: 11px 0px 4px 0px; */
  box-shadow: 0px 3px 13px #00000012;
  border-radius: 10px;
}
.task_summary_item_top {
  background-color: #EBEFFF;
  padding: 10.5px 0px;
  border-radius: 5px 5px 0px 0px;
}
.task_summary_item_bottom {
  padding: 12px 0px 4px 0px;
  background-color: #fff;
  border-radius: 0px 0px 5px 5px;
}
.task_summary_item_bottom p {
  color: #424242;
  font: 500 14px/19px "Roboto" !important;
  margin: 0px;
  text-transform: uppercase;
}
.task_summary_item_bottom span {
  color: #303960;
  font: 500 40px/53px "Roboto" !important;
}

/* footer added so removing parent padding */
.body-bg-color.dashboard-page{
  padding-bottom:116px;
  position: relative;
}
.in_progress_btn span:before{
  top:5px;
}
/* event_acceptance_actions */
.dashboard_task_card_item_three .event_acceptance_actions{
  margin:0;
  text-align: center;
}
.dashboard_task_card_item_three .event_acceptance_actions button{
  margin: 7px 0 0;
}
.dashboard_task_card_item_three .event_acceptance_actions > button:first-child{
  margin-top: 0;
}
/* pagination */
.task-list-pagination{
  display: inline-block;
  margin-top: 4px;
  width: 100%;
}
.task-list-pagination .mat-pagination{
  padding:8px;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
}
.event_attendee_tab_icon.event_organizer_icon{
  margin: 0 5px;
  border: 1px solid #ffffff;
}
.ant-popover-inner-content .event_attendee_tab_icon.event_organizer_icon{
  margin-left: 0;
}
.dashboard_task_wrapper{
  display: flex;
  justify-content: space-between;
  width: 54%;
}
.warning_icon{
  width: 21px;
  height: 19px;
}
.recurring_text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1800px;
}
@media (max-width:991px){
  .body-bg-color.dashboard-page {
    padding-bottom: 140px;
  }
}
@media only screen and (min-width: 600px){
  .dashboard_task_card_item.pending_acceptance_event{
    max-width: 54%;
  }
}
@media only screen and (max-width: 600px) {
  .dashboard_task_tab {
    display: flex;
  }
  .dashboard_task_filters{
    display: block;
  }
  .dashboard_task_filters .tableTitle{
    margin-bottom: 10px;
    display: block;
  }
  .emergency_card {
    border: 1px solid #e10000;
  }
  .todays_tasks_grid_heading {
    display: block;
  }
  .error_task {
    display: none;
  }
  .dashboard_task_card {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .todays_tasks_grid_heading_one {
    margin-bottom: 5px;
  }
  .dashboard_task_card_item_one {
    margin-right: 0px;
    max-width: 200px;
  }
  .dashboard_task_card_item_four {
    margin-right: 0px;
  }
  .hosp_loc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 98px;
  }
  .event_title_name {
      overflow: hidden ;
      text-overflow: ellipsis;
      white-space: nowrap ;
      max-width: 98px;  
  }
  .dashboard_task_card_item_one,
  .dashboard_task_card_item_two,
  .pending_acceptance_event {
    margin-bottom: 8px;
  }
  .dashboard_task_card_item_four {
    width: 122px;
  }
  .body-bg-color.dashboard-page {
    padding-bottom: 163px;
  }
}
.selected{
  font-weight: bold !important;
}

.task_summary_inprogress{
  background-color: #F5FFFA;
}
.task_summary_cancelled{
  background-color: #E3E3E3;
}
.task_summary_uncovered{
  background-color: #FFD1D1;
}

.task_summary_completed{
  background-color: #E1FFF0;
}
.task_summary_assigned{
  background-color: #D1DAFF;
}
.task_summary_unassigned{
  background-color:#EBEFFF ;
}

/* tasks-summary */
.tasks_section{
  margin:0;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tasks_section .task_summary_item{
  width: calc(14.28% - 11px);
  background: #fff;
  display: inline-flex;
  flex-direction: column;
}
.tasks_section .task_summary_item_bottom{
  display:inline-flex;
  flex-direction: column;
  flex-grow: 1;
}
.tasks_section .task_summary_item_bottom span{
  margin-top:auto;
}
.task_unassigned .task_summary_item_bottom span{
  color:#617FFF;
}
.task_pending .task_summary_item_bottom span{
  color:#617FFF;
}
.task_assigned .task_summary_item_bottom span{
  color:#303960;
}
.task_inprogress .task_summary_item_bottom span{
  color:#69F3B1;
}
.task_completed .task_summary_item_bottom span{
  color:#21BF73;
}
.task_uncovered .task_summary_item_bottom span{
  color:#D24856;
}
.task_cancelled .task_summary_item_bottom span{
  color:#888888;
}

/* pending-task-summary */
.dashboard_task_card_item_three{
  max-width:115px;
  width:100%;
  text-align: center;
}
.dashboard_task_card_item_three .assigned_to_card p{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pending_acceptance_event{
  max-width: 320px;
  width: 100%;
}
.task_assign_model_container > .MuiGrid-container{
  align-items: center;
}
.task_event_heading {
  display: flex;
  align-items: center;
  margin-bottom:20px;
}
.task_event_heading > h1.event_heading{
  font: 500 12px/16px "Roboto" !important;
  color: #424242;
  background: #D4D4D41F;
  border-radius: 5px;
  margin-right: 13px;
  padding:6px 13px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.task_event_heading .task_event_emergency{
  font: 500 12px/16px "Roboto" !important;
  color: #E91111;
  background: #F2222226;
  border-radius: 5px;
  padding:6px 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.tasking-assign-right span.task_event_timing{
  margin-bottom: 10px;
  
}
.tasking-assign-right .dashboard_task_event_attendees_section  span {
  margin-bottom: 0;
} 

.tasking-assign-right .dashboard_task_event_attendees_section  {
  margin-bottom: 10px;
} 
.task_assignment_dialog .add_user_header.task_assignment_modal_header{
  margin-bottom:17px !important;
}
.task_assign_errror_msg{
  margin-bottom:0;
  display: flex;
  align-items: flex-start;
}
.task_assign_errror_msg img{
  margin-top:1px;
}
.task_assign_action_buttons, .task_assign_action_buttons > button{
  margin-top: 10px;
}
.task_assign_action_buttons .cancel_btn{
  margin-right: 30px;
}
.task_assignment_modal_content{
  padding:0 25px 20px !important;
}
.task_reassign_action{
  flex-wrap: wrap;
}
.task_assignment_accept_dialog .task_assign_model_container{
  margin-bottom:50px;
}
.task_assignment_accept_dialog .MuiPaper-rounded{
  border-radius: 10px;
}
.dashboard_task_event_attendees_section{
  display:flex;
}
.dashboard_task_event_attendees_section .dashboard_task_event_attendees{
  display:inline-flex;
  align-items: center;
}
.dashboard_task_event_organizer{
  margin-right:5px;
}
.dashboard_task_event_attendee_images{
  position: relative;
  padding-right: 25px;
}
.dashboard_task_event_attendee_images > span{
  display: inline-block;
}
.dashboard_task_event_attendee_images > span:nth-child(2){
  position: absolute;
  left:13px;
}
.dashboard_task_event_attendee_images > span:nth-child(3){
  position: absolute;
  left:26px;
}
.dashboard_task_event_attendees_section .dashboard_task_event_attendees_plus{
  color:#617FFF;
}
.recurring_acceptance{
  top: 471px;
    left: 210px;
    right: 210px;
    width: 25px;
    height: 16px;
    opacity: 1;
}

@media (min-width:1024px){
  .task_assign_action_buttons .ok_btn{
    min-width:170px;
  }
  .task_assign_action_buttons .cancel_btn, .task_reassign_action .skip-btn{
    min-width:155px;
  }
}
@media (max-width:991px){
  .tasks_section{
    justify-content: start;
    margin: 0 -10px;
  }
  .tasks_section .task_summary_item{
    width: calc(33.33% - 22px);
    margin: 11px;
  }
}
@media (min-width:767px){
  .task-list-pagination .mat-pagination{
    height: 50px;
  }
}
@media (max-width:600px){
  .tasks_section{
    justify-content: center;
    margin:0;
  }
  .tasks_section .task_summary_item{
    width:100%;
    margin:0 0 22px;
  }
  .tasks_section .task_summary_item:last-child{
    margin-bottom:0;
  }
}
@media (max-width:378px){
  .dashboard_task_card_item.dashboard_task_card_item_two{
    margin-bottom: 0;
  }
  .dashboard_task_card_item:not(.dashboard_task_card_item_four){
    margin-bottom:15px;
  }
}
@media (max-width:350px){
  .dashboard_task_event_attendees_section{
    flex-wrap: wrap;
  }
  .dashboard_task_event_organizer{
    width:100%;
    margin-bottom: 5px;
  }
}
.event_titles {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}