/* Dialog box */
.MuiDialog-paper{
    border-radius: 10px !important;
}
.dialog-box {
    text-align: center;
    top: 121px;
    left: 463px;
    width: 442px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: 2px solid #FF6F5E;
    opacity: 1;
}

.dialog-box h1 {
    font: normal normal bold 32px/43px "Roboto" !important;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
    margin-bottom: 10px;
}

.dialog-box p {
    font: normal normal 18px/24px "Roboto" !important;
    letter-spacing: 0px;
    color: #303960;
    opacity: 1;
    margin-bottom: 10px;
}

.dialog-box h5 {
    font: normal normal bold 18px/24px "Roboto" !important;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
}

.dialog-box .cancel_btn {
    color: #3A3A3A;
    right: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-red {
    color: #FFFFFF;
    background-color: #D5450F;
    left: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-red:hover {
    background-color: #D5450F;
}

.dialog-box .ok_btn-green {
    color: #FFFFFF;
    background-color: green;
    left: 20px;
    width: 171px;
    margin-bottom: 20px;
}

.dialog-box .ok_btn-green:hover {
    background-color: green;
}

.reminderBtn {
  padding: 10px 20px 0px 0px !important
}
