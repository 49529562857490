.addUserBtn {
  color: #fff !important;
  background-color: #21bf73 !important;
}
.User-Table .userFilters{
  margin-right: 25px;
    width: 150px;
}
.user-detail-header{
  display: flex;
  position: relative;
}
.user-detail-header .profile_photo_edit{
  cursor: pointer;
  position: absolute;
  bottom: -5px;
  left: 37px;
}
.user-detail-container .MuiAccordionDetails-root{
  padding: 10px 10px 20px 10px !important;
}
.user-detail-container .Acc-Table{
  width: 100%;
    margin: 0;
}
.user-detail-container  .Acc-Table .table_container{
  margin: 0px;
}
.user-detail-container .upcoming-task .MuiAccordionSummary-expandIcon{
  display: none !important;
}
.user-role-width{
  width: 180px !important;
}
.user-profile-img {
  width: 50px;
  height: 50px;
  box-shadow: 0px 3px 20px #0000000F;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}
.user-profile-img-task{
  width: 40px;
  height: 40px;
}
.edit-input-header{
  display: flex;
  align-self: center;
}
.edit-input-header button{
  color: #21BF73 !important;
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}
.edit-profile-buttons{
  margin-right:10px;
}
.profile_upload{
  display: flex;
}
.profile_upload div{
  position: relative;;
}
.profile_upload .input_one {
  left: 0;
  height: 38px;
  width: 100%;
  /* position: absolute; */
  /* width: 71%; */
  opacity: 0;
  z-index: 999;
}
.user-detail-header .tableTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile_upload .input_two{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  padding: 8px 11px;
  top:0;
  left: 0;
  max-height: 38px;
  border: 1px solid #E2E5F2;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  font: normal 16px/21px "Roboto" !important;
  color: #B5B5B5;
  background-color: #FAF9FE;
}
.add_company_dialog .MuiFormControl-root, .add_company_dialog .MuiInputBase-root{
  height:38px !important;
  
}
.profile_upload .suc-button{
  /* position: absolute;
  right: 0; */
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #21BF73;
  background-color: #21BF730F;
  box-shadow: none;
  text-transform: capitalize;
  font: normal 16px/21px "Roboto" !important;
  color: #21BF73 !important;
  /* width: 145px; */
  height: 38px;
}
.profile_upload .fail-button{
  /* position: fixed;
  right: 47%; */
  height: 38px;
  margin-right: 10px;
}
.edit-flex{
  align-items: center !important;
}
.hide-edit-1{
  display: none;
}
.user-detail-container .Acc-Table .MuiTableRow-root td:nth-child(2) {
  padding-left: 42px !important;
  position: relative;
}
.new_custom_filter_div .leftName {
  font-weight: 600 !important;
  line-height: 18px;
}
.table_action_btns img{
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-detail-container .Acc-Table .MuiTableRow-root td:nth-child(2)::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #21BF73;
  left: 16px;
  position: absolute;
  top: calc(50% - 5px);
}
.update_status_btn_user {
  font: normal 14px/19px "Roboto" !important;
  color: #21BF73;
  text-align: center;
  padding: 7px 0px 4px 0px;
  cursor: pointer;
}
.ant-dropdown-menu li {
  cursor: pointer;
}
.date-color{
  color: #ACACAC !important;
}
.user-detail-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select_parent .switch_login_btn{
  border-radius: 5px;
  background-color: rgba(48, 57, 96, 0.16);
  padding: 10px 29px 10px 33px!important;
  color: #303960;
  font: normal 16px/21px "Roboto"!important;
  text-transform: none;
  font-weight: 600!important;
}

.select_parent .switch_login_btn:hover{
  background-color: rgba(48,57,96,.3411764705882353)!important;
}
@media only screen and (max-width:1288px){
  .User-Table .userFilters{
    margin-right: 15px;
      width: 150px;
      margin-bottom: 15px;
  }
}
@media only screen and (max-width:800px){
  .userfilter-search-dev{
    max-width: 60%;
  }
}
@media only screen and (max-width: 767px){
  .user-detail-wrapper{
    flex-wrap: wrap;
  }
  .user-detail-wrapper .user-detail-header{
    width:100%;
  }
  .select_parent .switch_login_btn{
    margin-top:10px;
    margin-right: auto;
  }
}
@media only screen and (max-width:567px){
  .filter-search{
    padding: 17px 5px;
  }
}