 /* custom code start*/
 @import url('https://cdn.syncfusion.com/ej2/material.css');
 .drag-sample-wrapper {
    display: -ms-flexbox;
    display: flex;

}
.e-schedule-toolbar-container .e-toolbar-right{
    display: none !important;
}
.schedule-container {
    padding-right: 10px;
    width: 100%;
}

.title-container {
    padding-bottom: 10px;
    position: relative;
}

.title-container button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #21BF73;
    color: #ffffff;
    font: normal 16px/21px "Roboto";
    text-transform: capitalize;
    padding: 5px 20px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.treeview-external-drag #waiting {
    height: 100%;
    padding: 0;
}

.treeview-external-drag #waitid {
    float: left;
    width: 13%;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-family: "Segoe UI";
    color: #f9920b;
    overflow: hidden;
    margin: 9px 0;
    padding: 0 2px 0 0;
}

.treeview-external-drag #waitdetails {
    width: 95%;
    float: left;
    height: 100%;
    padding: 0;
}

.title-text {
    font-size: 18px;
    margin: 0px;
    font-weight: bold;
    text-align: center;
}
 /* custom code end*/
.treeview-external-drag #waitlist {
    width: 100%;
    height: auto;
    font-weight: bold;
    font-family: "Segoe UI";
    font-size: 12px;
    color: #545554;
    padding: 5px 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag #waitcategory {
    height: 50%;
    font-family: "Segoe UI";
    font-size: 10px;
    color: #545554;
    opacity: 0.6;
    padding-left: 10px;
    padding-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag .e-list-text,
.treeview-external-drag.e-rtl .e-list-text,
.e-bigger .treeview-external-drag .e-list-text,
.e-bigger .treeview-external-drag.e-rtl .e-list-text {
    background: white;
    border: 0.5px solid #E1E7EC;
    height: 50px;
    line-height: 15px;
    padding: 0 5px;
    width: 220px;
}

.treeview-external-drag .e-list-parent,
.treeview-external-drag.e-rtl .e-list-parent,
.e-bigger .treeview-external-drag .e-list-parent,
.e-bigger .treeview-external-drag.e-rtl .e-list-parent {
    height: 100%;
    padding: 0 2px;
}

.treeview-external-drag .e-list-item,
.e-bigger .treeview-external-drag .e-list-item {
    height: 100%;
    padding: 0 0 5px 0;
}

.treeview-external-drag .e-fullrow,
.e-bigger .treeview-external-drag .e-fullrow {
    height: 55px;
}

.treeview-external-drag .e-list-item.e-hover>.e-fullrow,
.treeview-external-drag .e-list-item.e-active>.e-fullrow,
.treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-hover>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active>.e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}

.treeview-external-drag .e-text-content,
.e-bigger .treeview-external-drag .e-text-content,
.treeview-external-drag.e-rtl .e-text-content,
.e-bigger .treeview-external-drag.e-rtl .e-text-content {
    padding: 0;
}
.e-drag-item.e-treeview.treeview-external-drag,
.e-bigger .e-drag-item.e-treeview.treeview-external-drag {
    padding: 0 !important;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
    width: 160px;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-left: 30px
}

.e-schedule.e-rtl.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-right: 30px
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category,
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
    padding: 5px
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-name {
    padding: 0 10px
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-name {
    font-size: 13px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-designation {
    font-size: 10px;
}

.e-schedule.schedule-drag-drop .specialist-image {
    width: 45px;
    height: 40px;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 45px;
}

.e-device-hover {
    background-color: #e0e0e0!important;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-image {
    display: none;
}

.e-location-container,.e-all-day-time-zone-row,.e-recurrenceeditor .e-editor,.e-description-row,.e-edit,.e-delete,.e-cell-popup,.e-start-end-row .e-date-icon,.e-start-end-row .e-time-icon{
    display: none !important;
}
.e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells), .e-schedule .e-timeline-month-view .e-work-cells:hover:not(.e-resource-group-cells) {
    background: #ffffff !important;
}
.e-schedule .e-timeline-view .e-selected-cell {
    background-color: #ffffff !important;
}
.e-subject-container{
    width: 100% !important;
}
.e-dialog-parent .e-label-top{
    color: #0014ff !important;
}
.e-footer-content .e-event-save{
    color: #0014ff !important;
}
.e-input[disabled], .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled{
    border-bottom-color: #949494 !important;
}
.e-quick-popup-wrapper .e-event-popup .e-popup-footer{
    display: block !important;
}
/* .schedule-drag-drop.e-schedule .template-wrap .specialist-image.alice {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robert {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robson {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.laura {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.nancy {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.margaret {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png');
} */

/* Treeview */
.schedule-task-container{
    padding:10px 21px 11px 8px;
}
.schedule-task-container .treeview-container{
    background-color: #fff;
    margin-right: 13px;
    min-width: 308px;
    max-height: calc(100vh - 110px);
    width: 308px;
    position: relative;
    margin-top: 38px;
}
.schedule-task-container .treeview-container .title-container{
    border-bottom: 1px solid #F5F5F5;
    padding:0;
}
.schedule-task-container .treeview-container h1.title-text{
    font: 500 18px/24px "Roboto";
    color:#424242;
    text-align: left;
    padding: 13px 14px 8.5px
}
.schedule-task-container .treeview-external-drag .e-list-parent{
    padding: 4px 13.5px 0px 14.5px;
    margin: 7px 5px 13px 0;
}
/* .schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar {
    width: 6px;
    position: relative;
    z-index: 9999;
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-track {
  background: #F5F5F5; 
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-thumb {
  background: #C2C2C2;
  border-radius:15px;
}
.schedule-task-container .treeview-external-drag .e-list-parent::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
} */
.schedule-task-container .e-text-content{
    display: inline-flex;
    flex-direction: row-reverse;
}
.schedule-task-container .e-icon-expandable{
    transform: translate(0, -2px);
}
.schedule-task-container .e-treeview .e-list-item .e-icons.e-icon-collapsible{
    transform: translate(0, -2px) rotate(90deg);
}
.schedule-task-container .treeview-external-drag .e-list-item{
    padding: 10.5px 0; 
    border-bottom: 1px solid #F5F5F5;
    position: relative;
}
.schedule-task-container .treeview-external-drag .e-fullrow{
    height:100%;
    top:0;
}
.schedule-task-container .treeview-external-drag .e-text-content{
    border:0;
}
.schedule-task-container .treeview-external-drag .e-list-text{
    height:auto;
    width:100%;
    padding:0;
    border: none;
}
.schedule-task-container .task-name#waitlist{
    font: normal 16px/21px "Roboto";
    color:#424242;
    margin-bottom:10px;
    padding:0;
    white-space: normal;
}
.schedule-task-container .task-duration#waitcategory{
    font: normal 14px/19px "Roboto";
    color:#747A97;
    padding:0;
    white-space: normal;
}
/* Schedule-table */
.schedule-container .e-schedule{
    border:0;
    padding: 10px 21px 0 19px;
}
.schedule-container .title-container .title-text{
    font-family: "Roboto";
    color: #424242;
}
.schedule-container .e-schedule .e-schedule-toolbar .e-toolbar-items,
.schedule-container .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.schedule-container .e-schedule .e-timeline-view .e-child-node,
.schedule-container .e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table td:first-child,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table tbody td.e-time-slots{
    background-color:#FCFCFC;
}
.schedule-container .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td,
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table td, 
.schedule-container .e-schedule .e-timeline-month-view .e-date-header-wrap table td,
.schedule-container .e-schedule .e-timeline-view .e-work-cells, 
.schedule-container .e-schedule .e-timeline-month-view .e-work-cells,
.schedule-container .e-schedule .e-timeline-view .e-resource-cells, 
.schedule-container .e-schedule .e-timeline-month-view .e-resource-cells{
    border-color: #F1F1F1;
}
.schedule-container .e-schedule .e-schedule-toolbar{
    margin-bottom:0;
    box-shadow: none;
}
.schedule-container .e-schedule .e-timeline-view .e-work-cells{
    background-color: #fff;
}
.schedule-container .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.schedule-container .e-schedule .e-timeline-view .e-date-header-wrap table tbody td > span, .schedule-container .e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span{
    font: normal 12px/16px "Roboto";
    color:#5B5B5B;
    cursor: text;
}
/* .e-dlg-container.e-dlg-center-center.e-schedule-dialog-container {
    display: none !important;
} */
.schedule-container .e-schedule.schedule-drag-drop .e-resource-cells .specialist-name{
    font-family:"Roboto";
    color:#5B5B5B;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.schedule-container .e-schedule .e-timeline-view .e-appointment .e-subject,
.schedule-container .e-schedule .e-timeline-view .e-appointment .e-time{
    font-family:"Roboto";
}
.schedule-container .e-schedule.schedule-drag-drop .specialist-image{
    height:auto;
}
.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category{
    display:flex;
    align-items: center;
}

.schedule-control-section .ant-select-selector input{
    font-family: "Roboto";
}

.control-section .ant-select-single .ant-select-selector{
    border:0 !important;
}
/* .schedule-container .e-toolbar-left .e-toolbar-item.e-date-range.e-overflow-show{
    display:none;
} */
.e-control.e-schedule.e-lib.schedule-drag-drop.task_list_container.e-keyboard{
    height:100vh !important;
    max-height: calc(100vh - 110px);
}
@media (max-width: 767px){
    .schedule-task-container{
        flex-wrap: wrap;
        padding: 10px 8px 11px 8px;
    }
    .schedule-task-container .treeview-container{
        width: 100%;
        margin-right: 0;
        margin-bottom:20px;
    }
    .schedule-task-container .treeview-container .empty_div{
        padding-bottom:10%;
    }
    .schedule-container{
        padding-right:0;
    }
}
@media (max-width: 550px) {
    .drag-sample-wrapper {
        display: block;
    }

    .schedule-container {
        padding-bottom: 10px
    }

    .treeview-external-drag.e-treeview,
    .e-bigger .treeview-external-drag.e-treeview {
        width: 225px;
    }

    .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
        position: relative !important;
    }
    .schedule-task-container .treeview-external-drag.e-treeview{
        width:100%;
    }
}
@media (max-width:480px){
    .schedule-control-section .control-section .ant-select{
        width: calc(100% - 16px) !important;
    }
}