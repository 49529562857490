.task_notification {
  width: 350px !important;
  padding: 5px !important;
  border-radius: 39px !important;
  background-color: #303960 !important;
}
.task_notification .ant-notification-notice-icon {
  margin: 0px !important;
  position: relative !important;
  float: left;
  padding-right: 15px !important;
}
.task_notification .ant-notification-notice-message {
  margin: 0px;
  color: #fff;
  margin-left: 18px !important;
  padding-left: 6px !important;
  padding-top: 10px;
  font: normal 18px/24px "Roboto" !important;
}
.task_notification .ant-notification-notice-close {
  display: none;
}
.update-notify {
  background-color: #4759a7 !important;
}
.error-notify {
  background-color: #eb4f15 !important;
}
.success-notify {
  background-color: #21bf73 !important;
}
.ant-notification {
  z-index: 99999 !important;
}
@media (max-width: 480px) {
  div.ant-notification {
    margin-right: 5px;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon
    .ant-notification-notice-icon
    img {
    width: 45px;
  }
  .task_notification.ant-notification-notice
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    font: normal 14px/19px "Roboto" !important;
  }
  .task-detail-timeline-one {
    flex-wrap: wrap;
  }
  .task-detail-timeline-one .task-detail-timeline-dtime {
    width: 100%;
  }
}
