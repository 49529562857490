body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* common - font */
body *{
  font-family:"Roboto";
}

/* Buttons-Hover */
.green-btn:hover, .green-btn:focus{
  background-color: #12A45E !important;
} 
.primary-btn:hover, .primary-btn:focus{
  background-color: #21BF732E !important;
}
.white-btn:hover, .white-btn:focus{
  background-color: #F5F5F5 !important;
}
.grey-btn:hover, .grey-btn:focus{
  background-color: #30396057 !important;
}
.red-btn:hover, .red-btn:focus{
  background-color: #9F1B3A !important;
}
.disabled-btn{
  background-color: #21BF730F !important;
}
.yellow-btn:hover, .yellow-btn:focus{
  background-color:#dea403 !important;
}
.MuiButton-root.Mui-disabled{
  color:unset !important;
}
.MuiButton-root.add_btn{
  color:green !important;
}
.add_company_action .ok_btn.MuiButton-root.Mui-disabled{
  background-color: #21BF73;
}

/* location */
.pac-container.pac-logo::after{
  display:none !important;
}

/* error msg */
.error {
  color: red !important;
  font-size: 12px;
  display: block;
  min-height: 14px;
  line-height: 14px;
}

/* label */
.label.bl-label{
  margin-bottom: 6px !important;
  display: inline-block;
  margin-top:0;
}

/* custom scrollbar */
.custom_scrollbar > div:nth-child(3){
  right:5px !important;
  width:7px !important;
}
.custom_scrollbar > div:nth-child(3) > div{
  background-color: rgba(0,0,0,0.3) !important;
}
.custom_scrollbar > div:nth-child(3) > div:hover{
  background-color: rgba(0,0,0,0.5) !important;
}

/* pagination */
.mat-pagination{
  display: flex;
  justify-content: flex-end;
}
.mat-pagination ul li > button{
  font: 500 12px/14px "Roboto";
  color: #424242;
}
.mat-pagination .MuiSvgIcon-root{
  color: #424242 !important;
}
.mat-pagination ul li > button.MuiPaginationItem-page:hover {
  background-color: rgba(47, 61, 123, 0.2);
}
.mat-pagination ul li > button.MuiPaginationItem-page.Mui-selected{
  background-color: #2f3d7b;
  color:#ffffff;
}

/* error */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* height increase in modal */
.auto_incress .MuiDialog-paperWidthMd {
  min-height: 350px;
}

/* Tooltip */
.tooltip-container{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width:100%;
}

/* Table Cell Cursor */
.tablecell_container td{
  cursor: pointer;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none !important;
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}