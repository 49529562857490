

/* .account-detail .add_division_btn{
   margin-bottom: 30px;
   background-color: #EBF5F0 !important;
} */

.divisionTitle{
    text-align: left;
font: 500 21px/28px 'Roboto' !important;
letter-spacing: 0px !important;
color: #424242;
opacity: 1;
}
.level_select_parent .open_all_btn{
    width: 100px;
    height: 32px;
    background: #21BF730F 0% 0% no-repeat padding-box;
    border: 1px solid #21BF73;
    border-radius: 3px;
    opacity: 1;
    right: 0;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
}
.division-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.accordion-first-mgr{
    text-align: left;
    font: 400 16px 'Roboto' !important;
    letter-spacing: 0px;
    color: #424242;
}

.accordion-head{
    text-align: left;
    font: 500 16px/21px 'Roboto';
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
}
.accordion-users{
    text-align: left;
    font: 16px'Roboto';
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 5px;
}


/* .accord-padd{
    padding: 10px;
} */
.account-detail .add_level_btn {
    background: #F6F2FF 0% 0% no-repeat padding-box !important;
}
.add_level_btn {
    width: 100%;
    border: 1px dashed #747A97 !important;
    height: 34px;
}
.add_level_btn .MuiButton-label{
    display: flex !important;
    flex-direction: column;
    color: #646670 !important;
    font: normal 14px/19px "Roboto" !important;
    text-transform: capitalize !important;
}


.division-left{
    display: flex !important;
    align-items: baseline;
}


.accordion-load{
    text-align: center;
    padding: 100px !important;
}
.add-level{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.level_save{
    /* width: 100px; */
    height: 32px;
    background: #21BF730F 0% 0% no-repeat padding-box !important;
    border: 1px solid #21BF73 !important;
    border-radius: 3px !important;
    opacity: 1;
    right: 0;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
}
.level_cancel{
    min-width: 25px !important;
    width: 25px;
    height: 32px;
    background: #bf21210f 0% 0% no-repeat padding-box !important;
    border: 1px solid red !important;
    border-radius: 3px !important;
    opacity: 1;
    right: 0;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: red !important;
}
.edit-input-level{
    align-items: center;
    display: flex;
    margin-bottom: 7px;
}
.edit-input-level button {
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}


/* .accordion-users::before{
    content: ".";
    width: 6px;
    height: 6px;
    background: #21BF73 0% 0% no-repeat padding-box;
    opacity: 1;
} */


.accordion-box .MuiAccordionSummary-root{
    min-height: 70px !important;
}


/* .accordion-box .MuiAccordionDetails-root{
    padding: 0px;
} */
.accordion-first .accordion-summary-first{
    padding: 0px !important;
    min-height: auto !important;
    margin: 15px 0px;
    height: auto !important;
}
.accord-details-area{
    display: block !important;
    padding: 0px !important;
}
.accordion-box .MuiPaper-elevation1{
    box-shadow: none !important;
    margin: 0px!important;
}
.accordion-first .accordion-summary-first .MuiAccordionSummary-content{
    margin: 0px !important;
}
.accordion-first .accordion-summary-first .MuiIconButton-edgeEnd{
    padding: 0px !important;
    margin: 0px !important;
}
.accordion-first:before{
    opacity: 0 !important;
}

.accord-details{
    display: block !important;
    padding: 15px 0px 15px 48px !important;
}
.accordion-box .accord-padd-ext{
    margin-bottom: 15px;
}
.accordion-box .MuiAccordion-rounded {
    border-radius: 2px !important;
}
.menu-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu-icon button{
    padding: 0px;
}
.accordion-box .MuiAccordionSummary-content.Mui-expanded{
    margin: 0px !important;
}
.accordion-box .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg) !important;
    align-self: flex-start;
}
.accordion-box .MuiAccordionSummary-expandIcon{
    transform: rotate(270deg) !important;
    align-self: flex-start;
}
.division-grid .MuiPaper-outlined{
    border: none;
    box-shadow: 0px 1px 2px #0000001A;
}
.division-grid .MuiCardContent-root{
    padding: 0px !important;
}
.add_division_btn .MuiButton-label {
    color: #6A6A6A !important;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize !important;
}

.division-grid .add_division_btn{
    width: 100%;
   
    border: 1px dashed #21BF73 !important;
    height: 50px;
    background-color: #EBF5F0 !important;
}
.add_division_btn_div{
    padding: 20px 15px 27px 15px;
}
.division-grid .MuiListItem-root{
    padding: 0px !important;
}
.division-grid .MuiList-padding {
    padding: 10px 27px !important;
}
.division-grid .division-list {
    margin: 0px !important;
}
.division-grid .division-list .MuiTypography-displayBlock{

    font: 500 18px/24px 'Roboto';
   
    color: #424242;

}
.division-grid .division-list .division-mgr{
    color: #646670;
    font: normal 14px/19px 'Roboto';
}
.division-grid .MuiListItemIcon-root{
    min-width: 0px;
    align-self: start;
    margin-top: 2px;
}
.division-grid .device-hub{
    color: #AFAFAF;
}
.device-hub{
    color: #21BF73;
    width: 15px !important;
    height: 15px !important;
    margin-right: 11px;
}
.add-level-btns{
    display: flex;
    /* width: 25%; */
    justify-content: space-evenly;
}
.accordion-acts{
    width: 100%;
    align-items: center;
    display: flex;
    
}
.accordion-acts .MuiAutocomplete-tag{
    height: auto;
}
.accordion-acts .level_cancel{
    margin-left: 10px;
}

.accordion-acts .MuiInputBase-root {
    min-height: 30px !important;
    height: auto !important;
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
}
.MuiAccordionDetails-root .cusautocomplete{
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E5F2;
    border-radius: 2px;
    min-height: 30px;
    margin: 0px 10px 0px 5px;
}
.MuiAccordionDetails-root .cusautocomplete .MuiChip-label{
    font: normal 12px/16px 'Roboto';
    color: #646670;
    padding-top: 2px;
}
.MuiAccordionDetails-root .cusautocomplete  .MuiChip-deleteIcon{
    width: 15px !important;
}
.accordion-details{
    display: flex;
    flex-direction: column;
    padding: 0px 16px 10px 50px !important;
}
/* .accordion-box .MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
} */
.accord-territory .MuiAccordionSummary-root{
    height: 45px !important;
    min-height: auto !important;
}
.division-grid .division-list .division-mgr span{
    color: #424242;
}
.add-level-input{
    width: 100%;
    /* margin-right: 10px !important; */
}
.add-level-input .MuiInputBase-root{
    height: 32px !important; 
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
.edit-input-level .MuiFormControl-root{
    width: 100%;
}
.active_level_nav{
    border-bottom: 1px solid #21BF73;
    background-color: #F5FBF8;
}
.active_level_nav .device-hub{
    color: #21BF73 !important;
}
.add-level button{
    margin-left: 10px;
}
.accordion-area .MuiAccordionSummary-root{
    border-bottom: 2px solid #9D9FDB;
}
.accordion-region .MuiAccordionSummary-root{
    border-bottom: 2px solid #37A2FF;
}
.accordion-territory {
    border-bottom: 2px solid #15DCDC;
}
.accordion-territory .MuiAccordionSummary-root{
    border: none !important;
}
.no-data-level{
    text-align: center;
    padding: 50px;
    font: 500 large "Roboto" !important;
}
.accordion-territory .MuiAutocomplete-clearIndicator{
    display: none !important;
}
/* button-hover */
.division-grid .add_division_btn.primary-btn{
    background-color: #21BF730F !important;
}
.division-grid .add_division_btn.primary-btn:hover, .division-grid .add_division_btn.primary-btn:focus{
    background-color: #21BF732E !important;
}
.accordion-box .MuiAccordionSummary-content.Mui-expanded{
    margin: 12px 0px !important;
}
.billing_detail_item{
    margin-bottom:15px;
}
.billing_detail_item > p{
    margin-bottom:5px;
}
.billing_top_detail_item{
    margin-top:0;
    margin-right: 10px;
    margin-bottom:0;
}
.billing_detail_item .edit-input{
    align-items: center;
}
.billing_detail_item > p > span:not(.edit-input){
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
}
.accordion-box .add_company_dialog .MuiFormControl-root, 
.accordion-box .add_company_dialog .MuiInputBase-root{
    width: 100%;
    max-width:256px;
}
.add_subscription_level_dialog .task_assign_model_container{
    margin-bottom:30px;
}
.add_subscription_level_dialog .task_assign_model_container > .MuiGrid-container{
    align-items: flex-start;
}
.add_subscription_level_dialog .task_assignment_header{
    margin-bottom:20px;
}
.add_subscription_action{
    margin-top:0;
}
.accordion-first .MuiAccordionSummary-root.Mui-focused{
  background-color: transparent;
}
.billing_detail_item .edit-input .MuiOutlinedInput-adornedStart{
 padding-left:17px;
}
.billing_detail_item .edit-input .MuiOutlinedInput-adornedStart input{
    padding-left: 3px;
}
.billing_detail_item .trail_field{
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
}
.billing_detail_item .trail_field:hover, .billing_detail_item .trail_field:focus{
    border: 1px solid #14193A !important;
}
@media only screen and (min-width:768px){
    .accordion-box{
        padding-right: 28px !important;
    }
    .accordion-box .accordion-first{
        margin-left: 52px;
    }
    .accordion-acts-span{
        min-width: 78px;
    }
    .billing_top_detail_item{
        min-width:310px;
    }
}
@media only screen and (max-width:767px){
    .accord-details{
        padding: 15px 0px 15px 20px !important;
    }
    .accordion-acts .level_cancel {
        margin: 10px 0px 0px 0px;
        width: 100%;
    }
    .MuiAccordionDetails-root .cusautocomplete{
        margin: 10px 0px 10px 0px;
    }
    .accordion-acts .level_save{
        width: 100%;
    }
    .accordion-acts{
        display: block;
    }
    .accordion-box .MuiAccordionSummary-root{
        /* min-height: 70px !important; */
        height: auto !important;
    }
    .accordion-box .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0px !important;
    }
    .accordion-box .MuiAccordionSummary-content{
        flex-wrap: wrap;
    }
    .billing_top_detail_item{
        margin-right: 0;
        width:100%;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width:480px){
    .billing_detail_item .billing-period .edit-input{
        flex-wrap: wrap;
        margin-top: 2px;
    }
    .billing-period .trail_field{
        height: 38px;
        flex-wrap: nowrap;
    }
    .billing_detail_item .billing-period .edit-input > span{
        width: 100%;
    }
    .billing_detail_item .billing-period .edit-input button.MuiButton-root{
        margin-left: 0;
    }
}