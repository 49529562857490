.products_btn_right{
    display: flex;
}
.products_btn_right .add_product_main_btn{
    margin-right: 8px;
}
@media (max-width: 600px){
.header-add-btn-product .MuiTypography-h5 {
    margin-bottom: 10px;
}
}
