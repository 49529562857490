.credit_card_dialog .MuiPaper-root{
    width:100%;
    max-width:661px;
}
.card_detail_input_field{
    border: 1px solid #E2E5F2 !important;
    margin: 2px 0 4px;
    padding: 9px 17px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    height: 38px;
    border-radius: 4px;
}
.card_detail_input_field .ElementsApp .InputElementinput{
    color: #424242 !important;
    font: normal 16px/21px "Roboto" !important;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field input::placeholder{
    color: #b2b2b2;
}
.card_detail_input_field:hover, .card_detail_input_field:focus{
    border: 1px solid #14193A !important;
}
.card_pay_button{
    width:100%;
}
.card_cvc_number_field{
    position: relative;
}
.card_cvc_number_field > img{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0 , -50%);
}
.subscription_page{
    padding: 30px;
}
.subscription_section{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 23px 25px;
    max-width:705px;
}
.subscription_section .bill_details_card_container{
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    margin-right: 20px;
}
.subscription_page .add_company_action{
    justify-content: flex-start;
}
@media (max-width: 959px){
    .credit_card_dialog .MuiGrid-item.card_expiration_date{
        padding-bottom:0;
    }
    .credit_card_dialog .MuiGrid-item.card_cvc_number{
        padding-top:0;
    }
}
@media (max-width: 480px){
    .subscription_page{
        padding: 30px 15px;
    }
    .subscription_section{
        padding:23px 15px;
    }
    .subscription_section .bill_details_card_container{
        min-width: auto;
        margin-right: 0;
    }
}
