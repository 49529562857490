.login_form_container{
    height: 100vh;
}
.login_form_div{
    padding: 45px 35px 45px 35px;
}
.login_form .logo{
    margin-bottom: 40px;
}
.login_form .login-header{
    font: bold 33px/44px "Roboto";
    margin: 0;
    margin-bottom: 38px;
    color: #424242;
}
.inputHeight{
    margin-bottom: 30px !important;
}
.mar-bot-10{
    margin-bottom: 3px !important;
}
.inputHeight, .MuiInputBase-root{
    height:50px !important;
    width: 100%;
}
.inputHeight input{
    padding: 13px 17px;
    font: normal 18px/24px "Roboto"  !important;
    color: #141A3B;
}
.loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
}
.loginCheckbox .MuiTypography-root, .forget_password{
    font: normal 14px/19px "Roboto" !important;
    color: #303960 !important;
    align-self: center;
    cursor: pointer;
}
.LoginBtn{
    width: 100% !important;
    padding: 17px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #21BF73 !important;
    box-shadow: none !important;
    margin-top: 20px !important;
}
.login_banner{
    background-image: url("../../Assets/images/Group 145.png");
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.login_banner p{
    position: absolute;
    top: calc(50% - 16px);
    padding-left: 66px;
    font: normal 24px/31px "Roboto" !important;
    color: #F5F7FF;
    max-width: 428px;
    font-weight: 400;position: absolute;
    top: calc(50% - 16px);
    left: 50px;
    padding-left: 10px;
    border-left: 6px solid #21BF73;
    font: normal 24px/31px "Roboto" !important;
    color: #F5F7FF;
    max-width: 428px;
    font-weight: 400;
}
.login_banner .icon_small {
    position: absolute;
    left: 43px;
    top: 31px;
}
.login_banner span {
    position: absolute;
    right: 24px;
    color: #FFFFFF;
    bottom: 20px;
    max-width: 243px;
    font: normal 14px/19px "Roboto" !important;
}
.repeat_grid_bottom{
    position: absolute;
    left: 25px;
    bottom: -53px;
}
.repeat_grid_right{
    position: absolute;
    top: 118px;
    right: -126px;
}
.social-link{
    padding: 0px 10px 12px 10px;
}
.social-link .mobile_app a{
    margin-left: 7.5px;
    display: inline-block;
}
.social-link .mobile_app a img{
    vertical-align: unset !important;
}
.social-link .mobile_app{
    display: flex;
}
.social-link .mobile_app span{
    font: normal 12px/16px "Roboto" !important;
    align-self: center;
}
.social_links a{
    font: normal 12px/16px "Roboto" !important;
    margin-right: 10px;
}
.err-msg{
    margin-left: 14px;
    color: red;
    font-size: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}
.social-link .mobile_app a{
    display: inline-flex;
    align-items: center;
}
@media (max-width: 600px){
    .login_banner{
        display:none;
    }
    .header-content .go-home-btn{
        padding: 12px 25px !important;
    }
}