.textContainer{
    position:sticky;
    bottom:0px; 
    width:100%;
    z-index: 1;
}

.timeZoneText{
    background-color:#000000;
    color:white; 
    opacity:0.75; 
    text-align:center; 
    padding:15px
}

.updatebtn{
    background:#21BF73; 
    padding:5px 15px; 
    border-radius:5px;
    margin-left:20px; 
    cursor:pointer;
    border: none;
}

.closebtn{
    float:right; 
    cursor:pointer
}