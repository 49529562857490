.cancel-task-img {
    text-align: center !important;
}

.closeButton {
    position: absolute !important;
    right: 0;
    margin-top: 10px !important;
    padding-top: 30px !important;
}

.cancel-task_action {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.cancel-task_action .cancel_btn {
    min-width: 130px;
    color: #6A6A6A;
    height: 40px;
    border: 1px solid #BEBEBE;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
}

.cancel-task_action .ok_btn {
    background-color: #21BF73;
    box-shadow: none;
    color: #FFFFFF;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
    height: 40px;
    min-width: 130px;
}

.cancel-task-label {
    /* top: 231px;
    left: 611px; */
    width: 156px !important;
    height: 37px !important;
    /* UI Properties */
    text-align: left !important;
    font: normal normal bold 28px/37px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #3A3A3A !important;
    opacity: 1 !important;
    /* text-decoration: none !important; */
}

.cancel-task-msg {
    /* top: 277px;
        left: 611px; */
    width: 363px !important;
    height: 57px !important;
    /* UI Properties */
    text-align: left !important;
    font: normal normal normal 20px/24px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #303960 !important;
    opacity: 1 !important;
    margin-top: 30px !important;
}

.waring-img {
    /* top: 221px; */
    left: 700px !important;
    width: 188px !important;
    height: 165px !important;
    /* UI Properties */
    background: transparent 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
}

.cancel-radio-btn {
    /* top: 374px;
    left: 611px; */
    width: 16px !important;
    height: 16px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #646670 !important;
    opacity: 1 !important;
}

.add-note {
    /* left: 364px;
        width: 75px; */
    top: 40px !important;
    height: 24px !important;
    text-align: left !important;
    font: normal normal normal 18px/24px Acumin Variable Concept !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    opacity: 1 !important;
}

.task_msg {
    /* top: 449px;
        left: 364px; */
    width: 100% !important;
    height: 110px !important;
    background: #F8F8F8 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1 !important;
    margin-top: 10px !important;
}

.register-form-middle {
    margin-top: 60px !important;
}

.recurring_popup .MuiInputBase-root {
    max-height: 450px !important;
}

.deleteServiceDialog.additional-rep .MuiDialog-paper{
    height: 400px !important;
    padding: 5px 10px 5px !important;
}

.deleteServiceDialog.additional-reps .MuiDialog-paper{
    height: 200px !important;
    padding: 5px 10px 5px !important;
}

.additionalRepCloseBtn {
   padding: 5px 10px 5px !important;
}

.add_company_action.additional_rep {
    margin-top: 250px !important;
}