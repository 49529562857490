@font-face {
    font-family: 'Roboto';
    src: url('roboto-black.eot');
    src: url('roboto-black.eot?#iefix') format('embedded-opentype'),
        url('roboto-black.woff2') format('woff2'),
        url('roboto-black.woff') format('woff'),
        url('roboto-black.ttf') format('truetype'),
        url('roboto-black.svg#roboto-black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-bold.eot');
    src: url('roboto-bold.eot?#iefix') format('embedded-opentype'),
        url('roboto-bold.woff2') format('woff2'),
        url('roboto-bold.woff') format('woff'),
        url('roboto-bold.ttf') format('truetype'),
        url('roboto-bold.svg#roboto-bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-light.eot');
    src: url('roboto-light.eot?#iefix') format('embedded-opentype'),
        url('roboto-light.woff2') format('woff2'),
        url('roboto-light.woff') format('woff'),
        url('roboto-light.ttf') format('truetype'),
        url('roboto-light.svg#roboto-light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-thin.eot');
    src: url('roboto-thin.eot?#iefix') format('embedded-opentype'),
        url('roboto-thin.woff2') format('woff2'),
        url('roboto-thin.woff') format('woff'),
        url('roboto-thin.ttf') format('truetype'),
        url('roboto-thin.svg#roboto-thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-medium.eot');
    src: url('roboto-medium.eot?#iefix') format('embedded-opentype'),
        url('roboto-medium.woff2') format('woff2'),
        url('roboto-medium.woff') format('woff'),
        url('roboto-medium.ttf') format('truetype'),
        url('roboto-medium.svg#roboto-medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-regular.eot');
    src: url('roboto-regular.eot?#iefix') format('embedded-opentype'),
        url('roboto-regular.woff2') format('woff2'),
        url('roboto-regular.woff') format('woff'),
        url('roboto-regular.ttf') format('truetype'),
        url('roboto-regular.svg#roboto-regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}