.prod_list{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #0000001A;
    border-radius: 5px;
}
.prod_list_text_main{
    padding:21px 15px;
    font: 500 18px/24px "Roboto" !important;
    border-bottom:1px solid #EFEFEF;
    background:#FFFFFF;
}
 .active_level{
    border-bottom:1px solid #21BF73;
    background: #F5FBF8;
} 
.prod_cat_list_one{
    font: normal 16px/23px "Roboto" !important;
    padding: 15px 20px;
    display: flex;
}
.prod_cat_list_one:hover{
background-color: #F9FAFC !important;
}
.prod_list_one a{
    color:#424242 !important;
}
.prod_list_one{
    max-width: 250px;
    overflow: hidden;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.division_head{
    text-align: left;
    font: 500 17px/23px "Roboto";
    color: #FFFFFF;
    background-color: #303960;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
}
.divison_main{
    border-radius: 5px;
    background: #FFFFFF;
}
.prod_category_main_div{
    padding: 0px 10px;
}
.add_btn{
    right: 0;
    border: 1px solid #21BF73 !important;
    background-color: #21BF730F !important;
    box-shadow: none;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
    width: 291px;
    height: 41px;
    margin-top: 10px !important;
}
.txt_field{
    width: 291px;
    height: 50px;
    border: 1px solid #E2E5F2 !important;
    border-radius: 5px;
    padding: 5px 12px !important;
}
.add_btn_labels{
    padding: 8px 9px;
    text-align: left;
    font: normal 15px/20px "Roboto" !important;
}
.prod_cat_division_one{
    padding: 23px 0px;
}
.prod_edit_icon{
    margin-right: 15px;
    height: 15px;
    width: 15px;
}
.prod_del_icon{
    width: 11px;
    height: 14px;
}
.add_product_category_dialog .MuiDialog-paperWidthMd {
    max-width: 500px;
}
.prod_category_main_div .prod_list{
    cursor: pointer;
}