.chart-conatiner{
    padding:12px;
}
.chart-conatiner .chart-section{
    margin-bottom: 11px;
}
.chart-conatiner .new_custom_filter_div.filter-option label,
.chart-conatiner .new_custom_filter_div.filter-option span{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.chart-conatiner .new_custom_filter_div.current-selected span{
    font-family:"Roboto";
}
.chart-conatiner .chart-section:last-child{
    margin-bottom: 0;
}
.graph-section > .MuiGrid-item{
    display:flex;
}
.chart-conatiner h3.summary-title{
    font: bold 18px/24px "Roboto";
    color: #424242;
    letter-spacing: 0px;
}
.task-graph{
    border-radius:20px;
    background-color: #fff;
    padding:19px 8px;
    width:100%;
}
.task-graph > .chart-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 12px;
}
.task-graph h5.chart-title{
    text-transform: uppercase;
    font: bold 17px/23px "Roboto";
    margin-bottom:0;
    color: #424242;
    text-transform: none;
}
.task-graph .chart-filter label{
font: normal 14px/19px "Roboto" !important;
color: #424242;
}
.task-graph select.select-box{
    font: normal 14px/19px "Roboto";
    border:none;
    color: #424242;
}
.task-graph img{
    width:100%;
}
.team-conatiner{
    margin-top:23px;
}
.team-conatiner .team-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color:#F9F9F9;
    margin: 0 -4px 23px;
    padding: 5px 17px;
}
.team-conatiner h4.team-title, .team-conatiner h4.report-title{
    color:#424242;
    font: 500 14px/19px "Roboto";
    margin-bottom:0;
}
.team-conatiner h4.report-title{
    margin-right:27px;
}
.team-conatiner ul.team-list{
    padding:0 17px;
    margin-bottom:0;
}
.team-conatiner ul.team-list li{
    margin-bottom:25px;
    list-style: none;
    display:flex;
    align-items: center;
    justify-content:space-between;
}
.team-conatiner ul.team-list li span{
    font: normal 14px/19px "Roboto";
    color:#5D5D5D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right:10px;
}
.team-conatiner ul.team-list li span.download-file{
    font: normal 14px/19px "Roboto";
    display: inline-flex;
    align-items: end;
    color: #21BF73;
    cursor: pointer;
}
.team-conatiner ul.team-list li span.download-file img{
    margin-right:8px;
    cursor: pointer;
}
.filter-wrapper{
    display:flex;
    margin:0 -5px;
}
.filter-wrapper > div{
    margin:0 5px;
}
/* chart */
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.task-graph.cost-revenue .highcharts-color-0, .task-graph.cost-revenue .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.task-graph.cost-revenue .highcharts-color-1, .task-graph.cost-revenue .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d8d3cd;
}

.task-graph.utilization .highcharts-color-0, .task-graph.utilization .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #f8bd7f;
}

.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d9adad;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #84a9ac;
}
.task-graph.utilization .highcharts-color-1, .task-graph.utilization .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d8d3cd;
}

.task-graph.cost-revenue .highcharts-xaxis-labels text,
.task-graph.cost-revenue .highcharts-yaxis-labels text,
.task-graph.utilization .highcharts-xaxis-labels text,
.task-graph.utilization .highcharts-yaxis-labels text,
.task-graph.num-tasks .highcharts-xaxis-labels text,
.task-graph.num-tasks .highcharts-yaxis-labels text,
.task-graph.active-acc .highcharts-xaxis-labels text,
.task-graph.active-acc .highcharts-yaxis-labels text,
.task-graph.sales .highcharts-xaxis-labels text,
.task-graph.sales .highcharts-yaxis-labels text{
    font: normal 11px "Roboto" !important;
    fill:#505050 !important
}
.graph-section .task-graph .highcharts-data-label text{
    font: bold 9px/11px "Roboto" !important;
}
.graph-section .task-graph .highcharts-axis-title{
    font: bold 17px/23px "Roboto";
    fill: #424242 !important;
}
.task-graph.cost-revenue .highcharts-yaxis text,
.task-graph.num-tasks .highcharts-xaxis text,
.task-graph.active-acc .highcharts-xaxis text,
.task-graph.sales .highcharts-xaxis text,
.task-graph.utilization .highcharts-yaxis text{
    transform: translate(0, 20px);
}

.task-graph .team-conatiner{
    position: relative;
}
.task-graph .summary_loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.7);  
    display: flex;
    align-items: center;
    justify-content: center;
}
.task-graph .highcharts-legend > g{
    transform: translate( 30px , 10px);
}
.chart-conatiner .dashboard_task_filters{
    margin:0 0 9px;
}
/* Pending Events Acceptance */
.task-graph.cost-revenue.acceptance_section{
    padding-bottom:22px;
}
.acceptance_container{
    padding:0 16px;
    margin: 0 -24px;
    display: flex;
    flex-wrap: wrap;
}
.event_acceptance{
    padding:13px 10px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin: 0 20px 30px;
    width: calc( 50% - 40px);
}
.event_acceptance_header{
    display:flex;
    align-items: center;
    margin-bottom: 11px;
}
.event_acceptance_header h5{
    font: bold 16px/19px "Roboto";
    color: #424242;
    margin-right:13px;
    margin-bottom:0;
    cursor: pointer;
}
.event_acceptance_header h5 span{
    white-space: nowrap; 
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event_acceptance_details{
    margin-right:10px;
}
.event_acceptance_location{
    display: inline-flex;
    align-items: center;
    margin-right:9px;
}
.event_acceptance_location img{
    width: 9px !important;
    height: 12px;
    margin-right:3px;
}
.event_acceptance_location span{
    font: normal 14px/17px "Roboto";
    color: #4759A7;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
}
.event_acceptance_type span{
    font: normal 12px/14px "Roboto";
    color: #B4B4B4;
    text-transform: uppercase;
}
.event_acceptance_title{
    font: normal 16px/19px "Roboto";
    color:#303960;
    margin-bottom: 10px;
}
.event_acceptance_attendees{
    display: flex;
    align-items: flex-start;
    margin-bottom:16px;
}
.attendees_title{
    font: normal 14px/17px "Roboto";
    color: #5B5B5B;
    margin-right:5px;
    white-space: nowrap;
}
.attendees_name{
    font: normal 14px/17px "Roboto";
    color: #aaaaaa;
    margin-right:7px;
}
.event_acceptance_attendees .attendees_name > span:last-child{
    font: normal 14px/17px "Roboto";
    color:#B4B4B4;
    white-space: nowrap;
}
.event_acceptance_time{
    display: flex;
    align-items: center;
}
.event_acceptance .event_acceptance_time img{
    width: 15px;
    height: 17px;
    margin-right: 7px;
}
.event_acceptance_time span{ 
    font: normal 14px/17px "Roboto";   
    color:#3A3A3A;
}
.event_acceptance_actions{
    margin:0 -7px;
    align-self: flex-end;
    text-align: end;
    width: auto;
}
.event_acceptance_actions button{
    width:100px;
    height:30px;
    cursor: pointer;
    transition: 0.2s linear all;
    margin: 15px 7px 0;   
}
.reject_btn{
    font: normal 14px/17px "Roboto";  
    background-color: #F9F9F9;
    border: none;
    border-radius: 5px;
    color:#424242;
}
.accept_btn{
    font: normal 14px/17px "Roboto";  
    background-color:#21BF73;
    border: none;
    border-radius: 5px;
    color:#FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.warning_btn{
    font: normal 14px/17px "Roboto";  
    background-color:#EEB20C;
    border: none;
    border-radius: 5px;
    color:#FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;    
}
button.warning_btn:focus, button.accept_btn:focus, button.reject_btn:focus{
    outline:none
}
.event_acceptance_actions .warning_btn img{
    width: 11px;
    height: 10px;
    margin-right: 5px;
}
@media (min-width:1122px){
    .event_acceptance_details{
        width:300px;
    }
}
@media (min-width: 992px) and (max-width: 1121px){
    .event_acceptance_actions{
        width:min-content;
    }
    .event_acceptance_details{
        width:300px;
    }
}
@media (max-width: 991px){
    .event_acceptance{
        width: 100%;
    }
}
@media (min-width:601px) and (max-width:767px){
    .event_acceptance_details{
        width:300px;
    }
}
@media (max-width : 600px){
    .task-graph > .chart-header{
        flex-wrap: wrap;
    }
    .task-graph h5.chart-title{
        margin-right:10px;
    }
    .event_acceptance, .event_acceptance_header , .event_acceptance_attendees{
        flex-wrap: wrap;
    }
    .event_acceptance_details{
        width:100%;
    }
}