/* .highcharts-figure, .highcharts-data-table table {
    min-width: 310px; 
    max-width: 800px;
    margin: 1em auto;
}

#container {
    height: 400px;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #EBEBEB;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
	font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.highcharts-credits{
    display: none;
} */


.selectBoi > span{
    color: #707070;
    font-size: 14px !important;
    font-weight: bolder;
    width: 10px;
    height: 5px;
}

.report_header_btn button {
    box-shadow: 0px 3px 10px #0000000A;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    font: normal 14px/19px "Roboto";
    background-color: #fff;
    color: #424242;
    text-transform: none;
    min-width: 150px;
    height: 35px;
    padding: 10px 0px 6px 0px;
}
.reports_down_tab button{
    box-shadow: 0px 3px 10px #0000000A;
    border-radius: 5px;
    font: normal 14px/19px "Roboto";
    background-color: #21bf73;
    color: #fff;
    text-transform: none;
    min-width: 250px;
    height: 40px;
    padding: 8px 0px 8px 0px;
}
.report_header_btn button img{
    margin-right: 10px;
    margin-bottom: 3px;
}
.report_header_btn_two{
    margin-left: 6px !important;
}
.reports_tab{
    padding: 0px 20px;
}
.report-tab-table{
    width: max-content;
    min-width: 50%;
    max-width: 100%;
}
.report-tab-table-header th.MuiTableCell-head:nth-child(2),
.report-tab-table-body td.MuiTableCell-body:nth-child(2){
    text-align: left !important;
}
.reports_tab .ant-tabs-tab {
    color: #AAAAAA;
    font: normal 14px/19px "Roboto" !important;
    padding: 17px 15px 11px 15px;
}
.reports_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #444444 !important;
}

.reports_tab .ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 2px solid #E2E5F2;
}
.reports_tab .ant-tabs-ink-bar{
    background: #21BF73 !important;
}
.report_filter {
    margin: 0px 10px 10px;
}
.report_ant_select .ant-select-selector {
    border: none !important;
    box-shadow: 0px 1px 2px #0000001A;
    border-radius: 5px !important;
    width: 285px !important;
    font: 500 14px/19px "Roboto" !important;
    color: #424242 !important;
    padding: 13px 15px 13px 15px !important;
    height: 45px !important;
    align-items: center;
}

.report_ant_select{
    margin-right:20px;
}
.report_filter .ant-picker, .report_filter .ant-picker-clear{
    background-color: transparent;
}
.reports_tab .highcharts-axis{
    font: normal 16px/21px "Roboto" !important;
    fill: #424242 !important;
}
.reports_tab .highcharts-xaxis-labels text{
    font: normal 11px/14px "Roboto" !important;
    fill: #444444 !important;
}
.reports_tab .highcharts-yaxis-labels text{
    font: normal 11px/14px "Roboto" !important;
    fill: #424242 !important;
}
.reports_tab .highcharts-axis .highcharts-axis-title{
    font: normal 16px/21px "Roboto";
    fill: #424242 !important;
}
.normal-barchart .highcharts-xaxis .highcharts-axis-title, .vertical-barchart .highcharts-yaxis .highcharts-axis-title{
    transform: translate(0, 10px);
}   
/* .reports_tab .highcharts-yaxis text.highcharts-axis-title{
    transform: translate(1 , 10px);
} */
.report_filter .new_custom_filters > div {
    margin-bottom: 10px;
}
.graph-table-header{
    text-align: left;
    font:normal 600 21px/28px "Roboto" !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    opacity: 1;
}
.graph-table-header.table-header{
  margin-bottom: 15px;
}
.graph-table-header.graph-header{
  margin-bottom:29px;
}
.report-popup-menu{
    width:223px;
    background: #FFFFFF;
    box-shadow: 0px 8px 17px #0000000F;
    border-radius: 10px;
    padding:0 0 2px;
}
.report-popup-menu .popup-header{
    font: normal normal 600 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    padding:14px 23px;
}
.report-popup-menu .popup-list{
    padding: 12px 23px;
    font: normal 14px/19px "Roboto";
    letter-spacing: 0px;
    color: #5B5B5B;
}
.report-popup-menu hr{
    background-color:#E2E5F2;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#2d4059;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.report-graph.cost-revenue .highcharts-color-0, .report-graph.cost-revenue .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#84a9ac;
}
.report-graph.cost-revenue .highcharts-color-1, .report-graph.cost-revenue .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d8d3cd;
}
.report-graph.utilization .highcharts-color-0, .report-graph.utilization .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#ea5455;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#5980b3;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-2:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-3:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#66bfbf;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-4:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #f8bd7f;
}

.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-5:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d9adad;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-6:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #84a9ac;
}
.report-graph.utilization .highcharts-color-1, .report-graph.utilization .highcharts-series-7:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill: #d8d3cd;
}

.report-graph.account-satisfaction .highcharts-color-0, .report-graph.account-satisfaction .highcharts-series-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#d9adad;
}
.report-graph.account-satisfaction .highcharts-color-1, .report-graph.account-satisfaction .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#28df99;
}
.report-graph.uncovered .highcharts-color-1, .report-graph.uncovered .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#D24856;
}
.report-graph.cancelled .highcharts-color-1, .report-graph.cancelled .highcharts-series-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#888888;
}
.reports_down_tab{
    max-width: 100% !important;
    text-align: center !important;
    padding: 50px 0;
}
.reports_tab .ant-empty{
    padding-bottom:30px;
}
.report_header_btn_down svg.MuiSvgIcon-root{
    color:#ffffff;
}
.report_table{
    text-align: "right"; 
    padding-right:"30px"
}