.container_weekly {
  display: inline-table;
  padding-right: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.container_enddate{
  display: inline-table;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.fieldName {
  vertical-align: middle;
  padding: 20px;
}
.fieldName_monthly {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  margin-left:5px;
}
.fieldName_monthlys {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 5px;
  margin-left:5px;
  opacity: 0.5;
}
.radioBtn{
  vertical-align: middle;
  padding: 5px;
  padding-right: 5px;
}

.cancel-task-img {
  text-align: center !important;
}

.closeBtn {
  position: absolute !important;
  right: 0;
  margin-top: 10px !important;
  padding-top: 30px !important;
}

.cancel-task_action {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.cancel-task_action .cancel_btn {
  min-width: 130px;
  color: #6A6A6A;
  height: 40px;
  border: 1px solid #BEBEBE;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
}

.cancel-task_action .ok_btn {
  background-color: #21BF73;
  box-shadow: none;
  color: #FFFFFF;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
}

.cancel-task-label {
  width: 156px !important;
  height: 37px !important;
  text-align: left !important;
  font: normal normal bold 28px/37px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #3A3A3A !important;
  opacity: 1 !important;
}

.cancel-task-msg {
  width: 363px !important;
  height: 57px !important;
  text-align: left !important;
  font: normal normal normal 20px/24px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #303960 !important;
  opacity: 1 !important;
  margin-top: 30px !important;
}


.waring-img {
  left: 700px !important;
  width: 188px !important;
  height: 165px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.cancel-radio-btn {
  width: 16px !important;
  height: 16px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #646670 !important;
  opacity: 1 !important;
}

.add-note {
  top: 40px !important;
  height: 24px !important;
  text-align: left !important;
  font: normal normal normal 18px/24px Acumin Variable Concept !important;
  letter-spacing: 0px !important;
  color: #424242 !important;
  opacity: 1 !important;
}

.task_msg {
  width: 100% !important;
  height: 110px !important;
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  margin-top: 10px !important;
}

.reschedule_err_msg {
  color: red !important;
  background: #F3183D1C 0% 0% no-repeat padding-box !important;
  border-radius: 9px;
  margin-top: 30px;
  opacity: 0.1;
  padding: 15px 15px 15px 15px;
  width: 100% !important;
  height: 47px;
  text-align: left;
  font: normal normal normal 16px/21px Acumin Variable Concept !important;
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
}

.reschedule_err_msg .MuiInputBase-root {
  color: red !important
}

.register-form-middle {
  margin-top: 60px !important;
}

.add_company_action {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.add_company_action .ok_btn.MuiButton-root.Mui-disabled {
  background-color: #21BF73;
}

.add_company_action .ok_btn {
  background-color: #21BF73;
  box-shadow: none;
  color: #FFFFFF;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
}

.add_company_action .cancel_btn {
  min-width: 130px;
  color: #6A6A6A;
  height: 40px;
  border: 1px solid #BEBEBE;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
}

.add_company_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #14193a !important;
}

.add_company_dialog .MuiSelect-outlined.MuiSelect-outlined {
  padding-left: 0px;
  background: transparent;
}

.add_account_dialog .custom_upload button {
  text-transform: inherit !important;
  font: normal 14px/19px "Roboto" !important;
}

.add_account_dialog .MuiExpansionPanel-root {
  box-shadow: none !important;
}

.add_account_dialog .MuiInputBase-root {
  background: #fff;
}

.custom-ant-select .label {
  display: block;
  margin: 0px;
}

.label.bl-label {
  margin-bottom: 6px !important;
  display: inline-block;
  margin-top: 0;
}

.recurring_popup .MuiInputBase-root{
  max-height: 450px !important;
}

.reschedule-recurring .MuiDialog-paperWidthMd{
    height: 400px !important;
    width: 600px !important;
}
.reschedule-recurring .ant-picker-input{
    padding-left: 10px !important;
}

