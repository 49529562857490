.new_filter_modal .MuiPaper-root {
    min-width: 278px;
    overflow: hidden;
    padding: 11px 0px;
    max-height: 320px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    margin-top: 5px !important;
}
.new_filter_search {
    padding: 0px 19px 7px 17px;
    border-bottom: 1px solid #E9E9E9;
}
.new_filter_search .MuiFormControl-root{
    width: 100%;
    height: 19px;
    color: #5B5B5B;
    
    /* font: normal 14px/19px "Roboto"; */
}
.new_filter_search .MuiFormControl-root input{
    color: #5B5B5B;
    font: normal 16px/19px "Roboto";
}
.new_filter_list{
    padding: 3px 0px 0px 0px;
    overflow-y: auto;
    max-height: 300px;
    height: 100%;
}
.new_filter_lists{
    color: #424242;
    font: normal 16px/21px "Roboto";
    padding: 8px 19px;
    cursor: pointer;
}
.new_filter_modal .MuiPaper-root .new_filter_lists span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 268px;
    display: list-item;
}
.new_filter_lists:hover{
    background-color: #FBFBFB;
}
.new_custom_filter_div {
    color: #5B5B5B;
    font: normal 14px/19px "Roboto" !important;
    align-items: center;
    display: flex;
    /* cursor: pointer; */
}
.rightName{
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
    text-overflow: ellipsis;
    cursor: pointer;
}
.new_custom_filter_div .carret{
    margin-left: 7px;
    width: 10px;
    height: 5px;
    cursor: pointer;
}
.filter_date_range.ant-picker-range .ant-picker-active-bar{
    margin-left: 0px !important;
}