.recurring-text-detail{
    background:#2B2B2B21;
    padding-left:5px;
    padding-right:5px;
    border-radius:5px;
    width:fit-content;
    max-width:100%;
    display: flex;
    min-width: 250px;
  }
.recurring-header{
    font: 410 18px/10px Roboto;
    padding-left:20px; 
    display:block
}