.body-bg-color.billing-page{
    padding-bottom:0;
  }
  .billing-page header{
    position: relative;
  }
  .billing_container{
    display: flex;
  }
  .billing_sidebar{
    width:271px;
    min-height: calc(100vh - 75px);
    background: #FFFFFF;
    padding: 28px 18px;
  }
  .billing_sidebar .comp-image img{
    width:100%;
  }
  .sidebar_header{
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 44px;
  }
  .sidebar_companyname{
    font: normal 14px/17px "Roboto";
    color: #424242;
    padding: 17px 0 10px;
    text-transform: capitalize;
  }
  .billing_list{
    list-style: none;
    margin-bottom:0;
    padding:0
  }
  .billing_list_item{
    margin-bottom:8px;
  }
  .configure_btn{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
    display: inline-block;
    background: transparent;
    border:none;
    outline: none;
    border-radius: 5px;
    padding: 11px 17px;
    width:100%;
    text-align: left;
  }
  .configure_btn.active, .configure_btn:hover{
    background: #FBFBFB;
    border:none;
    outline: none;
    border-radius: 5px;
    font: normal 16px/21px "Roboto";
    color: #4759A7;
    padding: 11px 17px;
    width:100%;
  }
  .billing_page{
    width: calc( 100% - 271px );
    padding: 28px 70px 16px;
  }
  .billing_main_container{
    width: calc( 100% - 271px );
  }
  .billing_main_container .billing_page{
    width: 100%;
    padding: 16px 70px;
  }
  .billing_configure{
    padding: 28px 11px 16px 70px; 
  }
  .billing_page_title{
    font: bold 21px/25px "Roboto";
    color: #303960;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .billing_configure_form{
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 25px 25px 31px;
    width: 100%;
    max-width: 1014px;
  }
  .billing_form_title{
    font: 500 18px/22px "Roboto";
    color: #424242;
    margin-bottom: 26px;
  }
  .billing_configure_form .label{
    font: 500 16px/21px "Roboto";
    color: #333758;
  }
  .label.bill_label{
    margin-bottom: 8px;
    display: inline-block;
  }
  .form_input_field{
    position: relative;
  }
  .form_input_field span.error{
    position: absolute;
    bottom:3px;
  }
  .billing_configure .MuiGrid-spacing-xs-2 > .MuiGrid-item.billing_form_field{
    padding-top:0 ;
    padding-bottom:0 ;
  }
  .billing_configure .MuiInputBase-root{
    height: 38px !important;
  }
  .billing_configure .MuiInputBase-root input{
    padding: 13px 17px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
  }
  .add_card_btn{
    padding:12px !important;
    font: 500 14px/17px "Roboto";
    color: #424242 !important;
    background-color: #F6F6F6 !important;
    border-radius: 5px !important;
    border:none !important;
  }
  .billing_configure_form .add_card_btn{
    width: 100%;
    margin:8px 0 10px;
    padding:10px 0;
    font: 500 14px/19px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    text-transform: capitalize;
    background: #F6F6F6;
    border-radius: 5px;
    margin: 6px 0 10px;
  }
  .billing_configure_form .form_input_field span.error{
    bottom:0;
  }
  
  /* Billing Overview */
  .billing_overview_sections .MuiGrid-root.MuiGrid-item > *{
    min-height: 100%;
  }
  .billing_overview_section{
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 23px 25px;
  }
  .billing_overview_section .overview_section_heading{
    font: 500 18px/24px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  .bill_estimate_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 18px 20px;
  }
  .bill_estimate_container h4{
    font: normal 900 21px/21px "Roboto";
    color: #030303;
    margin-bottom:8px;
  }
  .bill_estimate_container span{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
  }
  .bill_estimate_container p{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom:0;
  }
  .billing_history_list{
    margin:0;
    padding:0;
  }
  .billing_history_list li{
    background: #FBFBFB;
    border-radius: 5px;
    margin-bottom:15px;
    padding: 10px 20px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .billing_history_list li>:last-child{
    margin-bottom: 0;
  }
  .billing_history_list li p{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  .bill_contact_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 16px 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .bill_contact_image_section{
    margin-right:9px;
  }
  .bill_contact_content_section{
    overflow: hidden;
  }
  .bill_contact_content_section > *{
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bill_contact_profile{
    width:43px;
    height:43px;
    object-fit: cover;
    border-radius: 50%;
  }
  .bill_contact_username{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  .bill_contact_email{
    font: normal 14px/19px "Roboto";
    color: #A2A2A2;
    margin-bottom: 0;
  }
  .bill_details_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding:9px 15px 11px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .bill_period_heading{
    font: 500 14px/19px "Roboto";
    color: #5B5B5B;
    margin-bottom: 0;
    margin-right: 39px;
    text-transform: capitalize;
  }
  .bill_period{
    font: normal 14px/19px "Roboto";
    color: #646670;
    margin-bottom: 0;
  }
  .bill_details_card_container{
    background: #FBFBFB;
    border-radius: 10px;
    padding: 15px;
    display: flex;
  }
  .bill_details_image_section{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    width:33px;
    height:33px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-right:16px;
  }
  .bill_contact_card_image{
    width:22px;
    height:16px;
    object-fit: cover;
  }
  .bill_details_card_heading{
    font: normal 14px/19px "Roboto";
    color: #A2A2A2;
    margin-bottom: 0;
  }
  .bill_details_card_number{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 2px;
  }
  .bill_details_card_number span{
    font-size: 40px;
    line-height: 0;
    display: inline-block;
    transform: translate(0px, -2px);
    margin-right: 5px;
    letter-spacing: -2px;
  }
  .bill_details_card_holder{
    font: 500 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 4px;
    margin-bottom: 4px;
  }
  .bill_details_card_expire{
    font: normal 14px/19px "Roboto";
    color: #424242;
    margin-bottom: 0;
  }
  /* Billing History */
  .billing_history_table{
    max-width:786px;
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 23px 36px #00000008;
    border: 1px solid #E5E5E5;
    padding: 20px 25px;
    border-radius: 10px;
  }
  .billing_history_table .MuiTableCell-root{
  border-bottom:0;
  }
  .billing_history_table thead.MuiTableHead-root .MuiTableRow-head{
    background: #4759A7;
  }
  .billing_history_table .MuiTableCell-head{
    padding: 17px 15px 19px;
    font: 700 14px/19px "Roboto";
    color: #FFFFFF;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .billing_history_table .MuiTableCell-body {
    padding:0 !important;
    white-space: nowrap;
  }
  .billing_history_table .MuiTableCell-body div{
    padding: 17px 15px 19px;
    font: 700 14px/19px "Roboto";
    background: #F5F5F5;
    margin-bottom: 15px;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status{
    padding: 16px 15px;
  }
  .billing_history_table tr:first-child td.MuiTableCell-body div{
    margin-top:8px;
  } 
  .billing_history_table tr:last-child td div{
    margin-bottom: 0;
  }
  .billing_history_table thead tr td:first-child div, .billing_history_table thead tr th:first-child,
  .billing_history_table tbody tr td:first-child div, .billing_history_table tbody tr th:first-child{
    padding: 17px 15px 19px 20px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
 }
  .billing_history_table thead tr td:last-child div, .billing_history_table thead tr th:last-child,
  .billing_history_table tbody tr td:last-child div, .billing_history_table tbody tr th:last-child{
    padding: 17px 20px 19px 15px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
 }
  .billing_history_table tbody.MuiTableBody-root .billing_invoice_section{
    background: transparent;
  }
  .billing_history_table .billing_invoice_section .MuiTableCell-body{
    font: 500 14px/19px "Roboto";
    color: #606060;
    text-transform: uppercase;
    padding: 8px 0 15px;
  }
  .billing_history_table .billing_invoice_section.billing_history_section .MuiTableCell-body{
    padding: 13px 0 15px;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_date{
    font: 500 14px/19px "Roboto";
    color: #303960;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_number{
    font: 500 14px/19px "Roboto";
    color: #5B5B5B;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_plan,
  .billing_history_table .MuiTableCell-body div.billing_history_amount{
    font: normal 14px/19px "Roboto";
    color: #6A6A6A;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status p.billing_history_paid{
    font: normal 14px/19px "Roboto";
    color: #363636;
    width: 58px;
    height: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(101, 236, 114, 31%);
    border-radius: 11px;
    margin-bottom: 0;
  }
  .billing_history_table .MuiTableCell-body div.billing_history_status p.billing_history_unpaid{
    font: normal 14px/19px "Roboto";
    color: #363636;
    width: 75px;
    height: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #FEDE93;
    border-radius: 11px;
    margin-bottom: 0;
  }
  .billing_history_action img{
    margin-left: 7px;
  }

  .billing_detail .bill_details_container {
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
}
.billing_detail .bill_details_container div {
  display: flex;
}
.billing_detail .bill_details_container .bill_period {
  font: bold 14px/19px "Roboto";
}
.switch_annual_paymt {
  color: #4759A7;
  font: 500 14px/19px "Roboto";
  cursor: pointer;
  border: 0;
  background: transparent;
}
.switch_annual_paymt:disabled{
  cursor: not-allowed;
}
  .billing_detail .bill_details_card_container{
    margin-bottom: 23px;
    position: relative;
  }
  .billing_detail .bill_edit_btn {
    position: absolute;
    right: 10px;
    text-transform: capitalize;
    bottom: 10px;
    background-color: #E3E3E3;
    border-radius: 5px;
    color: #424242;
    font: 500 14px/19px "Roboto";
}
.bill_edit_btn img {
  margin-right: 6.5px;
}
  .billing_detail .contact_detail_info {
  padding: 11px 15px;
  margin-bottom: 15px;
  background-color: #F1F4FF;
  border-radius: 5px;
  font: normal 13px/18px "Roboto";
  color: #424242;
}
.billing_detail .contact_detail_info img{
  margin-right: 9.5px;
}
.contact_detail_main_header {
  font: 500 16px/21px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.contact_detail_main_values {
  border: 1px solid #F3F3F3;
  border-radius: 10px;
  padding: 15px 13px 10px 10px;
  position: relative;
}
.contact_detail_main_key {
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.contact_detail_main_key_info {
  font: normal 13px/18px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 13px;
}
.contact_detail_main {
  margin-bottom: 20px;
}
.contact_detail_main_footer p {
  font: 500 14px/19px "Roboto";
  margin: 0px;
  color: #424242;
  padding: 11px 27px;
  background-color: #F6F6F6;
  border-radius: 5px;
  margin-right: 40px;
  cursor: pointer;
}
.contact_detail_main_footer span{
  align-self: center;
  font: 500 14px/19px "Roboto";
  margin: 0px;
  color: #4759A7;
  cursor: pointer;
}
.billing_detail .billing_overview_sections .MuiGrid-root.MuiGrid-item > * {
  min-height: unset;
}
.billing_estimate{
  padding: 23px 20px 20px 20px;
}
.contact_detail_main_footer{
  display: flex;
}
.billing_estimate .overview_section_heading {
  margin-bottom: 3px !important;
}
.billing_estimate_date {
  color: #646670;
  font: normal 12px/16px "Roboto";
  margin-bottom: 20px;
  display: block;
}
.billing_estimate_price_secion {
  padding: 20px 15px;
  background-color: #FBFBFB;
  border-radius: 10px;
  margin-bottom: 20px;
}
.billing_estimate_price_plans {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}
.billing_estimate_price_plan {
  margin: 0px;
  font: 500 14px/19px "Roboto";
  color: #424242;
}
.billing_estimate_price_plan_amount{
  margin: 0px;
  font: bold 14px/19px "Roboto";
  color: #424242;
}
.billing_estimate_price_plan_duration {
  color: #646670;
  font: normal 12px/16px "Roboto";
  display: block;
  margin-bottom: 20px;
}
.billing_estimate_price_plans_main {
  margin-bottom: 18.5px;
  border-bottom: 1px solid #dfdfdf;
}
.billing_estimate_price_tax {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18.5px;
}
.billing_estimate_price_tax.billing_estimate_amount{
  margin-bottom: 41px;
}
.billing_estimate_price_tax p {
  margin: 0px;
  font: 500 12px/16px "Roboto";
  color: #959595;
}
.billing_estimate_price_total {
  display: flex;
  justify-content: space-between;
  
}
.billing_estimate_price_total p {
  margin: 0px;
  font: 500 16px/21px "Roboto";
  color: #959595;
}
.billing_estimate_price_total .billing_estimate_price_total_amount {
  font: 600 16px/21px "Roboto";
  color: #424242;
}
.billing_estimate .view_full_estimate_btn{
  border: 1px solid transparent;
  width: 100%;
  padding: 10px;
  font: 500 14px/19px "Roboto";
}
/* Billing Estimate */
.billing_estimate_section{
  background: #FFFFFF;
  box-shadow: 0px 23px 36px #00000008;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}
.billing_estimate_main{
  padding: 33px 25px 25px;
}
.billing_estimate_contact{
  padding: 23px 20px 25px;
}
.billing_estimate_time{
font: normal 16px/21px "Roboto";
color: #424242;
margin-bottom:20px;
}
.billing_estimate_subscription_container{
  padding: 11px 15px;
  background: #F1F4FF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.billing_estimate_subscription_container img{
  margin-right: 10px;
}
.billing_estimate_subscription_container p{
  font: normal 12px/16px "Roboto";
  color: #424242;
  margin-bottom:0;
}
.billing_estimate_subscription_container p span{
  color: #3C57CB;
}
.billing_estimate_plan_container{
  background: #F5F5F5;
  border-radius: 10px;
  padding: 25px 20px 25px 25px;
  display: flex;
  margin-bottom: 10px;
}
.billing_estimate_plan_container img{
  margin-right: 15px;
}
.billing_estimate_plan_name{
  font: 700 18px/24px "Roboto";
  color: #424242;
  margin-bottom:5px;
}
.billing_estimate_plan_period{
  font: normal 12px/16px "Roboto";
  color: #424242;
  margin-bottom: 0;
}
.billing_estimate_plan_price{
  font: 700 16px/21px "Roboto";
  color: #171717;
  margin-bottom: 0;
  margin-left: auto;
}
.billing_estimate_tax_container{
  background: #FCFCFC;
  border-radius: 10px;
  padding: 10px 20px 10px 25px;
  margin-bottom: 15px;
  display: flex;
}
.billing_estimate_tax_container h6{
  font: 500 16px/21px "Roboto";
  color: #424242;
  margin-bottom: 0;
}
.billing_estimate_total{
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  padding: 24px 20px 25px 25px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.billing_estimate_total h5{
  font: 700 21px/28px "Roboto";
  color: #303960;
  margin-bottom: 0;
}
.billing_estimate_message{
  font: 500 12px/16px "Roboto";
  color: #646670;
  margin-bottom: 0;
}
.billing_estimate_contact h5{
  font: 500 18px/24px "Roboto";
  color: #424242;
  margin-bottom: 20px;
}
.billing_estimate_contact p{
  font: normal 16px/21px "Roboto";
  color: #3C57CB;
  margin-bottom: 14px;
}
.billing_estimate_contact p:last-child{
  margin-bottom: 0;
}
.billing_detail .billing_send_invoice_label{
  font: 500 16px/21px "Roboto" !important;
  color: #333758 !important;
}
.update_billing_contact .MuiPaper-root{
  max-width:600px;
  width:100%;
}
/* manage subcription */
.manage_subscriptions_section{
  padding: 23px 25px 20px;
  background: #FFFFFF;
  box-shadow: 0px 23px 36px #00000008;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}
.active_subscription_heading{
  font: 500 18px/24px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.subscription_plan_container{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 17px 20px 20px;
}
.subscription_plan_heading_section{
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.subscription_plan_heading_section img{
  width:34px;
  margin-right: 8px;
}
.subscription_plan_heading_section h5{
  font: 700 16px/21px "Roboto";
  letter-spacing: 0px;
  color: #313131;
  margin-bottom: 0;
}
.subscription_plan_detail_section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  border-bottom: 1px solid #E8E8E8;
}
.subscription_plan_detail_section h5{
  font: 500  12px/16px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.subscription_plan_detail_section h6{
  font: normal 12px/16px "Roboto";
  letter-spacing: 0px;
  color: #787878;
}
.subscription_plan_cancel{
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #646670;
  margin-bottom: 0;
  text-align: end;
  cursor: pointer;
}
.subscription_plan_cancel:hover{
  color:#EE0E0E;
}
.subscription_plan_actions{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.subscription_plan_change{
  display: flex;
  margin-top: 14px;
}
.subscription_plan_change h5{
  font: 700 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #303960;
  margin-bottom: 0;
  padding-right: 10px;
  border-right: 1px solid #B8B8B8;
}
.subscription_plan_change p{
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #21BF73;
  margin-bottom: 0;
  padding: 0 10px;
  cursor: pointer;
}
.subscription_plan_actions .manage-btn{
  padding:8px 29px;
  font: 500 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #424242;
  border-radius: 5px;
  background: #F6F6F6;
  margin-top: 14px;
}
.billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div{
  margin-top: 5px;
}
.billing_page.pending_status .reports_down_tab {
padding: 0;
}
.billing_page .Acc-Table.export_btn{
  background: transparent;
}
.billing_page .export_btn button{
  min-width:130px;
}
.billing_page.pending_status, .billing_page.subscriptions_page{
  padding: 28px 10px 16px
}
.card_added_success{
  margin: 6px 0 10px;
  border: 1px dashed #21bf73;
  background-color: #f2fcf8;
  border-radius: 5px;
  padding:12px;
  width: 100%;
  color: #21bf73;
  text-align: center;
}
.card_added_success img{
  margin-bottom:2px;
}
.comp_container{
  display: flex;
  align-items: center;
  margin: 0 6px 17.5px;
}
.comp_container > img{
  width: 44px;
  height: 44px;
  object-fit: cover;
  object-position: center;
  border-radius: 25px;
  border: 1px solid #E5E5E5;
}
.comp_container .team_level{
  width: 44px;
  height: 44px;
  object-fit: contain;
  object-position: center;
  border-radius: 25px;
  border: 1px solid #E5E5E5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.comp_container .team_level img{
  width: 21.5px;
  height: 21.5px;
}
.comp_container .comp_title{
  margin-left: 15px;
}
.comp_container .comp_title h5{
  font: bold 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #2D2D2D;
  margin-bottom: 3px;
  text-align: left !important;
}
.comp_container .comp_title p{
  font: normal 14px/19px "Roboto";
  letter-spacing: 0px;
  color: #5D5D5D;
  margin-bottom: 0;
  text-align: left !important;
}
.billing_overview_container .billing_history_table{
  max-width: none;
}
.subscriptions_custom_filter input:-webkit-autofill,
.subscriptions_custom_filter input:-webkit-autofill:hover, 
.subscriptions_custom_filter input:-webkit-autofill:focus, 
.subscriptions_custom_filter input:-webkit-autofill:active {
  transition: background-color 5000s;
}
.bill_contact_details{
  margin-bottom: 6px;
}
.bill_contact_heading{
  color: #424242;
  font: 500 14px/19px "Roboto";
  margin-bottom: 5px;
}
.bill_contact_details p{
  color: #424242;
  font: normal 13px/18px "Roboto";
  margin-bottom: 15px;
}
.bill_plan_section{
  padding: 9px 11px 11px;
  background-color: #FBFBFB;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.bill_plan_title{
  color: #5B5B5B;
  font: 500 14px/19px "Roboto";
  margin-bottom: 0;
  margin-right: 20px;
}
.bill_current_plan{
  color: #646670;
  font: bold 14px/19px "Roboto";
  margin-bottom: 0;
  margin-right: 20px;
}
.bill_plan_switch{
  color: #4759A7;
  font: 500 14px/19px "Roboto";
  margin-bottom: 0;
  margin-left:auto;
}
.billing_due_total{
  margin-top:-4px;
  margin-bottom: 15px;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 18px;
}
.billing_due_total h6{
  font: bold 18px/24px "Roboto";
  color:#303960;
  margin-bottom: 0;
}
.billing_overview_container .view_full_estimate_btn{
  padding: 14px 10px; 
}
.pagination_container_history ul{
  justify-content: flex-end;
  padding:12px 0;
}
.no-data-found{
  color: #424242;
  font: 500 14px/19px "Roboto";
  margin-bottom: 5px;
  background-color: transparent !important;
  margin-bottom:0 !important;
}
.card_added_failure{
  margin-bottom:5px;
  background-color: rgba(232, 71, 29, 0.18);
  border-radius: 4px;
  padding: 9px 12px;
  color: #E8471D;
  font: normal 16px/21px "Roboto";
  text-align: center;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogContent-root{
  padding-bottom:0;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogContentText-root{
  margin-bottom: 0;
}
.add_company_dialog.not_subscribe_dialog .MuiDialogActions-root{
  padding: 0 20px 20px 20px;
}
.subscribing_section{
  display: flex;
  justify-content: space-between;
  align-items:center;
  flex-wrap: wrap;
  margin-bottom: 13px;
}
.billing_main_container .subscribing_section{
  margin: 28px 70px 1px;
  
} 
.subscribing_section .add_company_action{
  margin-bottom: 5px;
  margin-top: 0;
}
.subscribing_section .add_company_action .ok_btn{
  font: 500 14px/19px "Roboto" !important;
}
.bill_multi_card_container .bill_card_left{
  display: flex;
}
.bill_multi_card_container .bill_details_card_container{
  display: flex;
  justify-content: space-between;
  padding: 16px 13px 14px 25px;
  margin-bottom: 10px;
}
.bill_multi_card_container .bill_details_card_container:last-child{
  margin-bottom: 0;
}

/* Invoice Page */
.billing_page.billing_invoice{
  max-width: 1058px;
}
.billing_heading_section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.billing_heading_section{
  margin-bottom: 19px;
}
.billing_heading_section .billing_page_title{
  margin-bottom: 0;
}
.billing_heading_section .logout-button{
  padding: 10px 17px;
  font: 500 14px/19px "Roboto";
}
.billing_heading_section .logout-button img{
  margin-right: 13px;
}
.billing_invoice_section{
  padding: 26px 29px 36px 35px;
}


.billing_invoice .invoice_heading {
  padding: 0 0 10px;
  border-bottom: 1px solid #F1F1F3;
  display: flex;
  justify-content: space-between;
}
.billing_invoice .invoice_heading_right {
  text-align: right;
}
.billing_invoice .company_name {
  font: normal 28px/39px "Roboto";
  color: #43425D;
  margin-bottom: 14px;
}
.billing_invoice .invoice_heading_left .mail_section {
  margin-bottom: 38px;
}
.billing_invoice .invoice_heading_right .mail_section {
  margin-bottom: 15px;
}
.billing_invoice .mail_section h6 {
  font: bold 14px/25px "Roboto";
  color: #515465;
  margin-bottom: 2px;
}
.billing_invoice .mail_section p {
  font: normal 14px/19px "Roboto";
  color: #4D4F5C;
  margin-bottom: 0;
}
.billing_invoice .bill_due {
  font: bold 30px/52px "Roboto";
  color: #515465;
  margin-bottom: 0;
}
.billing_invoice .invoice {
  font: normal 20px/28px "Roboto";
  color: #43425D;
  margin-bottom: 14px;
}
.billing_invoice .invoice_date {
  list-style: none;
  margin-bottom: 0;
  padding-left:0;
}
.billing_invoice .invoice_date li {
  font: normal 14px/19px "Roboto";
  color: #4D4F5C;
  margin-bottom: 4px;
}
.billing_invoice .invoice_date li:last-child{
  margin-bottom: 0;
}
.billing_invoice .invoice_date_content {
  display: inline-block;
  min-width: 118px;
}
.billing_invoice .invoice_date li .invoice_date_label {
  font: 500 14px/19px "Roboto";
}
.billing_invoice .invoice_detail {
  padding: 24px 0 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.billing_invoice .invoice_detail ul{
  margin-bottom: 0;
  padding-left: 0;
}
.billing_invoice .invoice_detail li {
  list-style: none;
  font: normal 14px/25px "Roboto";
  color: #515465;
  margin-bottom: 6px;
}
.billing_invoice .invoice_detail li .invoice_detail_label {
  font: 500 14px/25px "Roboto";
  display: inline-block;
}
.billing_invoice .invoice_detail_first li .invoice_detail_label {
  min-width: 77px;
}
.billing_invoice .invoice_detail_second li .invoice_detail_label {
  min-width: 50px
}
.billing_invoice .invoice_detail_third li .invoice_detail_label {
  min-width: 69px
}
.billing_invoice .invoice_table {
  width: 100%;
}
.billing_invoice .invoice_table {
  border-spacing: 0;
  padding-bottom: 2px;
  border-bottom: 1px solid #F1F1F3;
  margin-bottom: 17px;
}
.billing_invoice .invoice_table th {
  background-color: #F5F6FA;
  padding: 8px 9px;
  border-spacing: 0;
  font: bold 14px/19px "Roboto";
  color: #A3A6B4;
  margin-bottom:0;
  border-bottom: none;
}
.billing_invoice .invoice_table td {
  font: normal 14px/19px "Roboto";
  padding: 12px 9px;
  color: #4D4F5C;
  margin-bottom: 0;
  border-bottom: none;
  white-space: nowrap;
}
.billing_invoice .invoice_total {
  text-align: end;
}
.billing_invoice .invoice_total h5 {
  font: normal 14px/19px "Roboto";
  color: #43425D;
  margin-bottom: 10px;
}
.billing_invoice .invoice_total h5 .invoice_total_label {
  font: bold 14px/19px "Roboto";
  min-width: 147px;
  display: inline-block;
  margin-right: 13px;
}
.billing_invoice .invoice_total .total_amount {
  font: bold 20px/28px "Roboto";
  color: #43425D;
  margin-bottom: 0;
}
.billing_overview_section .billing_estimate{
  padding:0;
  margin-top: 15px;
}
.billing_overview_section .billing_estimate .view_full_estimate_btn{
  max-width: 300px;
}

/* cost break up */
.credit_card_dialog.cost_breakup_dialog .MuiPaper-root{
  max-width: 427px;
}
.add_company_dialog.cost_breakup_dialog .MuiDialogContent-root{
  padding: 12px 16px 17px;
}
.cost_breakup_table th{
  font: 500 14px/19px  "Roboto";
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  padding: 0 2px 13px;
  border: 0;
}
.cost_breakup_table td{
  border:0;
  padding:0;
  padding-bottom:11px;
}
.cost_breakup_table td div{
  background: #FBFBFB;
  font: 500 14px/19px  "Roboto";
  color: #424242;
  letter-spacing: 0px;
  border: 0;
  text-align: center;
  padding: 10px 2px 10px;
  position: relative;
}
.cost_breakup_table td div:after{
  content: "";
  position: absolute;
  width:1px;
  height: 18px;
  background-color: #DFDBDB;
  right:0;
  top:50%;
  transform: translate(0, -50%);
}
.cost_breakup_table td:last-child div:after{
  content: none;
}
.cost_breakup_table tr td:first-child div{
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.cost_breakup_table tr td:last-child div{
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.cost_breakup_total{
  text-align: end;
  margin-top: 3px;
}
.cost_breakup_total h5 {
  font: normal 14px/19px "Roboto";
  color: #424242;
  margin-bottom: 7px;
}
.cost_breakup_total h5 .cost_breakup_total_label{
  font: 500 14px/17px "Roboto";
  display: inline-block;
  margin-right: 15px;
}
.cost_breakup_total h5 .cost_breakup_total_amount, .cost_breakup_total .total_amount .cost_breakup_total_amount{
  display: inline-block;
  min-width: 62px;
}
.cost_breakup_total .total_amount {
  font: 500 16px/17px "Roboto";
  color: #43425D;
  margin-bottom: 0;
}
.cost_breakup_total h3.total_amount .cost_breakup_total_label{
  font: bold 16px/17px "Roboto";
  margin-right: 15px;
}
/* cost break up */

/* multi-card */
/* The container */
/* The container */
.bill_card_select_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.bill_card_select_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.bill_card_select_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.bill_card_select_container input:checked ~ .checkmark {
  background-color:#4759A7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.bill_card_select_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.credit_card_dialog.multi_card_dialog .MuiPaper-root{
 max-width: 623px;
}
.bill_multi_card_container{
  padding: 23px 15px 0;
}
.bill_multi_card_container .bill_details_section{
  margin-left: 5px;
}
.bill_card_right{
  text-align: right;
}
.bill_card_select_container .checkmark:after {
  top: 5.4px;
  left: 5.4px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.expiry_details_field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -5px;
}
.expiry_details_field > div{
  margin: 0 5px;
  width:100%;
}
.bill_card_buttons{
  margin: 0 -10px;
}
.bill_card_buttons button{
  padding:0; 
  margin: 0 10px;
  font: normal 14px/19px "Roboto";
  color: #828282;
  text-transform: capitalize;
  min-width: 0;
}
.bill_card_buttons button:last-child{
  color: #4759A7;
}
.bill_card_buttons button:hover{
  background-color: transparent;
  text-decoration: underline;
}
.atm_card_image{
  margin-bottom: 8px;
}
/* multi-card */
@media (max-width:1121px){
  .billing_page.pending_status .filter-search{
    flex-wrap: wrap;
  }
  .billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div{
    margin-bottom:10px;
    margin-right: 10px;
  }
  .billing_page.pending_status .filter-search .new_custom_filters .new_custom_filter_div:last-child{
    margin-top: 0;
    width:auto;
  }
 
}
@media (min-width:769px) and (max-width:1121px){
  .billing_page.billing_invoice{
    padding: 28px 30px 16px;
  }
}
  @media (max-width: 992px){
    .billing_configure{
      padding-left:40px;
    }
    .billing_history_table .MuiTableCell-body {
      white-space: nowrap;
      max-width: none;
    }
    .subscription_plan_actions{
      flex-wrap: wrap;
    }
  }
  @media (min-width:769px) and (max-width: 992px){
    .billing_main_container .billing_page{
      padding: 16px 30px;
    }
    .billing_main_container .subscribing_section{
      margin: 20px 30px 1px;
    }
    .billing_invoice .invoice_heading{
      flex-wrap: wrap;
    }
    .billing_invoice .invoice_heading_right{
      text-align: left;
      width:100%;
    }
  }
  @media (max-width: 768px){
    .billing_container{
      flex-wrap: wrap;
    }
    .billing_sidebar{
      width: 100%;
      min-height: auto;
    }
    .billing_main_container{
      width:100%;
    }
    .billing_main_container .billing_page{
      width:100%;
      padding:16px 15px;
    }
    .billing_page{
      width:100%;
      padding: 20px 15px;
    }
    .billing_main_container .subscribing_section{
      margin: 20px 15px 1px;
    }
    .billing_page.pending_status, .billing_page.subscriptions_page{
      padding:20px 0;
    }
    .billing_sidebar > *{
      text-align: center;
    }
  }
  @media (max-width: 699px){
    .billing_invoice .invoice_heading{
      flex-wrap: wrap;
    }
    .billing_invoice .invoice_heading_right{
      text-align: left;
      width:100%;
    }
    .expiry_details_field{
      flex-wrap: wrap;
    }
  }
  @media (max-width: 480px){
    .billing_configure{
      padding: 20px 5px;
    }
    .billing_configure_form {
      padding: 25px 15px 31px;
    }
    .billing_container .add_company_action .cancel_btn {
      min-width: 120px;
      margin-right: 10px;
    }
    .billing_detail .bill_details_container{
      display: block;
    }
    .switch_annual_paymt{
      margin-top: 10px;
    }
    .bill_plan_section{
      flex-wrap: wrap;
    }
    .bill_plan_section > *{
      margin-top: 5px;
    } 
    .bill_plan_switch{
      margin-left: 0;
    }
    .billing_invoice_section{
      padding: 15px 15px 25px;
    }
    .billing_invoice  ul{
      width:100%;
    }
    .billing_invoice  li{
      display: flex;
    }
    .billing_invoice  li span{
      display: inline-block;
    }
    .billing_invoice  li .invoice_detail_label{
      min-width: 70px !important;
    }
    .manage_subscriptions_section.credit_card_add_section {
        padding: 23px 10px 20px;
    }
    .add_company_dialog.multi_card_dialog .MuiDialogContent-root{
      padding-right:0;
      padding-left:0;
    }
    .bill_multi_card_container .bill_details_card_container{
      flex-wrap: wrap;
    }
    .bill_details_card_container .bill_card_left{
      width:100%;
    }
    .bill_details_card_container .bill_card_right{
      text-align: left;
      margin-left: 40px;
      margin-top: 5px;
    }
  }
  @media (min-width:769px) and (max-width:840px){
    .billing_detail .bill_details_container{
      display: block;
    }
    .switch_annual_paymt{
      margin-top: 10px;
    }

  }