.map-container{
    height: calc(100vh - 75px);
}
.map_detail_dialog{
    max-width: 395px;
    top: 75px !important;
}
.map_detail_dialog .MuiDialog-paperWidthXs{
    max-width: 370px;
    width: 100%;
    max-height: calc(100% - 20px);
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 8px 23px #00000052;
}
.map_detail_dialog .MuiDialogContent-root{
    padding: 20px 0px;
}
.map-hosp-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
}
.map-hosp-empty{
    padding:0 20px;
    align-items: center;
}
.map-hosp-name {
    color: #AAAAAA;
    font: normal 14px/19px "Roboto";
    margin-right: 10px;
    display: inline-block;
}
.map-hosp-condition {
    color: #EE0E0E;
    font: normal 12px/16px "Roboto";
    padding: 2px 14px 1px 14px;
    background-color: #FF00001A;
    border-radius: 5px;
    display: inline-block;
}
.map_dialog_close{
    height: 11px;
    width: 11px;
    cursor: pointer;
}
.map_dialog_header h2{
    font: bold 18px/24px "Roboto";
    color: #424242;
    margin-bottom: 13px;
}
.map-status-time {
    display: flex;
    justify-content: space-between;
}
.map-status {
    padding: 3px 0px;
    font: normal 14px/19px "Roboto";
    border-radius: 5px;
    background-color: #E81C2312;
    color: #CC1837;
    width: 93px;
    text-align: center;
    margin: 0px;
    margin-top:10px;
}
.map_event_title {
  white-space: nowrap; 
  width: 300px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

.date_time_div span{
    display: block;
    text-align: right;
}
.date_time_div_top span{
    display: inline;
}
   .map_time_unassigned{
    color:#AAAAAA !important;
    }
    .map_time_pending{
    color:#617FFF !important;
    }
    .map_time_assigned{
    color:#303960 !important;
    }
    .map_time_inprogress{
    color:#33BE9D !important;
    }
    .map_time_completed{
    color:#21BF73 !important;
    }
    .map_time_uncovered{
    color:#D24856 !important;
    }
    .map_time_cancelled{
    color:#888888 !important;
    }

/* .date_time_div span:first-child{
    color: "#AAAAAA";
} */
.map-status span{
    margin-left: 13px;
    position: relative;
}
.map-status span:before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #CC1837;
    position: absolute;
    border-radius: 50%;
    top: 5px;
    left: -13px;
}
.map-time{
    font: normal 18px/24px "Roboto";
    color: #21BF73;
}
.map_dialog_header {
    border-bottom: 0.5px solid #F6F6F6;
    padding-bottom: 13.5px;
    padding: 0px 20px 13.5px 20px;
    margin-bottom: 20.5px;
}
.map-get-dir-btn {
    padding: 0px 20px 20px;
}
.map-other-task{
    font: 500 16px/21px "Roboto";
    color: #424242;
    padding: 10px 20px 9px 20px;
    background-color: #FBFBFB;
    margin-bottom: 10px;
}
.map-other-task-detail {
    padding: 0px 20px 14px;
    border-bottom: 0.5px solid #F6F6F6;
    margin-bottom: 14px;
}
.map-other-task-detail-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.map-other-task-detail-flex p{
    max-width: 252px;
    font: normal 16px/21px "Roboto";
    margin: 0px;
    color: #424242;
}
.map-other-task-detail-flex span{
    align-self: flex-end;
    color: #4759A7;
    font: normal 14px/19px "Roboto";
}
.map-loc-cal-sub img{
    margin-right: 8px;
}
.map-loc-cal-sub span{
    color: #5B5B5B;
    font: normal 16px/21px "Roboto";
}
.map-loc-cal-sub {
    margin-bottom: 10px;
    display: flex;
}
.map_event_dialog_header{
    margin: 0px;
    border: none;
}
.map-attendees{
    padding: 0px 20px 20px;
}
.map-attendee{
    margin-bottom: 10px;
}
.map_event_dialog_header .map-hosp-name {
    font: normal 16px/21px "Roboto";
    color: #424242;
}
.map-attendee img{
    box-shadow: 0px 3px 12px #0000000D;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    object-fit: cover;
}
.map-attendee span{
    color: #424242;
    font: normal 16px/21px "Roboto";
}
.map_sales_rep_dialog_profile{
    padding: 0px 20px 20.5px 20px;
    margin-bottom: 20.5px;
    border-bottom: 1px solid #E2E5F2;
}
.map_sales_rep_dialog_profile-one .map_sales_rep_dialog_profile_img{
    box-shadow: 0px 5px 28px #00000014;
    border-radius: 33px;
    width: 66px;
    height: 66px;
    margin-right: 25px;
}
.map_sales_rep_dialog_profile_name p{
    color: #424242;
    font: normal 16px/21px "Roboto";
    margin-bottom: 2px;
}
.map_sales_rep_dialog_profile_name h3{
    color: #424242;
    font: 500 18px/24px "Roboto";
    margin: 0px;
}
.map_sales_rep_dialog_profile-one {
    display: flex;
    justify-content: space-between;
}
.map_sales_rep_dialog_profile-one_div{
    display: flex;
}
.infoBox img {
    display: none;
}
.infoBox{
    min-width:125px !important;
}
.infoBox h2{
    font: normal 12px/16px "Roboto";
    background-color: #303960;
    margin: 0px;
    padding: 9px 9px 7px 9px;
    color: #FFFFFF;
    border-radius: 5px;
}
.map_sales_rep_dialog_profile_name {
    align-self: center;
}
.map_sales_rep_dialog_profile_info{
    margin-bottom: 20px;
}
.map_sales_rep_dialog_profile_info img{
    margin-right: 8px;
}
.map_sales_rep_dialog_profile_info span{
    color: #5B5B5B;
    font: normal 16px/21px "Roboto";
}
.map_sales_rep_other_task{
    display: inline-block;
    margin-bottom: 0px;
}
.map_sales_rep_other_task_main{
    margin-bottom: 10px;
}
.MuiDialogContent-root .map-other-task-detail:last-child {
    border: none;
}
.map_sales_rep_dialog_profile-two .map_sales_rep_dialog_profile_info:last-child {
    margin-bottom: 0px;
}
.custom_map_filters .ant-select-lg{
  margin:5px  
}
.custom_map_filters{
    padding:10px;
    display: flex;
}
.new_custom_filter_div.todays_tasks_grid_heading_select.map_filter {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 1px 2px #30396030;
    border-radius: 5px;
}
.map-container .gmnoprint, .map-container .gm-style-cc{
    display:none;
}
.map_event_dialog_header .dashboard_task_event_attendees{
    margin-bottom: 10px;
}
.map-attendee{
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 1130px){
    .map-container{
        height: calc(100vh - 67px);
    }
}
@media only screen and (min-width: 768px){
    .map_detail_dialog .MuiDialog-scrollPaper{
        justify-content: left;
    }
}

img[src^="https://salesc2userprofile.s3.amazonaws.com/"] {
   border-radius: 50% !important;
  }