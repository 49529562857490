.chart-conatiner{
    padding:12px;
}
.chart-conatiner .chart-section{
    margin-bottom: 11px;
}
.chart-conatiner .chart-section.table-section{
    margin-bottom:7px;
}
.chart-conatiner .new_custom_filter_div.filter-option label,
.chart-conatiner .new_custom_filter_div.filter-option span{
    font: normal 14px/19px "Roboto";
    color: #5B5B5B;
}
.chart-conatiner .new_custom_filter_div.current-selected span{
    font-family:"Roboto";
}
.chart-section > div {
    display: flex;
}
.chart-conatiner .chart-section:last-child{
    margin-bottom: 0;
}
.chart-conatiner h3.summary-title{
    font: bold 18px/24px "Roboto";
    color: #424242;
    letter-spacing: 0px;
}
.access-section, .task-graph{
    border-radius:20px;
    background-color: #fff;
    padding: 16px 4px;
    width:100%;
    overflow: hidden;
}
.access-section > .chart-header, .task-graph > .chart-header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 0 12px;
}
.access-section h5.chart-title, .task-graph h5.chart-title{
    text-transform: uppercase;
    font: bold 17px/23px "Roboto";
    margin-bottom:0;
    color: #424242;
    text-transform: none;
}
.access-section .chart-filter label, .task-graph .chart-filter label{
font: normal 14px/19px "Roboto" !important;
color: #424242;
}
.access-section select.select-box, .task-graph select.select-box{
    font: normal 14px/19px "Roboto";
    border:none;
    color: #424242;
}
.access-section img, .task-graph img{
    width:100%;
}

/* Search Result */
.access-section .result-detial {
    padding:15px 12px 0; 
}
.result-detial .result-header{
    display:flex;
    align-items: center;
    margin-bottom:14px;
}
.result-detial .result-header img{
    height:45px;
    width:45px;
    border-radius:5px;
}
.result-detial .result-header .acc-initial{
    height:45px;
    width:45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font: 500 21px/28px "Roboto";
    color: #5A5A5A;
    background-color: #EEEEEE;
    border-radius:5px;
}
.result-detial .result-header h3.acc-name{
    font: normal 14px/19px "Roboto";
    color:#424242;
    padding:0 17px;
    margin-bottom:0;
}
.result-detial .result-actions{
    display:flex;
    flex-wrap: wrap;
    margin-bottom:10px;
}
.result-detial .result-actions:last-child{
    margin-bottom: 0;
}
.access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
    background: #21BF730F   ;
    border: 1px solid #21BF73;
    font: 500 14px/19px "Roboto";
    color: #21BF73;
    text-transform: inherit;
    padding: 8px 30px 8px;
    box-shadow: none;
    border-radius:5px;
    margin-right:16px;
    margin-top:10px;
    cursor: pointer;
    outline:none;
}
.result-detial .result-contact{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom:10px;
}
.result-contact > div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.result-contact .contact-label{
    font: normal 14px/19px "Roboto";
    color:#AAAAAA;
}
.result-contact .contact-detial{
    font: normal 14px/19px "Roboto";
    color:#303960;
    padding: 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.result-contact > div:last-child{
    width:100%
}
.access-section .chart-search{
    display:flex;
    flex-direction: column;
    padding: 0 12px;
}
.chart-search .search-box{
    display:flex;
    align-items: center;
    margin-bottom: 8px;
    border: 1px solid #EBEBEB !important;
    border-radius:5px;
}
.search-box .ant-select-selector, .search-box .ant-select-selector:focus{
    border:0 !important;
    box-shadow: none !important;
}
.search-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 100%;
    color:#141A3B;
}
.search-box .ant-select-single .ant-select-selector .ant-select-selection-search-input::placeholder{
    color:#A6A9C4;
}
.chart-search .search-field{
    margin:0;
    width:100%;
}
.chart-search .search-field > div{
    height:40px !important;
    margin:0;
}
.chart-search .search-field input{
    padding:10px;
    font: normal 14px/19px "Roboto";
    color:#AAAAAA; 
}
.chart-search .search-field input::placeholder{
    font: normal 14px/19px "Roboto";
    color:#AAAAAA; 
}
.chart-search .search-box img.search-icon{
    width:15px;
    height:auto;
    margin:0 10px;
}
.chart-search .search-label{
    font: normal 12px/16px "Roboto";
    color:#6b6969;
}
.chart-header .view-btn{
    font: normal 14px/20px "Roboto";
    color: #424242;
    width:98px;
    height:31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:0;
    border-radius: 38px;
    background: #efefef;
}
.chart-header .view-btn:focus{
    outline:none !important;
}
.pending-reg-table .table-header{
    font: 500 14px/19px "Roboto";
    color: #5A63A2;
    border-bottom:0;
    padding:7px 12px 23px;
    white-space: nowrap;
} 
.pending-reg-table .table-values{
    font: normal 14px/19px "Roboto";
    color: #303960;
    border-bottom:0;
    padding:0 12px 28px;
    white-space: nowrap;
} 
.pending-reg-table .table-values a{
    font: normal 14px/19px "Roboto";
    border-bottom:0;
    white-space: nowrap;
}

/* charts */
.task-graph.active-accounts .highcharts-point.highcharts-color-0, 
.task-graph.active-accounts .highcharts-legend-item.highcharts-color-0:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#E2E2E2!important;
}
.task-graph.active-accounts .highcharts-point.highcharts-color-1, 
.task-graph.active-accounts .highcharts-legend-item.highcharts-color-1:not(.highcharts-legend-item-hidden) .highcharts-point{
    fill:#3C57CB!important;
}
.task-graph.company-report .highcharts-label text{
    font: bold 14px/19px "Roboto" !important;
    fill: #000000!important;
} 
.task-graph.company-report .highcharts-legend-item text, .task-graph.active-accounts .highcharts-data-labels .highcharts-label text{
    font-family: "Roboto" !important;
}
.task-graph.active-accounts .highcharts-legend-item text{
    font:  500 16px/21px "Roboto" !important;
    fill: #424242!important;
}
.task-graph.tasks-created .highcharts-axis-labels text, .task-graph.quick-access  .highcharts-axis-labels text{
    font: normal 11px/12px "Roboto" !important;
    fill: #666666 !important;
} 
.task-graph.tasks-created .highcharts-legend-item text, .task-graph.quick-access .highcharts-legend-item text{
    font:bold 12px/15px "Roboto" !important;
    fill: #333333 !important;
}
.task-graph.tasks-created .highcharts-axis text, .task-graph.quick-access .highcharts-axis text{ 
    font: normal 20px/24px "Roboto" !important;
    letter-spacing: 0px;
    fill: #666666 !important;
}
text.highcharts-credits{
    display:none;
}
.chart-conatiner .dashboard_task_filters{
    margin: 0 0 9px;
}
.event_acceptance_pagination {
    width:100%;
}
.event_acceptance_pagination .MuiPagination-ul{
    justify-content: flex-end;
}
@media (min-width:1199px){
    .result-detial .result-actions{
        flex-wrap: nowrap;
    }
}
@media (min-width: 960px){
    .pending-reg-table .table-values{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width:0;
    }
    .access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 165px;
    } 
}
@media (max-width:480px){
    .access-section .result-detial .result-actions .default-btn, .result-detial .result-actions .default-btn:focus{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 165px;
    } 
}