.adduserform {
  position: relative;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 0 4px !important;
}
.defaultSelect {
  border: 1px solid #e2e5f2;
  border-radius: 5px;
  height: 38px !important;
  font: normal 16px/21px "Roboto" !important;
  color: #424242;
  padding: 9px 14px;
}

.MuiCircularProgress-colorPrimary {
  color: #3f51b5;
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 99;
}

.add_company_dialog .add_user_header {
  background: #fcfcfe;
  padding: 16px 20px 16px 20px;
  margin-bottom: 5px;
}

.add_user_select_team label {
  margin-bottom: 0px;
  margin-right: 0px;
}
.add_user_select_team {
  display: flex;
  align-items: center;
  margin-top: 12px;
}