.taskfilter {
  display: flex;
  /* margin-bottom: 25px; */
  padding: 0 10px;
  flex-wrap: wrap;
}
.filterSearch {
  float: right;
  display: flex;
  justify-content: space-between;
  /* background: white; */
  padding: 17px 20px 17px 20px;
}
.error_massage {
  color: red;
  font-size: 14px;
}

.task_custom_filter_label {
  font: normal 16px/21px "Roboto" !important;
  color: #b2b2b2 !important;
  margin-right: 13px !important;
  align-self: center !important;
  margin-bottom: 25px;
}
.taskfilter .MuiFormControl-root,
.taskfilter .MuiAutocomplete-root {
  height: 40px !important;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 25px;
  background-color: #fff !important;
}
.taskfilter .ant-picker-range {
  width: 240px;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-right: 15px;
  font: normal 16px/21px "Roboto" !important;
  color: #505050 !important;
  padding: 11px 13px 9px 13px;
  height: 40px;
}
.taskfilter .ant-picker:hover,
.ant-picker-focused {
  box-shadow: none !important;
  border-color: #14193a !important;
}
.taskfilter .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 0 !important;
}
.task-detail-header {
  margin-bottom: 22px;
}
.task-detail-stepper {
  display: flex;
  background: #fff;
  box-shadow: 0px 5px 35px #0000000a;
  border-radius: 5px;
  padding: 24px 0px 24px 20px;
}
.task-detail-stepper-item {
  position: relative;
  width: 25%;
  text-align: right;
}
.task-detail-stepper-pending:before {
  background-color: #617fff;
}
.task-detail-stepper-assigned:before {
  background-color: #303960;
}
.task-detail-stepper-inprogress:before {
  background-color: #69f3b1;
}
.task-detail-stepper-completed:before {
  background-color: #21bf73;
}
.task-detail-stepper-uncovered:before {
  background-color: #d24856;
}
.task-detail-stepper-gray:before {
  background-color: #888888;
}
.task-detail-stepper-diabled:before {
  background-color: #dbdbdb;
}
.task-detail-stepper-item:before {
  content: "";
  position: absolute;
  /* background-color: red; */
  left: 0;
  height: 4px;
  top: calc(50% - 2px);
  width: calc(100% - 66px);
  border-radius: 21px;
}
.task-detail-stepper-item img {
  margin-right: 10px;
}
.task-detail-stepper-item span {
  position: absolute;
  left: 0;
  bottom: 0;
  font: 500 16px/21px "Roboto" !important;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.task-detail-timeline .task-detail-timeline-header {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  border-bottom: 1px solid #cecece;
  padding-bottom: 12px;
  margin: 0px;
}
.task-detail-timeline-card {
  box-shadow: 0px 3px 6px #e1e1e129;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 20px;
  /* border-left: 8px solid #21BF73; */
  margin-bottom: 20px;
  position: relative;
}
.dark_blue_color {
  border-left: 8px solid #303960;
}
.light_green_color {
  border-left: 8px solid #67f3b1;
}
.dark_green_color {
  border-left: 8px solid #21bf73;
}
.gray_color {
  border-left: 8px solid #888888;
}
.light_blue_color {
  border-left: 8px solid #617fff;
}
.red_color {
  border-left: 8px solid #d24856;
}
.medium_blue_color {
  border-left: 8px solid #303960;
}
.medium_blue_color {
  border-left: 8px solid #303960;
}

.dark_blue_color:before {
  border: 2px solid #303960;
}
.light_green_color:before {
  border: 2px solid #67f3b1;
}
.dark_green_color:before {
  border: 2px solid #21bf73;
}
.gray_color:before {
  border: 2px solid #888888;
}
.light_blue_color:before {
  border: 2px solid #617fff;
}
.red_color:before {
  border: 2px solid #d24856;
}
.medium_blue_color:before {
  border: 2px solid #303960;
}
.medium_blue_color:before {
  border: 2px solid #303960;
}
.task-detail-timeline-one {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.task-detail-timeline-one-name {
  color: #424242;
  font: 500 16px/21px "Roboto" !important;
}
.task-detail-timeline-dtime {
  align-self: center;
  color: #aaaaaa;
  font: 500 14px/19px "Roboto" !important;
}
.task-detail-timeline-two .task-detail-timeline-two-status {
  color: #21bf73;
  font: 500 18px/24px "Roboto" !important;
  margin-bottom: 5px;
}
.task-detail-timeline-three-text {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  word-wrap: break-word !important;
}
.task-detail-timeline-main {
  padding: 20px 0px 0px 36px;
  margin-left: 36px;
  border-left: 1px solid #303960;
}
.task-detail-timeline-card:before {
  content: "";
  background-color: #dfe5ff;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* border: 2px solid #21BF73; */
  top: calc(50% - 12.5px);
  left: -56.5px;
}
.tab-card-container .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none !important;
}
.tab-card-container .ant-tabs-tab {
  margin: 0 !important;
  border: none !important;
  border-radius: 5px 5px 0px 0px !important;
  height: 38px;
  width: 160px;
  text-align: center;
  background-color: transparent !important;
  font: 500 16px/21px "Roboto" !important;
  box-shadow: none;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  color: #646670 !important;
  width: 100%;
  font-weight: 600;
}
.tab-card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #fff !important;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

.tab-card-container .ant-tabs > .ant-tabs-nav {
  margin: 0px !important;
}
.tab-card-container .ant-tabs {
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 30px;
}
.tab-card-container .ant-tabs-content-holder {
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 0px 5px 5px 5px;
}
.tab-card-container .ant-tabs-content-holder .tab-container-title {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  padding: 0px 10px 10px 12px;
  border-bottom: 1px solid #f2f4fb;
  margin-bottom: 12px;
}

.tab-container-expense {
  display: flex;
  padding: 0px 30px 11.5px 42px;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 15.5px;
}

.tab-container-expense-type {
  margin: 0;
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
  position: relative;
}

.tab-container-expense-doller {
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
  margin-right: 27px;
}
.tab-container-expense-remove {
  color: #6a6a6a;
  font: 500 16px/21px "Roboto" !important;
  cursor: pointer;
}
.tab-container-expense-user {
  display: flex;
  justify-content: space-between;
  padding: 0px 116px 14px 42px;
}
.tab-container-expense-user-name img {
  margin-right: 9px;
}
.tab-container-expense-user-name span {
  color: #707070;
  font: normal 18px/24px "Roboto" !important;
}
.tab-container-expense-user-name,
.tab-container-expense-dtime {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tab-container-expense-date {
  color: #707070;
  font: normal 18px/24px "Roboto" !important;
  margin-right: 20px;
}
.tab-container-expense-time {
  color: #2d2d2d;
  font: 500 18px/24px "Roboto" !important;
}
.tab-container-expense-add-btn {
  margin: 0px 30px 18px 42px;
}
.tab-container-expense-add-btn button {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 15px 0px 10px 0px;
}
.tab-container-expense-add-btn button span {
  color: #949494;
  font: normal 14px/19px "Roboto" !important;
}
.total_expense {
  color: #303960;
  font: normal 16px/21px "Roboto" !important;
  padding-right: 30px;
  text-align: right;
}
.tab-container-expense-type::before {
  content: "";
  position: absolute;
  left: -32px;
  background-color: #c9fbe3;
  height: 16px;
  width: 16px;
  border: 1px solid #21bf73;
  border-radius: 50%;
}
.tab-card-container-timeline {
  display: flex;
  padding: 0px 12px;
}
.tab-card-container-timeline button {
  height: 38px;
  margin-left: 20px;
  background-color: #21bf730f;
  margin-top: 2px;
  border: 1px solid #21bf73;
  width: 110px;
}
.tab-card-container-timeline button span {
  font: normal 14px/19px "Roboto" !important;
  color: #21bf73;
  text-transform: capitalize;
}
.tab_product_container {
  padding: 0px 10px;
}
.task-detail .add_dept_btn {
  margin: 0px !important;
  border: 1px dashed #d4d4d4 !important;
  background-color: #fcfcfc !important;
  height: 50px;
}
.task-detail .add_dept_btn .MuiButton-label {
  color: #303960 !important;
  font: normal 16px/21px "Roboto" !important;
}
.info-card-detail-contact {
  border: 1px solid #f3f3f3;
  margin-bottom: 10px;
}
.info-card-detail-left.info-card-detail-left-contact {
  max-width: none;
  margin-bottom: 15px;
}
.info-card-detail-left.info-card-detail-left-contact img {
  align-self: start;
  margin-top: 5px;
}
span.custom-span.info-card-detail-left-contact-name {
  font: normal 14px/19px "Roboto" !important;
  width: 100%;
  padding: 6px 15px;
  background-color: #f3f3f3;
}
.info-card-detail-left-contact-data {
  padding: 0px 15px 15px 15px;
}
.task-detail-header-btns {
  display: flex;
}
.task-detail-header-btns .assigned-to-btn {
  font: normal 16px/21px "Roboto" !important;
  color: #878787;
  height: 40px;
  padding: 10px 13px;
  box-shadow: 0px 2px 2px #0000001c;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  /* max-width: 230px; */
  /* min-width: 270px; */
  /* justify-content: space-between; */
}
.assigned-to-btn-edit
  .edit-input
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiAutocomplete-inputRoot {
  padding-right: 25px !important;
}
.assigned-to-btn-edit .edit-input button {
  margin-left: 0px !important;
}
.task-detail-header-btns .assigned-to-btn span {
  white-space: nowrap;
}
.task_detail_drop_btn {
  display: flex;
}
.assigned-to-btn-edit {
  padding: 10px 0 10px 13px !important;
  min-width: 240px;
  align-items: center;
}
.assigned-to-btn-edit .MuiAutocomplete-endAdornment {
  display: none;
}
.assigned-to-btn-edit.assigned-to-btn
  .edit-input
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiAutocomplete-inputRoot {
  padding-right: 13px !important;
  padding-left: 0;
}
.task-detail-header-btns .custom-span {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 600;
  color: #303960 !important;
}
.task-detail-header-btns .assigned-to-btn img {
  margin-left: 10px;
}
.task-detail-header-btns .assigned-to-btn .assigned-to-btn-rep img {
  margin-left: 5px;
  width: 20px !important;
  height: 20px !important;
}
.add_company_dialog .task-assignee-person .MuiFormControl-root {
  margin-top: 0;
}
.task-detail-header-btns .update_status_btn {
  height: 40px;
  margin-left: 10px;
  background-color: #21bf730f;
  border: 1px solid #21bf73;
  padding: 9px 30px 11px 30px;
  /* margin-right: 10px; */
}
.task-detail-header-btns .update_status_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #21bf73;
  white-space: nowrap;
  text-transform: capitalize;
}

.task-detail-header-btns .revert_status_btn {
  height: 40px;
  color: #303960;
  border: 1px solid #303960;
  margin-left: 10px;
  padding: 18px;
}
.task-detail-header-btns .revert_status_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #303960;
  white-space: nowrap;
  text-transform: capitalize;
}

.task-detail-header-btns .task_detail_cancel_btn {
  height: 40px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid #bebebe;
  padding: 10px 40px;
}
.task-detail-header-btns .task_detail_cancel_btn span {
  font: normal 16px/21px "Roboto" !important;
  color: #6a6a6a;

  text-transform: capitalize;
}
.task_detail_dialog
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.task-detail-header-btns .assigned-to-btn .edit-input {
  width: 100%;
}
.cal-info-card-detail-left .edit-input {
  width: auto !important;
}
.cal-info-card-detail-left {
  max-width: none !important;
}
.task_priority_conflict .MuiDialog-paperWidthSm,
.task_emergency .MuiDialog-paperWidthSm {
  border: 2px solid #ff6f5e;
}
.task_conflict .MuiDialog-paperWidthSm {
  border: 2px solid #f6ac4c;
}
.auto-assignee-profile-pic {
  height: 30px !important;
  width: 30px !important;
}
.auto-assignee-profile-name {
  color: #565656;
  font: normal 14px/19px "Roboto" !important;
  margin-left: 8px;
}
.auto-assignee-profile-name.task-detail-assignee-name {
  text-overflow: ellipsis;
  max-width: 90px;
}
.auto-assignee-profile-name.assingee-to-other {
  display: flex;
  align-items: center;
}
.auto-assignee-name-field {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ant-select-item-option {
  align-items: center;
}
.new_custom_filters {
  display: flex;
  flex-wrap: wrap;
}
.new_custom_filters .new_custom_filter_div {
  margin-right: 20px;
}
.filter_date_range {
  padding: 0px !important;
  border: none !important;
}
.filter_date_range .ant-picker-input > input {
  text-align: center;
  padding: 0;
}
.filter_date_range .ant-picker-range .ant-picker-active-bar {
  margin-left: 0px !important;
}
.new_custom_filters .new_custom_filter_div {
  margin-right: 10px;
}
.priority_container {
  padding: 16px 13px;
  background: #ffffff;
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 5px;
}
.add_company_dialog .priority_container label {
  margin: 0;
  font: 500 14px/19px "Roboto";
  color: #333758;
}
.add_company_dialog .priority_container label span.MuiFormControlLabel-label {
  margin-right: 9px;
}
.priority_container .labelEmergency span {
  color: #fb4d4d;
  padding-left: 6px;
}
.priority_icon {
  margin-left: 5px;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper {
  display: flex;
  align-items: flex-start;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper > span {
  display: block;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper .priority_icon {
  margin-left: 0;
  margin-right: 5px;
}
.auto-assignee-priority-icon {
  margin-left: 5px;
  width: 15px;
  height: auto;
  object-fit: contain;
}
img.auto-assignee-priority-icon.attendees_status_icon {
  width: 20px;
}
.ant-picker-panel-container {
  margin: 15px 0;
}
@media (min-width: 960px) and (max-width: 1122px) {
  .calender-page {
    padding: 0 10px;
  }
  .calender-page .fc .fc-toolbar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75px;
  }
  .new_custom_filters .new_custom_filter_div {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 961px) {
  .assigned-to-btn-edit {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 960px) {
  .task-detail-header {
    display: block !important;
  }
  .task-detail-header .tableTitle {
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .filter-search {
    display: block;
  }
  .new_custom_filters .new_custom_filter_div {
    margin-bottom: 10px;
  }
  .tab-container-expense-user {
    padding: 0px 30px 14px 30px;
  }
  .task_detail_drop_btn {
    justify-content: space-between;
  }
  .taskfilter {
    display: block;
  }
  .task_custom_filter_label {
    margin-bottom: 5px;
  }
  .taskfilter .MuiFormControl-root,
  .taskfilter .MuiAutocomplete-root {
    margin-bottom: 15px;
  }
  .task-detail-stepper-item span {
    text-align: center;
    width: 100%;
    bottom: -21px;
  }
  .task-detail-stepper {
    padding: 15px 0px 33px 10px;
  }
  .tab-card-container .ant-tabs-tab {
    width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .task-detail-header-btns {
    display: initial;
  }
  .task-detail-header-btns .assigned-to-btn {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .update_status_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .revert_status_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .task_detail_cancel_btn {
    margin: 0px;
    margin-bottom: 10px;
  }
  .task-detail-header-btns .update_status_btn,
  .task-detail-header-btns .revert_status_btn,
  .task-detail-header-btns .task_detail_cancel_btn {
    width: 100%;
  }
  .task_detail_drop_btn {
    display: block;
  }
  /* ant date picker */
  .ant-picker-dropdown .ant-picker-panels,
  .ant-picker-dropdown .ant-picker-datetime-panel {
    max-width: 280px;
    display: flex;
    flex-wrap: wrap !important;
  }
  .auto-assignee-profile-name {
    max-width: 100%;
  }
}
.new_custom_filter_div .carret {
  margin-left: 7px;
  width: 10px;
  height: 5px;
  cursor: pointer;
}

.task-detail-header .tableTitle img {
  width: 26px;
  height: 22px;
  cursor: pointer;
}
.task-detail-header .tableTitle img.priority_icon {
  margin-bottom: 4px;
}
.task-detail-header .tableTitle img.priority_icon {
  margin-bottom: 4px;
}


